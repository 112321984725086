import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import Footer from './../components/Footer';
import ModalDialog from './../components/ModalDialog';
import ModalEvent from './../components/ModalEvent';
import NotAllowed from './../components/NotAllowed';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import BigCalendar from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from 'moment/min/moment-with-locales';
import ApiCalendar from 'react-google-calendar-api';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = BigCalendar.momentLocalizer(moment);

window.React = React;

class Calendar extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'calendar',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Agenda', link: 'dashboard/calendar' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      events: [],
      messages: {
        allDay: 'Todo dia',
        previous: '<',
        next: '>',
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Hora',
        event: 'Evento',
        showMore: total => `+ Mostrar mais (${total})`,
      },
      sign: ApiCalendar.sign,
      title: '',
      title_modal: '',
      title_modal_event: '',
      body: '',
      date_start: '',
      date_end: '',
      open: false,
      open_event: false,
      eev: '',
      have_google: false,
      statusDate: true,
    };

    ApiCalendar.onLoad(() => {
      this.setState({ sign: ApiCalendar.sign });
      if (ApiCalendar.sign) {
        this.readGoogleEvents();
      }
      ApiCalendar.listenSign(sign => {
        this.setState({ sign });
        if (sign) {
          this.readGoogleEvents();
        }
      });
    });

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.toggleSwitch = this.toggleSwitch.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCoachees();
    this.loadCalendar();
    window.addEventListener('resize', this.handleResize);
    moment.locale('pt-BR');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCoachees() {
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ rows: result.coachees });
    }
  }

  async loadCalendar() {
    let result = await this.service.calendar();
    if (result && result.success) {
      let events = this.parseEventsDate(result.events);
      this.setState({ events: events });
    }
    if (this.state.have_google) {
      this.readGoogleEvents();
    }
  }

  parseEventsDate(events) {
    for (let _evk in events) {
      events[_evk].end = new Date(events[_evk].end);
      events[_evk].start = new Date(events[_evk].start);
    }
    return events;
  }

  async removeCoachee(id) {
    let result = await this.service.removeCoachee(id);
    if (result && result.success) {
      this.loadCoachees();
    }
  }

  edit(id) {
    this.setSidebarLinks(`coachees/create/${id}`);
  }

  async toggleSwitch(key) {
    let rows = this.state.rows;
    rows[key].status = rows[key].status == 'true' ? 'false' : 'true';
    this.setState({ rows });
    let result = await this.service.updateCoachee(
      { status: rows[key].status },
      rows[key]._id
    );
    if (result && result.success) {
      this.loadCoachees();
    }
  }

  selectEvent(e) {
    this.setState({ title_modal_event: e.title, open_event: e });
  }

  syncGoogleCalendar() {
    if (!this.state.sign) {
      ApiCalendar.handleSignoutClick();
      ApiCalendar.handleAuthClick();
    } else {
      this.readGoogleEvents();
    }
  }

  desyncGoogleCalendar() {
    if (this.state.sign) {
      ApiCalendar.handleSignoutClick();
      this.loadCalendar();
    }
  }

  readGoogleEvents() {
    this.setState({ have_google: true });
    ApiCalendar.listUpcomingEvents(250, 'primary')
      .then(({ result }) => {
        this.parseGoogleEvents(result.items);
      })
      .catch(error => {
        console.error(
          'Error during listUpcomingEvents google Calendar:',
          error
        );
      });
  }

  parseGoogleEvents(_events) {
    let events = this.state.events;
    for (let _ev of _events) {
      let _pay = {
        action: '',
        allDay: false,
        sessionId: '',
        end: this.parseGoogleDate(_ev.end.dateTime),
        responsible: '',
        start: this.parseGoogleDate(_ev.start.dateTime),
        title: _ev.summary,
        _from: 'google',
      };
      if (events.indexOf(_pay) === -1) {
        events.push(_pay);
      }
    }
    this.setState({ events });
  }

  parseGoogleDate(date) {
    if (date && date !== undefined) {
      let _d = date.split('T');
      return new Date(`${_d[0]} ${_d[1].split('-')[0]}`);
    }
  }

  createEvent(event) {
    let date_end = new Date();
    let date_start = new Date();
    this.setState({
      date_end,
      date_start,
      open: true,
      title_modal: 'Novo Evento',
    });
  }

  async save() {
    if (
      this.state.date_start !== undefined &&
      this.state.date_start !== null &&
      this.state.date_start !== '' &&
      this.state.date_end !== undefined &&
      this.state.date_end !== null &&
      this.state.date_end !== ''
    ) {
      await this.setState({ statusDate: true });
    } else {
      await this.setState({ statusDate: false });
    }

    if (this.state.statusDate) {
      let _pay = {
        title: this.state.title,
        end: this.utils.parseDateSave(this.state.date_end),
        start: this.utils.parseDateSave(this.state.date_start),
      };

      let result =
        this.state.eev != ''
          ? await this.service.updateCalendar(_pay, this.state.eev)
          : await this.service.createCalendar(_pay);

      this.loadCalendar();
      this.setState({
        open: false,
        title: '',
        date_start: new Date(),
        date_end: new Date(),
        eev: '',
      });
    }
  }

  action() {
    if (!this.state.open_event._from) {
      this.setState({
        date_end: this.state.open_event.end,
        eev: this.state.open_event._id,
        date_start: this.state.open_event.start,
        open: true,
        title_modal: `Alterar ${this.state.open_event.title}`,
        title: this.state.open_event.title,
      });
    } else {
      if (this.state.open_event._from == 'google') {
        window.open('https://calendar.google.com/');
      } else {
        let _from = this.state.open_event._from;
        // master | meeting
        this.goTo(
          `dashboard/programs/coaching/edit/${this.state.open_event.sessionId}/${_from}`
        );
      }
    }

    this.setState({ open_event: false });
  }

  async remove() {
    await this.service.deleteCalendar(this.state.open_event._id);
    this.setState({ open_event: false });
    this.loadCalendar();
  }

  render() {
    return (
      <div className="main-content zoomresolve">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            {(this.state.user &&
              this.state.user.plans &&
              !this.state.user.plans.platform) ||
            (this.state.user &&
              this.state.user.plans &&
              this.state.user.plans.platform &&
              this.state.user.plans.platform.plan &&
              !this.state.user.plans.platform.plan.schedule_area) ? (
              <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
            ) : (
              <div className="dash-content dash-fixed">
                <div className="dash-crumb-filter">
                  <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                </div>
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100">
                    <div className="content-calendar">
                      <BigCalendar
                        messages={this.state.messages}
                        components={{ toolbar: CalendarToolbar }}
                        localizer={localizer}
                        events={this.state.events}
                        views={['day', 'week', 'month']}
                        defaultView="month"
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={e => {
                          this.selectEvent(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-button" style={{ display: 'flex' }}>
                  {(this.state.user &&
                    this.state.user.plans &&
                    !this.state.user.plans.platform) ||
                  (this.state.user &&
                    this.state.user.plans &&
                    this.state.user.plans.platform &&
                    !this.state.user.plans.platform.plan
                      .use_google_calendar) ? null : !this.state.sign ? (
                    <div
                      className="bt-blue"
                      onClick={() => {
                        this.syncGoogleCalendar();
                      }}
                    >
                      <img
                        src={require('./../../assets/images/buttons/sync.svg')}
                        style={{ width: 20, marginRight: 10 }}
                      />
                      Sincronizar Agenda
                    </div>
                  ) : (
                    <div
                      className="bt-blue grey"
                      onClick={() => {
                        this.desyncGoogleCalendar();
                      }}
                    >
                      <img
                        src={require('./../../assets/images/buttons/sync.svg')}
                        style={{ width: 20, marginRight: 10 }}
                      />
                      Sair da agenda Google
                    </div>
                  )}
                  <div
                    className="bt-blue"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      this.createEvent();
                    }}
                  >
                    <img
                      src={require('./../../assets/images/buttons/add_plus.svg')}
                      style={{ width: 20, marginRight: 10 }}
                    />
                    Criar Evento{' '}
                  </div>
                </div>
                <Footer />
              </div>
            )}
          </div>
        </div>
        <ModalDialog
          close={() => {
            this.setState({ open: false });
          }}
          state={this.state}
          open={this.state.open}
          body={this.state.body}
          title_modal={this.state.title_modal}
          save={this.save.bind(this)}
          title={this.state.title_modal}
          setState={this.setState.bind(this)}
        />

        <ModalEvent
          close={() => {
            this.setState({ open_event: false });
          }}
          state={this.state}
          open={this.state.open_event}
          title={this.state.title_modal_event}
          action={this.action.bind(this)}
          remove={this.remove.bind(this)}
          setState={this.setState.bind(this)}
        />
      </div>
    );
  }
}

export class CalendarToolbar extends Toolbar {
  render() {
    return (
      <div className="calendar-header">
        <div className="rbc-btn-group">
          <button
            type="button"
            className="bt-calendar-green"
            onClick={() => this.navigate('TODAY')}
          >
            Hoje
          </button>
        </div>
        <div className="calendar-center">
          <button
            type="button"
            className="mright-20 bt-calendar-navigate"
            onClick={() => this.navigate('PREV')}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className="rbc-toolbar-label">{this.props.label}</div>
          <button
            type="button"
            className="mleft-20 bt-calendar-navigate"
            onClick={() => this.navigate('NEXT')}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <div className="rbc-btn-group">
          <button
            type="button"
            className={
              this.props.view == 'day'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'day')}
          >
            Dia
          </button>
          <button
            type="button"
            className={
              this.props.view == 'week'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'week')}
          >
            Semana
          </button>
          <button
            type="button"
            className={
              this.props.view == 'month'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'month')}
          >
            Mês
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Calendar)
);
