import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
 
import PropTypes from "prop-types";

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'; 

import { updateAlert } from '../../../actions/SimpleAction'


import ReactLoading from "react-loading";

class ModalExport extends Component {
  //// params required
  /* 
    $ modal_contents 
    + toggleModalContent
  */

  constructor(props, context) {
    super(props);  
    this.state = { 
      loading_options: false,
      periocity:'monthly',
      month:'',
      year:'',
      months:['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      years:[],
    };  
    this.changeMonth = this.changeMonth.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.selectPeriocity = this.selectPeriocity.bind(this)
  }

  componentDidMount(){ 
    this.calculateYears()
  }

  componentWillUnmount() { 
  }

  changeMonth(month){
    this.setState({ month })
  }

  changeYear(year){
    this.setState({ year })
  }

  selectPeriocity(periocity){
    this.setState({ periocity })
  }

  calculateYears(){
    let years = []
    let minYear = 2018
    let atualYear = ((new Date()).getFullYear())
    for(let i = minYear; i <= atualYear; i ++ ){
      years.push(i)
    }
    this.setState({ years })
  }

  exportFile(format){
    if( this.state.periocity != 'monthly'){
        this.props.save( format, this.state.periocity, this.state.month, this.state.year )
    }else{
      if(this.state.month != '' && this.state.year != ''){
        this.props.save( format, this.state.periocity, this.state.month, this.state.year )
      }else{
        this.props.updateAlert("Escolha uma data")
        this.props.toggleModal() 
      } 
    }
       
  }
 
  render() { 

    return (  
         <div className={ this.props.show ? "modal-content active" : "modal-content" }>
          <Col sm={{ size:12 }} md={{ size:6, offset:3 }}>
            <div className="modal-box">
                <div className="modal-row-between">
                  <div className="box-title">Selecione o período</div>
                  <i className="fas fa-times" onClick={ () => { this.props.toggleModal() } }></i>
                </div>


                {/*<div className="top-textarea modal-table-box"> </div>*/}
                  <ul className="list-period">
                    <li onClick={ () => { this.selectPeriocity('monthly') } }
                    className={ this.state.periocity == 'monthly' ? "active" : "" } >Mensal</li>
                    <li onClick={ () => { this.selectPeriocity('quarterly') } }
                    className={ this.state.periocity == 'quarterly' ? "active" : "" } >Trimestral</li>
                    <li onClick={ () => { this.selectPeriocity('semiannual') } }
                    className={ this.state.periocity == 'semiannual' ? "active" : "" } >Semestral</li>
                    <li onClick={ () => { this.selectPeriocity('yearly') } }
                    className={ this.state.periocity == 'yearly' ? "active" : "" } >Anual</li>
                  </ul> 
                
                  <div className="remove-padding"> 
                  { this.state.periocity == 'monthly' ?
                    <Col sm={{ size:12 }} className="col-mobile-center">
                      <Col sm={{ size:6 }}>
                        <div className="form-input">
                          <label className="blue-label">Mês</label>
                          {
                            this.state.loading_month ? 
                            <ReactLoading type="bubbles" color="#B1CD49" />
                            : <select value={ this.state.month } className="blue-input" 
                                   onChange={ (e) => { this.changeMonth(e.target.value) }}>
                              <option value=""> &nbsp; Mês</option>
                              {
                                  this.state.months.map( (item, key) => {
                                      return <option key={ key } value={ item }> &nbsp; { item }</option>
                                  })
                              }
                            </select> 
                          }
                        </div>
                      </Col>
                      <Col sm={{ size:6 }}>
                        <div className="form-input">
                          <label className="blue-label">Ano</label>
                          {
                            this.state.loading_year ? 
                            <ReactLoading type="bubbles" color="#B1CD49" />
                            : <select value={ this.state.year } className="blue-input" 
                                   onChange={ (e) => { this.changeYear(e.target.value) }}>
                              <option value=""> &nbsp; Ano</option>
                              {
                                  this.state.years.map( (item, key) => {
                                      return <option key={ key } value={ item }> &nbsp; { item }</option>
                                  })
                              }
                            </select> 
                          }
                        </div>
                      </Col>
                    </Col> 
                    : 
                    <Col sm={{ size:12 }}>
                      <div className="label-periocity">
                        <img src={ require('./../../../assets/images/reports/micon-0.svg') } width="16" className="image-over"  />
                        { this.state.periocity == 'quarterly' ? "Último trimestre a partir de hoje" : 
                          this.state.periocity == 'semiannual' ? "Último semestre a partir de hoje"  :
                          this.state.periocity == 'yearly' ? "Último ano a partir de hoje" : null }
                      </div>
                    </Col> 
                  }
                  </div>





                <Col sm={{ size:12 }}>
                  <div className="modal-row-between">
                    <div className="box-title">O que deseja fazer?</div> 
                  </div>


                  <ul className="list-export-options">
                    <li onClick={ () => { this.exportFile('save'); } }>
                      <img src={ require('./../../../assets/images/reports/dicon-0.svg') } width="23" className="image-over"  />
                      Salvar em meus conteúdos
                    </li>
                    <li onClick={ () => { this.exportFile('xls'); } }>
                      <img src={ require('./../../../assets/images/reports/dicon-1.svg') } width="23" className="image-over"  />
                      Download .xls
                    </li>
                    <li onClick={ () => { this.exportFile('pdf'); } }>
                      <img src={ require('./../../../assets/images/reports/dicon-2.svg') } width="23" className="image-over"  />
                      Gerar .pdf
                    </li>
                    <li onClick={ () => { this.exportFile('email'); } }>
                      <img src={ require('./../../../assets/images/reports/dicon-3.svg') } width="23" className="image-over"  />
                      Enviar por e-mail
                    </li> 
                  </ul>


                </Col>

                {/*<div className="modal-row-actions">
                  <div className="bt-blue" onClick={ () => { this.props.toggleModal() } }>
                    Adicionar conteúdo
                  </div>
                </div>*/}
            </div>
          </Col>
        </div>
    );
  }
} 
const mapStateToProps = state => ({ 
  image: state.simpleReducer.image
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalExport);