import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 

class NotAllowed extends Component { 

  constructor(props) {
    super(props);  

    this.state = {
      periodo_teste: ""
    }

    this.utils = new Utils(); 
    this.service = new Service(); 
  }

  async componentDidMount(){  
    let result = await this.service.config('periodo_teste');
    this.setState({ periodo_teste: result.config.value })
  }

  componentWillUnmount() {  

  } 
  
  render() {  
    return (  
      <Col md={{ size:6, offset:3 }} >
        <p>&nbsp;</p>


         
        { this.props.user && this.props.user.view_first_access ? 
        <div className={ `dashboard-box box100 ` }>
            <div className="box-row-title">
              <div className="box-title">Caro Coach { this.props.user ? this.props.user.name : '' } { this.props.user ? this.props.user.surname : '' },</div> 
            </div> 
            <div>
                <div className="have-no-access">
                  <p> 
                    Você não possui acesso à está funcionalidade.<br />
                    Gostaria de realizar uma atualização no seu plano e desfrutar funcionalidades adicionais?
                  </p>
                  <p>&nbsp;</p>
                </div>
                <div>
                    <button type="button" className="bt-calendar-green btoff"  
                      onClick={ () => { window.history.back(); } }>seguir como está</button>
                      <button type="button" className="bt-calendar-green" style={{ marginLeft:10 }}
                      onClick={ () => { this.props.go('dashboard/profile/plans') } }>Atualizar plano</button>
                </div> 
            </div>
        </div> : !this.props.user ? null : 
        <div className={ `dashboard-box box100 ` }>
            <div className="box-row-title">
              <div className="box-title">Seja bem vindo a climby.</div> 
            </div> 
            <div>
                <div className="have-no-access">
                  <p>  
                    Aproveite o seu período de teste de { this.state.periodo_teste } (dias da área admin) dias da plataforma. 
                  </p>
                  <p>&nbsp;</p>
                </div>
                <div>
                    <button type="button" className="bt-calendar-green btoff"  
                      onClick={ () => { this.props.go('dashboard/profile') } }>Completar meu perfil</button>
                      <button type="button" className="bt-calendar-green" style={{ marginLeft:10 }}
                      onClick={ () => { this.props.go('dashboard/profile/plans') } }>Escolher um plano</button>
                </div> 
            </div>
        </div>
        }
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotAllowed);