import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../assets/images/logo-facebook.jpg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { updateAlert } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 
 
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


class ModalAlert extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.service = new Service();  

    this.state = { } 
 
  }

  componentDidMount(){  }

  componentWillReceiveProps(nextProps){  }

  componentWillUnmount() {  }  
 

  render() {  
    return (   
          <div>
            {
              this.props.open ? 
              <div className="modal-alert" style={{ zIndex:1000 }}>
                <div className="modal-alert-box">
                  <i className="fas fa-times icon-close" onClick={() => { this.props.close() }}></i>
                    <div className="row-modal-alert">
                      <img src={ logo } width={70} /> 
                      <div> 
                         


                        <div className="modal-alert-text">
                          <div>{ this.props.open.title }</div> 

                          <div style={{ display:'flex', flexDirection:"row" }}> 
                            <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px auto', borderRadius:30 }} onClick={ () => { this.props.action() } }>
                              <img src={ require('./../../assets/images/tables/follow.svg') } style={{ marginRight:10 }} /> 
                              {
                                !this.props.open._from ? 'Alterar' :
                                this.props.open._from == 'google' ? "Ver no Google" : "Ver"
                              } 
                            </div> 

                            {
                                !this.props.open._from ? 

                                  <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px 10px', borderRadius:30 }} onClick={ () => { this.props.remove() } }>
                                    <img src={ require('./../../assets/images/tables/trash.svg') } style={{ marginRight:10 }} /> 
                                    Deletar
                                  </div>

                                 : null
                            } 
                          </div>

                          { this.props.open._from == 'google' ? 
                            <div style={{ float:'right', fontSize:12, marginRight: '-50px', marginBottom: '-20px', marginTop: '10px' }}>
                              de { this.utils.parseDatetime(this.props.open.start) } <br />
                              até { this.utils.parseDatetime(this.props.open.end) }
                            </div> :
                            <div style={{ float:'right', fontSize:12, marginRight: '-50px', marginBottom: '-20px', marginTop: '10px' }}>
                              { this.utils.parseDatetime(this.props.open.start) }
                            </div>
                          }
                        </div> 



                    </div> 
                  </div>
                </div>
              </div> : null
            }
          </div>
    );
  }
}
const mapStateToProps = state => ({  
  alert: state.simpleReducer.alert
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);