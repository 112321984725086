import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { LinkedIn } from 'react-linkedin-login-oauth2';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Col } from 'reactstrap';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import Header from './../../includes/Header';
import './../../styles/css/index.css';
import {
  updateUser,
  updateAlert,
  modalStartTestPeriod,
} from '../../actions/SimpleAction';
import loginPlans from '../../consts/loginPlans';
const qs = require('query-string');

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      password_type: 'password',
      pass_visible: false,
      loading: false,
      loading_facebook: false,
      cardNumber: '',
    };

    this.service = new Service();
    this.utils = new Utils();

    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard');
    }

    const { hash } = qs.parse(this.props.location.search);
    if (hash) {
      this.service.me().then(response => {
        if (
          response &&
          response.status &&
          response.status === 'Success' &&
          response.user &&
          response.user._id === hash
        )
          this.verifySocialLogin();
      });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  async login() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let user = {
        email: this.state.email,
        password: this.state.password,
      };
      let result = await this.service.login(user);
      this.doLogin(result);
    } else {
      alert('Preencha todos os campos');
    }
  }

  async loadCard() {
    let result = await this.service.myCard();
    if (result && result.paymentMethod) {
      this.setState({
        cardNumber: `${result.paymentMethod.data.last_digits}`,
      });
    }
  }

  showExpirationMessage() {
    this.props.updateAlert(
      <div>
        <p>
          O seu período experimental da plataforma acabou. Contrate agora um dos
          planos disponíveis.
        </p>
        <br />
        <div
          className="bt-blue btoff"
          style={{ margin: '0 auto', marginBottom: 10 }}
          onClick={() => {
            this.props.updateAlert('');
          }}
        >
          Seguir como está
        </div>
        <div
          className="bt-blue"
          style={{ margin: '0 auto' }}
          onClick={() => {
            this.goTo('dashboard/profile/plans');
          }}
        >
          Escolher um plano
        </div>
      </div>
    );
  }

  async removeSubscription() {
    let result = await this.service.removeSubscription();
    if (result && result.success) {
      this.loadUser();
    }
    this.props.updateAlert('');
  }

  async showSubscriptionPaymentLateMessage() {
    await this.loadCard();
    const { cardNumber, user } = this.state;
    const { subscription } = user;
    this.props.updateAlert(
      <div>
        <p>
          Identificamos um problema no pagamento da sua mensalidade com o cartão
          cadastrado (Final {cardNumber}). Caso não regularize o pagamento até o
          dia {subscription.expirationDate}, você perderá o acesso às
          funcionalidades do plano.
        </p>
        <br />
        <div
          className="bt-blue btoff"
          style={{ margin: '0 auto', marginBottom: 10 }}
          onClick={() => {
            this.props.updateAlert('');
          }}
        >
          Agora não
        </div>
        <div
          className="bt-blue"
          style={{ margin: '0 auto' }}
          onClick={() => {
            this.goTo('dashboard/profile/creditcard');
          }}
        >
          Cadastrar novo cartão
        </div>
      </div>
    );
  }

  async showSubscriptionExpirationMessage() {
    await this.loadCard();
    const { cardNumber } = this.state;
    this.props.updateAlert(
      <div>
        <p>
          Identificamos um problema no pagamento da sua mensalidade com o cartão
          cadastrado (Final {cardNumber}). O prazo para regularização do
          pagamento expirou. Caso você queira continuar acessando as
          funcionalidades do plano contratado, insira um novo cartão. Caso
          contrário, você pode cancelar a sua assinatura e continuar acessando o
          seu perfil com as funcionalidades do buscador gratuitamente
        </p>
        <br />
        <div
          className="bt-blue"
          style={{ margin: '0 auto' }}
          onClick={() => {
            this.goTo('dashboard/profile/creditcard');
          }}
        >
          Cadastrar novo cartão
        </div>
        <div
          className="bt-blue btoff"
          style={{ margin: '0 auto', marginBottom: 10 }}
          onClick={() => this.removeSubscription()}
        >
          Cancelar assinatura
        </div>
      </div>
    );
  }

  async doLogin(result) {
    if (result && result.success) {
      this.service.setToken(result.token);
      this.utils.setStorage('atoken', 'a');
      this.utils.setStorage('logged_user', result.user);

      await this.loadUser();

      if (!this.state.user.validated_email) {
        alert('Confirme seu email para acessar');
        this.utils.setLogout();
        this.setState({ loading: false });
      } else {
        if (this.state.user.isFirstLoginAccess) {
          this.props.modalStartTestPeriod(true);
          await this.service.firstLoginAccess();
        }

        const isExpired =
          this.state.user.subscription &&
          this.state.user.subscription.isExpired;
        const isPaymentLate =
          this.state.user.subscription &&
          this.state.user.subscription.isPaymentLate;
        if (isExpired) {
          await this.showSubscriptionExpirationMessage();
        } else if (isPaymentLate) {
          await this.showSubscriptionPaymentLateMessage();
        }

        if (
          (this.state.user &&
            this.state.user.plans &&
            this.state.user.plans.platform) ||
          this.state.user.testPeriod
        ) {
          this.props.history.push('/dashboard');
        } else if (
          this.state.user.isFirstLoginAccess &&
          this.state.user.firstLoginPlan === loginPlans.SIGNATURE
        ) {
          this.props.history.push('/dashboard/profile/plans');
        } else {
          const isTestExpired = this.state.user.isTestPeriodStarted;
          const isTestExpiredBySubscription = this.state.user
            .isTestExpiredBySubscription;
          if (
            !isTestExpiredBySubscription &&
            !isExpired &&
            !isPaymentLate &&
            isTestExpired
          ) {
            this.showExpirationMessage();
          }

          this.props.history.push('/dashboard/profile');
        }
      }
    } else {
      this.resolveError(result);
      this.setState({ loading: false });
    }
  }

  formValid() {
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      return true;
    }
    return false;
  }

  resolveError(result) {
    if (result && result.messages && result.messages.length > 0) {
      let messages = '';
      result.messages.map((message, mkey) => {
        messages += `${message}\n`;
      });
      alert(messages);
    } else {
      alert('Tente novamente mais tarde');
    }
    this.setState({ loading: false });
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    await this.setState({ user });
    this.props.updateUser(user);
  }

  verifySocialLogin() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard/profile');
    }
  }

  verifyLogin() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard');
    }
  }

  togglePassword() {
    if (this.state.pass_visible) {
      this.setState({ pass_visible: false });
    } else {
      this.setState({ pass_visible: true });
    }
  }

  responseFacebook = async response => {
    const success = response && response.accessToken;
    this.setState({ loading_facebook: true });
    if (success) {
      let _payload = { access_token: response.accessToken };
      let result = await this.service.loginFacebook(_payload);
      this.doLogin(result);
    }
    this.setState({ loading_facebook: false });
  };

  responseLinkedIn = async response => {
    let _payload = { access_token: response.code };
    let result = await this.service.loginLinkedin(_payload);
    this.doLogin(result);
  };

  render() {
    return (
      <div className="zoom100">
        <Header />
        <Col xs="12" className="mtop30">
          <div className="card-shadow">
            <p className="card-title">login</p>

            <LinkedIn
              clientId={process.env.REACT_APP_LINKEDIN_ID}
              onSuccess={this.responseLinkedIn}
              redirectUri={`${process.env.REACT_APP_URL_SITE}linkedin`}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <div
                  className="bt-linkedin hover-shadow"
                  onClick={onClick}
                  disabled={disabled}
                >
                  <p className="bt-icon">in</p>
                  <p className="bt-label">Login com Linkedin</p>
                  <p className="bt-label-hide">Linkedin</p>
                </div>
              )}
            />

            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_ID}
              callback={this.responseFacebook}
              disableMobileRedirect={true}
              render={renderProps => (
                <div
                  className="bt-facebook hover-shadow"
                  onClick={renderProps.onClick}
                >
                  <p className="bt-icon">f</p>
                  <p className="bt-label">Login com facebook</p>
                  <p className="bt-label-hide">facebook</p>
                </div>
              )}
            />

            <input
              type="email"
              placeholder="EMAIL"
              value={this.state.email}
              className="cool-input"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />

            <input
              type={this.state.pass_visible ? 'text' : 'password'}
              placeholder="SENHA"
              value={this.state.password}
              className="cool-input input-password"
              onChange={e => {
                this.setState({ password: e.target.value });
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.login();
                }
              }}
            />
            <i
              className={
                this.state.pass_visible
                  ? 'far fa-eye-slash icon-password'
                  : 'far fa-eye icon-password'
              }
              onClick={() => {
                this.togglePassword();
              }}
            />

            <div className="termos" style={{ textAlign: 'center' }}>
              <Link to="register" className="policy highlight">
                Cadastre-se
              </Link>
              <span className="agree-terms" style={{ fontWeight: 700 }}>
                {' '}
                ou{' '}
              </span>
              <Link to="forgot" className="policy highlight">
                Recupere sua senha
              </Link>
            </div>
          </div>
          <div className="container-bt-confirm">
            {this.state.loading ? (
              <ReactLoading type="bubbles" color="#B1CD49" />
            ) : (
              <div
                onClick={() => {
                  this.login();
                }}
                className={
                  !this.formValid()
                    ? 'bt-confirm hover-shadow'
                    : 'bt-confirm hover-shadow avaiable'
                }
              >
                <p className="bt-label-o">Entrar</p>
              </div>
            )}
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateUser, updateAlert, modalStartTestPeriod },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
