import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage } from '../../actions/SimpleAction';
import SidebarSessions from '../sidebar/SidebarSessions';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';
import RowStats from './../components/GenericRowStats';
import CardGenericTable from './../components/CardGenericTable';
import NotAllowed from './../components/NotAllowed';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';

class Programs extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      side: 'painel',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      rows: [],
      title_rows: [
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Endereço', key: 'address' },
        { title: 'Nascimento', key: 'birthday' },
        { title: 'Status', key: '_status' },
      ],
      actions: [],
      rows_oportunities: [],
      title_rows_oportunities: [
        { title: 'Data', key: 'date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Título da proposta', key: 'description' },
        { title: 'Origem', key: 'origin' },
      ],
      actions_oportunities: [],
      rows_sessions: [],
      title_rows_sessions: [
        { title: 'Data', key: 'expected_date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Projeto', key: 'project' },
        // { title: "Gratuita", key: 'its_free' }
      ],
      actions_sessions: [],

      rows0: [],
      titles0: [
        { title: 'Coachee', key: '_coachee' },
        { title: 'Data', key: 'date' },
        { title: 'Título', key: 'title' },
        //{ title: "Status", key: 'taks_status' }
      ],

      rows1: [],
      titles1: [
        { title: 'Coachee/Grupo', key: '_coachee' },
        { title: 'Data', key: 'start' },
        { title: 'Assunto', key: 'title' },
      ],

      rows2: [],
      titles2: [
        { title: 'Enviado de', key: '_from' },
        { title: 'Data', key: 'date' },
        { title: 'Enviado a', key: '_to' },
        { title: 'Assunto', key: 'message' },
      ],

      rows3: [],
      titles3: [
        { title: 'Coachee', key: '_coachee' },
        { title: 'Data Limite', key: 'udate' },
        { title: 'Ação', key: 'title' },
      ],

      rows4: [],
      titles4: [
        { title: 'Cliente', key: 'name' },
        { title: 'Status', key: '_status' },
      ],

      rows5: [],
      titles5: [
        { title: 'Nome', key: 'group' },
        { title: 'Tipo de atendimento', key: 'typeService' },
      ],

      sort1: '',
      sort2: '',
      sort3: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCoachees();
    this.loadOportunities();
    this.loadSessions();
    this.loadDash();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    const result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    const user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    if (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    ) {
      this.loadGroups();
    }
    this.setState({ user });
  }

  async loadCoachees() {
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ rows: result.coachees });
    }
  }

  async loadOportunities() {
    let result = await this.service.oportunities();
    if (result && result.success) {
      this.setState({ rows_oportunities: result.oportunities });
    }
  }

  async loadSessions() {
    let result = await this.service.sessions();
    if (result && result.success) {
      let rows_sessions = result.sessions.filter(item => {
        return (
          item.name != '' ||
          item.email != '' ||
          item.phone != '' ||
          item.project != ''
        );
      });
      this.setState({ rows_sessions });
    }
  }

  async loadDash() {
    let result = await this.service.dashboard();
    if (result && result.success) {
      this.setState({
        rows0: result.dash.tasks,
        rows1: result.dash.week,
        rows2: result.dash.messages,
        rows3: result.dash.last,
      });
    }
  }

  async loadGroups() {
    let result = await this.service.groups();
    if (result && result.success) {
      const groups = result.groups.map(item => ({
        ...item,
        group: item.name,
        typeService: this.getTypeService(item),
      }));
      this.setState({ rows5: groups });
    }
  }

  getTypeService = item => (
    <div className="display-flex-end">
      <div
        style={{ width: 85 }}
        className={
          !item.presential
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-5.svg')}
          width="12"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-5b.svg')}
          width="12"
        />
        Presenciais
      </div>
      <div
        style={{ width: 85 }}
        className={
          !item.online
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-6.svg')}
          width="30"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-6b.svg')}
          width="30"
        />
        Online
      </div>
    </div>
  );

  sort1(key) {
    let order =
      this.state.sort1 == '' || this.state.sort1 == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    // console.log(key, this.state.sort)
    this.setState({ sort1: `${key}${order}`, rows });
  }
  sort2(key) {
    let order =
      this.state.sort2 == '' || this.state.sort2 == key ? '-down' : '';

    let rows_oportunities = this.state.rows_oportunities.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    // console.log(key, this.state.sort)
    this.setState({ sort2: `${key}${order}`, rows_oportunities });
  }
  sort3(key) {
    let order =
      this.state.sort3 == '' || this.state.sort3 == key ? '-down' : '';

    let rows_sessions = this.state.rows_sessions.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    // console.log(key, this.state.sort)
    this.setState({ sort3: `${key}${order}`, rows_sessions });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                {/*<PeriodiceFilter filter={ "Semestral" } />*/}
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan.use_program_register) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div>
                  <RowStats
                    user={this.state.user}
                    folder={'dashp'}
                    stats={
                      this.state.user.stats_program
                        ? this.state.user.stats_program
                        : []
                    }
                  />

                  <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-1"
                          title="Coachees"
                          sort={this.sort1.bind(this)}
                          _sort={this.state.sort1}
                          actions={this.state.actions}
                          title_rows={this.state.title_rows}
                          rows={this.state.rows}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-8"
                          className="scrollOp2"
                          title="Grupos"
                          state={this.state}
                          title_rows={this.state.titles5}
                          rows={this.state.rows5}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-2"
                          title="Oportunidades"
                          sort={this.sort2.bind(this)}
                          _sort={this.state.sort2}
                          actions={this.state.actions_oportunities}
                          title_rows={this.state.title_rows_oportunities}
                          rows={this.state.rows_oportunities}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-3"
                          title="Sessão master"
                          sort={this.sort3.bind(this)}
                          _sort={this.state.sort3}
                          actions={this.state.actions_sessions}
                          title_rows={this.state.title_rows_sessions}
                          rows={this.state.rows_sessions}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-7"
                          title="Últimas ações"
                          className="scrollOp"
                          state={this.state}
                          title_rows={this.state.titles3}
                          rows={this.state.rows3}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '5' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-5"
                          title="Agenda da semana"
                          className="scrollOp"
                          state={this.state}
                          title_rows={this.state.titles1}
                          rows={this.state.rows1}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-4"
                          title="CONTEÚDO COMPARTILHADO"
                          className="scrollOp"
                          state={this.state}
                          title_rows={this.state.titles0}
                          rows={this.state.rows0}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '5' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="programs-dashboard-6"
                          title="Mensagens"
                          state={this.state}
                          title_rows={this.state.titles2}
                          rows={this.state.rows2}
                          campoImagem={false}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>
                  </div>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Programs)
);
