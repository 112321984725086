import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 

              <div className="dash-stats-content">
                {
                  this.props.stats.map((item, key) => {
                    return <div key={ key } className="dash-stats">
                            <div className="stats-title">{ item.title }</div>
                            <div className="stats-info">
                              <div>{ item.value ? item.value : 0 }</div>
                              <div><img src={ require(`./../../assets/images/${ this.props.folder }/${ key + 1 }.svg`) } className="stats-image" /></div>
                            </div>
                          </div>
                  })
                } 
              </div>

 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);