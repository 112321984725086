import ApiService from '../../services/ApiService';

class Service extends ApiService {
  async respondProposal({ hash, r, email }) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposals/respond`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload({
          response: r,
          hash,
          email,
        }),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
