import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import DropToUpload from 'react-drop-to-upload';

//libs sistema
import Service from '../Service.js';
import Utils from '../../../libs/Utils.js';

//css
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import './css/UploadCert.css';

class UploadCert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      over: false,
      original_name: '',
      loading: false,
      sleepFile: '',
      validDoc: false,
      withError: false,
      isTooLarge: false,
      isUploadCert: true,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.openUpload = this.openUpload.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    return true;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readFile(file);
    }
  }

  readFile(file) {
    this.setState({ loading: true });
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${reader.result}`.split(',')[1];
      let image = {
        filename: file.name,
        filetype: file.type,
        source,
      };

      this.props.setState({ cert_original_name: file.name });
      let fileExtension = image.filename.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      let thefile = this.prepareFile(image);
      let result = await this.service.postFile(thefile, this.props.notConvert);
      if (result && result.success) {
        if (!this.props.notConvert) {
          if (fileExtension === 'docx') {
            let converted = await this.service.convertFileDocx({
              url: result.source,
            });
            if (converted && converted.success) {
              this.props.setState({ html: converted.html });
            }
          }

          if (fileExtension === 'pdf') {
            let converted = await this.service.convertFilePDF({
              url: result.source,
            });
            if (converted && converted.success) {
              this.props.setState({ html: converted.html });
            }
          }
        }

        this.props.setState({
          cert_source: result.source,
          cert_format: fileExtension,
        });
        this.setState({ loading: false });
      } else {
        if (result && result.status === 413) {
          this.setState({ isTooLarge: true });
        } else {
          this.setState({ withError: true });
        }
        this.setState({ loading: false });
        return;
      }
    };
    reader.readAsDataURL(file);
  }

  prepareFile(image) {
    let fileExtension = image.filename.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    let btc = window.atob(image.source);
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
      btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: image.filetype });
    return new File([boobs], `${image.filename}.${fileExtension}`);
  }

  openUpload() {
    document.getElementById('filecert').click();
  }

  async removeAttachment() {
    const removedCertificates = this.props.removedCertificates;
    removedCertificates.push(this.props.cert_source);
    this.props.setState({
      cert_source: '',
      cert_format: '',
      cert_original_name: '',
      sleepFile: '',
      removedCertificates,
    });
  }

  showUpload() {
    if (this.props.cert_source === '' && this.props.cert_source.length === 0) {
      return true;
    }
    return false;
  }

  onDrop(item) {
    this.readFile(item[0]);
    this.setState({ over: false });
  }

  onOver() {
    this.setState({ over: true });
  }

  onLeave() {
    this.setState({ over: false });
  }

  render() {
    return (
      <DropToUpload
        onDrop={this.onDrop.bind(this)}
        onLeave={this.onLeave.bind(this)}
        onOver={this.onOver.bind(this)}
        className="dropToUpload"
      >
        {this.state.loading ? (
          <div className=".upLoadCert-loading-container">
            <ReactLoading type="spinningBubbles" color="#B1CD49" />
          </div>
        ) : this.state.over ? (
          <div className="bt-blue hei40">
            <div className="uploadFile-content">
              <div>Solte aqui</div>
            </div>
          </div>
        ) : this.showUpload() ? (
          <>
            <div
              className="bt-blue-enviar-certif"
              onClick={() => {
                this.openUpload();
              }}
            >
              <input
                type="file"
                accept=".xlsx, .docx, .pdf, .zip, .png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                onChange={ev => {
                  this.onFileChange(ev);
                }}
                id="filecert"
                style={{ display: 'none' }}
              />
              <div className="uploadFile-certificate">
                <div>
                  <span>Carregar Certificado</span>
                </div>
              </div>
            </div>
            {this.state.validDoc && (
              <div className="upLoadCert-error-container">
                Não pode subir arquivos diferentes dessas extensões:
                .xlsx,.docx,.pdf,.zip,.png, .PNG, .jpg, .JPG, .jpeg, .JPEG
              </div>
            )}

            {this.state.isTooLarge && (
              <div className="upLoadCert-error-container">
                Este arquivo é maior do que o suportado pelo servidor, não será
                possível fazer o upload.
              </div>
            )}

            {this.state.withError && (
              <div className="upLoadCert-error-container">
                Ocorreu um erro ao efetuar o upload.
              </div>
            )}
          </>
        ) : (
          <p className="box-subtitle upLoadCert-name">
            <img
              src={require('./../../../assets/images/contents/icon-0.svg')}
              className="upLoadCert-img"
              width="14"
              alt="icone"
            />
            {this.props.cert_original_name}
            &nbsp;
            <i
              className="fas fa-times fright cpointer"
              onClick={() => {
                this.removeAttachment();
              }}
            ></i>
          </p>
        )}
      </DropToUpload>
    );
  }
}

export default UploadCert;
