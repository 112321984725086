import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

class SidebarContents extends Component {
  updateLayout = _.debounce(() => {
    this.setState({ isLessThen1220: (window.innerWidth < 1220) });
  }, 300);

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    this.updateLayout();
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    this.updateLayout();
    window.removeEventListener('resize', this.updateLayout);
  }

  render() {
    const { isLessThen1220 } = this.state || false;
    if (isLessThen1220 === undefined) {
      this.setState({ isLessThen1220: false });
    }

    return (
      <ul className="side-menu menuLateral">
        <li className="content-pop" onClick={() => { this.props.setSidebarLinks(''); }} >
          <img src={require('./../../assets/images/menu/icon-0.svg')} width="28" width="28" />
          <img src={require('./../../assets/images/menu/icon-0w.svg')} className="image-over" width="28" />
          <span>Dashboard</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">
              Dashboard
              </div>
          </div>
        </li>
        <li className="content-pop"
          onClick={() => { this.props.setSidebarLinks('coachees'); }} >
          <img src={require('./../../assets/images/menu/icon-1.svg')} width="28" />
          <img src={require('./../../assets/images/menu/icon-1w.svg')} className="image-over" width="28" />
          <span>Coachees</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">
              Coachees
              </div>
          </div>
        </li>
        <li onClick={() => { this.props.setSidebarLinks('contents'); }}
          className={this.props.side == "my" ? "active content-pop" : "content-pop"} >
          <img src={require('./../../assets/images/contents/icon-0.svg')} width="28" />
          <img src={require('./../../assets/images/contents/icon-0w.svg')} className="image-over" width="28" />
          <span>Minha Área</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">
              Minha Área
              </div>
          </div>
        </li>
        {this.props.simpleReducer.menu === false && isLessThen1220 === false ?
          <li onClick={() => { this.props.setSidebarLinks('contents/climby'); }}
            className={this.props.side === "climb" ? "active content-pop" : "content-pop"}
            data-tooltip="Conteúdo Climby aqui você pode importar os conteúdos que podem apoiar seu programa de coaching."
          >
            <img src={require('./../../assets/images/contents/icon-1.svg')} width="28" />
            <img src={require('./../../assets/images/contents/icon-1w.svg')} className="image-over" width="28" />
            <span>Conteúdo Climby</span>
          </li>
          :
          <li onClick={() => { this.props.setSidebarLinks('contents/climby'); }}
            className={this.props.side === "climb" ? "active content-pop" : "content-pop"}
            data-tooltip-min="Conteúdo Climby aqui você pode importar os conteúdos que podem apoiar seu programa de coaching."
          >
            <img src={require('./../../assets/images/contents/icon-1.svg')} width="28" />
            <img src={require('./../../assets/images/contents/icon-1w.svg')} className="image-over" width="28" />
            <span>Conteúdo Climby</span>
          </li>
        }
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContents);