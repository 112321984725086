import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 
import { updateAlert } from '../../actions/SimpleAction'
import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 
import "react-datepicker/dist/react-datepicker.css";
import './styeModalCompact.css';

class NewModalCoachee extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.service = new Service();  

    this.state = { }  
  }

  componentDidMount(){ }

  componentWillReceiveProps(nextProps){   }

  componentWillUnmount() {   }   

  render() {  
    return (   
          <div>
            {
              this.props.open ? 
              <div className="modal-alert" style={{ zIndex:1000 }}>
                <div className="modal-alert-box">
                  <i className="fas fa-times icon-close" onClick={() => { this.props.close() }}></i>
                    <div className="row-modal-alert">
                      <img src={ logo } width={70} /> 
                      <div> 
                        <div className="modal-alert-text"> { this.props.title} </div>

                        <div className="modal-alert-text btnComp">
                          <div className="bt-blue" onClick={ () => { this.props.close() } }> 
                            Não
                          </div>
                          <div className="bt-blue" onClick={ () => { this.props.save() } }> 
                            Sim
                          </div>
                        </div>
                    </div> 
                  </div>
                </div>
              </div> : null
            }
          </div>
    );
  }
}
const mapStateToProps = state => ({  
  alert: state.simpleReducer.alert
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewModalCoachee);