import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import { Col } from 'reactstrap';

import {
  updateImage,
  updateAlert,
  updateSession,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarSessions from './../../sidebar/SidebarSessions';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';
import ClimbyEditor from './../../components/Editor';

import Submenu from './../Session/SubmenuSession';

import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class TemplateEditor extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'contents',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteudos', link: 'contents' },
        { title: 'Editor de conteúdos', link: 'template-editor' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      editorState: '',
      content: {
        title: '',
      },
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  async resolveParams() {
    const {
      match: { params },
    } = this.props;

    const { sessionId, meetingType, coacheeId, contentId } = params;

    if (sessionId && meetingType && coacheeId && contentId) {
      this.loadContent(sessionId, meetingType, coacheeId, contentId);
    } else {
      window.history.back();
    }
  }

  async loadContent(sessionId, meetingType, coacheeId, contentId) {
    this.setState({ loading: true });
    const result = await this.service.sessionContent(
      sessionId,
      meetingType,
      coacheeId,
      contentId
    );
    if (result && result.success) {
      this.setState({
        editorState: result.content.html || '',
        content: {
          title: result.content.title || '',
        },
      });
      this.props.updateSession(sessionId);
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      content,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={subview} />

                <div className="dashboard-box box100-menu">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="form-input input-title">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={content.title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>

                    <div className="box-row-title row-center">
                      <div className="box-title">Resposta do Coachee</div>
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <ClimbyEditor
                      setState={this.setState.bind(this)}
                      state={this.state}
                    />
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateEditor)
);
