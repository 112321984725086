import React from 'react';

function Jitsi({
  containerId = 'jitsi-container-id',
  domain = 'meet.jit.si',
  onApiLoad,
  containerStyle = { width: '800px', height: '400px' },
  frameStyle = { height: '100%', width: '100%' },
  loadingComponent,
  options = {
    roomName: undefined,
    // configOverwrite: undefined,
    // interfaceConfigOverwrite: undefined,
    noSSL: undefined,
    // jwt: undefined,
    onload: undefined,
    // invitees: undefined,
    // devices: undefined,
    // userInfo: undefined,
  },
  getUrlVideoSession,
}) {
  const [jitsi, setJitsi] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const Loader = loadingComponent;

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise(resolve => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const https = options && options.noSSL ? 'http' : 'https';
    const script = document.createElement('script');
    script.src = `${https}://${domain}/external_api.js`;
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const jitsiApi = new window.JitsiMeetExternalAPI(domain, {
      parentNode: document.getElementById(containerId),
      ...options,
    });

    if (onApiLoad) {
      onApiLoad(jitsiApi);
    }

    if (getUrlVideoSession) {
      const https = options && options.noSSL ? 'http' : 'https';
      const urlRoom = `${https}://${domain}/${options.roomName}`;
      getUrlVideoSession(urlRoom);
    }

    setJitsi(jitsiApi);
    setIsLoading(false);
  };

  React.useEffect(() => {
    initialiseJitsi();

    return () => {
      if (jitsi && jitsi.dispose) {
        jitsi.dispose();
      }
    };
  }, []);

  return (
    <div style={containerStyle}>
      {isLoading && <Loader />}
      <div
        id={containerId}
        style={
          isLoading ? { display: 'none' } : { display: 'block', ...frameStyle }
        }
      />
    </div>
  );
}

export default Jitsi;
