import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo-facebook.jpg';
import icon7w from '../../assets/images/menu/icon-7.svg';
import notificationSound from '../../assets/sounds/notification.wav';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import {
  updateImage,
  updateMenu,
  updateAlert,
  updateUser,
} from '../../actions/SimpleAction';
import Utils from './../../libs/Utils.js';
import Service from './../../services/ApiService.js';
import ActivityMonitor from './ActivityMonitor';
import NotificationBox from './NotificationBox';
import HelpCenterBox from './HelpCenterBox';
import ModalAlert from './ModalAlert';
import Notification from 'react-web-notification';
import Avatar from 'react-avatar';

class Dashboard extends Component {
  _isMounted = false;

  state = {
    expression: '',
    visible: false,
    helpCenterBoxVisible: false,
    user: {},
    notificatons: [],
    ignore: true,
    title: '',
    loading: true,
    timeInterval: 0,
  };
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.service = new Service();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.search = this.search.bind(this);
    this.goTo = this.goTo.bind(this);
  }
  timeInterval = 1;
  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.timerID = setInterval(() => this.tick(), 1000);
    this.loadUser();
  }

  /*
  Autor: Natanael Diego
  Data: 13/05/2020
  Hora: 22:30
  Funcao: Responsavel por gerenciar o temporizador que verifica a inatividade
  do usuario
  */
  tick() {
    if (this._isMounted) {
      this.setState({
        timeInterval: this.timeInterval++,
      });
      const timeInSeconds = 3 * 60 * 60; //3h
      if (this.state.timeInterval > timeInSeconds) {
        clearInterval(this.timerID);
        this.utils.setLogout();
        this.goTo('');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this._isMounted) {
      this.setState({
        expression: nextProps.expression,
      });
    }
  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
    clearInterval(this.timerID);
    this.props.updateAlert('');
  }

  toggleMenu() {
    this.props.updateMenu(!this.props.menu);
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  search() {
    if (!this.props.expression) {
      if (this.props.search) {
        setTimeout(() => {
          this.props.search();
        }, 100);
      }
      this.props.goTo(`dashboard/search/${this.state.expression}`);
    }
  }

  toggleNotifications() {
    this.setState({
      helpCenterBoxVisible: false,
      visible: !this.state.visible,
    });
  }

  toggleHelpCenterBox() {
    this.setState({
      visible: false,
      helpCenterBoxVisible: !this.state.helpCenterBoxVisible,
    });
  }

  userHasPlataformPlan(user) {
    return user && user.plans && user.plans.platform;
  }

  async loadUser(init = true) {
    if (this._isMounted) {
      this.setState({ loading: true });
      let result = await this.service.me();
      if (result) {
        this.setState({ user: result.user });
        this.props.updateUser(result.user);
      }

      let _result = await this.service.notificationMe();
      if (_result && _result.success) {
        this.setState({ notificatons: _result.notificatons });
      }
      if (result && init) this.initSocket(result.user);
      this.setState({ loading: false });
    }
  }

  initSocket(user) {
    const socketParams = {
      channel: `${user._id}`,
      type: 'cnotification',
    };

    this.setState({
      timeInterval: 0,
    });

    this.socket = this.service.createSocket(
      this.service.parsePayload(socketParams)
    );

    this.socket.off(`${socketParams.type}.message.${socketParams.channel}`);
    this.socket.off(`broadcast`);
    setTimeout(() => {
      this.socket.on(
        `${socketParams.type}.message.${socketParams.channel}`,
        message => {
          console.log('Socket', message);
          this.showNotification(message.text);
          this.loadUser(false);
        }
      );
      this.socket.on(`broadcast`, broad => {
        if (broad.all_coachs) {
          this.showNotification(broad.text);
          this.loadUser(false);
        }
      });
    }, 1000);
  }

  async open(item) {
    let result = await this.service.notificationSee(item._id);
    if (item.action == 'internal_link') {
      this.props.history.push(`/dashboard/${item.content}`);
    } else {
      window.open(item.content, '_new');
    }
    this.loadUser();
  }

  async closeNotification(item) {
    let result = await this.service.notificationSee(item._id);
    this.loadUser();
  }

  handlePermissionGranted() {
    console.log('Permission Granted');
    this.setState({
      ignore: false,
    });
  }
  handlePermissionDenied() {
    console.log('Permission Denied');
    if (this._isMounted) {
      this.setState({
        ignore: true,
      });
    }
  }
  handleNotSupported() {
    console.log('Web Notification not Supported');
    if (this._isMounted) {
      this.setState({
        ignore: true,
      });
    }
  }

  handleNotificationOnClick(e, tag) {
    console.log(e, 'Notification clicked tag:' + tag);
  }

  handleNotificationOnError(e, tag) {
    console.log(e, 'Notification error tag:' + tag);
  }

  handleNotificationOnClose(e, tag) {
    console.log(e, 'Notification closed tag:' + tag);
  }

  handleNotificationOnShow(e, tag) {
    this.playSound();
    console.log(e, 'Notification shown tag:' + tag);
  }

  playSound() {
    let effect = new Audio(notificationSound);
    setTimeout(() => {
      try {
        effect.play();
      } catch (ee) {}
    }, 1);
  }

  showNotification(text) {
    if (this.state.ignore) {
      return;
    }

    const now = Date.now();

    const title = 'Climby';
    const body = text;
    const tag = now;
    // const icon = 'https://georgeosddev.github.io/react-web-notification/example/Notifications_button_24.png';
    // const icon = 'https://localhost:3000/Notifications_button_24.png';

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: logo,
      lang: 'pt',
      dir: 'ltr',
      // sound: './sound.mp3'
    };
    this.setState({
      title: title,
      options: options,
    });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  render() {
    return (
      <ActivityMonitor userId={this.state.user && this.state.user._id}>
        <div
          className="header-climb menuClimby"
          style={this.props.style ? this.props.style : {}}
        >
          <div
            className={this.props.menu ? 'header-logo-closed' : 'header-logo'}
          >
            {this.props.menu ? (
              <img
                src={require('./../../assets/images/header-logo-close.png')}
                className="header-web-container"
                alt="logo"
              />
            ) : (
              <img
                src={require('./../../assets/images/header-logo-open.png')}
                className="header-web-container"
                alt="logo"
              />
            )}
            <img
              src={require('./../../assets/images/header-logo-close.png')}
              className="header-mobile-container"
              alt="logo"
            />
          </div>
          <div
            className={
              this.props.menu ? 'header-content-closed' : 'header-content'
            }
          >
            <div className="hcenter header-web-container">
              {this.props.menu ? (
                <img
                  src={require('./../../assets/images/header-chevron-close.png')}
                  onClick={() => {
                    this.toggleMenu();
                  }}
                  alt="chevron-close"
                />
              ) : (
                <img
                  src={require('./../../assets/images/header-chevron-open.png')}
                  onClick={() => {
                    this.toggleMenu();
                  }}
                  alt="chevron-open"
                />
              )}
              <span className="header-title">
                {this.state.user &&
                !this.state.user.view_first_access &&
                !this.state.loading
                  ? 'Seja bem-vindo'
                  : ''}
                {/* Dashboard CLIMBY */}
              </span>
            </div>
            <div className="hcenter header-web-minor">
              <input
                type="text"
                placeholder="Pesquisar"
                className="header-search"
                value={
                  this.props.expression
                    ? this.props.expression
                    : this.state.expression || ''
                }
                onChange={e => {
                  this.props.updateSearch
                    ? this.props.updateSearch(e.target.value)
                    : this.setState({ expression: e.target.value });
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.search();
                  }
                }}
              />
              <i
                className="fas fa-search header-search-icon"
                onClick={() => this.search()}
              />
            </div>
            <div className="hcenter header-user-content">
              <div
                className={`header-help ${
                  this.props.menu ? 'help-close' : 'help-open'
                }`}
                onClick={() => this.toggleHelpCenterBox()}
              >
                <img src={icon7w} />
              </div>
              {this.state.notificatons.length > 0 ? (
                <div className="notification-badge">
                  {this.state.notificatons.length}
                </div>
              ) : null}
              <i
                className="far fa-bell header-bell-icon"
                onClick={() => this.toggleNotifications()}
              />
              {this.props.tutorial_status &&
                this.props.tutorial_posicao >= 3 && (
                  <span className="modalTutorial">1</span>
                )}
              {!this.props.image ? (
                <Avatar
                  name={this.utils.resolveCompleteName(
                    this.props.user.name,
                    this.props.user.surname
                  )}
                  size="40"
                  round={true}
                  fgColor="#FFFFFF"
                  onClick={() => this.props.goTo('dashboard/profile')}
                  style={{ cursor: 'pointer' }}
                  maxInitials={2}
                  color={Avatar.getRandomColor(
                    this.utils.resolveCompleteName(
                      this.props.user.name,
                      this.props.user.surname
                    ),
                    ['#a6ce39', '#2c8440', '#303440', '#a4cc3c', '#043448']
                  )}
                />
              ) : (
                <div
                  onClick={() => this.props.goTo('dashboard/profile')}
                  className="header-user"
                  style={{
                    background: `url(${
                      !this.props.image
                        ? require('./../../assets/images/header-user.png')
                        : this.resolveImage(this.props.image)
                    }) center center / cover`,
                  }}
                />
              )}
              {this.state.visible ? (
                <NotificationBox
                  open={this.open.bind(this)}
                  closeNotification={this.closeNotification.bind(this)}
                  notifications={this.state.notificatons}
                />
              ) : null}
              {this.state.helpCenterBoxVisible ? <HelpCenterBox /> : null}
            </div>
          </div>
          <ModalAlert />
          <Notification
            ignore={this.state.ignore && this.state.title !== ''}
            notSupported={this.handleNotSupported.bind(this)}
            onPermissionGranted={this.handlePermissionGranted.bind(this)}
            onPermissionDenied={this.handlePermissionDenied.bind(this)}
            onShow={this.handleNotificationOnShow.bind(this)}
            onClick={this.handleNotificationOnClick.bind(this)}
            onClose={this.handleNotificationOnClose.bind(this)}
            onError={this.handleNotificationOnError.bind(this)}
            timeout={5000}
            title={this.state.title}
            options={this.state.options}
          />
        </div>
      </ActivityMonitor>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  user: state.simpleReducer.user,
  tutorial_status: state.simpleReducer.tutorial_status,
  tutorial_posicao: state.simpleReducer.tutorial_posicao,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateMenu,
      updateAlert,
      updateUser,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
