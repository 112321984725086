import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ReadMoreReact from 'read-more-react';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import {
  updateImage,
  updateSession,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarSessions from './../../sidebar/SidebarSessions';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import ClimbyEditor from './../../components/Editor';
import NotAllowed from './../../components/NotAllowed';
import Submenu from './SubmenuSession';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import NewModalCoacheeDelete from './../../components/NewModalCoacheeDelete';

class RelatoryEditor extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'relatory',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Editor de relatório', link: 'programs/coaching/relatory' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_groups: false,
      groups: [],

      loading_contents: false,
      sessionContents: [],
      contentsRelList: [],
      sessionActions: [],
      opened_toggle: '',

      categories: [],

      title: '',
      category: 'Relatório Coaching',
      tag: '',
      editorState: '',
      contentState: {},
      contents: [],
      selectedContent: undefined,

      maxLength: 3660,
      session_master: [],
      height: 20,

      modal_content_delete: false,

      activeSession: undefined,
      activeCoacheeId: '',
    };

    this.utils = new Utils();
    this.service = new Service();

    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.save = this.save.bind(this);

    this.removeContent = this.removeContent.bind(this);
  }

  toggleContentsContent = content => {
    const { contents } = this.state;
    const find = this.contentIndex(content);
    if (find === -1) {
      contents.push(content);
    } else {
      contents.splice(find, 1);
    }
    this.setState({ contents });
  };

  contentIndex = content => {
    const { contents } = this.state;
    return contents.findIndex(
      c => c._id === content._id && c.meeting === content.meeting
    );
  };

  toggleContentsAction = action => {
    const { contents } = this.state;
    const find = this.actionIndex(action);
    if (find === -1) {
      contents.push(action);
    } else {
      contents.splice(find, 1);
    }
    this.setState({ contents });
  };

  actionIndex = action => {
    const { contents } = this.state;
    return contents.findIndex(
      a => a.meeting === action.meeting && a.action === action.action
    );
  };

  toggleContentsSessionInfo(sessionInfo) {
    const { contents } = this.state;
    const find = this.sessionInfoIndex(sessionInfo);
    if (find === -1) {
      contents.push(sessionInfo);
    } else {
      contents.splice(find, 1);
    }
    this.setState({ contents });
  }

  sessionInfoIndex(sessionInfo) {
    const { contents } = this.state;
    return contents.findIndex(si => si.title === sessionInfo.title);
  }

  removeContent(item) {
    let contents = this.state.contents;
    let find = contents.indexOf(item);
    contents.splice(find, 1);
    this.setState({ contents });
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadSession(),
        this.resolveParams(),
        this.loadCoachees(),
        this.loadCategory(),
      ]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Editor de relatório', link: 'programs/coaching/relatory' },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { activeSession } = this.state;
    if (!activeSession) return;

    if (activeSession.coachee_id)
      return this.getCoacheeName(activeSession.coachee_id);
    if (activeSession.group_id)
      return this.getGroupName(activeSession.group_id);
  };

  mapApiSessionToState = result => {
    const { session } = result;
    this.setState(
      {
        activeSession: session,
        activeCoacheeId: session.coachee_id || '',
      },
      () => {
        this.loadContentsRel().then(() => {
          const report = this.editedReport();
          if (report) {
            this.setState({ selectedContent: report });
            this.loadRelatory(report._id);
          }
        });
        this.loadSessionContents();
        this.loadSessionActions();
        this.loadAttatchs();
      }
    );
  };

  loadSession = async () => {
    const { sessionId } = this.props;
    if (!sessionId) {
      this.goTo('dashboard/programs/coaching');
      return;
    }

    this.setState({ loading: true });
    const result = await this.service.sessionS(sessionId);
    if (result && result.success && result.session) {
      if (result.session.its_free) {
        this.goTo('dashboard/programs/master');
        return;
      }

      this.mapApiSessionToState(result);
    }
    this.setState({ loading: false });
  };

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.changeContent(params.id);
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadSessionContents() {
    const { sessionId } = this.props;
    this.setState({ loading_contents: true });
    let result = await this.service.sessionContents(sessionId);
    if (result && result.success) {
      this.setState({ sessionContents: result.contents });
    }
    this.setState({ loading_contents: false });
  }

  async loadSessionActions() {
    const { sessionId } = this.props;
    this.setState({ loading: true });
    let result = await this.service.sessionActions(sessionId);
    if (result && result.success) {
      this.setState({ sessionActions: result.actions });
    }
    this.setState({ loading: false });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadContentsRel() {
    const { sessionId } = this.props;
    if (!sessionId) return;

    this.setState({ loading_contents: true });
    let result = await this.service.contentsRel();
    if (result && result.success) {
      this.setState({
        contentsRelList: result.contents,
      });
    }
    this.setState({ loading_contents: false });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  /*
  Alterado por: Natanael Diego
  Data: 13/04/2020
  Hora: 18:38
  Funcao: Alterado para identificar update e save.
  */
  async save() {
    const {
      title,
      category,
      tag,
      editorState,
      activeSession: session,
      contents,
      activeCoacheeId,
    } = this.state;

    if (!activeCoacheeId) return;

    this.setState({ loading: true });

    const report = this.editedReport();

    let _pay = {
      _id: (report && report._id) || '',
      title: title,
      category: category,
      tag,
      personal: true,
      html: editorState,
      doc_format: session.coachee_id || session.group_id ? 'report' : 'REL',
      subtype: 'coaching',
      contents: JSON.stringify(contents),
      status: true,
      coachee_id: activeCoacheeId,
    };

    if ((session.coachee_id || session.group_id) && session._id) {
      _pay['session_id'] = session._id;
    }

    let result;
    if (_pay._id) {
      result = await this.service.saveContentUpdate(_pay);
    } else {
      result = await this.service.saveContent(_pay);
    }

    if (result && result.success) {
      this.props.updateAlert('Salvo com sucesso');

      this.loadContentsRel();
      this.changeContent(result.inserted_id);
    }
    this.setState({ loading: false });
  }

  async loadRelatory(rel_id) {
    this.setState({ loading: true });
    let result = await this.service.content(rel_id);
    if (result && result.success && result.content) {
      const editorState =
        result.content.html !== null && result.content.html !== ''
          ? result.content.html
          : result.content.doc_source;

      this.setState({
        title: result.content.title,
        tag: result.content.tag,
        editorState,
        selectedContent: result.content,
        contents: result.content.contents,
      });

      if (result.content.doc_format === 'report') {
        this.setState({ activeCoacheeId: result.content.shared_with });
      }
    } else {
      this.setState({
        editorState: '',
        contents: [],
        title: '',
        tag: '',
      });
    }
    this.setState({ loading: false });
  }

  async loadCategory() {
    this.setState({ loading_category: true });
    let result = await this.service.categories();
    if (result && result.success) {
      this.setState({ categories: result.categories });
    }
    this.setState({ loading_category: false });
  }

  changeContent(contentId) {
    this.loadRelatory(contentId);
  }

  selectCategory(category) {
    this.setState({ category });
  }

  loadAttatchs() {
    const { activeSession: session } = this.state;
    let session_master = [];

    if (
      session &&
      session.coachee_objectives &&
      session.coachee_objectives.length > 0
    ) {
      session_master.push({
        title: `Objetivo do ${session.its_group ? 'Grupo' : 'Coachee'}`,
        text: session.coachee_objectives,
      });
    }
    if (
      session &&
      session.success_evidence &&
      session.success_evidence.length > 0
    ) {
      session_master.push({
        title: 'Evidência de Sucesso',
        text: session.success_evidence,
      });
    }
    if (session && session.motivators && session.motivators.length > 0) {
      session_master.push({
        title: 'Motivadores',
        text: session.motivators,
      });
    }
    if (session && session.saboteurs && session.saboteurs.length > 0) {
      session_master.push({
        title: 'Sabotadores',
        text: session.saboteurs,
      });
    }
    if (session && session.values && session.values.length > 0) {
      session_master.push({
        title: 'Valores',
        text: session.values,
      });
    }
    if (session && session.strategies && session.strategies.length > 0) {
      session_master.push({
        title: 'Estratégias',
        text: session.strategies,
      });
    }
    if (
      session &&
      session.necessary_resources &&
      session.necessary_resources.length > 0
    ) {
      session_master.push({
        title: 'Recursos necessários',
        text: session.necessary_resources,
      });
    }

    this.setState({ session_master });
  }

  openPDF() {
    const report = this.editedReport();
    if (report) {
      window.open(`${this.service.getServer()}generate/session/${report._id}`);
    }
  }

  async share() {
    const report = this.editedReport();

    if (report) {
      const {
        title,
        category,
        tag,
        editorState: html,
        contents,
        activeCoacheeId,
      } = this.state;
      const sharedReport = {
        title,
        category,
        tag,
        html,
        contents: contents,
        content_id: report._id,
        coachee_id: activeCoacheeId,
      };

      const result = await this.service.shareReport(sharedReport);
      if (result && result.success) {
        this.completeShare(report._id);
      }
    }
  }

  verifyCreateCoacheeSession(coachee_id) {
    return this.service.verifyCoachee({ coachee_id });
  }

  verifyCreateGroupSession(group_id) {
    return this.service.verifyGroup({ group_id });
  }

  async completeShare(reportId) {
    if (reportId) {
      const { activeSession, activeCoacheeId } = this.state;
      let session;
      if (activeSession.its_group) {
        session = await this.verifyCreateGroupSession(activeSession.group_id);
      } else {
        session = await this.verifyCreateCoacheeSession(
          activeSession.coachee_id
        );
      }

      const { _id: sessionId } = session;
      const result = await this.service.storeSessionContents(
        sessionId,
        'session',
        [activeCoacheeId],
        [reportId],
        true
      );

      if (result && result.success) {
        await this.props.updateSession(sessionId);
        this.props.updateAlert('Compartilhado com sucesso');
      }
    }
  }

  async removeConteudo() {
    this.setState({
      modal_content_delete: true,
    });
  }

  deleteContent = async () => {
    if (!this.canDeleteContent(this.state.selectedContent)) return;
    const { success } = await this.service.removeContent(
      this.state.selectedContent._id
    );
    if (success) {
      this.setState({
        title: '',
        tag: '',
        editorState: '',
        modal_content_delete: false,
      });
      this.loadContentsRel();
    }
  };

  getGroupCoachees = () => {
    const { activeSession, groups } = this.state;
    const group =
      activeSession.group_id &&
      groups.find(group => group._id === activeSession.group_id);
    return group ? group.coachees : [];
  };

  changeCoachee = coacheeId => {
    if (!coacheeId) return;
    this.setState({ activeCoacheeId: coacheeId }, () => {
      const report = this.editedReport();
      if (report) {
        this.setState({ selectedContent: report });
        this.loadRelatory(report._id);
      } else {
        this.setState({
          editorState: '',
          contents: [],
          title: '',
          tag: '',
        });
      }
    });
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasRights = user => {
    const { activeSession } = this.state;
    return (
      !activeSession.its_group ||
      (activeSession.its_group && this.userHasGroupSessionRight(user))
    );
  };

  canDeleteContent = content => {
    return content && content.doc_format === 'report';
  };

  editedReport = () => {
    const { activeSession, activeCoacheeId, contentsRelList } = this.state;
    return (
      contentsRelList &&
      activeSession &&
      activeCoacheeId &&
      contentsRelList.find(
        content =>
          content.doc_format === 'report' &&
          content.session_id === activeSession._id &&
          content.shared_with === activeCoacheeId
      )
    );
  };

  isEditing = () => !!this.editedReport();

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />
                {this.state.isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : this.state.activeSession &&
                  !this.userHasRights(this.state.user) ? (
                  <NotAllowed
                    go={this.goTo.bind(this)}
                    user={this.state.user}
                  />
                ) : this.state.loading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="dashboard-box box100-menu">
                    <div className="box-row-title row-center">
                      <div className="box-title">Editor de relatório</div>
                    </div>
                    <Col
                      md={{ size: 4, offset: 8 }}
                      className="row-info-project"
                    >
                      <div>
                        {this.state.activeSession &&
                          (this.state.activeSession.coachee_id ? (
                            <div className="form-input input-title">
                              <label className="blue-label">Coachee</label>
                              {this.state.loading ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                <select
                                  disabled={true}
                                  value={this.state.activeSession.coachee_id}
                                  className="blue-input caret-none"
                                >
                                  <option value=""> &nbsp; Coachee</option>
                                  {this.state.coachees.map((item, key) => {
                                    return (
                                      <option key={key} value={item._id}>
                                        {' '}
                                        &nbsp; {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            </div>
                          ) : this.state.activeSession.group_id ? (
                            <div className="form-input input-title">
                              <label className="blue-label">Grupo</label>
                              {this.state.loading ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                <select
                                  disabled={true}
                                  value={this.state.activeSession.group_id}
                                  className="blue-input caret-none"
                                >
                                  <option value=""> &nbsp; Grupo</option>
                                  {this.state.groups.map(group => {
                                    return (
                                      <option key={group._id} value={group._id}>
                                        {' '}
                                        &nbsp; {group.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            </div>
                          ) : (
                            ''
                          ))}
                      </div>
                      <div>
                        {this.state.activeSession ? (
                          <div className="form-input">
                            <label className="blue-label">Projeto</label>
                            <input
                              disabled={true}
                              type="text"
                              value={this.state.activeSession.project}
                              className="blue-input"
                              placeholder="Projeto"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                      <Col xs={{ size: 12 }} sm={{ size: 6, offset: 3 }}>
                        {this.state.loading ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          this.state.activeSession &&
                          this.state.activeSession.its_group && (
                            <div className="form-input">
                              <label className="blue-label">Coachee</label>
                              <select
                                value={this.state.activeCoacheeId}
                                className="blue-input"
                                onChange={e => {
                                  this.changeCoachee(e.target.value);
                                }}
                              >
                                <option value=""> &nbsp; Coachee</option>
                                {this.getGroupCoachees().map((coachee, key) => {
                                  return (
                                    <option key={key} value={coachee._id}>
                                      {' '}
                                      &nbsp; {coachee.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          )
                        )}
                        <div className="form-input">
                          <label className="blue-label">
                            Selecionar relatório de meus conteúdos
                          </label>
                          {this.state.loading_contents ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={
                                this.state.selectedContent
                                  ? this.state.selectedContent._id
                                  : ''
                              }
                              className="blue-input"
                              onChange={e => {
                                this.changeContent(e.target.value);
                              }}
                            >
                              <option value="">
                                {' '}
                                &nbsp; Selecionar relatório de meus conteúdos
                              </option>
                              {this.state.contentsRelList
                                .filter(
                                  content =>
                                    (content.doc_format === 'report' &&
                                      this.state.activeSession &&
                                      content.session_id ===
                                        this.state.activeSession._id &&
                                      content.shared_with ===
                                        this.state.activeCoacheeId) ||
                                    content.doc_format === 'REL'
                                )
                                .map((item, key) => {
                                  return (
                                    <option key={key} value={item._id}>
                                      {' '}
                                      &nbsp; {item.title}
                                    </option>
                                  );
                                })}
                            </select>
                          )}
                        </div>

                        <div className="form-input">
                          <label className="blue-label">Título</label>
                          <input
                            type="text"
                            value={this.state.title}
                            className="blue-input"
                            placeholder="Título"
                            onChange={e => {
                              this.setState({ title: e.target.value });
                            }}
                          />
                        </div>

                        <div className="form-input">
                          <label className="blue-label">Modelo</label>
                          <input
                            type="text"
                            value={this.state.tag}
                            className="blue-input"
                            placeholder="Modelo"
                            onChange={e => {
                              this.setState({ tag: e.target.value });
                            }}
                          />
                        </div>
                      </Col>
                      {this.isEditing() &&
                        this.canDeleteContent(this.state.selectedContent) && (
                          <Col
                            xs={{ size: 12 }}
                            sm={{ size: 12 }}
                            md={{ size: 3 }}
                            style={{
                              height: 45,
                              alignItems: 'end',
                              display: 'grid',
                            }}
                          >
                            <p
                              title="Excluir"
                              style={
                                this.props.uploadCert ? { marginTop: 18 } : {}
                              }
                              className="box-subtitle"
                            >
                              <i
                                className="fas fa-times cpointer"
                                onClick={() => {
                                  this.removeConteudo();
                                }}
                              ></i>
                            </p>
                          </Col>
                        )}
                    </Col>

                    <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 9 }}>
                      <ClimbyEditor
                        setState={this.setState.bind(this)}
                        state={this.state}
                      />

                      <div
                        className="row-between mtop-50 mbottom-50"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div
                          className={`bt-blue ${
                            this.isEditing() ? '00838F' : 'grey'
                          }`}
                          onClick={() => {
                            if (this.isEditing()) {
                              this.openPDF();
                            }
                          }}
                        >
                          Imprimir
                        </div>
                        <div
                          className={`bt-blue ${
                            this.isEditing() ? '' : 'grey'
                          }`}
                          onClick={() => {
                            this.share();
                          }}
                        >
                          Compartilhar
                        </div>
                        {this.state.loading ? (
                          <ReactLoading type="bubbles" color="#00838F" />
                        ) : (
                          <div
                            className={`bt-blue ${
                              this.state.activeCoacheeId ? '' : 'grey'
                            }`}
                            onClick={() => {
                              this.save();
                            }}
                          >
                            Salvar
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 3 }}>
                      <p className="title-attachment" style={{ marginTop: 0 }}>
                        Anexos
                      </p>

                      {[
                        {
                          title: 'Sessão master',
                          key: 'session',
                          array: 'session_master',
                        },
                        {
                          title: 'Ações',
                          key: 'actions',
                          array: 'sessionActions',
                        },
                        {
                          title: 'Conteúdos',
                          key: 'contents',
                          array: 'sessionContents',
                        },
                      ].map((__item, __key) => {
                        return (
                          <div key={__key} className="relatory-attachment">
                            <div
                              className="subtitle-attachment"
                              onClick={() => {
                                this.setState({
                                  opened_toggle:
                                    this.state.opened_toggle == __item.key
                                      ? ''
                                      : __item.key,
                                });
                              }}
                            >
                              {__item.title}
                              <i
                                style={{ marginLeft: 15, fontSize: 10 }}
                                className="fas fa-chevron-down"
                              ></i>
                            </div>

                            <div
                              style={{
                                transition: 'all 1s ease',
                                overflow: 'hidden',
                                height:
                                  this.state.opened_toggle == __item.key
                                    ? 'auto'
                                    : 0,
                                marginBottom:
                                  this.state.opened_toggle == __item.key
                                    ? 10
                                    : 0,
                              }}
                            >
                              {this.state.loading_contents ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                this.state[__item.array] &&
                                (['sessionActions', 'sessionContents'].includes(
                                  __item.array
                                )
                                  ? this.state[__item.array].filter(
                                      item =>
                                        item.coachee_id ===
                                        this.state.activeCoacheeId
                                    )
                                  : this.state[__item.array]
                                ).map((item, key) => {
                                  return (
                                    <label
                                      key={key}
                                      className="container-ckb attachment"
                                      style={{
                                        height: '100%',
                                        minWidth: '100%',
                                        float: 'left',
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        checked={
                                          __item.key == 'contents'
                                            ? this.contentIndex(item) !== -1
                                            : __item.key == 'actions'
                                            ? this.actionIndex(item) !== -1
                                            : __item.key == 'session'
                                            ? this.sessionInfoIndex(item) !== -1
                                            : false
                                        }
                                        onChange={ev => {
                                          if (__item.key == 'contents') {
                                            this.toggleContentsContent(item);
                                          }
                                          if (__item.key == 'actions') {
                                            this.toggleContentsAction(item);
                                          }
                                          if (__item.key == 'session') {
                                            this.toggleContentsSessionInfo(
                                              item
                                            );
                                          }
                                        }}
                                      />
                                      <span
                                        className="chklbl word-break"
                                        style={{ float: 'left' }}
                                      >
                                        <ReadMoreReact
                                          text={
                                            __item.key === 'contents'
                                              ? item.title
                                              : __item.key === 'actions'
                                              ? item.description
                                              : __item.key === 'session'
                                              ? item.title
                                              : ''
                                          }
                                          min={50}
                                          ideal={50}
                                          max={100}
                                          readMoreText={
                                            <p className="readmoretext-attachment">
                                              ...continue lendo
                                            </p>
                                          }
                                        />
                                      </span>
                                      <span className="checkmark attachment"></span>
                                    </label>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ modal_content_delete: false });
          }}
          state={this.state}
          open={this.state.modal_content_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RelatoryEditor)
);
