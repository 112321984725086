import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Col } from 'reactstrap';
import CurrencyInput from 'react-currency-input';
import ReactTooltip from 'react-tooltip';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { updateImage } from '../../../actions/SimpleAction';
import SidebarProposes from './../../sidebar/SidebarProposes';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import ClimbyEditor from './../../components/Editor';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class ProposalCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'generate',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Gerar Proposta', link: 'proposals/proposal/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      contents: [],

      editorState: '',
      contentState: {},
      title: '',
      value: '',
      tag: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.proposal(_id);
    if (result && result.success) {
      let _text =
        result.proposal && result.proposal.text ? result.proposal.text : '';

      const editorState = _text;

      if (result.proposal)
        this.setState({
          value: result.proposal.value,
          title: result.proposal.title,
          tag: result.proposal.tag,
          status: result.proposal.status,
          origin: result.proposal.origin,
          oportunity_id: result.proposal.oportunity_id,
          editorState,
        });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async save(emit) {
    this.setState({ loading: true });
    let _pay = {
      text: this.state.editorState,
      value: this.state.value != undefined ? this.state.value : 'R$ 0,00',
      title: this.state.title != undefined ? this.state.title : '',
      tag: this.state.tag != undefined ? this.state.tag : '',
      origin:
        this.state.origin != undefined
          ? this.state.origin
          : 'climby.com/dashboard',
      status: this.state.editing_id && emit ? 'emitted' : 'opened',
      oportunity_id:
        this.state.oportunity_id != undefined ? this.state.oportunity_id : '',
    };
    let result = this.state.editing
      ? await this.service.updateProposal(_pay, this.state.editing_id)
      : await this.service.saveProposal(_pay);
    if (result && result.success) {
      this.goTo('dashboard/proposals');
    }
    this.setState({ loading: false });
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  onContentStateChange(contentState) {
    this.setState({
      contentState,
    });
  }

  uploadImageCallBack(file) {
    return new Promise(async (resolve, reject) => {
      let result = await this.service.postFile(file);
      resolve({
        data: {
          link: this.utils.resolveURL(result.source),
          type: 'image/png',
        },
      });
    });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan
                  .use_oportunities_proposals) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100">
                    <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                      <div className="form-input input-title">
                        <label className="blue-label">Título</label>
                        <input
                          type="text"
                          value={this.state.title}
                          className="blue-input"
                          placeholder="Título"
                          onChange={e => {
                            this.setState({ title: e.target.value });
                          }}
                        />
                      </div>

                      <div className="box-row-title row-center">
                        <div className="box-title">Editor de propostas</div>
                      </div>
                    </Col>
                    <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                      <ClimbyEditor
                        setState={this.setState.bind(this)}
                        state={this.state}
                      />

                      <div className="form-input  max-320">
                        <label className="blue-label">Valor da proposta</label>
                        <CurrencyInput
                          value={this.state.value}
                          className="blue-input"
                          onChangeEvent={e => {
                            this.setState({ value: e.target.value });
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$ "
                          placeholder="Valor da proposta"
                        />
                      </div>

                      <div className="form-input max-320">
                        <label className="blue-label">Modelo</label>
                        <input
                          type="text"
                          value={this.state.tag}
                          className="blue-input"
                          placeholder="Modelo"
                          onChange={e => {
                            this.setState({ tag: e.target.value });
                          }}
                        />
                      </div>

                      <div className="row-center mtop-50 mbottom-50">
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="bt-blue-outline"
                            style={{ margin: 5 }}
                            onClick={() => {
                              this.goTo(
                                'dashboard/proposals/proposal/register'
                              );
                            }}
                          >
                            Cancelar
                          </div>
                          {this.state.loading ? (
                            <ReactLoading type="bubbles" color="#00838F" />
                          ) : (
                            <>
                              <div
                                className={
                                  true ? 'bt-blue' : 'bt-blue bt-disabled'
                                }
                                onClick={() => {
                                  this.save(false);
                                }}
                                style={{ margin: 5 }}
                              >
                                {this.state.editing ? 'Salvar ' : 'Salvar'}
                              </div>
                              <div data-tip data-for="tooltip">
                                <div
                                  className={
                                    true ? 'bt-blue' : 'bt-blue bt-disabled'
                                  }
                                  style={{ margin: 5 }}
                                  onClick={() => {
                                    this.save(true);
                                  }}
                                >
                                  {this.state.editing
                                    ? 'Emitir Proposta '
                                    : 'Emitir Proposta'}
                                </div>
                                <ReactTooltip
                                  id="tooltip"
                                  type="light"
                                  effect="solid"
                                  textColor="#757575"
                                  borderColor="#B1B1B1"
                                  border={true}
                                >
                                  <p style={{ maxWidth: 200 }}>
                                    Ao Emitir Proposta, seu cliente receberá um
                                    e-mail para acessar a mesma. Esta proposta
                                    estará disponível em sua área de Propostas
                                    na posição Propostas Geradas.
                                  </p>
                                </ReactTooltip>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProposalCreate)
);
