import {
  SW_INIT,
  SW_UPDATE,
  UPDATE_CHRONOMETER,
  UPDATE_CHRONOMETER_SESSIONS,
  UPDATE_ACTIVE_MEETING_CHRONOMETER,
  UPDATE_URL_VIDEO_SESSION,
  UPDATE_COUNTRIES,
} from '../consts/reduxTypes';
/**
 * Action Types
 */
export const SIMPLE_ACTION = 'SIMPLE_ACTION';

/**
 * Simple action creator
 */
export const simpleAction = () => dispatch => {
  dispatch({
    type: SIMPLE_ACTION,
    payload: false,
  });
};

export const updateImage = value => ({
  type: 'UPDATE_IMAGE',
  value,
});

export const updateUser = value => ({
  type: 'UPDATE_USER',
  value,
});

export const updateSession = value => ({
  type: 'UPDATE_SESSION',
  value,
});

export const updateMenu = value => ({
  type: 'UPDATE_MENU',
  value,
});

export const updateAlert = value => ({
  type: 'UPDATE_ALERT',
  value,
});

export const updatePeriodicy = value => ({
  type: 'UPDATE_PERIODICY',
  value,
});

export const updateFrom = value => ({
  type: 'UPDATE_FROM',
  value,
});

export const modalTutorialStatus = value => ({
  type: 'MODAL_TUTORIAL_STATUS',
  value,
});

export const modalTutorialPosicao = value => ({
  type: 'MODAL_TUTORIAL_POSICAO',
  value,
});

export const updateVideoSession = value => ({
  type: 'VIDEO_SESSION',
  value,
});

export const modalStartTestPeriod = value => ({
  type: 'MODAL_START_TEST_PERIOD',
  value,
});

export const updateServiceWorkerInitialized = value => ({
  type: SW_INIT,
  value,
});

export const updateServiceWorkerUpdated = value => ({
  type: SW_UPDATE,
  value,
});

export const updateChronometer = value => ({
  type: UPDATE_CHRONOMETER,
  value,
});

export const updateChronometerSessions = value => ({
  type: UPDATE_CHRONOMETER_SESSIONS,
  value,
});

export const updateActiveMeetingChronometer = value => ({
  type: UPDATE_ACTIVE_MEETING_CHRONOMETER,
  value,
});

export const updateUrlVideoSession = value => ({
  type: UPDATE_URL_VIDEO_SESSION,
  value,
});

export const updateCountries = value => ({
  type: UPDATE_COUNTRIES,
  value,
});
