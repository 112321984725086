import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import Service from './Service.js';
import { Col, Row } from 'reactstrap';
import Switch from 'react-switch';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './Submenu';
import UploadImage from './UploadImage';
import UploadFile from './../Create/NewUploadFile';
import UploadCert from './../Create/UploadCert';
import Utils from './../../../libs/Utils.js';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const Typeahead = require('react-typeahead').Typeahead;

class ProfileProfessional extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'profile_professional',
      subview: 'description',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados profissionais', link: '' },
        { title: 'Descrição profissional', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      experience: '',
      professional: '',
      methodology: '',
      institute: '',
      _institute: '',
      year: '',
      graduate: '',
      maxLength: 3660,
      upload: '',
      professional_video: '',
      doc_source: '',
      doc_format: '',
      loading_institutes: false,
      uploading: false,
      institutes: [],
      certificates: [],
      cert_format: '',
      cert_source: '',
      removedCertificates: [],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.save = this.save.bind(this);
    this.createInstitute = this.createInstitute.bind(this);
    this.addCertificate = this.addCertificate.bind(this);
    this.removeCertificate = this.removeCertificate.bind(this);
    this.verifyCanUpload = this.verifyCanUpload.bind(this);
    this.verifyCanVideo = this.verifyCanVideo.bind(this);
    this.saveWithoutCuration = this.saveWithoutCuration.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  async componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    await this.loadUser();
    await this.load();
    await this.loadInstitutes();
    if (
      this.props.history.location.state &&
      this.props.history.location.state.professionalDescriptionIncomplete
    ) {
      this.props.updateAlert(
        'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  formValid() {
    return true;
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    const { user } = this.props;
    if (!user.curation || !user.curation.active) {
      this.curationAlert();
    } else if (user.curation && user.curation.status === 'Aprovado') {
      this.newCurationProcessAlert();
    } else if (user.curation && user.curation.status === 'Solicitado') {
      if (!this.professionalDescriptionCompleted()) {
        return this.props.updateAlert(
          'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
        );
      }
      this.saveWithoutCuration();
      this.props.updateAlert('Perfil atualizado');
    }
  }

  async saveWithoutCuration() {
    if (this.formValid()) {
      this.addCertificate();
      this.setState({ loading: true });
      let professional_description = {
        experience: this.state.experience,
        professional: this.state.professional,
        methodology: this.state.methodology,
        certificate: this.state.certificates,
        upload: this.state.upload.length > 0 ? this.state.upload : '',
      };
      let data = {
        professional_description: JSON.stringify(professional_description),
        professional_video:
          this.state.professional_video.length > 0
            ? this.state.professional_video
            : ' ',
        professional_present: JSON.stringify({
          doc_format: this.state.doc_format,
          doc_source: this.state.doc_source,
          original_name: this.state.original_name,
        }),
      };
      let result = await this.service.save(data);
      if (result.success) {
        await this.removeCertificateFromServer();
        this.loadUser();
      }
      this.setState({ loading: false });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  async load() {
    this.setState({ loading: true });
    let result = await this.service.professional_description();
    if (result && result.professional_description !== undefined) {
      this.setState({
        experience: result.professional_description.experience,
        professional: result.professional_description.professional,
        methodology: result.professional_description.methodology,
        upload: result.professional_description.upload,
        certificates: result.professional_description.certificate,
        professional_video: result.professional_video || '',
      });

      const certificates = this.state.certificates.map(certificate => ({
        ...certificate,
        certificateURL: this.utils.resolveURL(
          certificate.certificate,
          this.service.getServer()
        ),
      }));

      this.setState({ certificates });

      if (result.professional_present) {
        let rpp = JSON.parse(result.professional_present);
        this.setState({
          doc_format: rpp.doc_format,
          doc_source: rpp.doc_source,
          original_name: rpp.original_name,
          uploading: this.videoSlideshowRule(),
        });
      }
    }
    this.setState({ loading: false });
  }

  async loadInstitutes() {
    let institu = [];
    this.setState({ loading_institutes: true });
    let result = await this.service.institutes();
    if (result && result.success) {
      result.options.map(inst => {
        if (inst.partner) {
          institu.push(inst);
        }
      });
      this.setState({ institutes: institu });
    }
    this.setState({ loading_institutes: false });
  }

  async createInstitute(value) {
    this.setState({ loading_institutes: true });
    let result = await this.service.saveInstitute({
      name: value,
    });
    this.loadInstitutes();
  }

  async addCertificate() {
    if (this.state.institute !== '' && this.state.year !== '') {
      this.resolveAdd();
    } else {
      if (this.state._institute !== '' && this.state.year !== '') {
        await this.createInstitute(this.state._institute);
        await this.setState({ institute: this.state._institute });
        this.resolveAdd();
      }
    }
  }

  resolveAdd() {
    let certificates = this.state.certificates;
    certificates.push({
      institute: this.state.institute,
      year: this.state.year,
      graduate: this.state.graduate,
      certificate: this.state.cert_source,
      certificateURL: this.utils.resolveURL(
        this.state.cert_source,
        this.service.getServer()
      ),
    });
    this.setState({
      certificates,
      institute: '',
      _institute: '',
      year: '',
      graduate: '',
      cert_source: '',
      cert_format: '',
      cert_original_name: '',
    });
  }

  removeCertificate(cert) {
    let certificates = this.state.certificates;
    let ckey = certificates.indexOf(cert);
    if (ckey !== -1) {
      certificates.splice(ckey, 1);
    }
    this.setState({ certificates });
    let removedCertificates = this.state.removedCertificates;
    removedCertificates.push(cert.certificate);
    this.setState({ removedCertificates });
  }

  async removeCertificateFromServer() {
    if (this.state.removedCertificates.length > 0) {
      await this.service.removeCert(this.state.removedCertificates);
    }
  }

  parseYoutubeID(value) {
    if (value.indexOf('?v=') !== -1) {
      // https://www.youtube.com/watch?v=lKKJylfqE8M

      let _begin = value.indexOf('?v=') + 3;
      let _end = value.indexOf('?v=') + 3 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf('youtu.be/') !== -1) {
      // https://youtu.be/lKKJylfqE8M

      let _begin = value.indexOf('youtu.be/') + 9;
      let _end = value.indexOf('youtu.be/') + 9 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf('embed/') !== -1) {
      // https://www.youtube.com/embed/lKKJylfqE8M

      let _begin = value.indexOf('embed/') + 6;
      let _end = value.indexOf('embed/') + 6 + 11;
      return value.substring(_begin, _end);
    }

    return false;
  }

  verifyCanUpload() {
    if (this.videoSlideshowRule()) {
      this.setState({ uploading: true });
    } else {
      this.props.updateAlert(
        <div>
          <p>Aumente seu plano para continuar..</p>
          <br />
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    }
  }

  verifyCanVideo(e) {
    if (this.videoSlideshowRule()) {
      this.setState({ uploading: true });
    } else {
      e.target.blur();
      this.props.updateAlert(
        <div>
          <p>Aumente seu plano para continuar..</p>
          <br />
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    }
  }

  videoSlideshowRule() {
    return (
      this.state.user &&
      this.state.user.plans &&
      this.state.user.plans.seacher &&
      this.state.user.plans.seacher &&
      this.state.user.plans.seacher.plan &&
      this.state.user.plans.seacher.plan.title.toLowerCase() === 'premium'
    );
  }

  newCurationProcessAlert = () => {
    this.props.updateAlert(
      <div>
        <p className="mb-20">
          Para manter sua condição de perfil verificado, você aceita passar por
          um novo processo de curadoria?
        </p>

        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => this.newCurationProcess(false)}
          >
            Não aceito
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => this.newCurationProcess(true)}
          >
            Aceito
          </div>
        </div>
      </div>
    );
  };

  curationAlert = () => {
    this.props.updateAlert(
      <div className="postition-relative">
        <div className="mb-20">
          Gostaria de passar pela curadoria
          <i className="info-curation fas fa-info-circle">
            <div className="curation-hint">
              Curadoria climby é uma validação das informações apresentadas no
              seu perfil. Seu perfil receberá um selo de validação ao ser
              aprovado no processo.
            </div>
          </i>
          e receber o selo de validação de dados?
        </div>

        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => {
              this.saveWithoutCuration();
              this.props.updateAlert('Perfil atualizado');
            }}
          >
            Não
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => this.handleCuration()}
          >
            Sim
          </div>
        </div>
      </div>
    );
  };

  handleCuration = async () => {
    const activeCuration = this.props.user.curation
      ? !this.props.user.curation.active
      : true;
    if (activeCuration && !this.professionalDescriptionCompleted()) {
      return this.props.updateAlert(
        'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
      );
    }
    this.setState({ loading: true });
    const data = {
      active: activeCuration,
      date: activeCuration ? new Date() : '',
      status: activeCuration ? 'Solicitado' : '',
      review: false,
    };
    const result = await this.service.save({ curation: JSON.stringify(data) });
    if (result && result.success) {
      this.loadUser();
      this.saveWithoutCuration();
      this.setState({ activeCuration });
      if (activeCuration) {
        this.props.updateAlert(
          <div>
            <p className="mb-20">
              Você autorizou a curadoria a avaliar seu perfil. Caso tenhamos
              alguma dúvida entraremos em contato. Ao ser aprovado seu perfil
              receberá o selo climby de validação.
            </p>
            <div className="btn-footer-modal">
              <div
                className="bt-blue"
                onClick={() => this.props.updateAlert('')}
              >
                OK
              </div>
            </div>
          </div>
        );
      }
    }
    this.setState({ loading: false });
  };

  newCurationProcess = async curationValue => {
    if (curationValue && !this.professionalDescriptionCompleted()) {
      return this.props.updateAlert(
        'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
      );
    }
    const data = {
      active: curationValue,
      date: curationValue ? new Date() : '',
      status: curationValue ? 'Solicitado' : '',
      review: curationValue,
    };
    const result = await this.service.save({ curation: JSON.stringify(data) });
    if (result && result.success) {
      this.saveWithoutCuration();
      this.props.updateAlert('Perfil atualizado');
    }
  };

  professionalDescriptionCompleted = () => {
    const { experience, professional, methodology, certificates } = this.state;
    let isFilled = true;
    if (!experience || !professional || !methodology || !certificates.length) {
      isFilled = false;
    }
    return isFilled;
  };

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <Submenu goTo={this.goTo} subview={this.state.subview} />

              <div className="dashboard-box box100-menu">
                <div className="box-row-title mobile-column">
                  <div className="box-title">Descrição profissional</div>
                  <UploadImage
                    className="up-image-resp-new img-refine"
                    user={this.state.user}
                    uuser={this.loadUser}
                    newOption={true}
                  />
                </div>

                <div className="info-required-field">
                  <span className="required-field mr-5">*</span>
                  Campos de preenchimento obrigatório para o processo de
                  curadoria.
                </div>
                <div className="row-form">
                  <div className="form-textarea">
                    <label className="blue-label">
                      Breve descrição profissional
                      <span className="required-field">*</span>
                    </label>
                    <textarea
                      value={this.state.professional}
                      className="blue-input mobile"
                      placeholder="Este texto estará disponível na área principal do seu perfil no ambiente de negócios. Fale brevemente sobre você e seus principais pontos e nichos de atuação. Torne seu perfil atrativo para seu potencial cliente."
                      onChange={e => {
                        this.setState({ professional: e.target.value });
                      }}
                    ></textarea>
                    <span>
                      {this.state.professional.length}/{this.state.maxLength}
                    </span>
                  </div>
                </div>

                <div className="row-form">
                  <div className="form-textarea">
                    <label className="blue-label">
                      Descrição da experiência
                      <span className="required-field">*</span>
                    </label>
                    <textarea
                      value={this.state.experience}
                      className="blue-input mobile"
                      placeholder="Descreva sua experiência com o coaching. Mostre para seu potencial cliente que você está habilitado e possui experiência para apoiá-lo. Caso se sinta confortável, e tenha autorização, fique a vontade em incluir referências."
                      onChange={e => {
                        this.setState({ experience: e.target.value });
                      }}
                    ></textarea>
                    <span>
                      {this.state.experience.length}/{this.state.maxLength}
                    </span>
                  </div>
                </div>

                <div className="row-form">
                  <div className="form-textarea">
                    <label className="blue-label">
                      Descrição da metodologia
                      <span className="required-field">*</span>
                    </label>
                    <textarea
                      value={this.state.methodology}
                      className="blue-input mobile"
                      placeholder="Aqui nesta área explique para seu potencial cliente como você trabalha de uma forma geral. Se você utiliza uma metodologia de alguma escola de formação ou metodologia própria, faça uma breve descrição da mesma para que seu potencial cliente possa entender um pouco sobre como será o trabalho."
                      onChange={e => {
                        this.setState({ methodology: e.target.value });
                      }}
                    ></textarea>
                    <span>
                      {this.state.methodology.length}/{this.state.maxLength}
                    </span>
                  </div>
                </div>

                <div className="row-form">
                  <div className="form-certificate">
                    <div
                      className="form-title"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Certificado<span className="required-field">*</span>
                      <div data-tip data-for="tooltip">
                        <i className="fas fa-info-circle table-icon"></i>
                        <ReactTooltip
                          id="tooltip"
                          type="light"
                          effect="solid"
                          textColor="#757575"
                          borderColor="#B1B1B1"
                          border={true}
                        >
                          <p style={{ maxWidth: 200 }}>
                            Mostre para seus potenciais clientes sua formação e
                            conquiste ainda mais credibilidade. Ao incluir seu
                            certificado, nossa equipe de curadoria poderá
                            aprovar de forma mais rápida sua participação no
                            engage, seu ambiente de negócios.
                          </p>
                        </ReactTooltip>
                      </div>
                    </div>

                    <div className="form-doubleinput">
                      <div className="form-input year">
                        <label className="blue-label">Instituição</label>
                        {this.state.loading_institutes ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <Typeahead
                            value={this.state.institute}
                            defaultValue={this.state.institute}
                            filterOption="name"
                            displayOption="name"
                            placeholder="Instituição"
                            options={this.state.institutes}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                this.createInstitute(e.target.value);
                              }
                              this.setState({ _institute: e.target.value });
                            }}
                            onOptionSelected={e => {
                              this.setState({
                                institute: e.name,
                                _institute: '',
                              });
                            }}
                            customClasses={{
                              input: 'blue-input',
                              results: 'list-messages listahead',
                            }}
                          />
                        )}
                      </div>

                      <div className="form-input year">
                        <label className="blue-label">Curso</label>
                        <input
                          type="text"
                          value={this.state.graduate}
                          placeholder="Curso"
                          className="blue-input"
                          onChange={e => {
                            this.setState({ graduate: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-input year">
                        <label className="blue-label">Ano de conclusão</label>
                        <input
                          type="text"
                          value={this.state.year}
                          placeholder="Ano"
                          className="blue-input"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.addCertificate();
                            }
                          }}
                          onChange={e => {
                            this.setState({ year: e.target.value });
                          }}
                        />
                      </div>

                      <UploadCert
                        notConvert={true}
                        setState={this.setState.bind(this)}
                        cert_format={this.state.cert_format}
                        cert_source={this.state.cert_source}
                        cert_original_name={this.state.cert_original_name}
                        state={this.state}
                        typeUploadCss="btnCertif"
                      />

                      <div
                        className="bt-blueicon"
                        onClick={e => {
                          this.addCertificate();
                        }}
                      >
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row-form">
                  <div className="row-certificates">
                    {this.state.certificates.map((item, key) => {
                      return (
                        <div key={key} className="certificate-content-item">
                          <div className="certificate-title">
                            <small>{`${
                              item.graduate !== undefined ? item.graduate : ''
                            }`}</small>
                            {item.graduate !== undefined && (
                              <>
                                {' '}
                                <br />
                                <br />{' '}
                              </>
                            )}
                            {`${item.institute} / ${item.year}`}
                            {item.certificate &&
                            item.certificate !== undefined ? (
                              <>
                                {' '}
                                <br />
                                <br />
                                <a
                                  target="new"
                                  className="cert-down"
                                  href={item.certificateURL}
                                >
                                  Certificado{' '}
                                  <i className="fas fa-download"></i>
                                </a>{' '}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className="bt-blueicon"
                            style={{ marginTop: -10 }}
                            onClick={e => {
                              this.removeCertificate(item);
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <Col md={{ size: 8 }} style={{ marginTop: 20 }}>
                  <div className="form-title">Apresentação</div>
                  {!this.state.uploading ? (
                    <div className="row-form" style={{ marginBottom: 15 }}>
                      <div
                        className="bt-blue container-pop"
                        onClick={() => {
                          this.verifyCanUpload();
                        }}
                      >
                        <i className="fas fa-upload"></i>
                        Enviar Apresentação
                        <div className="popover-container">
                          <div className="popover-content">
                            Ao assinar o pacote Premium voce pode incluir um
                            video e uma apresentacao para se diferenciar dos
                            demais Coaches.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <UploadFile
                        notConvert={true}
                        setState={this.setState.bind(this)}
                        doc_format={this.state.doc_format}
                        doc_source={this.state.doc_source}
                        state={this.state}
                        acceptTypes={'application/pdf'}
                      />
                    </>
                  )}

                  {this.state.professional_video ? (
                    this.parseYoutubeID(this.state.professional_video) ? (
                      <div class="video-container">
                        <iframe
                          title="professional"
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${this.parseYoutubeID(
                            this.state.professional_video
                          )}`}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </div>
                    ) : (
                      <div className="box-subtitle">
                        {' '}
                        Url inválida, informe um url do youtube válida{' '}
                      </div>
                    )
                  ) : null}

                  <p>&nbsp;</p>
                  <div className="form-input container-pop form-video">
                    <label className="blue-label">Vídeo</label>
                    <input
                      type="text"
                      value={this.state.professional_video}
                      placeholder="Vídeo"
                      className="blue-input"
                      onChange={e => {
                        this.setState({ professional_video: e.target.value });
                      }}
                      onFocus={e => {
                        this.verifyCanVideo(e);
                      }}
                    />
                    {!this.state.uploading ? (
                      <div>
                        <div className="popover-container">
                          <div
                            className="popover-content"
                            style={{ fontSize: '12px' }}
                          >
                            Ao assinar o pacote Premium você pode incluir um
                            video e uma apresentacao para se diferenciar dos
                            demais Coaches.
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>

                <Col md={{ size: 12 }}>
                  {this.state.loading ? (
                    <div className="div-loading">
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    </div>
                  ) : (
                    <Row className="row-footer">
                      <div
                        onClick={() => {
                          this.save();
                        }}
                        className={
                          !this.formValid()
                            ? 'mb-10 mr-20 bt-save hover-shadow'
                            : 'mb-10 mr-20 bt-save hover-shadow active-btn'
                        }
                      >
                        Salvar Alterações
                      </div>
                      <div className="switch-profile">
                        <span className="switch-span">Solicitar curadoria</span>
                        <Switch
                          onChange={this.handleCuration}
                          id={`toggle-visibility`}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          offColor={'#D8D8D8'}
                          offHandleColor={'#9F9F9F'}
                          onColor={'#96CBCF'}
                          onHandleColor={'#00838F'}
                          width={40}
                          height={20}
                          checked={
                            this.props.user.curation
                              ? this.props.user.curation.active
                              : false
                          }
                        />
                        <div className="info">
                          <i className="info fas fa-info-circle"></i>
                          <div className="switch-hint">
                            Curadoria climby é uma validação das informações
                            apresentadas no seu perfil. Seu perfil receberá um
                            selo de validação ao ser aprovado no processo.
                          </div>
                        </div>
                        {this.props.user.curation &&
                          this.props.user.curation.active && (
                            <span className="curation-status-date">{`${
                              this.props.user.curation.status
                            } em ${moment(this.props.user.curation.date).format(
                              'DD/MM/YYYY'
                            )}`}</span>
                          )}
                      </div>
                    </Row>
                  )}
                </Col>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  user: state.simpleReducer.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileProfessional)
);
