import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import { simpleAction } from '../../../actions/SimpleAction';

class Submenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { user } = this.props;
    const groupSessionPermission =
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan &&
      user.plans.platform.plan.group_sessions;
    return (
      <div className="submenu submenu-coachee">
        <ul className="side-menu">
          <li
            className={this.props.subview == 'coachee' ? 'active' : ''}
            onClick={() => {
              this.props.goTo('dashboard/coachees');
            }}
          >
            <img
              src={require('./../../../assets/images/menu/icon-1.svg')}
              width="28"
            />
            <img
              src={require('./../../../assets/images/menu/icon-1w.svg')}
              width="28"
              className="image-over"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Coachees</div>
            </div>
          </li>
          {groupSessionPermission && (
            <li
              className={this.props.subview == 'groups' ? 'active' : ''}
              onClick={() => {
                this.props.goTo('dashboard/groups');
              }}
            >
              <img
                src={require('./../../../assets/images/menu/icon-15.svg')}
                width="28"
              />
              <img
                src={require('./../../../assets/images/menu/icon-15w.svg')}
                width="28"
                className="image-over"
              />
              <div className="popover-container">
                <div className="triangle-border"></div>
                <div className="popover-content">Grupos</div>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Submenu);
