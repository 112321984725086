import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactLoading from 'react-loading';
import logo from '../../assets/images/header-logo-open.png';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import './styles.css';

const ModalEventTutorial = props => {
  let nomeUser = `${props.dadosUser.name} ${props.dadosUser.surname}`;
  return (
    <div>
      {props.open ? (
        <div className="modal-alert" style={{ zIndex: 1000 }}>
          <div className="modal-alert-box topoModal">
            {/* <i className="fas fa-times icon-close" onClick={() => { props.close() }}></i> */}
            <div className="row-modal-alert">
              <div className="topoGeral">
                <div className="titulo">
                  Tutorial{' '}
                  {props.typeTela === 2
                    ? ' - O seu universo'
                    : props.typeTela === 3
                    ? ' - O seu perfil'
                    : props.typeTela === 4
                    ? ' - Cadastrar meu primeiro coachee'
                    : props.typeTela === 5
                    ? ' - Trocando mensagens com meu coachee'
                    : props.typeTela === 6
                    ? ' - Preparando as ferramentas de trabalho'
                    : props.typeTela === 7
                    ? ' - Iniciando com as sessões de coaching'
                    : props.typeTela === 8
                    ? ' - Finalizando'
                    : ''}
                </div>
              </div>
              <div className="logoPontinho">
                <div className={`logoClimby `}>
                  <img src={logo} />
                </div>
                <div className="pontinhoTutorial">
                  <div
                    className="bolinhasTutor"
                    style={{ backgroundColor: '#0a8bfc' }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 2 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 3 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 4 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 5 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 6 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela >= 7 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                  <div
                    className="bolinhasTutor"
                    style={{
                      backgroundColor: `${
                        props.typeTela === 8 ? '#0a8bfc' : '#b9bbbd'
                      }`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="lineDivider"></div>

              <div>
                <div className="modal-alert-text">
                  {props.typeTela === 1 && (
                    <div className="textoTutorial">
                      Seja bem-vindo {nomeUser} a sua plataforma de coaching.
                      <br />
                      A partir deste momento você está se diferenciando e
                      profissionalizando cada vez mais sua entrega.
                      <br />
                      <br />
                      Vamos te explicar rapidamente os principais passos da
                      plataforma para que você possa extrair o máximo de
                      benefício.
                      <br />
                      <br />
                      Recomendamos que você acompanhe este rápido tutorial até o
                      final, mas caso precise interromper não se procupe pois a
                      qualquer momento você poderá acessá-lo através do lado
                      esquerdo do seu menú.
                    </div>
                  )}

                  {props.typeTela === 2 && (
                    <div className="textoTutorial">
                      A partir de agora, você está acessando um Sistema com
                      diversos benefícios:
                      <br />
                      <br />
                      <strong
                        style={{ fontWeight: 'bold', fontFamily: 'initial' }}
                      >
                        <div className="btnPlataforma"></div> A Plataforma:
                      </strong>{' '}
                      Local onde você realiza a gestão de todo o seu dia a dia
                      como coach. Aqui você poderá, gerenciar seu coachee,
                      controlar sua agenda, criar um website próprio, realizar
                      propostas para seus clientes, personalizar e armazenar
                      seus conteúdos, realizar a gestão dos seus programas de
                      coaching, gerenciar seu lado financeiro, extrair
                      relatórios gereciais e muito mais.
                      <br />
                      <strong
                        style={{ fontWeight: 'bold', fontFamily: 'initial' }}
                      >
                        <div className="btnBuscador"></div> O Buscador:
                      </strong>{' '}
                      É o local em que seu serviço será vendido e você aparecerá
                      para o mercado. Quanto mais informação você preencher mais
                      atrativo você estará para seus potenciais clientes. Por
                      isso é fundamental que você mantenha o seu perfil sempre
                      atualizado e atraente.
                      <br />
                    </div>
                  )}

                  {props.typeTela === 3 && (
                    <>
                      <span className="modTutorial">1</span>
                      <div className="textoTutorial">
                        Vamos ao primeiro passo e mais importante: Completar seu
                        perfil!
                        <br />
                        <br />
                        No canto superior direito da sua plataforma está sua
                        área de perfil onde você é convidado a preencher seus
                        dados pessoais e profissionais.
                        <br />
                        <br />
                        Lembre-se que esta etapa é fundamental para você
                        conquistar seus clientes através do buscador da
                        plataforma.
                        <br />
                        <br />
                        Importante: Após preencher seus dados e foto selecione o
                        botão "Habilitar buscador" para que seus dados sejam
                        disponibilizados para potenciais clientes.
                      </div>
                    </>
                  )}

                  {props.typeTela === 4 && (
                    <>
                      <span className="modTutorial">2</span>
                      <div
                        className="textoTutorial"
                        style={{ marginBottom: 10 }}
                      >
                        Para iniciar seus programas de coaching você deve
                        cadastrar seu Coachee
                        <br />
                        <br />
                        No menu lateral "Coachees" você será convidado a
                        cadastrar seu cliente com apenas algumas informações:
                        nome, email, dados de contato e data de nascimento.
                        Apenas Nome e email são obrigatórios nesta etapa e os
                        demais podem ser preenchidos posteriormente.
                        <br />
                        <br />
                        Após cadastrar seu coachee, você já poderá imediatamente
                        interagir com ele compartilhando mensagens, conteúdos e
                        outras ações.
                        <br />
                        <br />O processo é muito rápido! Tudo pronto?
                      </div>
                    </>
                  )}

                  {props.typeTela === 5 && (
                    <>
                      <span className="modTutorial">3</span>
                      <div className="textoTutorial">
                        Uma vez cadastrado meu coachee você já pode interagir
                        com ele diretamente pela plataforma através da área de
                        Mensagens
                        <br />
                        <br />
                        Ao clicar em "Mensagens" no menu lateral esquerdo, você
                        poderá abrir um chat com seus clientes. Basta digitar o
                        nome do seu coachee e enviar mensagens. Ele poderá
                        acompanhar as mensagens trocadas a partir da Plataforma
                        do Coachee.
                        <br />
                        <br />
                        Quer se aproximar mais e mais dos seus clientes? Vamos
                        enviar uma mensagem para eles agora?
                      </div>
                    </>
                  )}

                  {props.typeTela === 6 && (
                    <>
                      <span className="modTutorial">4</span>
                      <div className="textoTutorial">
                        Um passo importante para você se sentir a vontade
                        durante os processos de coaching e torná-los cada vez
                        mais profissionais é quando você cria o seu próprio
                        repositório de ferramentas e conteúdos.
                        <br />
                        <br />
                        Nesta área você pode realizar upload de documentos,
                        artigos, ferramentas, vídeos, fotos e muito mais. O
                        armazenamento é seu e você compartilha com quem você
                        quiser. Até documentos em formatos PDF podem ser
                        editados nesta área.
                        <br />
                        <br />
                        Chega de deixar seus documentos espalhados! Organize-os
                        aqui na sua área de conteúdo.
                      </div>
                    </>
                  )}

                  {props.typeTela === 7 && (
                    <>
                      <span className="modTutorial">5</span>
                      <div className="textoTutorial">
                        Agora que você já cadastrou um coachee e já possui seus
                        conteúdos disponíveis é hora de iniciar suas sessões de
                        coaching!
                        <br />
                        <br />
                        Ao clicar na área de Programas no menu superior, você
                        terá acesso a um outro menu chamado Sessões de Coaching.
                        Clicando, você será convidado a selecionar o seu coachee
                        cadastrado para seguir com o processo.
                        <br />
                        <br />
                        Nesta área você poderá realizar uma sessão master
                        gratuita, realizar o planejamento das sessões e
                        registrar cada um dos encontros podendo compartilhar
                        conteúdos e ações como seus clientes. Vamos começar?
                      </div>
                    </>
                  )}

                  {props.typeTela === 8 && (
                    <div className="textoTutorial">
                      Agora que você já conhece os passos inicias é só começar a
                      trabalhar e descobrir muitos outros detalhes e
                      funcionalidades.
                      <br />
                      <br />
                      Você pode sempre tirar suas dúvidas na Central de Ajuda ou
                      através no nosso chat.
                      <br />
                      <br />
                      Lembrando que se você está com o plano gratuito,
                      gostariamos de alertar que ele é válido por 15 dias e que
                      durante este tempo você terá acesso limitado a algumas
                      funcionalidades. Fique a vontade em assinar o seu plano
                      ideal a qualquer momento através da área Meus Planos
                      localizado na área de Perfil.
                      <br />
                      <br />
                      Seja bem-vindo a climby!!
                    </div>
                  )}

                  {props.typeTela === 3 && (
                    <>
                      <div className="irParaPerfil" style={{ margin: -10 }}>
                        <div
                          className="bt-blue btnTutorial"
                          onClick={() => {
                            props.preencherPerfil();
                          }}
                        >
                          {'Preencher meu perfil agora'}
                        </div>
                      </div>
                    </>
                  )}

                  {props.typeTela === 4 && (
                    <>
                      <div className="irParaPerfil">
                        <div
                          className="bt-blue btnTutorial"
                          onClick={() => {
                            props.cadastrarCoachee();
                          }}
                        >
                          {'Cadastrar meu 1º Coachee'}
                        </div>
                      </div>
                    </>
                  )}

                  {props.typeTela === 5 && (
                    <>
                      <div className="irParaPerfil">
                        <div
                          className="bt-blue btnTutorial"
                          onClick={() => {
                            props.enviarMensagem();
                          }}
                        >
                          {'Enviar uma mensagem'}
                        </div>
                      </div>
                    </>
                  )}

                  {props.typeTela === 6 && (
                    <>
                      <div className="irParaPerfil">
                        <div
                          className="bt-blue btnTutorial"
                          onClick={() => {
                            props.realizandoUpload();
                          }}
                        >
                          {'Realizando um upload'}
                        </div>
                      </div>
                    </>
                  )}

                  {props.typeTela === 7 && (
                    <>
                      <div className="irParaPerfil">
                        <div
                          className="bt-blue btnTutorial"
                          onClick={() => {
                            props.realizandoSessao();
                          }}
                        >
                          {'Realizando uma sessão'}
                        </div>
                      </div>
                    </>
                  )}

                  {props.typeTela === 1 && (
                    <>
                      <div
                        className="btnVamosLa"
                        style={{
                          backgroundColor: 'white',
                          margin: '20px auto',
                          color: '#00050a',
                          fontWeight: 600,
                        }}
                      >
                        {'Vamos lá?'}
                      </div>
                    </>
                  )}

                  <div className="geralBTNPontos">
                    {props.loadingModal ? (
                      <div className="row-loading">
                        <ReactLoading type="bubbles" color="#00838F" />
                      </div>
                    ) : (
                      <>
                        {props.typeTela >= 2 && (
                          <>
                            <div
                              className="bt-blue btnTutorial"
                              onClick={() => {
                                props.acaoModalVoltar();
                              }}
                            >
                              {'Voltar'}
                            </div>
                          </>
                        )}

                        {props.typeTela <= 7 && (
                          <>
                            <div
                              className="bt-blue btnTutorial"
                              onClick={() => {
                                props.acaoModalSeguir();
                              }}
                            >
                              {'Seguir em frente'}
                            </div>
                          </>
                        )}

                        {props.typeTela === 8 && (
                          <>
                            <div
                              className="bt-blue btnTutorial"
                              onClick={() => {
                                props.escolherUpload();
                              }}
                            >
                              {'Fazer um upgrade agora'}
                            </div>
                          </>
                        )}

                        <div
                          className="bt-blue btnTutorial"
                          style={{ width: '12%' }}
                          onClick={() => {
                            props.acaoModalSair();
                          }}
                        >
                          Sair
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  dadosUser: state.simpleReducer.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEventTutorial);
