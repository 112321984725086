import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Draggable from 'react-draggable';
import ReactLoading from 'react-loading';

import ApiService from '../../services/ApiService';

import Jitsi from '../Jitsi';
import { useVideoRoomSockets } from './sockets';
import {
  updateVideoSession,
  updateUrlVideoSession,
} from '../../actions/SimpleAction';
import { roles as videoRoomRoles } from '../../consts/videoRoomTypes';
import userTypes from '../../consts/userTypes';
import Utils from '../../libs/Utils';

import './styles.css';

const INITIAL_POSITION = () => ({
  left: 8,
  bottom: 8,
});

const INITIAL_DRAGGABLE_POSITION = () => ({
  x: 0,
  y: 0,
});

export const events = {
  joined: 'joined',
  changed: 'changed',
  disconnect: 'disconnect',
};

const apiService = new ApiService();
const utils = new Utils();

const participantRights = {
  config: {
    remoteVideoMenu: {
      disableKick: true,
    },
    disableRemoteMute: true,
  },
  toolbarButtons: [
    'microphone',
    'camera',
    'closedcaptions',
    'desktop',
    'embedmeeting',
    'fullscreen',
    'fodeviceselection',
    'hangup',
    'chat',
    'etherpad',
    'settings',
    'raisehand',
    'videoquality',
    'filmstrip',
    'feedback',
    'tileview',
    'videobackgroundblur',
    'download',
    'invite',
  ],
  settingsSections: ['devices', 'language'],
};

const moderatorRights = {
  config: {
    // focusUserJid: 'focus@auth.jitsi-meet.example.com',
    remoteVideoMenu: {
      disableKick: false,
    },
    disableRemoteMute: false,
  },
  toolbarButtons: [
    ...participantRights.toolbarButtons,
    'recording',
    'mute-everyone',
  ],
  settingsSections: [...participantRights.settingsSections, 'moderator'],
};

function VideoSession({
  roomName,
  subject,
  type,
  userType,
  role,
  lobbyMessage,
}) {
  const dispatch = useDispatch();
  const socket = useVideoRoomSockets(type, roomName);

  const apiRef = useRef();

  const [user, setUser] = useState();
  const [isExpanded, setIsExpanded] = useState(true);
  const [position, setPosition] = useState(INITIAL_POSITION);
  const [draggablePosition, setDraggablePosition] = useState(
    INITIAL_DRAGGABLE_POSITION
  );
  const [isDragging, setIsDragging] = useState(false);

  const [roomMembers, setRoomMembers] = useState(null);

  const userName = useMemo(
    () =>
      user
        ? `${user.name ? user.name : ''}${
            user.surname ? ` ${user.surname}` : ''
          }`
        : '',
    [user]
  );

  const isInLobby = useMemo(
    () =>
      user &&
      roomMembers &&
      roomMembers.lobby.find(member => member.id === user._id),
    [user, roomMembers]
  );

  function onDrag(e, position) {
    const { x, y } = position;
    setDraggablePosition({ x, y });
  }

  function emitJoined() {
    socket.emit(`${type}.event.${roomName}`, {
      event: events.joined,
      role,
      name: userName,
      token: apiService.getToken(),
    });
  }

  const onApiLoad = api => {
    apiRef.current = api;
    api.executeCommand('toggleVideo');

    api.addEventListener('readyToClose', function() {
      dispatch(updateVideoSession({ isActive: false }));
      dispatch(updateUrlVideoSession(undefined));
    });

    api.addEventListener('videoConferenceJoined', () => {
      api.executeCommand('displayName', userName, user);
    });

    api.addEventListener('subjectChange', ({ newSubject }) => {
      if (role === videoRoomRoles.moderator && newSubject !== subject) {
        api.executeCommand('subject', subject);
      }
    });

    api.addEventListener('participantRoleChanged', function(event) {
      if (event.role === 'moderator') {
        if (role === videoRoomRoles.moderator) {
          api.executeCommand('subject', subject);
          emitJoined();
        }
      }
    });

    api.addEventListener('log', function(event) {
      if (event) {
        const apiLogLevels = ['warn', 'error'];
        if (apiLogLevels.indexOf(event.logLevel) > -1) {
          console.error(`log_jitsi ${event.logLevel}`, event.args);
        } else {
          console.log(`log_jitsi ${event.logLevel}`, event.args);
        }
      }
    });
  };

  const getUrlVideoSession = url => {
    dispatch(updateUrlVideoSession(url));
  };

  useEffect(() => {
    setPosition(INITIAL_POSITION);
    setDraggablePosition(INITIAL_DRAGGABLE_POSITION);
  }, [isExpanded]);

  useEffect(() => {
    async function loadUser() {
      let result =
        userType === userTypes.coach
          ? await apiService.me()
          : await apiService.meCoachee();
      if (result) setUser(result.user);
    }
    loadUser();

    const verifyLogin = setInterval(function() {
      if (!utils.isLogged()) dispatch(updateVideoSession({ isActive: false }));
    }, 5000);

    return function() {
      clearInterval(verifyLogin);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(events.changed, roomMembers => {
        setRoomMembers(roomMembers);
      });

      socket.on(events.disconnect, () => {
        dispatch(updateVideoSession({ isActive: false }));
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket && user && role !== videoRoomRoles.moderator) emitJoined();
  }, [socket, user]);

  function getRoom() {
    if (!socket || !user) {
      return <ReactLoading type="bubbles" color="#00838F" />;
    }

    if (isInLobby) {
      return (
        <div
          className="not-found-label"
          style={{
            margin: 0,
            padding: 10,
            ...(isExpanded
              ? { width: '800px', height: '400px' }
              : { width: '400px', height: '200px' }),
          }}
        >
          <p className="title-bold">
            {lobbyMessage || 'A chamada ainda não foi iniciada pelo moderador'}
          </p>
          <ReactLoading type="bubbles" color="#00838F" />
        </div>
      );
    }

    return (
      <Jitsi
        options={{
          roomName: `climby-${roomName}-${type}`,
          // configOverwrite: {
          //   apiLogLevels: ['warn', 'log', 'error', 'info'],
          //   defaultLanguage: 'ptBR',
          // },
          // interfaceConfigOverwrite: {
          //   LANG_DETECTION: false,
          // },
          // roomName: `climby-teste-do-marcelo-sala`,
          // configOverwrite: {
          //   apiLogLevels: ['warn', 'log', 'error', 'info'],
          //   prejoinPageEnabled: false,
          //   ...(role === videoRoomRoles.moderator
          //     ? moderatorRights.config
          //     : participantRights.config),
          // },
          // interfaceConfigOverwrite: {
          //   SETTINGS_SECTIONS:
          //     role === videoRoomRoles.moderator
          //       ? moderatorRights.settingsSections
          //       : participantRights.settingsSections,

          //   TOOLBAR_BUTTONS:
          //     role === videoRoomRoles.moderator
          //       ? moderatorRights.toolbarButtons
          //       : participantRights.toolbarButtons,
          // },
        }}
        onApiLoad={onApiLoad}
        loadingComponent={() => <ReactLoading type="bubbles" color="#00838F" />}
        containerStyle={
          isExpanded
            ? { width: '800px', height: '400px' }
            : { width: '400px', height: '200px' }
        }
        getUrlVideoSession={getUrlVideoSession}
      />
    );
  }

  return (
    <Draggable
      bounds="parent"
      handle=".draggable"
      position={draggablePosition}
      onDrag={onDrag}
      onStart={() => setIsDragging(true)}
      onStop={() => setIsDragging(false)}
    >
      <div
        className="over-all"
        style={{ ...position, transition: !isDragging && 'all 0.2s ease' }}
      >
        <div className="video-room-top box draggable">
          <div
            className="resize-button"
            onTouchEnd={() => setIsExpanded(!isExpanded)}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <i
              className={
                isExpanded ? 'fas fa-compress' : 'fas fa-expand-arrows-alt'
              }
            ></i>
          </div>
          <div
            className="close-button"
            onTouchEnd={() => dispatch(updateVideoSession({ isActive: false }))}
            onClick={() => dispatch(updateVideoSession({ isActive: false }))}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="video-room-container box">{getRoom()}</div>
      </div>
    </Draggable>
  );
}

export default VideoSession;
