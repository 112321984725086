import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';
import ReactLoading from 'react-loading';

import * as queryString from 'query-string';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import Header from './../../includes/Header';

class ProposalResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responding: true,
      success_response: false,
      hash: '',
      r: '',
      email: '',
    };
    this.service = new Service();
    this.utils = new Utils();
  }

  async componentDidMount() {
    const { hash, r, email } = queryString.parse(this.props.location.search);
    await this.setState({ hash, r, email });
    this.respondProposal();
  }

  async respondProposal() {
    this.setState({ responding: true });
    const { hash, r, email } = this.state;
    let result = await this.service.respondProposal({ hash, r, email });
    if (result && result.success) {
      this.setState({ responding: false, success_response: true });
    } else {
      this.setState({ responding: false, success_response: false });
    }
  }

  render() {
    return (
      <div>
        <Header />
        {!this.state.responding ? (
          <div className="not-found-label">
            <p className="title-bold">
              {this.state.success_response
                ? this.state.r === 'yes'
                  ? 'Proposta aceita!'
                  : 'Agradeço sua atenção. Esta proposta foi recusada'
                : 'Ocorreu um erro ao responder proposta'}
            </p>
            <i
              className={
                this.state.success_response
                  ? 'fas fa-check big-grey-icon'
                  : 'fas fa-times big-grey-icon'
              }
              style={{ marginTop: 30 }}
            />
            {this.state.success_response && this.state.r === 'yes' && (
              <p className="text-register-success" style={{ marginTop: 30 }}>
                Informamos o(a) coach que você aceitou a proposta e em breve
                ele(a) entrará em contato
              </p>
            )}
          </div>
        ) : (
          <div className="not-found-label">
            <p className="title-bold" style={{ marginBottom: 30 }}>
              Respondendo proposta
            </p>
            <ReactLoading type="bubbles" color="#00838F" />
          </div>
        )}
      </div>
    );
  }
}

export default connect()(ProposalResponse);
