import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import PropTypes from 'prop-types';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import SidebarContents from '../sidebar/SidebarContents';
import CardGenericTable from './../components/CardGenericTable';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import Footer from './../components/Footer';
import NewModalCoachee from './../components/NewModalCoachee';
import NewModalCoacheeDelete from './../components/NewModalCoacheeDelete';
import ModalCoachee from './../components/ModalCoachee';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';

class Contents extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'contents',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdo', link: 'contents' },
        { title: 'Minha Área', link: 'contents' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      rows: [],
      title_rows: [],
      actions: [],
      folder: '',
      folders: [],
      content_size: 0,
      content_total: 0,
      ocontent_size: 0,
      ocontent_total: 0,
      content_share: false,
      coachees: [],
      coachee_id: '',
      sort1: '',
      new_modal_coachee: false,
      new_modal_coachee_delete: false,
      modal_coachee: false,
      nameCoachee: '',
      rowsTotal: [],
      typeCompat: false,
      posicaoDelete: 0,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.setFolder = this.setFolder.bind(this);
    this.goTo = this.goTo.bind(this);
    this.openFolder = this.openFolder.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  edit(item) {
    if (item.doc_format === 'wheel') {
      this.setSidebarLinks(`wheel-editor/${item._id}`);
    } else if (item.doc_format === 'form') {
      this.setSidebarLinks(`form-editor/${item._id}`);
    } else {
      this.setSidebarLinks(`contents/create/${item._id}`);
    }
  }

  see(item) {
    if (!item.doc_format) {
      this.setSidebarLinks(`proposals/proposal/register/${item._id}`);
    } else {
      let url;
      if (item.doc_format.toLowerCase() === 'link') {
        url = item.doc_source;
      } else if (['rel', 'propsals'].includes(item.doc_format.toLowerCase())) {
        this.setSidebarLinks(`template-editor/${item._id}`);
        return;
      } else if (item.doc_format.toLowerCase() === 'wheel') {
        this.setSidebarLinks(`wheel-editor/${item._id}`);
        return;
      } else if (item.doc_format.toLowerCase() === 'form') {
        this.setSidebarLinks(`form-editor/${item._id}`);
        return;
      } else {
        url = this.utils.resolveURL(item.doc_source, this.service.getServer());
      }
      window.open(url);
    }
  }

  seeShared(item, hash, key) {
    let url;
    if (
      item.html ||
      item.doc_format === 'wheel' ||
      item.doc_format === 'form'
    ) {
      this.responseView(item, key);
    } else {
      if (item.doc_format.toLowerCase() === 'link') {
        url = item.doc_source;
      } else {
        url = this.utils.resolveURL(item.doc_source, this.service.getServer());
      }
      window.open(url);
    }
  }

  responseView(content) {
    const {
      sessionId,
      meeting,
      coachee_id: coacheeId,
      _id: contentId,
      doc_format,
    } = content;
    if (doc_format === 'wheel') {
      this.goTo(
        `dashboard/wheel-viewer/${sessionId}/${meeting}/${coacheeId}/${contentId}`
      );
    } else if (doc_format === 'form') {
      this.goTo(
        `dashboard/form-viewer/${sessionId}/${meeting}/${coacheeId}/${contentId}`
      );
    } else {
      this.goTo(
        `dashboard/response-viewer/${sessionId}/${meeting}/${coacheeId}/${contentId}`
      );
    }
  }

  async removeContentShared(item) {
    this.setState({
      posicaoDelete: 1,
      content_share: item,
      coachee_id: item.coachee ? item.coachee._id : 0,
      nameCoachee: item.coachee_name ? item.coachee_name : '',
    });
    this.toggleModalDelete();
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadContentsFolders();
    this.loadCoachees();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadCoachees() {
    let result = await this.service.coacheesAtivos();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }

    let state = { user };

    await this.setState(state);

    this.loadContents();
  }

  async loadContentsFolders() {
    let result = await this.service.foldersAvailable();
    if (result && result.success) {
      this.setState({ folders: result.folders });
    }
  }

  async loadContents(folder = false) {
    this.setState({ rows: [], content_total: 0, content_size: 0.0 });
    let query = folder ? { folder: folder.path } : {};
    if (folder.category) {
      query['category'] = folder.category;
      query['doc_format'] = 'REL';
      query['subtype'] = 'coaching';
    }
    const contentRel = [
      'report/saved/coachees',
      'report/saved/coaching',
      'report/saved/finance',
    ];
    if (contentRel.includes(folder.path)) {
      query = {
        folder: folder.path,
        category: 'Relatórios',
      };
    }
    let result = folder
      ? await this.service.contentsFolder(query)
      : await this.service.contents();
    if (result && result.success) {
      if (folder && folder.path === 'report/saved/coaching') {
        this.setState({
          typeCompat: true,
        });
      } else {
        this.setState({
          typeCompat: false,
        });
      }

      this.setState({ rowsTotal: result });

      let state = {
        rows: result.contents,
        content_size: result.size.toFixed(2),
        content_total: result.total,
      };

      if (folder && folder.path == 'template/shared/coachees') {
        this.setState({
          actions: [
            {
              title: 'Ver Resposta',
              svg: {
                icon: require('./../../assets/images/tables/eye.svg'),
                width: 22,
              },
              action: this.seeShared.bind(this),
            },
            {
              title: 'Deletar',
              svg: {
                icon: require('./../../assets/images/tables/trash.svg'),
                width: 15,
              },
              action: this.removeContentShared.bind(this),
            },
          ],
          title_rows: [
            { title: 'Coachee', key: 'coachee_name' },
            { title: 'Título', key: 'title' },
            { title: 'Formato', key: 'doc_format' },
            { title: 'Categoria', key: 'category' },
            { title: 'Modelo', key: 'tag' },
            { title: 'Ação', key: 'options' },
          ],
        });
      } else {
        if (folder && folder.path == 'report/shared/coachees') {
          this.setState({
            actions: [
              {
                title: 'Editor de template',
                hidden: 'html',
                svg: {
                  icon: require('./../../assets/images/tables/eye.svg'),
                  width: 18,
                },
                action: this.templateEditor.bind(this),
              },
              {
                title: 'Visualizar',
                svg: {
                  icon: require('./../../assets/images/tables/eye.svg'),
                  width: 22,
                },
                action: this.see.bind(this),
              },
              {
                title: 'Compartilhar',
                svg: {
                  icon: require('./../../assets/images/tables/share_line.svg'),
                  width: 18,
                },
                action: this.share.bind(this),
              },
              {
                title: 'Editar',
                svg: {
                  icon: require('./../../assets/images/tables/edit.svg'),
                  width: 18,
                },
                action: this.edit.bind(this),
              },
              {
                title: 'Deletar',
                svg: {
                  icon: require('./../../assets/images/tables/trash.svg'),
                  width: 15,
                },
                action: this.removeContent.bind(this),
              },
            ],
            title_rows: [
              { title: 'Título', key: 'title' },
              //{ title: "Coachee", key: "coachee_name" },
              { title: 'Formato', key: 'doc_format' },
              { title: 'Categoria', key: 'category' },
              { title: 'Modelo', key: 'tag' },
              { title: 'Ação', key: 'options' },
            ],
          });
        } else {
          if (folder && folder.path == 'report/saved/coaching') {
            this.setState({
              actions: [
                {
                  title: 'Editor de template',
                  hidden: 'coaching',
                  svg: {
                    icon: require('./../../assets/images/tables/eye.svg'),
                    width: 18,
                  },
                  action: this.templateEditor.bind(this),
                },
                //{ title:"Visualizar", svg:{ icon: require('./../../assets/images/tables/eye.svg'), width:22 }, action: this.see.bind(this) },
                /*
                {
                  title: "Compartilhar",
                  svg: {
                    icon: require("./../../assets/images/tables/share_line.svg"),
                    width: 18
                  },
                  action: this.share.bind(this)
                },
                */
                //{ title:"Editar", svg:{ icon: require('./../../assets/images/tables/edit.svg'), width:18 }, action: this.edit.bind(this) },
                {
                  title: 'Deletar',
                  svg: {
                    icon: require('./../../assets/images/tables/trash.svg'),
                    width: 15,
                  },
                  action: this.removeContent.bind(this),
                },
              ],
              title_rows: [
                { title: 'Título', key: 'title' },
                //{ title: "Coachee", key: "coachee_name" },
                { title: 'Formato', key: 'doc_format' },
                { title: 'Categoria', key: 'category' },
                { title: 'Modelo', key: 'tag' },
                { title: 'Ação', key: 'options' },
              ],
            });
          } else if (folder && folder.path == 'report/shared/coaching') {
            this.setState({
              actions: [
                {
                  title: 'Editor de template',
                  hidden: 'html',
                  svg: {
                    icon: require('./../../assets/images/tables/eye.svg'),
                    width: 18,
                  },
                  action: this.openPDF.bind(this),
                },
                {
                  title: 'Compartilhar',
                  svg: {
                    icon: require('./../../assets/images/tables/share_line.svg'),
                    width: 18,
                  },
                  action: this.share.bind(this),
                },
                //{ title:"Editar", svg:{ icon: require('./../../assets/images/tables/edit.svg'), width:18 }, action: this.edit.bind(this) },
                {
                  title: 'Deletar',
                  svg: {
                    icon: require('./../../assets/images/tables/trash.svg'),
                    width: 15,
                  },
                  action: this.removeContent.bind(this),
                },
              ],
              title_rows: [
                { title: 'Coachee', key: 'coachee_name' },
                { title: 'Título', key: 'title' },
                { title: 'Formato', key: 'doc_format' },
                { title: 'Categoria', key: 'category' },
                { title: 'Modelo', key: 'tag' },
                { title: 'Ação', key: 'options' },
              ],
            });
          } else if (result.can_share) {
            if (
              folder &&
              (folder.path === 'report/saved/finance' ||
                folder.path === 'report/saved/coachees')
            ) {
              this.setState({
                actions: [
                  {
                    title: 'Editor de template',
                    hidden: 'html',
                    svg: {
                      icon: require('./../../assets/images/tables/eye.svg'),
                      width: 18,
                    },
                    action: this.templateEditor.bind(this),
                  },
                  {
                    title: 'Visualizar',
                    svg: {
                      icon: require('./../../assets/images/tables/eye.svg'),
                      width: 22,
                    },
                    action: this.see.bind(this),
                  },
                  {
                    title: 'Editar',
                    svg: {
                      icon: require('./../../assets/images/tables/edit.svg'),
                      width: 18,
                    },
                    action: this.edit.bind(this),
                  },
                  {
                    title: 'Deletar',
                    svg: {
                      icon: require('./../../assets/images/tables/trash.svg'),
                      width: 15,
                    },
                    action: this.removeContent.bind(this),
                  },
                ],
                title_rows: [
                  { title: 'Título', key: 'title' },
                  //{ title: "Coachee", key: "coachee_name" },
                  { title: 'Formato', key: 'doc_format' },
                  { title: 'Categoria', key: 'category' },
                  { title: 'Modelo', key: 'tag' },
                  { title: 'Ação', key: 'options' },
                ],
              });
            } else {
              this.setState({
                actions: [
                  {
                    title: 'Editor de template',
                    hidden: 'html',
                    svg: {
                      icon: require('./../../assets/images/tables/eye.svg'),
                      width: 18,
                    },
                    action: this.templateEditor.bind(this),
                  },
                  {
                    title: 'Visualizar',
                    svg: {
                      icon: require('./../../assets/images/tables/eye.svg'),
                      width: 22,
                    },
                    action: this.see.bind(this),
                  },
                  {
                    title: 'Compartilhar',
                    svg: {
                      icon: require('./../../assets/images/tables/share_line.svg'),
                      width: 18,
                    },
                    action: this.share.bind(this),
                  },
                  {
                    title: 'Editar',
                    svg: {
                      icon: require('./../../assets/images/tables/edit.svg'),
                      width: 18,
                    },
                    action: this.edit.bind(this),
                  },
                  {
                    title: 'Deletar',
                    svg: {
                      icon: require('./../../assets/images/tables/trash.svg'),
                      width: 15,
                    },
                    action: this.removeContent.bind(this),
                  },
                ],
                title_rows: [
                  { title: 'Título', key: 'title' },
                  //{ title: "Coachee", key: "coachee_name" },
                  { title: 'Formato', key: 'doc_format' },
                  { title: 'Categoria', key: 'category' },
                  { title: 'Modelo', key: 'tag' },
                  { title: 'Ação', key: 'options' },
                ],
              });
            }
          } else {
            this.setState({
              actions: [
                {
                  title: 'Editor de template',
                  hidden: 'html',
                  svg: {
                    icon: require('./../../assets/images/tables/eye.svg'),
                    width: 18,
                  },
                  action: this.templateEditor.bind(this),
                },
                {
                  title: 'Visualizar',
                  svg: {
                    icon: require('./../../assets/images/tables/eye.svg'),
                    width: 22,
                  },
                  action: this.see.bind(this),
                },
                {
                  title: 'Editar',
                  svg: {
                    icon: require('./../../assets/images/tables/edit.svg'),
                    width: 18,
                  },
                  action: this.edit.bind(this),
                },
                {
                  title: 'Deletar',
                  svg: {
                    icon: require('./../../assets/images/tables/trash.svg'),
                    width: 15,
                  },
                  action: this.removeContent.bind(this),
                },
              ],
              title_rows: [
                { title: 'Título', key: 'title' },
                { title: 'Formato', key: 'doc_format' },
                { title: 'Categoria', key: 'category' },
                { title: 'Modelo', key: 'tag' },
                { title: 'Ação', key: 'options' },
              ],
            });
          }
        }
      }

      if (!folder) {
        if (this.state.user) {
          state['ocontent_size'] = this.state.user.limits.storage.used.toFixed(
            2
          );
          state['ocontent_total'] = this.state.user.limits.storage.total;
          state['content_size'] = this.state.user.limits.storage.used.toFixed(
            2
          );
          state['content_total'] = this.state.user.limits.storage.total;
        } else {
          state['ocontent_size'] = result.size.toFixed(2);
          state['ocontent_total'] = result.total;
        }
      }
      this.setState(state);
    }
  }

  async removeContent(item) {
    await this.setState({
      posicaoDelete: 2,
      content_share: item,
      coachee_id: item.coachee ? item.coachee._id : 0,
      nameCoachee: item.coachee ? item.coachee.name : '',
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    if (this.state.posicaoDelete === 2) {
      // let result = !this.state.content_share.doc_format
      //   ? await this.service.removeProposal(this.state.content_share._id)
      //   : await this.service.removeContent(this.state.content_share._id);
      let result = await this.service.removeContent(
        this.state.content_share._id
      );
      if (result && result.success) {
        this.loadContents(this.state.folder);
        this.props.updateAlert('Deletado com sucesso');
      }
    } else if (this.state.posicaoDelete === 1) {
      const {
        sessionId,
        meeting,
        coachee_id: coacheeId,
        _id: contentId,
      } = this.state.content_share;

      let result = await this.service.destroySessionContent(
        sessionId,
        meeting,
        coacheeId,
        contentId
      );
      if (result && result.success) {
        this.loadContents({ path: 'template/shared/coachees' });
      }
    }
    this.toggleModalDelete();
  };

  setFolder(folder) {
    this.setState({ folder });
    if (folder == '') {
      this.setState({
        rows: [],
        content_total: this.state.ocontent_total,
        content_size: this.state.ocontent_size,
      });
    }
  }

  openFolder(item, key, sub, key2) {
    if (item.folders.length > 0) {
      let folders = this.state.folders;

      if (!sub) {
        folders[key].open = !item.open;
      }

      if (sub) {
        folders[key].folders[key2].open = !item.open;
      }

      this.setState({ folders });
    } else {
      this.setFolder(item);
      this.loadContents(item);
    }
  }

  share(item) {
    this.setState({
      content_share: item,
      coachee_id: item.coachee ? item.coachee._id : 0,
      nameCoachee: item.coachee ? item.coachee.name : '',
    });
    this.toggleModal();
  }

  verifyCreateCoacheeSession(coachee_id) {
    return this.service.verifyCoachee({ coachee_id });
  }

  async completeShare(coachee) {
    let contents = [this.state.content_share];
    if (contents.length > 0) {
      const { coachee_id: coacheeId, content_share } = this.state;
      const session = await this.verifyCreateCoacheeSession(coacheeId);

      const result = await this.service.storeSessionContents(
        session._id,
        'session',
        [coacheeId],
        [content_share._id]
      );

      if (result && result.success) {
        this.props.updateAlert('Compartilhado com sucesso');
      }
    }
  }

  toggleModal() {
    //this.setState({ new_modal_coachee: !this.state.new_modal_coachee });
    this.setState({ modal_coachee: !this.state.modal_coachee });
  }

  toggleModalDelete() {
    this.setState({
      new_modal_coachee_delete: !this.state.new_modal_coachee_delete,
    });
  }

  /*
  Autor: Natanael Diego
  Data: 09/01/2020
  Hora: 19:52

  Funcao: Responsavel por mandar o conteudo salvo coaching para a area de compartilhado coaching
  */
  async shareCoachee() {
    let contents = [this.state.content_share];
    if (contents.length > 0) {
      let _pay = {
        idContent: this.state.content_share._id,
        idCoachee: this.state.coachee_id,
      };

      let result = await this.service.updateShare(_pay, this.state.coachee_id);

      if (result && result.success) {
        this.props.updateAlert('Compartilhado com sucesso');

        let rowsContent = [];
        this.state.rowsTotal.contents.map(data => {
          if (data._id !== this.state.content_share._id) {
            rowsContent.push(data);
          }
        });

        this.setState({ rows: rowsContent });
      } else {
        this.props.updateAlert('Não foi possível compartilhar');
      }
    }
  }

  save() {
    this.toggleModal();
    if (this.state.typeCompat) {
      this.shareCoachee();
    } else {
      this.completeShare(this.state.coachee_id);
    }
  }

  changeCoachee(e) {
    this.setState({ coachee_id: e.target.value });
  }

  templateEditor(item) {
    if (item.doc_format == 'wheel') {
      this.goTo(`dashboard/wheel-preview/${item._id}`);
    } else if (item.doc_format == 'report') {
      this.goTo(`dashboard/report-editor/${item._id}`);
    } else if (item.doc_format == 'form') {
      this.goTo(`dashboard/form-preview/${item._id}`);
    } else {
      this.goTo(`dashboard/template-editor/${item._id}`);
    }
  }

  openPDF(item) {
    if (item.doc_format === 'REL') {
      window.open(`${this.service.getServer()}generate/session/${item._id}`);
    }
  }

  sort1(key) {
    let order =
      this.state.sort1 == '' || this.state.sort1 == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort1: `${key}${order}`, rows });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarContents
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={
              this.props.menu ? 'content-dash closed' : 'content-dash '
            }
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                {this.state.folder != '' ? (
                  <div>
                    <div
                      className="div-folder box100"
                      onClick={() => {
                        this.setFolder('');
                      }}
                    >
                      <i className="fas fa-arrow-alt-circle-left"></i>
                      Voltar
                    </div>
                    <CardGenericTable
                      id="contents-content-1"
                      sort={this.sort1.bind(this)}
                      _sort={this.state.sort1}
                      title={`Minha Área de Conteúdo: ${this.state.folder.name}`}
                      actions={this.state.actions}
                      title_rows={this.state.title_rows}
                      rows={this.state.rows}
                    />
                  </div>
                ) : (
                  <div className="dashboard-box box100">
                    <div className="box-row-title">
                      <div className="box-title">Minha Área de Conteúdo</div>
                    </div>

                    <div className="row-folders">
                      {this.state.folders.map((item, key) => {
                        return (
                          <div key={key}>
                            <div
                              className="div-folder"
                              onClick={() => {
                                this.openFolder(item, key, false);
                              }}
                            >
                              {item.open ? (
                                <i className="fas fa-folder-open"></i>
                              ) : item.folders.length > 0 ? (
                                <i className="fas fa-folder-plus"></i>
                              ) : (
                                <i className="fas fa-folder"></i>
                              )}
                              {item.name}
                            </div>
                            <div
                              className="row-folders"
                              style={{ paddingLeft: 20 }}
                            >
                              {item.open
                                ? item.folders.map((item2, key2) => {
                                    return (
                                      <div key={key2}>
                                        <div
                                          className="div-folder"
                                          onClick={() => {
                                            this.openFolder(
                                              item2,
                                              key,
                                              true,
                                              key2
                                            );
                                          }}
                                        >
                                          {item2.open ? (
                                            <i className="fas fa-folder-open"></i>
                                          ) : item2.folders.length > 0 ? (
                                            <i className="fas fa-folder-plus"></i>
                                          ) : (
                                            <i className="fas fa-folder"></i>
                                          )}
                                          {item2.name}
                                        </div>

                                        <div
                                          className="row-folders"
                                          style={{ paddingLeft: 20 }}
                                        >
                                          {item2.open
                                            ? item2.folders.map(
                                                (item3, key3) => {
                                                  return (
                                                    <div key={key3}>
                                                      <div
                                                        className="div-folder"
                                                        onClick={() => {
                                                          this.openFolder(
                                                            item3
                                                          );
                                                        }}
                                                      >
                                                        {item3.open ? (
                                                          <i className="fas fa-folder-open"></i>
                                                        ) : item3.folders
                                                            .length > 0 ? (
                                                          <i className="fas fa-folder-plus"></i>
                                                        ) : (
                                                          <i className="fas fa-folder"></i>
                                                        )}
                                                        {item3.name}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                            : null}
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div className="row disk-space">
                <div className="disk-total">
                  <div
                    className="disk-consumed"
                    style={{
                      width: `${
                        this.state.content_size > this.state.content_total
                          ? 100
                          : parseInt(
                              (this.state.content_size * 100) /
                                this.state.content_total
                            )
                      }%`,
                    }}
                  ></div>
                </div>
                <div className="disk-label">{this.state.content_size} MB</div>
              </div>

              <div className="row-start">
                <div className="row row-button mt-20">
                  {this.state.folder &&
                    this.state.folder.name === 'Formulários' && (
                      <div
                        className="bt-blue"
                        onClick={() => this.setSidebarLinks('form-editor')}
                      >
                        <img
                          src={require('./../../assets/images/buttons/add_plus.svg')}
                          style={{ marginTop: -3, marginRight: 14 }}
                        />
                        Criar Formulário
                      </div>
                    )}
                  {this.state.folder && this.state.folder.name === 'Rodas' && (
                    <div
                      className="bt-blue"
                      onClick={() => this.setSidebarLinks('wheel-editor')}
                    >
                      <img
                        src={require('./../../assets/images/buttons/add_plus.svg')}
                        style={{ marginTop: -3, marginRight: 14 }}
                      />
                      Criar Roda
                    </div>
                  )}
                  {!this.state.folder && (
                    <div
                      className="bt-blue"
                      onClick={() => {
                        if (
                          this.state.user &&
                          !this.state.user.limits.storage.canUpload
                        ) {
                          this.props.updateAlert(
                            'Limite de upload do plano atingido, aumente seu plano ou apague conteúdos para continuar'
                          );
                        } else {
                          this.setSidebarLinks('contents/create');
                        }
                      }}
                    >
                      <img
                        src={require('./../../assets/images/file_upload.svg')}
                        style={{ marginTop: -3, marginRight: 14 }}
                      />
                      Upload
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        {/* <NewModalCoachee
          close={() => {
            this.setState({ new_modal_coachee: false });
          }}
          state={this.state}
          open={this.state.new_modal_coachee}
          save={this.save.bind(this)}
          title={`Compartilhar com o coachee: ${this.state.nameCoachee}`}
        /> */}

        <ModalCoachee
          close={() => {
            this.setState({ modal_coachee: false });
          }}
          state={this.state}
          open={this.state.modal_coachee}
          body={'Selecione o coachee'}
          save={this.save.bind(this)}
          title={'Compartilhar com coachee'}
          setState={this.setState.bind(this)}
          changeCoachee={this.changeCoachee.bind(this)}
        />

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_coachee_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_coachee_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contents)
);
