import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { arrowCircleRight } from 'react-icons-kit/fa/arrowCircleRight';
import { NavLink } from 'reactstrap';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/planos/Plans.scss';
import ReactLoading from 'react-loading';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
  updateFrom,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Utils from './../../../libs/Utils.js';
import cornerPlanImg from '../../../assets/images/corner-plan.svg';
import checkMark from '../../../assets/images/checked.svg';
import ModalEventAcao from '../../components/ModalEventAcao';
import ModalEventPedido from '../../components/ModalEventPedido';
import cartIcon from '../../../assets/images/carrinho.png';

import CreditCard from './CreditCard';

const periodType = [
  { title: 'Mensal', key: '1m' },
  { title: 'Anual', key: '12m' },
];

class Plans extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      sideOpen: false,
      principalOpen: true,
      loading: true,
      view: 'plans',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: 'profile' },
        { title: 'Meus Planos', link: 'profile/plans' },
      ],
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      plans: [],
      combos: [],
      haveCombo: null,
      have_card: false,
      current_periodicy: { title: 'Anual', key: '12m' },

      title_modal_event: '',
      open_event: false,
      loadingModal: false,

      openCartModal: false,
      newCreditCard: false,
      creditCard: {
        customerId: '',
        lastDigits: '',
        name: '',
        id: '',
      },
      coupon: undefined,
      couponError: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    window.addEventListener('resize', this.handleResize);
    this.getUserInfoPlansAndAsingPlans();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getPlanIds = () => {
    const { haveCombo, plans } = this.state;

    const planSelectedSearcher = plans.find(
      item => item.type === 'seacher' && item.selected
    );
    const planSelectedPlatform = plans.find(
      item => item.type === 'platform' && item.selected
    );
    const planSearcherId = haveCombo
      ? haveCombo.searcherPlanId
      : planSelectedSearcher._id;
    const planPlatformId = haveCombo
      ? haveCombo.platformPlanId
      : planSelectedPlatform && planSelectedPlatform._id;
    return { planSearcherId, planPlatformId };
  };

  finishPurchase = async () => {
    const { plans, coupon } = this.state;
    this.setState({ loadingModal: true });

    const { planSearcherId, planPlatformId } = this.getPlanIds();

    const data = {
      planSearcherId,
      planPlatformId,
      couponCode: coupon ? coupon.code : '',
    };

    const result = await this.service.buy(data);
    if (result.success) {
      const userResult = await this.service.me();
      if (!userResult.user) {
        this.logout();
        return;
      }
      this.props.updateUser(userResult.user);
      if (userResult.user.image) {
        this.props.updateImage(userResult.user.image);
      }
      plans.map(item => {
        if (item._id === planSearcherId || item._id === planPlatformId) {
          item.signedPlan = true;
        } else {
          item.signedPlan = false;
        }
      });
      this.props.updateAlert('Compra finalizada com sucesso.');
      this.setState({
        user: userResult.user,
        plans,
        coupon: undefined,
        couponError: '',
      });
    } else {
      this.props.updateAlert(
        'Não foi possível finalizar a compra. Ocorreu um problema com o pagamento da assinatura!'
      );
    }
    this.setState({ loadingModal: false, openCartModal: false });
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({ windowWidth: _ww });
  }

  parvalue(value) {
    return parseInt(
      value
        .replace('R$ ', '')
        .replace(',', '')
        .replace('.', '')
    );
  }

  getUserInfoPlansAndAsingPlans = async () => {
    await Promise.all([
      this.service.plans(),
      this.service.currentPlans(),
      this.service.me(),
      this.service.myCard(),
      this.service.combos(),
    ])
      .then(response => {
        const { plans } = response[0];
        const { buy } = response[1];
        const { user } = response[2];
        const { paymentMethod } = response[3];
        const { combos } = response[4];

        const newCombos = combos.map(
          item =>
            item.active && {
              id: item._id,
              title: item.title,
              searcherPlanId: item.searcher.plan_id,
              searcherPrice: item.searcher.price,
              platformPlanId: item.platform.plan_id,
              platformPrice: item.platform.price,
              iuguPlanIdentifier: item.iugu_plan_identifier,
              selected: false,
            }
        );

        const creditCard = {};

        if (paymentMethod) {
          creditCard.customerId = paymentMethod.customer_id;
          creditCard.customerId = paymentMethod.customer_id;
          creditCard.lastDigits = paymentMethod.data.last_digits;
          creditCard.name = paymentMethod.name;
          creditCard.id = paymentMethod.id;
        }

        if (!user) {
          this.logout();
          return;
        }
        this.props.updateUser(user);
        if (user.image) {
          this.props.updateImage(user.image);
        }

        const signedPlatformPlan = buy.platform ? buy.platform.plan._id : null;
        const signedSeacherPlan = buy.seacher ? buy.seacher.plan._id : null;
        const current_periodicy = {
          title:
            buy.seacher && buy.seacher.plan.periodice === '12m'
              ? 'Anual'
              : 'Mensal',
          key: buy.seacher && buy.seacher.plan.periodice,
        };

        plans.sort((a, b) => {
          if (this.parvalue(a.value) > this.parvalue(b.value)) {
            return 1;
          }
          if (this.parvalue(a.value) < this.parvalue(b.value)) {
            return -1;
          }
          return 0;
        });
        plans.map(item => {
          if (!signedSeacherPlan && item.title === 'Grátis') {
            item.selected = true;
          } else if (
            item._id === signedPlatformPlan ||
            item._id === signedSeacherPlan
          ) {
            item.selected = true;
            item.signedPlan = true;
          } else {
            item.selected = false;
          }
        });
        this.setState({
          plans,
          user,
          creditCard,
          combos: newCombos,
          loading: false,
          current_periodicy,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  cornerPlan = (type = 1) => {
    return (
      <img
        src={cornerPlanImg}
        className={`${
          type === 1
            ? 'corner-plan-buscador'
            : type === 2
            ? 'corner-plan-recomendado'
            : 'corner-plan-plataforma'
        }`}
      />
    );
  };

  checkPlan = () => {
    return <img src={checkMark} className="check-plan" />;
  };

  cancelPurchase = async () => {
    this.setState({ openCartModal: false });
  };

  acaoModalFinalizarCompra = () => {
    const { plans } = this.state;
    const signedPlans = plans.filter(item => item.signedPlan);
    const selectedPlans = plans.filter(item => item.selected);
    let selectedPlansTotal = 0;
    const equalPlans = [];

    selectedPlans.forEach(item => {
      selectedPlansTotal += parseFloat(this.utils.clearValue(item.value));
    });

    if (signedPlans.length === selectedPlans.length) {
      signedPlans.forEach(signed => {
        selectedPlans.forEach(selected => {
          if (signed._id === selected._id) {
            equalPlans.push(signed._id);
          }
        });
      });
    }

    if (equalPlans.length === signedPlans.length) {
      this.props.updateAlert(
        <div>
          <span>Você já assina o(s) plano(s): </span>
          {signedPlans.map(item => (
            <span key={item.title}>
              <br />
              {item.title} - {item.value}
            </span>
          ))}
        </div>
      );
      return;
    }
    if (!selectedPlans.find(item => item.type === 'seacher')) {
      this.props.updateAlert('Selecione um dos planos do buscador.');
      return;
    }
    if (!selectedPlansTotal) {
      this.props.updateAlert(
        'Selecione pelo menos um plano pago para finalizar a compra.'
      );
      return;
    }

    this.setState({ openCartModal: true });
  };

  selectPlan = plan => {
    const { plans } = this.state;
    const indexPlan = this.state.plans.indexOf(plan);
    plans.forEach((item, index) => {
      if (index === indexPlan) {
        plans[index].selected = !plan.selected;
      } else if (item.type === plan.type) {
        plans[index].selected = false;
      }
    });
    this.findComboForSelectedPlans(plans);
  };

  findComboForSelectedPlans = plans => {
    const { combos } = this.state;
    const selectedPlatformPlan = plans.find(
      item => item.selected && item.type === 'platform'
    );
    const selectedSearchPlan = plans.find(
      item => item.selected && item.type === 'seacher'
    );

    combos.forEach((combo, index) => {
      if (
        selectedPlatformPlan &&
        selectedSearchPlan &&
        selectedPlatformPlan._id === combo.platformPlanId &&
        selectedSearchPlan._id === combo.searcherPlanId
      ) {
        combos[index].selected = true;
        combos[index].periodice =
          selectedPlatformPlan.periodice === '1m' ? 'mês' : 'ano';
      } else {
        combos[index].selected = false;
      }
    });
    const haveCombo = combos.find(item => item.selected);

    this.setState({ plans, combos, haveCombo });
  };

  getTotalCart = () => {
    const { plans } = this.state;
    return plans.filter(item => item.selected && !item.signedPlan).length;
  };

  handlePeriodicy = _pr => {
    const { plans } = this.state;
    plans.forEach((_, index) => {
      plans[index].selected = false;
    });
    this.setState({
      current_periodicy: _pr,
      plans,
      coupon: undefined,
      couponError: '',
    });
  };

  registerNewCreditCard = value => {
    this.setState({ newCreditCard: value });
  };

  applyCoupon = async couponCode => {
    const { planPlatformId, planSearcherId } = this.getPlanIds();
    const result = await this.service.validateCoupon({
      couponCode,
      planPlatformId,
      planSearcherId,
    });
    if (result && result.success) {
      const { error, coupon, discount } = result;
      if (error) {
        this.setState({ coupon: undefined, couponError: error.label });
      } else {
        this.setState({
          coupon: { ...coupon, discount },
          couponError: '',
        });
      }
    }
  };

  removeCoupon = () => {
    this.setState({ coupon: undefined, couponError: '' });
  };

  handleCreditCard = paymentMethod => {
    const creditCard = {};
    creditCard.customerId = paymentMethod.customer_id;
    creditCard.customerId = paymentMethod.customer_id;
    creditCard.lastDigits = paymentMethod.data.last_digits;
    creditCard.name = paymentMethod.name;
    creditCard.id = paymentMethod.id;
    this.setState({ creditCard });
  };

  render() {
    let seacher = 0;
    let platform = 0;

    return (
      <div className="planos-plans">
        {this.state.newCreditCard ? (
          <CreditCard
            planFlow={true}
            goBackToPlans={() => this.registerNewCreditCard(false)}
            handleCreditCard={this.handleCreditCard}
          />
        ) : (
          <div className="main-content">
            <Header
              goTo={this.goTo}
              sideOpen={this.state.sideOpen}
              toggleMenu={this.toggleMenu}
            />
            <div className="content-climb user bodyClimby">
              <div
                className={
                  this.props.menu ? 'content-menu closed' : 'content-menu'
                }
              >
                <SidebarProfile
                  goTo={this.goTo}
                  view={this.state.view}
                  logout={this.logout}
                  setSidebarLinks={this.setSidebarLinks}
                />
              </div>
              <div
                className={
                  this.props.menu ? 'content-dash closed' : 'content-dash'
                }
              >
                <div className="dash-content">
                  <div className="dash-crumb-filter">
                    <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                  </div>
                  {this.state.user && this.state.user != null ? (
                    <>
                      {
                        <div className="content-plans content-dataPlans">
                          <div className="dashboard-box box100">
                            {this.state.loading ? (
                              <div className="plan-loading">
                                <ReactLoading type="bubbles" color="#00838F" />
                              </div>
                            ) : (
                              <div>
                                <div className="box-row-title">
                                  <div className="box-title">Meus planos</div>
                                </div>
                                {
                                  <div className="row-menu-toggle">
                                    <div className="menu-vazio-letf" />
                                    <div className="menu-toggle-plans">
                                      {periodType.map((_pr, _prk) => (
                                        <div
                                          className={
                                            _pr.key ===
                                            this.state.current_periodicy.key
                                              ? 'active'
                                              : ''
                                          }
                                          onClick={() =>
                                            this.handlePeriodicy(_pr)
                                          }
                                          key={_prk}
                                        >
                                          {_pr.title}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="row-carrinho">
                                      <div className="imagem-carrinho hcenter">
                                        <div className="notification-badge">
                                          {this.getTotalCart()}
                                        </div>
                                        <img
                                          src={cartIcon}
                                          alt=""
                                          width="30"
                                          height="30"
                                        />
                                      </div>
                                      <div
                                        className="finalizar-compra"
                                        title="Finalizar compra"
                                        alt="Finalizar compra"
                                        onClick={this.acaoModalFinalizarCompra}
                                      >
                                        Finalizar
                                        <br />
                                        compra
                                      </div>
                                    </div>
                                  </div>
                                }
                                <div className="plan-text-info">
                                  Selecione o(s) plano(s) que deseja assinar:
                                </div>
                                <div className="typePlanoForm">
                                  Planos do buscador *
                                </div>

                                <div
                                  className="planoBuscador hidden-nd"
                                  id="tab-planos"
                                >
                                  {this.state.plans
                                    .filter(item => {
                                      return (
                                        item.type != 'seacher' ||
                                        this.state.current_periodicy.key ==
                                          item.periodice
                                      );
                                    })
                                    .map(item => {
                                      seacher =
                                        item.type === 'seacher'
                                          ? seacher + 1
                                          : seacher;
                                      let gratis = '';
                                      let premium = '';
                                      const isPremium =
                                        parseFloat(
                                          this.utils.clearValue(item.value)
                                        ) > 0;
                                      gratis = !isPremium
                                        ? item.corner
                                          ? 'nome-plano-gratis-corner'
                                          : 'nome-plano-gratis'
                                        : '';
                                      premium = isPremium
                                        ? item.corner
                                          ? 'espacoGrupo nome-plano'
                                          : 'nome-plano-premium-corner'
                                        : '';
                                      let value = item.value
                                        ? item.value.replace('R$ ', '')
                                        : 0;
                                      // let value_full = item.value_full
                                      //   ? item.value_full.replace('R$ ', '')
                                      //   : 0;

                                      return (
                                        <>
                                          {item.type === 'seacher' && (
                                            <div
                                              className={`${
                                                seacher === 1
                                                  ? 'espInter'
                                                  : 'sepCard'
                                              }`}
                                              style={{
                                                marginBottom: 10,
                                              }}
                                            >
                                              <div
                                                className={`${
                                                  isPremium
                                                    ? 'bg-premium'
                                                    : 'bg-gratis'
                                                } head-plano`}
                                                style={{ padding: 0 }}
                                              >
                                                <div
                                                  className={
                                                    item.selected
                                                      ? 'plan selected'
                                                      : 'plan not-selected'
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      item.selected
                                                        ? 'border white'
                                                        : 'border transparent'
                                                    }
                                                  >
                                                    {item.selected &&
                                                      this.checkPlan()}
                                                    {item.corner
                                                      ? this.cornerPlan(1)
                                                      : null}
                                                    <span
                                                      className={`${gratis}${premium}`}
                                                      style={{ fontSize: 20 }}
                                                    >
                                                      Planos para buscador
                                                    </span>
                                                    <span
                                                      className={`${
                                                        seacher === 1
                                                          ? 'nome-plano'
                                                          : 'nome-plano-premium'
                                                      } planoMaisculo`}
                                                    >
                                                      {item.title}
                                                    </span>
                                                    <a
                                                      onClick={() =>
                                                        this.selectPlan(item)
                                                      }
                                                      className="btn-assinar"
                                                    >
                                                      {item.selected
                                                        ? 'REMOVER'
                                                        : 'SELECIONAR'}
                                                    </a>
                                                    <span className="preco">
                                                      <small>R$</small>
                                                      <span className="valor-starter">
                                                        {item.value ? value : 0}
                                                      </span>
                                                      <small>
                                                        {item.periodice === '1m'
                                                          ? '/mês'
                                                          : '/ano'}
                                                      </small>
                                                    </span>
                                                    <span className="desconto">
                                                      <span className="valor-starter-sdesconto">
                                                        {/* {item.value_full
                                                              ? value_full
                                                              : 0} */}
                                                      </span>
                                                      {/* {item.periodice === '1m'
                                                            ? '/mês'
                                                            : '/ano'} */}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="textPlan">{`${
                                                seacher === 1
                                                  ? 'Presença gratuíta no Buscador'
                                                  : 'Ganhe destaque no Buscador'
                                              }`}</div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                </div>

                                <div className="typePlanoForm">
                                  Planos da plataforma
                                </div>

                                <div
                                  className="planoPlataforma hidden-nd bottomPlanTop"
                                  id="tab-planos"
                                >
                                  {this.state.plans
                                    .filter(item => {
                                      return (
                                        item.type != 'platform' ||
                                        this.state.current_periodicy.key ==
                                          item.periodice
                                      );
                                    })
                                    .map(item => {
                                      platform =
                                        item.type === 'platform'
                                          ? platform + 1
                                          : platform;
                                      let value = item.value
                                        ? item.value.replace('R$ ', '')
                                        : 0;
                                      let value_full = item.value_full
                                        ? item.value_full.replace('R$ ', '')
                                        : 0;

                                      let startet = '';
                                      let expert = '';
                                      let prime = '';
                                      let master = '';

                                      startet =
                                        platform === 1
                                          ? item.corner
                                            ? 'platPlan01'
                                            : 'platPlan02'
                                          : '';
                                      expert =
                                        platform === 2
                                          ? item.corner
                                            ? 'platPlan01'
                                            : 'platPlan03'
                                          : '';
                                      prime =
                                        platform === 3
                                          ? item.corner
                                            ? 'platPlan01'
                                            : 'platPlan02'
                                          : '';
                                      master =
                                        platform === 4
                                          ? item.corner
                                            ? 'platPlan01'
                                            : 'platPlan02'
                                          : '';

                                      return (
                                        <>
                                          {item.type === 'platform' && (
                                            <div
                                              className={`${
                                                platform === 1
                                                  ? 'espInter'
                                                  : 'sepCard'
                                              } ${
                                                platform === 4
                                                  ? 'hidden-nd-master'
                                                  : ''
                                              } marginBottom-platform`}
                                            >
                                              <div
                                                className={`${
                                                  platform === 1
                                                    ? 'bg-purple'
                                                    : platform === 2
                                                    ? 'bg-green bottomPlan'
                                                    : platform === 3
                                                    ? 'bg-pink hidden-nd-prime'
                                                    : platform === 4
                                                    ? 'bg-orange hidden-nd-master-bottom'
                                                    : ''
                                                } head-plano`}
                                                style={{ padding: 0 }}
                                              >
                                                <div
                                                  className={
                                                    item.selected
                                                      ? 'plan selected'
                                                      : 'plan not-selected'
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      item.selected
                                                        ? 'border white'
                                                        : 'border transparent'
                                                    }
                                                  >
                                                    {item.selected &&
                                                      this.checkPlan()}
                                                    {platform === 2 ? (
                                                      <div className="recomendado">
                                                        Recomendado
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {item.corner &&
                                                    platform === 2
                                                      ? this.cornerPlan(2)
                                                      : item.corner &&
                                                        platform !== 2
                                                      ? this.cornerPlan(3)
                                                      : null}
                                                    <div className="refTex">
                                                      <span
                                                        className={`${
                                                          item.corner
                                                            ? 'espacoGrupo'
                                                            : ''
                                                        } ${startet}${expert}${prime}${master} nome-plano`}
                                                        style={{ fontSize: 20 }}
                                                      >
                                                        Planos para plataforma
                                                      </span>
                                                      <span
                                                        className={
                                                          platform !== 2
                                                            ? 'nome-plano'
                                                            : `nome-plano-expert`
                                                        }
                                                      >
                                                        {item.title}
                                                      </span>
                                                      <a
                                                        onClick={() =>
                                                          this.selectPlan(item)
                                                        }
                                                        className="btn-assinar"
                                                      >
                                                        {item.selected
                                                          ? 'REMOVER'
                                                          : 'SELECIONAR'}
                                                      </a>
                                                      <span className="preco">
                                                        <small>R$</small>
                                                        <span className="valor-starter">
                                                          {item.value
                                                            ? value
                                                            : 0}
                                                        </span>
                                                        <small>
                                                          {item.periodice ===
                                                          '1m'
                                                            ? '/mês'
                                                            : '/ano'}
                                                        </small>
                                                      </span>
                                                      <span className="desconto">
                                                        R$
                                                        <span className="valor-starter-sdesconto">
                                                          {item.value_full
                                                            ? value_full
                                                            : 0}
                                                        </span>
                                                        {item.periodice === '1m'
                                                          ? '/mês'
                                                          : '/ano'}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="textPlan">{`${
                                                  platform === 1
                                                    ? 'Mais indicado para Coaches que estão buscando as melhores práticas ao iniciar sua jornada no Coaching'
                                                    : platform === 2
                                                    ? 'Mais contratado por Coaches em crescimento que desejam expandir suas atividades com uma gestão profissional'
                                                    : platform === 3
                                                    ? 'Para Coaches que buscam automatizar e potencializar suas atividades'
                                                    : platform === 4
                                                    ? 'Para Coaches estabelecidos no mercado que atendem a um grande número de Coachees'
                                                    : ''
                                                }`}</div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                </div>
                                <div className="detalhesPlano">
                                  <span className="maisDetalhes">
                                    <NavLink
                                      href={`${process.env.REACT_APP_URL_WORDPRESS}planos/`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      Mais detalhes dos planos{' '}
                                      <Icon icon={arrowCircleRight} />
                                    </NavLink>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                    </>
                  ) : null}
                  <Footer />
                </div>
              </div>
            </div>

            <ModalEventPedido
              close={() => this.setState({ openCartModal: false })}
              open={this.state.openCartModal}
              plans={this.state.plans}
              haveCombo={this.state.haveCombo}
              cancelPurchase={this.cancelPurchase}
              finishPurchase={this.finishPurchase}
              registerNewCreditCard={this.registerNewCreditCard}
              creditCard={this.state.creditCard}
              loadingModal={this.state.loadingModal}
              combos={this.state.combos}
              applyCoupon={this.applyCoupon}
              removeCoupon={this.removeCoupon}
              coupon={this.state.coupon}
              couponError={this.state.couponError}
              hasSubscription={
                this.state.user &&
                this.state.user.subscription &&
                this.state.user.subscription.hasSubscription
              }
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  _from: state.simpleReducer._from,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateImage, updateUser, updateAlert, updateFrom },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plans));
