import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/datePicker/style.scss';
import InputMask from 'react-input-mask';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Sidebar from './../../sidebar/Sidebar';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Submenu from '../Groups/Submenu';

class CoacheeCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'coachees',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Coachees', link: 'coachees' },
        { title: 'Cadastrar', link: 'coachees/create' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading_coachee: false,
      loading: false,
      coachees: [],
      active_status: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      coachee_id: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
      validateEmail: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.selectCoachee = this.selectCoachee.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.resolveParams();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.coachee(_id);
    if (result && result.success) {
      let birthday = result.coachee.birthday;
      this.setState({
        active_status: result.coachee.status != 'false',
        name: result.coachee.name,
        email: result.coachee.email,
        phone: result.coachee.phone,
        address: result.coachee.address,
        birthday:
          birthday !== undefined && birthday !== null && birthday.trim()
            ? new Date(this.utils.resolveSessionDate(result.coachee.birthday))
            : '',
      });
    }
    this.setState({ loading: false });
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  selectCoachee(e) {
    this.setState({ active_status: e.target.checked });
  }

  async save() {
    this.setState({ loading: true });

    await this.validateEmail(this.state.email);

    let _pay = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address == undefined ? '' : this.state.address,
      birthday:
        this.state.birthday !== undefined &&
        this.state.birthday !== null &&
        this.state.birthday !== ''
          ? this.utils.parseDateUSA(this.state.birthday)
          : ' ',
      status: this.state.active_status,
    };

    let result;
    if (!this.state.validateEmail) {
      if (this.state.editing) {
        result = await this.service.updateCoachee(_pay, this.state.editing_id);
      } else {
        result = await this.service.saveCoachee(_pay);
      }
      if (result && result.success) {
        this.goTo('dashboard/coachees');
        this.clearForm();
      } else {
        const message = result.email_already_registered
          ? 'E-mail já cadastrado para outro coachee'
          : 'Você atingiu o limite máximo de cadastros permitidos para o seu plano, aumente seu plano ou remova um coachee para continuar';
        this.props.updateAlert(
          <div>
            <p>{message}</p>
            {!result.email_already_registered && (
              <div>
                <br />
                <div
                  className="bt-blue"
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    this.goTo('dashboard/profile/plans');
                  }}
                >
                  Alterar plano
                </div>
              </div>
            )}
          </div>
        );
      }
    }
    this.setState({
      loading: false,
      validateEmail: result.email_already_registered
        ? 'E-mail já cadastrado para outro coachee'
        : this.state.validateEmail,
    });
  }

  clearForm() {
    this.setState({
      active_status: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      coachee_id: '',
    });
  }

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: '' });
    } else {
      await this.setState({
        validateEmail: 'Campo "Email" é obrigatório e precisa ser válido',
      });
    }
  };

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter with-margin-left">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu
                  goTo={this.goTo}
                  subview="coachee"
                  user={this.state.user}
                />

                <div className="dashboard-box box100 dashboard-box-with-margin-left">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">
                        {this.state.editing ? 'Editar ' : 'Cadastrar '} Coachee
                      </div>
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Nome</label>
                      <input
                        type="text"
                        value={this.state.name}
                        className="blue-input"
                        placeholder="Nome"
                        onChange={e => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Email</label>
                      <input
                        type="text"
                        value={this.state.email}
                        className="blue-input"
                        placeholder="Email"
                        onChange={e => {
                          this.setState({ email: e.target.value });
                          this.validateEmail(e.target.value);
                        }}
                      />
                      {this.state.validateEmail && (
                        <span className="campObrigatorio">
                          {this.state.validateEmail}
                        </span>
                      )}
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Telefone</label>
                      <InputMask
                        {...this.props}
                        mask="(99) 999999999"
                        value={this.state.phone}
                        className="blue-input"
                        placeholder="Telefone"
                        onChange={e => {
                          this.setState({ phone: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Endereço</label>
                      <input
                        type="text"
                        value={this.state.address}
                        className="blue-input"
                        placeholder="Endereço"
                        onChange={e => {
                          this.setState({ address: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-input-data">
                      <label className="blue-label">Data de Nascimento</label>
                      <DatePicker
                        selected={this.state.birthday}
                        onChange={async e => {
                          await this.setState({ birthday: e });
                        }}
                        timeIntervals={30}
                        dateFormat="dd/MM/yyyy"
                        timeCaption="time"
                        className="blue-input"
                      />
                    </div>

                    <div className="check-content">
                      <label className="container-ckb">
                        <input
                          type="checkbox"
                          checked={this.state.active_status}
                          onChange={ev => {
                            this.selectCoachee(ev);
                          }}
                        />
                        <span className="chklbl">
                          {this.state.active_status ? 'Ativado' : 'Desativado'}
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div
                      className="row-between mtop-50 mbottom-50"
                      style={{ justifyContent: 'center' }}
                    >
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(`coachees`);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar' : 'Cadastrar'}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoacheeCreate)
);
