import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';

import { Col } from 'reactstrap';

import Service from './Service.js';

import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../../sidebar/Sidebar';

import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';

class Form extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'coach',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Coaches', link: 'coachs' },
        { title: 'Cadastrar Coach', link: 'coachs/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [
        { label: 'Nome', key: 'name', type: 'text' },
        { label: 'Sobrenome', key: 'surname', type: 'text' },
        // { label: 'CPF', key: 'cpf', type: 'cpf' },
        { label: 'Data de Nascimento', key: 'birthday', type: 'birthday-mask' },
        { label: 'Email', key: 'email', type: 'email' },
        // { label:"Telefone", key:'phone', type:'phone-mask'},
        { label: 'Senha', key: 'password', type: 'password' },
        {
          label: 'Bloqueado',
          offlabel: 'Ativado',
          key: 'blocked',
          type: 'checkbox',
        },
        {
          label: 'Confimou email',
          offlabel: 'Não confirmou email',
          key: 'validated_email',
          type: 'checkbox',
        },
        {
          label: 'Já acessou',
          offlabel: 'Sem primeiro acesso',
          key: 'view_first_access',
          type: 'checkbox',
        },
        {
          label: 'Tem selo - Sociedade Brasileira de Coaching',
          offlabel: 'Não tem selo - Sociedade Brasileira de Coaching',
          key: 'brasilian_society',
          type: 'checkbox',
        },
      ],
      validateEmail: true,
      validateTestPeriod: false,
      statusDate: true,
      approvedCuration: false,
      activeCuration: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Coach',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.removePassword();
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      const content = result[this.state.view];
      let approvedCuration = false;
      let activeCuration = false;
      if (content.curation && content.curation.active) {
        approvedCuration = content.curation.status === 'Aprovado';
        activeCuration = true;
      }
      this.setState({ content, approvedCuration, activeCuration });
      const detailsTest = await this.service.getCoachTestDetails(_id);
      this.validatedTestPeriod(detailsTest);
      // this.setCPFReadOnly(result[this.state.view]);
    }
    this.setState({ loading_contents: false });
  }

  async save() {
    this.setState({ loading: true });
    await this.validateEmail(this.state.content.email);

    // if (
    //   this.state.content.birthday !== undefined &&
    //   this.state.content.birthday !== null &&
    //   this.state.content.birthday !== ''
    // ) {
    //   await this.setState({ statusDate: true });
    // } else {
    //   await this.setState({ statusDate: false });
    // }

    this.setState({ statusDate: true });
    if (this.state.statusDate && this.state.validateEmail) {
      let _pay = this.state.content;
      let curation = {};
      if (this.state.activeCuration && this.state.editing) {
        curation = {
          active: true,
          date: new Date(),
          status: this.state.approvedCuration ? 'Aprovado' : 'Solicitado',
          review: this.getReviewCuration(),
        };
      } else {
        curation = this.state.content.curation;
      }

      _pay = { ..._pay, curation: JSON.stringify(curation) };

      let result = this.state.editing
        ? await this.service.update(_pay, this.state.editing_id)
        : await this.service.create(_pay);

      if (result && result.success) {
        this.setSidebarLinks(this.state.breadcrumbs[1].link);
      }
      if (result.status === 'Error') {
        this.props.updateAlert(result.messages[0]);
      }
    }
    this.setState({ loading: false });
  }

  removePassword() {
    let items = this.state.items;
    for (let _k in items) {
      if (items[_k].type == 'password') {
        items.splice(_k, 1);
      }
    }
    this.setState({ items });
  }

  // setCPFReadOnly(user) {
  //   let items = this.state.items.map(item =>
  //     item.type === 'cpf' ? { ...item, readOnly: user.validated_cpf } : item
  //   );

  //   this.setState({ items });
  // }

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  async restartCoachTestPeriod() {
    if (!this.state.validateTestPeriod) {
      return;
    }
    this.setState({ loading: true });
    const response = await this.service.restartCoachTestPeriod({
      coach_id: this.state.editing_id,
    });
    if (response.success) {
      const auxContent = this.state.content;
      auxContent.test_restarted_at = response.result.test_restarted_at.toString();
      this.setState({ content: auxContent, validateTestPeriod: false });
      this.setState({ loading: false });
    } else {
      alert(
        'Não foi possível resetar o período de teste. Tente novamente mais tarde.'
      );
    }
    this.setState({ loading: false });
  }

  async validatedTestPeriod(detailsTest) {
    if (this.userHasPlataformPlan(detailsTest) && !detailsTest.isTestPeriod) {
      this.setState({ validateTestPeriod: false }); //NÃO pode resetar o período de testes
    } else {
      this.setState({ validateTestPeriod: true }); //pode resetar o período de testes
    }
  }

  userHasPlataformPlan(coach) {
    if (coach && coach.plans && coach.plans.platform) {
      return true;
    }
    return false;
  }

  getReviewCuration() {
    const statusCuration = this.state.content.curation.status;
    let reviewCuration = this.state.content.curation.review;
    if (
      (statusCuration === 'Solicitado' && !this.state.approvedCuration) ||
      (statusCuration === 'Aprovado' && this.state.approvedCuration)
    ) {
      reviewCuration = this.state.content.curation.review;
    }
    if (statusCuration === 'Aprovado' && !this.state.approvedCuration) {
      reviewCuration = true;
    }
    if (statusCuration === 'Solicitado' && this.state.approvedCuration) {
      reviewCuration = false;
    }
    return reviewCuration;
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                      validateEmail={this.validateEmail}
                    />

                    {this.state.activeCuration && (
                      <div className="check-content">
                        <label className="container-ckb">
                          <input
                            type="checkbox"
                            disabled={!this.state.activeCuration}
                            checked={this.state.approvedCuration}
                            onChange={() =>
                              this.setState({
                                approvedCuration: !this.state.approvedCuration,
                              })
                            }
                          />
                          <span className="chklbl">
                            {this.state.approvedCuration
                              ? 'Tem selo - Autenticidade climby'
                              : 'Não tem selo - Autenticidade climby'}
                          </span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    )}

                    <div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className={
                            !this.state.validateTestPeriod
                              ? 'bt-blue btoff'
                              : 'bt-blue'
                          }
                          onClick={() => {
                            this.restartCoachTestPeriod();
                          }}
                          style={{ fontSize: '14px' }}
                        >
                          Resetar período de teste
                        </div>
                      )}
                      {this.state.content.test_restarted_at &&
                      this.state.content.test_restarted_at.length > 0 ? (
                        <label className="text-restarted-at">
                          Resetado em:{' '}
                          {this.utils.parseDate(
                            this.state.content.test_restarted_at
                          )}
                        </label>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
