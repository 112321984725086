import ApiService from '../../../services/ApiService';
 

class Service extends ApiService{  

	path = 'admin/plans'

	async read() {
		try {
		    let response = await fetch(`${ this.endPoint }${ this.path }`, {
					method: 'GET',
					headers: {  
					    'Authorization': `JWT ${ this.getToken() }`
					}
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	} 

	async readOne(id) {
		try {
		    let response = await fetch(`${ this.endPoint }${ this.path }/${ id }`, {
					method: 'GET',
					headers: {  
					    'Authorization': `JWT ${ this.getToken() }`
					}
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	}  

	async create(_payload) {
		try {
		    let response = await fetch(`${ this.endPoint }${ this.path }`, {
					method: 'POST',
					headers: { 
					    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 
					    'Authorization': `JWT ${ this.getToken() }`
					},
					body: this.parsePayload(_payload), 
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	} 

	async update(_payload, _id) {
		try {
		    let response = await fetch(`${ this.endPoint }${ this.path }/${ _id }`, {
					method: 'PUT',
					headers: { 
					    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 
					    'Authorization': `JWT ${ this.getToken() }`
					},
					body: this.parsePayload(_payload), 
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	}  

	async delete(_id){
		try {
		    let response = await fetch(`${ this.endPoint }${ this.path }/${ _id }`, {
					method: 'DELETE',
					headers: { 
					    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 
					    'Authorization': `JWT ${ this.getToken() }`
					}
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	} 

}

export default Service;