import { useRef, useEffect } from 'react';

import ApiService from '../../services/ApiService';

const apiService = new ApiService();

function useVideoRoomSockets(type, roomName) {
  const socketRef = useRef();

  useEffect(() => {
    const query = apiService.parsePayload({
      channel: roomName,
      type,
    });

    socketRef.current = apiService.createSocket(query);

    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, []);

  return socketRef.current;
}

export { useVideoRoomSockets };
