import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/sessaoMasterDate/style.scss';
import { Col } from 'reactstrap';
import Switch from 'react-switch';
import {
  updateImage,
  updateAlert,
  updateSession,
  updateVideoSession,
  updateChronometer,
  updateChronometerSessions,
  updateActiveMeetingChronometer,
} from '../../../actions/SimpleAction';
import SidebarSessions from './../../sidebar/SidebarSessions';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';
import TableAdd from './TableAdd';
import ModalActions from './ModalActions';
import ModalContents from './ModalContents';
import ModalContentUpload from './ModalContentUpload';
import ContentUploadConfirmation from './ContentUploadConfirmation';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Chronometer, {
  chronometerInitialState,
  normalizeChronometerFromApi,
} from './../../components/Chronometer';
import videoRoomTypes, {
  roles as videoRoomRoles,
} from '../../../consts/videoRoomTypes';
import userTypes from '../../../consts/userTypes';
import sessionParamTypes from '../../../consts/sessionParamTypes';
import RichText from './../../../components/RichText';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getSessionInitialState = () => ({
  _id: '',
  oportunity_id: '',
  coachee_id: '',
  group_id: '',
  its_free: false,
  its_group: false,
  project: '',
  expected_date: new Date(),
  coachee_objectives: '',
  coachee_objectives_active: false,
  success_evidence: '',
  success_evidence_active: false,
  motivators: '',
  motivators_active: false,
  saboteurs: '',
  saboteurs_active: false,
  values: '',
  values_active: false,
  strategies: '',
  strategies_active: false,
  necessary_resources: '',
  necessary_resources_active: false,
  editorState: '',
});

const getMasterInitialState = () => ({
  chronometer: chronometerInitialState(),
  contents: [],
  actions: [],
});

class SessionMaster extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'master',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Sessão Master', link: 'programs/coaching' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,
      loading_oportunities: false,
      oportunities: [],
      loading_coachees: false,
      coachees: [],
      loading_groups: false,
      groups: [],
      loading_contents: false,
      contentList: [],
      session: getSessionInitialState(),
      master: getMasterInitialState(),
      showModalContents: false,
      showModalActions: false,
      showModalContentUpload: false,
      maxLength: 3660,
      isEditing: false,
      sortField: '',
      selectedContents: [],
      selectedCoachees: [],
    };
    this.props.updateChronometer(chronometerInitialState());
    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);

    this.toggleModalContent = this.toggleModalContent.bind(this);
    this.toggleContent = this.toggleContent.bind(this);
    this.removeContent = this.removeContent.bind(this);

    this.toggleModalAction = this.toggleModalAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.removeAction = this.removeAction.bind(this);
  }

  notify = async () => {
    const { session } = this.state;
    if (session._id && (session.coachee_id || session.group_id)) {
      this.setState({ loading: true });
      let result;
      if (session.coachee_id) {
        result = await this.service.notifyCoachee(
          session.coachee_id,
          session._id
        );
      } else {
        result = await this.service.notifyGroup(session.group_id, session._id);
      }
      if (result && result.success) {
        this.props.updateAlert('Compartilhado com sucesso');
      }
      this.setState({ loading: false });
    }
  };

  toggleContent = item => {
    let selectedContents = this.state.selectedContents;
    const isContentSelected = selectedContents.find(
      content => content._id === item._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== item._id
      );
    } else {
      selectedContents.push(item);
    }
    this.setState({ selectedContents });
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  removeContent = async item => {
    const { session } = this.state;
    if (session._id) {
      let result;
      if (session.its_free) {
        result = await this.service.destroySessionContent(
          session._id,
          'master',
          item.oportunity_id,
          item._id
        );
      } else {
        result = await this.service.destroySessionContent(
          session._id,
          'master',
          item.coachee_id,
          item._id
        );
      }
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          master: this.parseMaster(session.planning.master),
        });
      }
    }
  };

  toggleModalContent = async (saving = false, content = []) => {
    const {
      selectedContents,
      selectedCoachees,
      session,
      contentList,
    } = this.state;

    const hasContent = contentList.length > 0;
    if (!hasContent) {
      this.props.updateAlert(
        <ContentUploadConfirmation
          cancel={() => this.props.updateAlert('')}
          upload={() => {
            this.props.updateAlert('');
            this.setState({ showModalContentUpload: true });
          }}
        />
      );
      return;
    }

    const contentIds = selectedContents.map(content => content._id);
    if (saving && contentIds.length > 0 && session._id) {
      const sendTo = [];
      if (session.its_free && session.oportunity_id) {
        sendTo.push(session.oportunity_id);
      } else if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionContents(
          session._id,
          'master',
          sendTo,
          contentIds
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            master: this.parseMaster(session.planning.master),
          });
        }
      }
    }
    this.setState({
      showModalContents: !this.state.showModalContents,
      selectedContents: [...content],
      selectedCoachees: [],
    });
  };

  toggleModalContentUpload = async isSaved => {
    const { showModalContentUpload } = this.state;
    this.setState({ showModalContentUpload: !showModalContentUpload });
    if (isSaved) await this.loadContents(true);
  };

  toggleModalAction = () => {
    const { showModalActions } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
    });
  };

  saveAction = async action => {
    const { session, selectedCoachees } = this.state;
    if (session._id) {
      const sendTo = [];
      if (session.its_free && session.oportunity_id) {
        sendTo.push(session.oportunity_id);
      } else if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionActions(
          session._id,
          'master',
          sendTo,
          action
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            master: this.parseMaster(session.planning.master),
          });
        }
      }
    }
    this.toggleModalAction();
  };

  removeAction = async action => {
    const { session } = this.state;
    if (session._id) {
      let result;
      if (session.its_free) {
        result = await this.service.destroySessionAction(
          session._id,
          'master',
          action.oportunity_id,
          action.action
        );
      } else {
        result = await this.service.destroySessionAction(
          session._id,
          'master',
          action.coachee_id,
          action.action
        );
      }
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          master: this.parseMaster(session.planning.master),
        });
      }
    }
  };

  toggleMenu = async () => {
    const { sideOpen } = this.state;
    await this.setState({ sideOpen: !sideOpen });
  };

  togglePrincipal = async () => {
    const { principalOpen } = this.state;
    await this.setState({ principalOpen: !principalOpen });
  };

  goTo = page => {
    this.props.history.push(`/${page}`);
  };

  verifyLogin = () => {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  };

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadContents(),
        this.loadOportunities(),
        this.loadCoachees(),
      ])
        .then(() => this.resolveParams())
        .finally(() => this.setState({ isLoading: false }));
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams = () => {
    const {
      match: { params },
    } = this.props;

    const { session, user } = this.state;
    this.setState(
      {
        session: {
          ...session,
          its_free: params.session_type === sessionParamTypes.MASTER,
        },
      },
      async () => {
        this.setBreadcrumbs();

        if (params.sessionId) {
          await this.loadSessionById(params.sessionId);

          if (params.session && params.session !== 'master') {
            this.goTo(`dashboard/programs/coaching/register/${params.session}`);
          }
        }

        if (!this.state.session._id && this.props.sessionId) {
          const result = await this.service.sessionS(this.props.sessionId);
          if (result && result.success && result.session) {
            const isSessionTypeOk =
              result.session.its_free ===
              (params.session_type === sessionParamTypes.MASTER);

            if (
              (result.session.its_group &&
                !this.userHasGroupSessionRight(user)) ||
              !isSessionTypeOk
            ) {
              this.props.updateSession('');
            } else {
              this.resolveSessionRequest(result.session);
            }
          }
        }
      }
    );
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;
    if (session.oportunity_id) {
      return this.getOportunityName(session.oportunity_id);
    }
    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  setBreadcrumbs = () => {
    const { session } = this.state;
    const breadcrumbDetail = this.getBreadcrumbDetail();

    if (session.its_free) {
      this.setState({
        side: 'master',
        breadcrumbs: [
          { title: 'Dashboard', link: '' },
          { title: 'Programas', link: 'programs' },
          { title: 'Sessão Master Gratuita', link: 'programs/master' },
          ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
        ],
      });
    } else {
      this.setState({
        subview: 'master',
        side: 'coaching',
        breadcrumbs: [
          { title: 'Dashboard', link: '' },
          { title: 'Programas', link: 'programs' },
          { title: 'Sessão Coaching', link: 'programs/coaching' },
          { title: 'Sessão Master', link: 'programs/coaching' },
          ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
        ],
      });
    }
  };

  setSidebarLinks = view => {
    if (view == 'programs/master' || view == 'programs/coaching') {
      setTimeout(() => {
        let its_free = view == 'programs/master';
        if (this.state.session.its_free !== its_free) {
          this.clearForm();
        }
        this.resolveParams();
      }, 1);
    }
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  };

  handleResize = () => {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  };

  logout = () => {
    this.utils.setLogout();
    this.goTo('');
  };

  loadUser = async () => {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  };

  loadOportunities = async () => {
    this.setState({ loading_oportunities: true });
    let result = await this.service.oportunitiesFree();
    if (result && result.success) {
      this.setState({ oportunities: result.oportunities });
    }
    this.setState({ loading_oportunities: false });
  };

  loadCoachees = async () => {
    this.setState({ loading_coachees: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      let coachees = result.coachees.filter(item => {
        return item.status == 'true';
      });
      this.setState({ coachees });
    }
    this.setState({ loading_coachees: false });
  };

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  loadContents = async (openModalAfterLoad = false) => {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents }, () => {
        if (openModalAfterLoad) {
          const { contentList } = this.state;
          const [newContent] = contentList;
          if (newContent) this.toggleModalContent(false, [newContent]);
        }
      });
    }
    this.setState({ loading_contents: false });
  };

  parseSession = session => {
    const {
      _id,
      oportunity_id,
      coachee_id,
      group_id,
      its_free,
      its_group,
      project,
      expected_date,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    } = session;

    return {
      _id,
      oportunity_id,
      coachee_id,
      group_id,
      its_free,
      its_group,
      project,
      expected_date: expected_date ? new Date(expected_date) : undefined,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    };
  };

  parseMaster = master => {
    const { chronometer, contents, actions } = master;
    const auxChronometer = normalizeChronometerFromApi(chronometer);
    this.props.updateChronometer(auxChronometer);
    return {
      contents,
      actions,
      chronometer: this.props.chronometer,
    };
  };

  saveSession = () => {
    if (this.state.isEditing) {
      this.updateSession();
    } else {
      this.createSession();
    }
  };

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_free,
      its_group,
    } = this.state.session;
    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free,
      group_id,
      its_group,
    });
    if (result && result.success) {
      const { session } = result;
      this.setState(
        {
          session: this.parseSession(session),
          master: this.parseMaster(session.planning.master),
          isEditing: true,
        },
        () => {
          this.setBreadcrumbs();
        }
      );
      this.props.updateSession(session._id);
    }
    this.setState({ loading: false });
  };

  updateSession = async () => {
    this.setState({ loading: true });
    const { session } = this.state;
    const {
      _id: sessionId,
      project,
      expected_date,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    } = session;

    const chronometer = this.props.chronometer;

    let result = await this.service.updateSession(sessionId, {
      session: {
        project,
        expected_date,
        coachee_objectives,
        coachee_objectives_active,
        success_evidence,
        success_evidence_active,
        motivators,
        motivators_active,
        saboteurs,
        saboteurs_active,
        values,
        values_active,
        strategies,
        strategies_active,
        necessary_resources,
        necessary_resources_active,
      },
      master: { chronometer },
    });
    if (result && result.success) {
      this.setState({
        session: this.parseSession(result.session),
        master: this.parseMaster(result.session.planning.master),
      });
    }
    this.setState({ loading: false });
  };

  clearForm = () => {
    this.setState({
      session: getSessionInitialState(),
      master: getMasterInitialState(),
      isEditing: false,
    });
  };

  changeOportunity = e => {
    this.loadSessionByOportunityId(e.target.value);
  };

  changeCoachee = e => {
    this.loadSessionByCoacheeId(e.target.value);
    //zerar cronometros globais para evitar problemas
    this.props.updateActiveMeetingChronometer(null);
    this.props.updateChronometerSessions({});
  };

  changeGroup = e => {
    this.loadSessionByGroupId(e.target.value);
  };

  resolveSessionRequest = session => {
    this.setState(
      {
        session: this.parseSession(session),
        master: this.parseMaster(session.planning.master),
        isEditing: true,
      },
      () => {
        this.setBreadcrumbs();
      }
    );

    this.props.updateSession(session._id);
  };

  loadSessionById = async sessionId => {
    if (!sessionId) return;
    const result = await this.service.sessionS(sessionId);
    if (result && result.success) {
      const { session } = result;

      this.resolveSessionRequest(session);
    }
  };

  loadSessionByOportunityId = async oportunity_id => {
    if (!oportunity_id) return;
    const result = await this.service.session(oportunity_id);
    if (result && result.success) {
      const { session } = result;
      if (session) {
        this.resolveSessionRequest(session);
      } else if (oportunity_id) {
        const updateState = {
          group_id: '',
          coachee_id: '',
          oportunity_id,
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  loadSessionByCoacheeId = async coachee_id => {
    if (!coachee_id) return;
    const result = await this.service.sessionC(coachee_id);
    if (result && result.success) {
      const { session } = result;
      if (session) {
        this.resolveSessionRequest(session);
      } else if (coachee_id) {
        const updateState = {
          group_id: '',
          coachee_id,
          oportunity_id: '',
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;
      if (session) {
        this.resolveSessionRequest(session);
      } else if (group_id) {
        const updateState = {
          group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  saveForm = () => {
    const { isEditing, session } = this.state;
    if (
      isEditing &&
      (session.coachee_id || session.oportunity_id || session.group_id)
    ) {
      this.saveSession();
    }
  };

  sort = key => {
    let order =
      this.state.sortField === '' || this.state.sortField === key
        ? '-down'
        : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sortField: `${key}${order}`, contentList });
  };

  handleChange = checked => {
    this.setState({ checked });
  };

  handleChronometer = chronometer => {
    const { master } = this.state;
    this.props.updateChronometer(chronometer);
    this.setState({ master: { ...master, chronometer } });
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasProgramRegisterRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.use_program_register
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      this.userHasProgramRegisterRight(user) &&
      (!session.its_group ||
        (session.its_group && this.userHasGroupSessionRight(user)))
    );
  };

  showSessionFields = () => {
    return !!this.props.sessionId;
  };

  getOportunityName = oportunityId => {
    const oportunity = this.state.oportunities.find(
      oportunity => oportunity._id === oportunityId
    );
    return oportunity && oportunity.name;
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  copyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      this.notifyToast('Link copiado para a área de transferência', 'success');
    } catch (err) {
      console.error('Failed to copy: ', err);
      this.notifyToast('Falha ao copiar link', 'error');
    }
  };

  notifyToast = (text, type) => {
    const options = {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      toastId: 'url_copied',
      progressClassName: 'fancy-progress-bar',
      className: 'toast-zindex',
    };

    switch (type) {
      case 'success':
        toast(text, options);
        break;
      case 'error':
        toast.error(text, options);
        break;
      default:
    }
  };

  sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  render() {
    const {
      principalOpen,
      sideOpen,
      side,
      view,
      subview,
      breadcrumbs,
      loading_oportunity,
      oportunities,
      loading_coachees,
      coachees,
      loading_groups,
      groups,
      user,
      session,
      master,
      maxLength,
      loading,
      sortField,
      showModalContents,
      showModalContentUpload,
      contentList,
      selectedContents,
      showModalActions,
      selectedCoachees,
      isLoading,
    } = this.state;
    return (
      <div className="sessao-master-date-style main-content">
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  {!session.its_free ? (
                    <Submenu goTo={this.goTo} subview={subview} />
                  ) : null}

                  <div
                    className={
                      !session.its_free
                        ? 'dashboard-box box100-menu'
                        : 'dashboard-box box100'
                    }
                    style={session.its_free ? { minHeight: '73vh' } : null}
                  >
                    {session.coachee_id ||
                    session.oportunity_id ||
                    session.group_id ? (
                      <Chronometer
                        chronometer={this.props.chronometer}
                        handleChronometer={this.handleChronometer}
                        onSave={this.saveForm}
                      />
                    ) : null}
                    <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">
                          {session.its_free
                            ? 'Sessão master gratuita'
                            : 'Sessão master'}{' '}
                        </div>
                      </div>
                      {session.its_free ? (
                        <div className="form-input input-title">
                          <label className="blue-label">Oportunidade</label>
                          {loading_oportunity ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={session.oportunity_id}
                              className="blue-input"
                              onChange={e => {
                                this.changeOportunity(e);
                              }}
                            >
                              <option value=""> &nbsp; Oportunidade</option>
                              {oportunities.map((item, key) => {
                                return (
                                  <option key={key} value={item._id}>
                                    {' '}
                                    &nbsp; {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.userHasGroupSessionRight(user) && (
                            <div className="row-between">
                              <div
                                className={`bt-blue ${
                                  session.its_group ? 'btoff' : ''
                                }`}
                                onClick={() => {
                                  this.setState(
                                    {
                                      session: {
                                        ...getSessionInitialState(),
                                        its_group: false,
                                      },
                                      master: getMasterInitialState(),
                                    },
                                    () => this.setBreadcrumbs()
                                  );
                                  this.props.updateSession('');
                                }}
                              >
                                Individual
                              </div>
                              <div
                                className={`bt-blue ${
                                  session.its_group ? '' : 'btoff'
                                }`}
                                onClick={() => {
                                  this.setState(
                                    {
                                      session: {
                                        ...getSessionInitialState(),
                                        its_group: true,
                                      },
                                      master: getMasterInitialState(),
                                    },
                                    () => this.setBreadcrumbs()
                                  );
                                  this.props.updateSession('');
                                }}
                              >
                                Grupo
                              </div>
                            </div>
                          )}
                          {session.its_group ? (
                            <div className="form-input input-title">
                              <label className="blue-label">Grupo</label>
                              {loading_groups ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                <select
                                  value={session.group_id}
                                  className="blue-input"
                                  onChange={e => {
                                    this.changeGroup(e);
                                  }}
                                >
                                  <option value=""> &nbsp; Grupo</option>
                                  {groups.map((group, key) => {
                                    return (
                                      <option key={group._id} value={group._id}>
                                        {' '}
                                        &nbsp; {group.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            </div>
                          ) : (
                            <div className="form-input input-title">
                              <label className="blue-label">Coachee</label>
                              {loading_coachees ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                <select
                                  value={session.coachee_id}
                                  className="blue-input"
                                  onChange={e => {
                                    this.changeCoachee(e);
                                  }}
                                >
                                  <option value=""> &nbsp; Coachee</option>
                                  {coachees.map((item, key) => {
                                    return (
                                      <option key={key} value={item._id}>
                                        {' '}
                                        &nbsp; {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {this.showSessionFields() && (
                        <>
                          <div className="check-content">
                            <div className="form-input w57">
                              <label className="blue-label">Projeto</label>
                              <input
                                type="text"
                                value={session.project}
                                onBlur={this.saveForm}
                                className="blue-input"
                                placeholder="Projeto"
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      project: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="form-input">
                              <label className="blue-label special">
                                Data prevista para sucesso
                              </label>

                              <DatePicker
                                selected={session.expected_date}
                                onChange={e =>
                                  this.setState(
                                    {
                                      session: { ...session, expected_date: e },
                                    },
                                    () => this.saveForm()
                                  )
                                }
                                timeIntervals={30}
                                dateFormat="dd/MM/yyyy"
                                timeCaption="time"
                                className="blue-input tamanhoOldData"
                              />
                            </div>
                            {session.coachee_id ||
                            session.group_id ||
                            session.its_free ? (
                              <>
                                <div
                                  className="bt-blueicon container-pop bt-blueicon-video-session"
                                  onClick={() =>
                                    this.props.updateVideoSession({
                                      isActive: true,
                                      roomName: session._id,
                                      type: videoRoomTypes.SESSION,
                                      role: videoRoomRoles.moderator,
                                      subject: session.project,
                                      userType: userTypes.coach,
                                    })
                                  }
                                >
                                  <i className="fas fa-video" />
                                  <div className="popover-container">
                                    <div className="popover-content">
                                      Vídeo-chamada
                                    </div>
                                  </div>
                                </div>
                                {session.its_free ? (
                                  <div
                                    className={
                                      this.props.urlVideoSession
                                        ? 'bt-blueicon container-pop bt-blueicon-copy-video-session'
                                        : 'bt-blueicon container-pop bt-blueicon-copy-video-session disabled'
                                    }
                                    data-tip
                                    data-for="tooltip"
                                    onClick={() =>
                                      this.copyToClipboard(
                                        this.props.urlVideoSession
                                      )
                                    }
                                  >
                                    <i className="fas fa-link" />
                                    <ReactTooltip
                                      id="tooltip"
                                      type="light"
                                      effect="solid"
                                      textColor="#757575"
                                      borderColor="#B1B1B1"
                                      border={true}
                                    >
                                      <p style={{ maxWidth: 200 }}>
                                        Copiar link da chamada
                                      </p>
                                    </ReactTooltip>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">
                                {`Objetivo do ${
                                  session.its_group ? 'Grupo' : 'Coachee'
                                }`}
                              </label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.coachee_objectives}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder={`Objetivo do ${
                                    session.its_group ? 'Grupo' : 'Coachee'
                                  }`}
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        coachee_objectives: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.coachee_objectives
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        coachee_objectives_active: !session.coachee_objectives_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.coachee_objectives_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">
                                Evidência de Sucesso
                              </label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.success_evidence}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Evidência de Sucesso"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        success_evidence: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.success_evidence
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        success_evidence_active: !session.success_evidence_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.success_evidence_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">Motivadores</label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.motivators}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Motivadores"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        motivators: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.motivators
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        motivators_active: !session.motivators_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.motivators_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">Sabotadores</label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.saboteurs}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Sabotadores"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        saboteurs: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.saboteurs
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        saboteurs_active: !session.saboteurs_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.saboteurs_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">Valores</label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.values}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Valores"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        values: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.values
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        values_active: !session.values_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.values_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">Estratégias</label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.strategies}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Estratégias"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        strategies: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.strategies
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        strategies_active: !session.strategies_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.strategies_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>

                          <div className="row-form adjust-flex top-textarea">
                            <div className="form-textarea maxWidth clear-textarea">
                              <label className="blue-label">
                                Recursos necessários
                              </label>
                              <div className="textarea">
                                <RichText
                                  setState={this.setState.bind(this)}
                                  state={this.state}
                                  value={session.necessary_resources}
                                  showToolbar={true}
                                  minHeight={260}
                                  height={260}
                                  maxLength={maxLength}
                                  placeholder="Recursos necessários"
                                  onBlur={this.saveForm}
                                  onChange={e => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        necessary_resources: e,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <span>
                                {this.utils.getLengthStringOfHtml(
                                  session.necessary_resources
                                )}
                                /{maxLength}
                              </span>
                            </div>

                            {session.coachee_id || session.group_id ? (
                              <div
                                style={{
                                  marginTop: 50,
                                  marginRight: 10,
                                  marginLeft: 25,
                                }}
                              >
                                <Switch
                                  onChange={() => {
                                    this.setState({
                                      session: {
                                        ...session,
                                        necessary_resources_active: !session.necessary_resources_active,
                                      },
                                    });
                                    setTimeout(() => {
                                      this.saveForm();
                                    }, 500);
                                  }}
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={session.necessary_resources_active}
                                />
                                <p className="detail-toggle">
                                  Liberar comentário
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </Col>

                    {this.showSessionFields() && (
                      <>
                        <Col
                          className="mtop-100"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de conteúdos"
                            linked={'content'}
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Coachee',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Título',
                                key: 'title',
                              },
                              {
                                title: 'Formato',
                                key: 'doc_format',
                              },
                              {
                                title: 'Categoria',
                                key: 'category',
                              },
                              {
                                title: 'Modelo',
                                key: 'tag',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeContent}
                            toggle={this.toggleModalContent}
                            button_label="Adicionar Conteúdo"
                            button_icon="fas fa-paperclip"
                            values={master.contents.map(content => ({
                              ...content,
                              coacheeName: content.coachee
                                ? content.coachee.name
                                : '',
                            }))}
                          />
                        </Col>

                        <Col
                          className="mtop-100"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de ações"
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Coachee',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Ação',
                                key: 'action',
                              },
                              {
                                title: 'Descrição',
                                key: 'description',
                              },
                              {
                                title: 'Responsável',
                                key: 'responsible',
                              },
                              {
                                title: 'Data para conclusão',
                                key: 'date',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeAction}
                            toggle={this.toggleModalAction}
                            button_label="Adicionar uma Ação"
                            button_icon="fas fa-plus"
                            values={master.actions.map(action => ({
                              ...action,
                              coacheeName: action.coachee
                                ? action.coachee.name
                                : '',
                            }))}
                          />

                          <div
                            className="row-between mtop-50 mbottom-50"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {(session.coachee_id || session.group_id) &&
                              !loading && (
                                <div
                                  className="bt-blue"
                                  onClick={() => {
                                    this.notify();
                                  }}
                                >
                                  Compartilhar
                                </div>
                              )}
                            {loading ? (
                              <ReactLoading type="bubbles" color="#00838F" />
                            ) : null}
                          </div>
                        </Col>
                      </>
                    )}
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={sortField}
          show={showModalContents}
          contentList={contentList}
          selectedContents={selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalActions
          toggleModalAction={this.toggleModalAction}
          saveAction={this.saveAction}
          modal_actions={showModalActions}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalContentUpload
          show={showModalContentUpload}
          toggle={this.toggleModalContentUpload}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
  chronometer: state.simpleReducer.chronometer,
  urlVideoSession: state.simpleReducer.urlVideoSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateAlert,
      updateSession,
      updateVideoSession,
      updateChronometer,
      updateChronometerSessions,
      updateActiveMeetingChronometer,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SessionMaster)
);
