import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Switch from 'react-switch'

import { updateImage, updateUser } from '../../actions/SimpleAction'
import Service from './../dashboard/Profile/Service.js';
// import Avatar from '../avatar/Avatar';
// import Logo from '../logo/Logo';

class SidebarProfile extends Component {

  constructor(props) {
    super(props); 
    this.state = { };  

    this.service = new Service();  
  }

  componentDidMount(){ 
  }
  

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li onClick={ () => { this.props.setSidebarLinks(''); } } >
          <img src={ require('./../../assets/images/menu/icon-0.svg') } width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-0w.svg') } width="28" className="image-over"  /> 
          <span>Dashboard</span>
        </li>
        <li className={ this.props.view == 'profile' ? 'active row-between-center flex1' : 'row-between-center flex1' } 
            onClick={ () => { this.props.goTo('admin/dashboard/profile') } }>
            <img src={ require('./../../assets/images/menu/icon-8.svg') } width="28" /> 
            <img src={ require('./../../assets/images/menu/icon-8w.svg') } width="28" className="image-over"  /> 
            <div className="row-between-center flex1">
              <span>Dados Básicos</span> 
            </div>
        </li>  
        {/*<li className={ this.props.view == 'terms' ? 'active' : '' } 
            onClick={ () => { this.props.goTo('admin/dashboard/terms') } }>
          <img src={ require('./../../assets/images/menu/icon-11.svg') } width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-11w.svg') } width="28" className="image-over"  /> 
          <span>Termos de Uso</span>
        </li>  */}
        <li className={ this.props.view == 'changepassword' ? 'active' : '' } 
            onClick={ () => { this.props.goTo('admin/dashboard/profile/changepassword') } }>
          <img src={ require('./../../assets/images/menu/icon-12.svg') } width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-12w.svg') } width="28" className="image-over"  /> 
          <span>Alterar Senha</span>
        </li> 
        <li onClick={ this.props.logout }>
          <img src={ require('./../../assets/images/menu/icon-13.svg') } width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-13w.svg') } width="28" className="image-over"  /> 
          <span>Logout</span>
        </li>  
      </ul>
    );
  }
}
const mapStateToProps = state => ({ 
  image: state.simpleReducer.image,
  user: state.simpleReducer.user
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProfile); 