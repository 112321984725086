import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Col } from 'reactstrap';
import Switch from 'react-switch';
import Chronometer, {
  chronometerInitialState,
  normalizeChronometerFromApi,
} from './../../components/Chronometer';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/sessaoMasterDate/style-register.scss';
import {
  updateImage,
  updateAlert,
  updateVideoSession,
  updateChronometerSessions,
  updateActiveMeetingChronometer,
} from '../../../actions/SimpleAction';
import SidebarSessions from './../../sidebar/SidebarSessions';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';
import TableAdd from './TableAdd';
import ModalActions from './ModalActions';
import ModalContents from './ModalContents';
import ModalContentUpload from './ModalContentUpload';
import ContentUploadConfirmation from './ContentUploadConfirmation';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import ModalAlertCustom from './../../components/ModalAlertCustom';
import 'react-datepicker/dist/react-datepicker.css';
import RichText from './../../../components/RichText';

import videoRoomTypes, {
  roles as videoRoomRoles,
} from '../../../consts/videoRoomTypes';
import userTypes from '../../../consts/userTypes';

class SessionRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'register',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de sessões', link: 'programs/coaching/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: true,
      loading_coachee: false,
      coachees: [],
      loading_contents: false,
      contentList: [],
      loading_groups: false,
      groups: [],
      selectedContents: [],
      selectedCoachees: [],
      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
      },
      activeMeeting: 'master',
      meetings: {},
      showModalContents: false,
      showModalActions: false,
      showModalContentUpload: false,
      maxLength: 3660,
      sortField: '',
      hasNoMeetings: false,
      alert: '',
      chronometer: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  notify = async () => {
    const { session } = this.state;
    const { sessionId } = this.props;

    if (sessionId && (session.coachee_id || session.group_id)) {
      this.setState({ loading: true });
      let result;
      if (session.coachee_id) {
        result = await this.service.notifyCoachee(
          session.coachee_id,
          sessionId
        );
      } else {
        result = await this.service.notifyGroup(session.group_id, sessionId);
      }
      if (result && result.success) {
        this.props.updateAlert('Compartilhado com sucesso');
      }
      this.setState({ loading: false });
    }
  };

  parseSession = session => {
    const { coachee_id, project, group_id, its_group } = session;
    return { coachee_id, project, group_id, its_group };
  };

  parseMeetings = session => {
    const arrayMeetings = [...session.planning.meetings];
    const meetings = {};

    arrayMeetings.forEach(meeting => {
      meeting.date = meeting.date ? new Date(meeting.date) : undefined;
      meeting.chronometer = meeting.chronometer
        ? normalizeChronometerFromApi(meeting.chronometer)
        : chronometerInitialState();

      meetings[meeting.number] = meeting;
    });

    return meetings;
  };

  toggleModalContent = async (saving = false, content = []) => {
    const { sessionId } = this.props;
    const {
      selectedContents,
      selectedCoachees,
      session,
      activeMeeting,
      contentList,
    } = this.state;

    const hasContent = contentList.length > 0;
    if (!hasContent) {
      this.props.updateAlert(
        <ContentUploadConfirmation
          cancel={() => this.props.updateAlert('')}
          upload={() => {
            this.props.updateAlert('');
            this.setState({ showModalContentUpload: true });
          }}
        />
      );
      return;
    }

    const contentIds = selectedContents.map(content => content._id);
    if (saving && contentIds.length > 0 && sessionId) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionContents(
          sessionId,
          activeMeeting,
          sendTo,
          contentIds
        );

        if (result && result.success) {
          const { session } = result;
          const meetings = this.parseMeetings(session);
          this.setState({
            session: this.parseSession(session),
            meetings,
          });
        }
      }
    }
    this.setState({
      showModalContents: !this.state.showModalContents,
      selectedContents: [...content],
      selectedCoachees: [],
    });
  };

  handleHtmlAlert() {
    return (
      <div>
        <p>
          Você ainda não tem nenhuma sessão planejada. Para planejar uma sessão,
          acesse o Planejamento de Sessões.
        </p>
        <br />
        <div className="btn-footer-modal">
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => {
              this.goTo('dashboard/programs/coaching/planning');
            }}
          >
            Planejamento de sessões
          </div>
        </div>
      </div>
    );
  }

  handleNoMeeting() {
    return (
      <ModalAlertCustom
        showCloseIcon={false}
        alert={this.state.alert}
        close={() => {
          this.setState({ alert: '' });
        }}
      />
    );
  }

  toggleModalAction = () => {
    const { showModalActions } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
    });
  };

  toggleContent = async item => {
    let selectedContents = [...this.state.selectedContents];
    const isContentSelected = selectedContents.find(
      content => content._id === item._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== item._id
      );
    } else {
      selectedContents.push(item);
    }
    this.setState({ selectedContents });
  };

  toggleModalContentUpload = async isSaved => {
    const { showModalContentUpload } = this.state;
    this.setState({ showModalContentUpload: !showModalContentUpload });
    if (isSaved) await this.loadContents(true);
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  removeContent = async item => {
    const { sessionId } = this.props;
    const { activeMeeting } = this.state;
    if (sessionId) {
      const result = await this.service.destroySessionContent(
        sessionId,
        activeMeeting,
        item.coachee_id,
        item._id
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  saveAction = async action => {
    const { sessionId } = this.props;
    const { selectedCoachees, session, activeMeeting } = this.state;
    if (sessionId) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionActions(
          sessionId,
          activeMeeting,
          sendTo,
          action
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            meetings: this.parseMeetings(session),
          });
        }
      }
    }
    this.toggleModalAction();
  };

  removeAction = async action => {
    const { sessionId } = this.props;
    const { activeMeeting } = this.state;
    if (sessionId) {
      const result = await this.service.destroySessionAction(
        sessionId,
        activeMeeting,
        action.coachee_id,
        action.action
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadCoachees(),
        this.loadContents(),
      ])
        .then(() => this.resolveParams())
        .finally(() => {
          this.setBreadcrumbs();
          this.setState({ isLoading: false });
        });
    });

    window.addEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de sessões', link: 'programs/coaching/register' },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async resolveParams() {
    const {
      match: { params },
    } = this.props;

    if (!this.props.sessionId) {
      this.goTo('dashboard/programs/coaching');
      return;
    }

    this.setState({ loading: true });
    const result = await this.service.sessionS(this.props.sessionId);
    if (result && result.success && result.session) {
      if (result.session.its_free) {
        this.goTo('dashboard/programs/master');
        return;
      }

      const parsedMeetings = this.parseMeetings(result.session);

      if (Object.keys(parsedMeetings).length === 0) {
        this.setState({
          loading: false,
          hasNoMeetings: true,
          alert: this.handleHtmlAlert(),
        });
      } else {
        this.setState(
          {
            session: this.parseSession(result.session),
            meetings: parsedMeetings,
            activeMeeting: Object.keys(parsedMeetings).sort(
              this.sortMeetings
            )[0],
          },
          () => {
            if (!this.props.activeMeetingChronometer) {
              this.props.updateActiveMeetingChronometer(
                this.state.activeMeeting
              );
              this.setChronometerActiveMeeting(this.state.activeMeeting);
            } else {
              this.setChronometerActiveMeeting(
                this.props.activeMeetingChronometer
              );
            }
          }
        );
      }

      this.setState({ loading: false });

      if (params.session) {
        this.changeMeeting(params.session);
      }
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }

    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  async loadContents(openModalAfterLoad = false) {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents }, () => {
        if (openModalAfterLoad) {
          const { contentList } = this.state;
          const [newContent] = contentList;
          if (newContent) this.toggleModalContent(false, [newContent]);
        }
      });
    }
    this.setState({ loading_contents: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  changeMeeting = meetingNumber => {
    this.setState({ activeMeeting: meetingNumber });

    if (
      this.props.chronometerSession &&
      (!this.props.chronometerSession.startTime ||
        this.props.chronometerSession.startTime === null)
    ) {
      this.props.updateActiveMeetingChronometer(meetingNumber);
      this.setChronometerActiveMeeting(meetingNumber);
    }
  };

  updateMeeting = async payload => {
    const { sessionId } = this.props;
    const { activeMeeting } = this.state;
    if (sessionId) {
      const result = await this.service.updateMeeting(
        sessionId,
        activeMeeting,
        payload
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  sort(key) {
    let order =
      this.state.sortField == '' || this.state.sortField == key ? '-down' : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });

    this.setState({ sortField: `${key}${order}`, contentList });
  }

  getActiveMeetingValue = fieldname => {
    const { meetings, activeMeeting } = this.state;
    if (!meetings[activeMeeting]) return;
    return meetings[activeMeeting][fieldname];
  };

  setActiveMeetingValue = payload => {
    const { meetings, activeMeeting } = this.state;
    this.setState({
      meetings: {
        ...meetings,
        [activeMeeting]: {
          ...meetings[activeMeeting],
          ...payload,
        },
      },
    });
  };

  sortMeetings = (a, b) => {
    return a.number - b.number;
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasProgramPlanningRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.use_program_planning
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      this.userHasProgramPlanningRight(user) &&
      (!session.its_group ||
        (session.its_group && this.userHasGroupSessionRight(user)))
    );
  };

  handleChronometer = chronometer => {
    const { meetings, activeMeeting } = this.state;
    this.props.updateChronometerSessions(chronometer);
    this.setState({
      meetings: {
        ...meetings,
        [activeMeeting]: {
          ...meetings[activeMeeting],
          ...chronometer,
        },
      },
    });
  };

  getChronometerFromActiveMeeting = activeMeeting => {
    const { meetings } = this.state;
    if (!meetings[activeMeeting]) return;
    return meetings[activeMeeting]['chronometer'];
  };

  setChronometerActiveMeeting = activeMeeting => {
    const chronometer = this.getChronometerFromActiveMeeting(activeMeeting);
    if (
      this.props.chronometerSession &&
      (!this.props.chronometerSession.startTime ||
        this.props.chronometerSession.startTime === null)
    )
      this.props.updateChronometerSessions(chronometer);
  };

  updateMeetingChronometer = async chronometer => {
    const { sessionId } = this.props;
    if (sessionId) {
      const result = await this.service.updateMeeting(
        sessionId,
        this.props.activeMeetingChronometer,
        chronometer
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  renderChronometer = (activeMeeting, chronometer) => {
    return (
      <Chronometer
        activeMeeting={activeMeeting}
        chronometer={chronometer}
        handleChronometer={this.handleChronometer}
        onSave={() => {
          if (activeMeeting !== this.state.activeMeeting)
            this.updateMeetingChronometer({
              chronometer: chronometer,
            });
          else
            this.updateMeeting({
              chronometer: chronometer,
            });
        }}
      />
    );
  };

  addZero = n => (n < 10 ? `0${n}` : n);

  renderTimeSession = activeMeeting => {
    const chronometer = this.getChronometerFromActiveMeeting(activeMeeting);
    const diff = chronometer.diff;
    const hours = diff ? chronometer.diff.hours() : 0;
    const minutes = diff ? chronometer.diff.minutes() : 0;
    const seconds = diff ? chronometer.diff.second() : 0;
    const hundredths = diff
      ? Math.round(chronometer.diff.millisecond() / 10)
      : 0;
    return (
      <div className="timesession-div">
        <label className="blue-label">Tempo da sessão </label>
        <div className="timesession-info">
          <span>
            {this.addZero(hours)}:{this.addZero(minutes)}:
            {this.addZero(seconds)}:{this.addZero(hundredths)}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      user,
      loading,
      session,
      coachees,
      groups,
      meetings,
      activeMeeting,
      maxLength,
      sortField,
      showModalContents,
      showModalContentUpload,
      contentList,
      selectedContents,
      selectedCoachees,
      showModalActions,
      isLoading,
      hasNoMeetings,
    } = this.state;
    return (
      <div className="sessao-master-date-style-register main-content">
        <Header
          style={hasNoMeetings ? { zIndex: 0 } : {}}
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={subview} />
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : !this.userHasRights(user) ? (
                  <NotAllowed go={this.goTo.bind(this)} user={user} />
                ) : loading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : !hasNoMeetings ? (
                  <div className="dashboard-box box100-menu">
                    {this.renderChronometer(
                      this.props.activeMeetingChronometer,
                      this.props.chronometerSession
                    )}
                    <div className="box-row-title row-center">
                      <div className="box-title">Registro de Sessões</div>
                    </div>

                    <Col
                      md={{ size: 4, offset: 8 }}
                      className="row-info-project"
                    >
                      <div>
                        {session.coachee_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Coachee</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.coachee_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Coachee</option>
                                {coachees.map(coachee => {
                                  return (
                                    <option
                                      key={coachee._id}
                                      value={coachee._id}
                                    >
                                      {' '}
                                      &nbsp; {coachee.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : session.group_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Grupo</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.group_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Grupo</option>
                                {groups.map(group => {
                                  return (
                                    <option key={group._id} value={group._id}>
                                      {' '}
                                      &nbsp; {group.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <div className="form-input">
                          <label className="blue-label">Projeto</label>
                          <input
                            disabled={true}
                            type="text"
                            value={session.project}
                            className="blue-input"
                            placeholder="Projeto"
                          />
                        </div>
                      </div>
                    </Col>

                    <div>
                      <ul className="list-sessions">
                        {Object.values(meetings)
                          .sort(this.sortMeetings)
                          .map((meeting, key) => {
                            return (
                              <span
                                key={String(meeting.number)}
                                className={
                                  String(meeting.number) ===
                                  String(activeMeeting)
                                    ? 'active'
                                    : ''
                                }
                              >
                                {key !== 0 &&
                                Object.values(meetings) &&
                                Object.values(meetings).length > 1 ? (
                                  <li className="line-sessions"></li>
                                ) : null}
                                <li
                                  onClick={() => {
                                    this.changeMeeting(meeting.number);
                                  }}
                                >
                                  Sessão {meeting.number}
                                </li>
                              </span>
                            );
                          })}
                      </ul>
                    </div>

                    <Col xs={{ size: 12 }} md={{ size: 7, offset: 3 }}>
                      <div>
                        {/* {this.renderTimeSession(activeMeeting)} */}
                        <div className="new-check-content">
                          <div className="form-input w57">
                            <label className="blue-label">Tema da sessão</label>
                            <input
                              type="text"
                              value={this.getActiveMeetingValue(
                                'session_theme'
                              )}
                              className="blue-input"
                              placeholder="Tema da sessão"
                              onBlur={e => {
                                this.updateMeeting({
                                  session_theme: e.target.value,
                                });
                              }}
                              onChange={e =>
                                this.setActiveMeetingValue({
                                  session_theme: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div
                            className="new-form-input"
                            style={{ marginLeft: 5 }}
                          >
                            <label className="blue-label">Data</label>

                            <DatePicker
                              selected={this.getActiveMeetingValue('date')}
                              onChange={e => this.updateMeeting({ date: e })}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={30}
                              dateFormat="dd/MM/yyyy HH:mm"
                              timeCaption="hora"
                              className="blue-input"
                              popperModifiers={{
                                preventOverflow: {
                                  enabled: true,
                                },
                              }}
                            />
                          </div>
                          <div
                            className="bt-blueicon container-pop bt-blueicon-video-session"
                            onClick={() =>
                              this.props.updateVideoSession({
                                isActive: true,
                                roomName: this.props.sessionId,
                                type: videoRoomTypes.SESSION,
                                role: videoRoomRoles.moderator,
                                subject: this.getActiveMeetingValue(
                                  'session_theme'
                                ),
                                userType: userTypes.coach,
                              })
                            }
                          >
                            <i className="fas fa-video" />
                            <div className="popover-container">
                              <div className="popover-content">
                                Vídeo-chamada
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row-form adjust-flex top-textarea">
                          <div className="form-textarea maxWidth clear-textarea">
                            <label className="blue-label">{`Comentário para o ${
                              session.its_group ? 'Grupo' : 'Coachee'
                            }`}</label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={this.getActiveMeetingValue(
                                  'comment_coachee'
                                )}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder={`Comentário para o ${
                                  session.its_group ? 'Grupo' : 'Coachee'
                                }`}
                                onBlur={e =>
                                  this.updateMeeting({
                                    comment_coachee: e,
                                  })
                                }
                                onChange={e =>
                                  this.setActiveMeetingValue({
                                    comment_coachee: e,
                                  })
                                }
                              />
                            </div>
                            <span>
                              {this.getActiveMeetingValue('comment_coachee')
                                ? this.utils.getLengthStringOfHtml(
                                    this.getActiveMeetingValue(
                                      'comment_coachee'
                                    )
                                  )
                                : '0'}
                              /{maxLength}
                            </span>
                          </div>

                          <div
                            style={{
                              marginTop: 50,
                              marginRight: 10,
                              marginLeft: 25,
                            }}
                          >
                            <Switch
                              onChange={() => {
                                const comment_coachee_active = !this.getActiveMeetingValue(
                                  'comment_coachee_active'
                                );
                                this.setActiveMeetingValue({
                                  comment_coachee_active,
                                });

                                setTimeout(() => {
                                  this.updateMeeting({
                                    comment_coachee_active,
                                  });
                                }, 500);
                              }}
                              id={`toggle-visibility`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#D8D8D8'}
                              offHandleColor={'#9F9F9F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              width={40}
                              height={20}
                              checked={this.getActiveMeetingValue(
                                'comment_coachee_active'
                              )}
                            />
                            <p className="detail-toggle">Liberar comentário</p>
                          </div>
                        </div>
                        <div className="row-form top-textarea">
                          <div className="form-textarea maxWidth clear-textarea">
                            <label className="blue-label">
                              Comentário privativo
                            </label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={this.getActiveMeetingValue(
                                  'comment_private'
                                )}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder="Comentário privativo"
                                onBlur={e =>
                                  this.updateMeeting({
                                    comment_private: e,
                                  })
                                }
                                onChange={e =>
                                  this.setActiveMeetingValue({
                                    comment_private: e,
                                  })
                                }
                              />
                            </div>
                            <span>
                              {this.getActiveMeetingValue('comment_private')
                                ? this.utils.getLengthStringOfHtml(
                                    this.getActiveMeetingValue(
                                      'comment_private'
                                    )
                                  )
                                : '0'}
                              /{maxLength}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      className="mtop-100"
                      xs={{ size: 12 }}
                      md={{ size: 10, offset: 1 }}
                    >
                      <TableAdd
                        title="Tabela de conteúdos"
                        linked={'content'}
                        rows={[
                          ...(session.its_group
                            ? [
                                {
                                  title: 'Coachee',
                                  key: 'coacheeName',
                                },
                              ]
                            : []),
                          {
                            title: 'Título',
                            key: 'title',
                          },
                          {
                            title: 'Formato',
                            key: 'doc_format',
                          },
                          {
                            title: 'Categoria',
                            key: 'category',
                          },
                          {
                            title: 'Modelo',
                            key: 'tag',
                          },
                          {
                            title: 'Remover',
                            key: 'remove',
                          },
                        ]}
                        remove={this.removeContent}
                        toggle={this.toggleModalContent}
                        button_label="Adicionar Conteúdo"
                        button_icon="fas fa-paperclip"
                        values={this.getActiveMeetingValue('contents').map(
                          content => ({
                            ...content,
                            coacheeName: content.coachee.name,
                          })
                        )}
                      />
                    </Col>

                    <Col
                      className="mtop-100"
                      xs={{ size: 12 }}
                      md={{ size: 10, offset: 1 }}
                    >
                      <TableAdd
                        title="Tabela de ações"
                        rows={[
                          ...(session.its_group
                            ? [
                                {
                                  title: 'Coachee',
                                  key: 'coacheeName',
                                },
                              ]
                            : []),
                          {
                            title: 'Ação',
                            key: 'action',
                          },
                          {
                            title: 'Descrição',
                            key: 'description',
                          },
                          {
                            title: 'Responsável',
                            key: 'responsible',
                          },
                          {
                            title: 'Data para conclusão',
                            key: 'date',
                          },
                          {
                            title: 'Remover',
                            key: 'remove',
                          },
                        ]}
                        remove={this.removeAction}
                        toggle={this.toggleModalAction}
                        button_label="Adicionar uma Ação"
                        button_icon="fas fa-plus"
                        values={this.getActiveMeetingValue('actions').map(
                          action => ({
                            ...action,
                            coacheeName: action.coachee.name,
                          })
                        )}
                      />
                    </Col>

                    <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
                      <div className="row-between mtop-50 mbottom-50">
                        {(session.coachee_id || session.group_id) && !loading && (
                          <div
                            className="bt-blue"
                            // style={{ margin: '0 auto' }}
                            onClick={() => {
                              this.notify();
                            }}
                          >
                            Compartilhar
                          </div>
                        )}
                        {loading ? (
                          <ReactLoading
                            type="bubbles"
                            style={{ margin: '0 auto' }}
                            color="#00838F"
                          />
                        ) : null}
                      </div>
                    </Col>
                  </div>
                ) : (
                  this.handleNoMeeting()
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={sortField}
          show={showModalContents}
          contentList={contentList}
          selectedContents={selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalActions
          toggleModalAction={this.toggleModalAction}
          saveAction={this.saveAction}
          modal_actions={showModalActions}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />
        <ModalContentUpload
          show={showModalContentUpload}
          toggle={this.toggleModalContentUpload}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
  chronometerSession: state.simpleReducer.chronometerSession,
  activeMeetingChronometer: state.simpleReducer.activeMeetingChronometer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateAlert,
      updateVideoSession,
      updateChronometerSessions,
      updateActiveMeetingChronometer,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SessionRegister)
);
