import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/datePicker/style.scss';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import { updateImage, updateUser } from '../../../actions/SimpleAction';
import UploadFile from './UploadFile';
import Utils from './../../../libs/Utils.js';
import Service from './../../../services/ApiService.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Form extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      aadd: {},
      maxLength: 3660,
    };

    this.service = new Service();
    this.utils = new Utils();
  }

  componentDidMount() {
    // this.initSocket()
  }

  componentWillUnmount() {
    // this.socket.disconnect()
  }

  add(item) {
    if (this.state.aadd != '') {
      let arr = this.props.content[item.key]
        ? this.props.content[item.key]
        : [];
      let aadd = this.state.aadd;
      arr.push({ [item.option]: aadd[item.key] });
      aadd[item.key] = '';
      this.setState({ aadd });
      this.props.setState({
        content: { ...this.props.content, [item.key]: arr },
      });
    }
  }

  remove(item, key) {
    let arr = this.props.content[item.key];
    arr.splice(key, 1);
    this.props.setState({
      content: { ...this.props.content, [item.key]: arr },
    });
  }

  changeSelect(e, item) {
    let content = this.props.content;
    content[item.key] = e.target.value;
    // if(item.key == 'doc_source'){ content['html'] = e.target.value; }
    this.props.setState({ content });
  }

  async loadUser() {}

  initSocket() {
    let socketParams = {
      channel: `socket`,
      type: 'admin',
    };

    this.socket = this.service.createSocket(
      this.service.parsePayload(socketParams)
    );

    this.socket.off(`admin.message.socket`);
    setTimeout(() => {
      this.socket.on(`admin.message.socket`, message => {
        // this.playSound()
      });
    }, 1000);
  }

  render() {
    return (
      <div className="datepicker-style">
        {this.props.items.map((item, key) => {
          return item.hide &&
            this.props.content[item.hide.key] ==
              item.hide.value ? null : item.show &&
            this.props.content[item.show.key] !=
              item.show.value ? null : item.type == 'text' ||
            item.type == 'email' ||
            item.type == 'phone' ||
            item.type == 'password' ||
            item.type == 'number' ? (
            <div className="form-input" key={key}>
              <label className="blue-label">{item.label}</label>
              {!this.props.content ? null : (
                <input
                  type={item.type}
                  value={this.props.content ? this.props.content[item.key] : ''}
                  className="blue-input"
                  maxLength={item.maxLength}
                  minLength={item.minLength}
                  name={item.key}
                  max={item.max}
                  min={item.min}
                  onChange={e => {
                    if (this.props.setItemError) {
                      this.props.setItemError(item.key, '');
                    }
                    this.props.setState({
                      content: {
                        ...this.props.content,
                        [item.key]: e.target.value,
                      },
                    });
                    if (item.type === 'email') {
                      this.props.validateEmail(e.target.value);
                    }
                  }}
                  readOnly={item.readOnly}
                />
              )}
              {item.error && (
                <span className="campObrigatorio">{item.error}</span>
              )}
              {this.props.state.validateEmail && item.type === 'email' && (
                <span className="campObrigatorio">
                  {this.props.state.validateEmail}
                </span>
              )}
            </div>
          ) : // : item.type == 'cpf' ? (
          //   <div className="form-input" key={key}>
          //     <label className="blue-label">{item.label}</label>
          //     <InputMask
          //       mask="999.999.999-99"
          //       value={this.props.content[item.key]}
          //       className="blue-input"
          //       onChange={e => {
          //         this.props.setState({
          //           content: {
          //             ...this.props.content,
          //             [item.key]: e.target.value.replace(/\D/g, ''),
          //           },
          //         });
          //       }}
          //       readOnly={item.readOnly}
          //     />
          //   </div>
          // )
          item.type == 'phone-mask' ? (
            <div className="form-input" key={key}>
              <label className="blue-label">{item.label}</label>
              <InputMask
                {...this.props}
                mask="(99) 999999999"
                value={this.props.content[item.key]}
                className="blue-input"
                onChange={e => {
                  this.props.setState({
                    content: {
                      ...this.props.content,
                      [item.key]: e.target.value,
                    },
                  });
                }}
              />
            </div>
          ) : item.type == 'birthday-mask' ? (
            <div className="form-input-data" key={key}>
              <label className="blue-label">{item.label}</label>
              <DatePicker
                readOnly={item.readOnly}
                selected={
                  this.props.content[item.key] !== undefined &&
                  this.props.content[item.key] !== null &&
                  this.props.content[item.key] !== ''
                    ? new Date(
                        this.utils.resolveSessionDate(
                          this.props.content[item.key]
                        )
                      )
                    : ''
                }
                onChange={async e => {
                  this.props.setState({
                    content: {
                      ...this.props.content,
                      [item.key]:
                        e !== null && e !== ''
                          ? this.utils.parseDateUSA(e)
                          : '',
                    },
                  });
                }}
                timeIntervals={30}
                dateFormat="dd/MM/yyyy"
                timeCaption="time"
                className="blue-input"
                customInput={<InputMask mask="99/99/9999" />}
              />
              {!item.readOnly && !this.props.state.statusDate && (
                <span className="campObrigatorio">
                  Campo "Data de Nascimento" obrigatório
                </span>
              )}
            </div>
          ) : item.type == 'checkbox' ? (
            <div className="check-content" key={key}>
              <label className="container-ckb">
                <input
                  type="checkbox"
                  checked={
                    this.props.content[item.key] &&
                    this.props.content[item.key] != 'false'
                  }
                  onChange={e => {
                    this.props.setState({
                      content: {
                        ...this.props.content,
                        [item.key]: e.target.checked,
                      },
                    });
                  }}
                />
                <span className="chklbl">
                  {this.props.content[item.key] &&
                  this.props.content[item.key] != 'false'
                    ? item.label
                    : item.offlabel}
                </span>
                <span className="checkmark"></span>
              </label>
            </div>
          ) : item.type == 'select' ? (
            <div className="form-input" key={key}>
              <label className="blue-label">{item.label}</label>
              {item.loading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : (
                <select
                  value={this.props.content ? this.props.content[item.key] : ''}
                  className="blue-input"
                  onChange={e => {
                    this.changeSelect(e, item);
                  }}
                >
                  {/* <option value=""> &nbsp; {item.label}</option> */}
                  {item.options &&
                    item.options.map((sitem, kkey) => {
                      return (
                        <option key={kkey} value={sitem[item.opt_key]}>
                          {' '}
                          &nbsp; {sitem[item.opt_value]}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          ) : item.type == 'money-mask' ? (
            <div className="form-input" key={key}>
              <label className="blue-label">{item.label}</label>
              <CurrencyInput
                value={this.props.content ? this.props.content[item.key] : ''}
                className="blue-input"
                onChangeEvent={e => {
                  this.changeSelect(e, item);
                }}
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
              />
            </div>
          ) : item.type == 'percent-mask' ? (
            <div className="form-input" key={key}>
              <label className="blue-label">{item.label}</label>
              <CurrencyInput
                value={this.props.content ? this.props.content[item.key] : ''}
                className="blue-input"
                onChangeEvent={e => {
                  this.changeSelect(e, item);
                }}
                decimalSeparator=","
                thousandSeparator="."
                prefix="% "
              />
            </div>
          ) : item.type == 'array-text' ? (
            <div key={key}>
              <label className="form-title">{item.title}</label>
              <div>
                {this.props.content[item.key]
                  ? this.props.content[item.key].map((_litem, _lkey) => {
                      return (
                        <div key={_lkey} className="row-options-item">
                          <div className="certificate-title">{`${
                            _litem[item.option]
                          }`}</div>
                          <div
                            className="bt-blueicon"
                            onClick={e => {
                              this.remove(item, _lkey);
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="form-input" style={{ marginTop: 20 }}>
                <label className="blue-label">{item.label}</label>
                <input
                  type={item.type}
                  value={this.state.aadd[item.key]}
                  className="blue-input"
                  name={item.key}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.add(item);
                    }
                  }}
                  style={{ paddingRight: '60px' }}
                  onChange={e => {
                    let aadd = this.state.aadd;
                    aadd[item.key] = e.target.value;
                    this.props.setState({ aadd });
                  }}
                />
                <div
                  className="bt-blue button-add"
                  onClick={() => {
                    this.add(item);
                  }}
                >
                  <i className="fas fa-check" style={{ margin: 0 }}></i>
                </div>
              </div>
            </div>
          ) : item.type == 'upload-file' ? (
            <UploadFile
              key={key}
              setState={this.props.setState.bind(this)}
              doc_source={
                this.props.content ? this.props.content[item.source_key] : ''
              }
              doc_format={
                this.props.content ? this.props.content[item.format_key] : ''
              }
              source_key={item.source_key}
              format_key={item.format_key}
              html_key={item.html_key}
              postFile={item.postFile}
              state={this.props.state}
              convertFileDocx={item.convertFileDocx}
              convertFilePDF={item.convertFilePDF}
              saveContent={this.props.saveContent}
              statusSave={this.props.statusSave}
            />
          ) : item.type == 'textarea' ? (
            <div className="form-textarea" style={{ paddingLeft: 0 }}>
              <label className="blue-label">{item.label}</label>
              <textarea
                value={this.props.content ? this.props.content[item.key] : ''}
                className="blue-input"
                placeholder={item.label}
                key={key}
                name={item.key}
                onChange={e => {
                  this.props.setState({
                    content: {
                      ...this.props.content,
                      [item.key]: e.target.value,
                    },
                  });
                }}
              ></textarea>
              <span>
                {this.props.content[item.key]
                  ? this.props.content[item.key].length
                  : 0}
                /{this.state.maxLength}
              </span>
            </div>
          ) : null;
        })}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
