import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import Service from './Service.js';
import { updateImage, updateUser } from '../../../actions/SimpleAction';
import Sidebar from '../../sidebar/Sidebar';
import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Utils from './../../../libs/Utils.js';
import NewModalCoacheeDelete from './../../components/NewModalCoacheeDelete';

class List extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'configs',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Configurações', link: 'configs' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      loading_contents: false,
      list_contents: [],

      title_rows: [
        { title: 'Configuração', key: 'config' },
        { title: 'Opções', key: 'options' },
      ],
      table_actions: [
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.see.bind(this),
        },
        {
          title: 'Deletar',
          icon: 'far fa-trash-alt',
          action: this.remove.bind(this),
        },
      ],

      new_modal_user_delete: false,
      content_share: [],
      nameRegistro: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  see(item) {
    this.setSidebarLinks(`${this.state.view}/form/${item._id}`);
  }

  async remove(item) {
    await this.setState({
      content_share: item,
      nameRegistro: item.config ? item.config : '',
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    let result = await this.service.delete(this.state.content_share._id);
    if (result && result.success) {
      this.load();
    }
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      new_modal_user_delete: !this.state.new_modal_user_delete,
    });
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.load();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load() {
    this.setState({ loading_contents: true });
    let result = await this.service.read();
    if (result && result.success) {
      this.setState({ list_contents: result[this.state.view] });
    }
    this.setState({ loading_contents: false });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                {/*<div className="row-button">
                        <div className="bt-blue" onClick={ () => { this.setSidebarLinks(`faqs`) } }>Central de Ajuda</div>
                      </div>  */}

                <div className="box100">
                  <div className="m--15">
                    <CardGenericTable
                      link={() => {
                        this.setSidebarLinks(`faqs`);
                      }}
                      id="admin-dashboard-categories-1"
                      title={this.state.breadcrumbs[1].title}
                      state={this.state}
                      actions={this.state.table_actions}
                      title_rows={this.state.title_rows}
                      rows={this.state.list_contents}
                    />
                  </div>
                  <div className="row-button">
                    <div
                      className="bt-blue"
                      onClick={() => {
                        this.setSidebarLinks(`${this.state.view}/form`);
                      }}
                    >
                      <img
                        src={require('./../../../assets/images/buttons/add_plus.svg')}
                        style={{ marginTop: -3, marginRight: 14 }}
                      />
                      Novo
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_user_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_user_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));
