import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import ReactLoading from 'react-loading';

import groupImage from '../../assets/images/group.png';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import '../../styles/css/tableRow/styleslink.scss';

import { simpleAction } from '../../actions/SimpleAction';
import Utils from './../../libs/Utils.js';
import Service from './../../services/ApiService.js';
import Avatar from 'react-avatar';

class TableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPop: '',
    };

    this.utils = new Utils();
    this.service = new Service();
  }

  componentWillReceiveProps(nextProps) {}

  renderItemTitle = itemTitle => {
    let title = itemTitle;
    const docFormat = this.props.__row.doc_format;

    const customizerTitles = {
      'Editor de template': {
        report: 'Editor de Relatórios',
        wheel: 'Visualizar Rodas',
        form: 'Visualizar',
      },
      'Editar labels': {
        wheel: 'Editor de Rodas',
        form: 'Editar',
      },
      Editor: {
        wheel: 'Visualizar Roda',
        form: 'Visualizar',
      },
    };

    if (
      (itemTitle === 'Visualizar' || itemTitle == 'Ver') &&
      docFormat &&
      !['link', 'rel', 'propsals', 'wheel', 'form'].includes(
        docFormat.toLowerCase()
      )
    ) {
      title = 'Download';
    } else {
      if (docFormat && customizerTitles[itemTitle]) {
        title = customizerTitles[itemTitle][docFormat] || itemTitle;
      }
    }

    return title;
  };

  render() {
    return (
      <>
        <tr style={{ border: 'none' }}>
          <th></th>
        </tr>
        <tr
          key={this.props.indexRow}
          onClick={() => {
            if (this.props.row_action) {
              this.props.row_action(this.props.__row);
            }
          }}
          className={
            this.props.finance == 'finance'
              ? this.props.__row.status != 'paid' && this.props.__row.passed
                ? 'row-received rfin'
                : this.props.__row.status == 'paid'
                ? 'row-paid rfin'
                : ' rfin'
              : ''
          }
          style={
            this.props.proposal == 'proposal' &&
            this.props.__row.response == 'no'
              ? {
                  background: '#fff5f5',
                  cursor: this.props.row_action ? 'pointer' : 'auto',
                }
              : { cursor: this.props.row_action ? 'pointer' : 'auto' }
          }
        >
          {this.props.title_rows.map((titles, key) => {
            return (
              <td
                className={
                  key != 0 && key + 1 != this.props.title_rows.length
                    ? 'tcenter'
                    : key + 1 == this.props.title_rows.length
                    ? 'tright'
                    : ''
                }
                style={{ width: 'auto' }}
                key={key}
              >
                <div className="div-box-row-table table-row-styles-link">
                  <span className="mobile-label">{titles.title}: </span>

                  <div
                    className={
                      'mobile-info ' + titles.key == 'action' ||
                      titles.key == 'options'
                        ? 'row-table-actions'
                        : 'word-break'
                    }
                  >
                    {titles.key == 'action' ? (
                      this.props.actions.map((item, _key) => {
                        return this.props.action_loading &&
                          this.props.action_loading ==
                            `${this.props.action_loading_key}-${this.props.indexRow}-${_key}` ? (
                          <ReactLoading
                            type="spin"
                            width={'19px'}
                            height={'fit-content'}
                            color="#00838F"
                          />
                        ) : (
                          <div>
                            <div
                              style={{
                                display:
                                  this.state.openPop ==
                                  `pop-${_key}-${this.props.indexRow}`
                                    ? 'block'
                                    : 'none',
                              }}
                              className="popover-container"
                              onClick={() => {
                                item.action(
                                  this.props.__row._id,
                                  `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`
                                );
                                this.setState({ openPop: '' });
                              }}
                              onMouseOver={() => {
                                this.setState({
                                  openPop: `pop-${_key}-${this.props.indexRow}`,
                                });
                              }}
                              onMouseOut={() => {
                                this.setState({ openPop: '' });
                              }}
                            >
                              <div className="popover-content">
                                {item.title}
                              </div>
                            </div>
                            {item.svg ? (
                              <img
                                key={_key}
                                ref={`target-${this.props.indexRow}-${_key}`}
                                onClick={() => {
                                  item.action(
                                    this.props.__row._id,
                                    `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`
                                  );
                                  this.setState({ openPop: '' });
                                }}
                                onMouseOver={() => {
                                  this.setState({
                                    openPop: `pop-${_key}-${this.props.indexRow}`,
                                  });
                                }}
                                onMouseOut={() => {
                                  this.setState({ openPop: '' });
                                }}
                                className="table-icon svg-image"
                                src={item.svg.icon}
                                style={{ width: item.svg.width }}
                                alt=""
                              />
                            ) : (
                              <i
                                key={_key}
                                ref={`target-${this.props.indexRow}-${_key}`}
                                onClick={() => {
                                  item.action(
                                    this.props.__row._id,
                                    `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`
                                  );
                                  this.setState({ openPop: '' });
                                }}
                                onMouseOver={() => {
                                  this.setState({
                                    openPop: `pop-${_key}-${this.props.indexRow}`,
                                  });
                                }}
                                onMouseOut={() => {
                                  this.setState({ openPop: '' });
                                }}
                                className={`${item.icon} table-icon`}
                              ></i>
                            )}
                          </div>
                        );
                      })
                    ) : titles.key == 'options' ? (
                      this.props.actions.map((item, _key) => {
                        return this.props.action_loading &&
                          this.props.action_loading ==
                            `${this.props.action_loading_key}-${this.props.indexRow}-${_key}` ? (
                          <ReactLoading
                            key={`${item.title}${_key}`}
                            type="spin"
                            width={'19px'}
                            height={'fit-content'}
                            color="#00838F"
                          />
                        ) : (
                          <div key={`${item.title}${_key}`}>
                            <div
                              style={{
                                display:
                                  this.state.openPop ==
                                  `pop-${_key}-${this.props.indexRow}`
                                    ? 'block'
                                    : 'none',
                              }}
                              className="popover-container"
                              onClick={() => {
                                item.action(
                                  this.props.__row,
                                  `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`
                                );
                                this.setState({ openPop: '' });
                              }}
                              onMouseOver={() => {
                                this.setState({
                                  openPop: `pop-${_key}-${this.props.indexRow}`,
                                });
                              }}
                              onMouseOut={() => {
                                this.setState({ openPop: '' });
                              }}
                            >
                              <div className="popover-content">
                                {this.renderItemTitle(item.title)}
                              </div>
                            </div>
                            {item.title != 'Boleto' ? (
                              item.title == 'Visualizar' &&
                              this.props.__row.html ? null : item.hidden ==
                                  'html' &&
                                !this.props.__row.html &&
                                !(
                                  this.props.__row.doc_format &&
                                  ['wheel', 'form'].includes(
                                    this.props.__row.doc_format.toLowerCase()
                                  )
                                ) ? null : item.hidden == 'coachee' &&
                                this.props.__row.coachee !=
                                  'false' ? null : this.props.__row
                                  .doc_format &&
                                this.props.__row.doc_format == 'report' &&
                                (item.title == 'Visualizar' ||
                                  item.title == 'Editar') ? null : this.props
                                  .__row.doc_format &&
                                this.props.__row.doc_format == 'REL' &&
                                item.title == 'Editar' ? null : this.props.__row
                                  .doc_format &&
                                this.props.__row.doc_format == 'propsals' &&
                                item.title == 'Editar' ? null : this.props.__row
                                  .doc_format &&
                                this.props.__row.doc_format == 'form' &&
                                item.title ==
                                  'Visualizar' ? null : (item.title ==
                                  'Visualizar' ||
                                  item.title == 'Ver') &&
                                this.props.__row.doc_format == 'wheel' &&
                                item.title ==
                                  'Visualizar' ? null : (item.title ==
                                  'Visualizar' ||
                                  item.title == 'Ver') &&
                                this.props.__row.doc_format &&
                                ![
                                  'link',
                                  'rel',
                                  'propsals',
                                  'wheel',
                                  'form',
                                ].includes(
                                  this.props.__row.doc_format.toLowerCase()
                                ) ? (
                                <i
                                  key={_key}
                                  ref={`target-${this.props.indexRow}-${_key}`}
                                  onMouseOver={() => {
                                    this.setState({
                                      openPop: `pop-${_key}-${this.props.indexRow}`,
                                    });
                                  }}
                                  onMouseOut={() => {
                                    this.setState({ openPop: '' });
                                  }}
                                  className={`fas fa-download table-icon`}
                                  onClick={() => {
                                    item.action(
                                      this.props.__row,
                                      `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`,
                                      _key
                                    );
                                    this.setState({ openPop: '' });
                                  }}
                                ></i>
                              ) : item.svg ? (
                                <img
                                  key={_key}
                                  ref={`target-${this.props.indexRow}-${_key}`}
                                  onClick={() => {
                                    item.action(
                                      this.props.__row,
                                      `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`,
                                      _key
                                    );
                                    this.setState({ openPop: '' });
                                  }}
                                  onMouseOver={() => {
                                    this.setState({
                                      openPop: `pop-${_key}-${this.props.indexRow}`,
                                    });
                                  }}
                                  onMouseOut={() => {
                                    this.setState({ openPop: '' });
                                  }}
                                  className="table-icon svg-image"
                                  src={item.svg.icon}
                                  style={{
                                    width: item.svg.width,
                                    maxWidth: 'none',
                                  }}
                                />
                              ) : (
                                <i
                                  key={_key}
                                  ref={`target-${this.props.indexRow}-${_key}`}
                                  onMouseOver={() => {
                                    this.setState({
                                      openPop: `pop-${_key}-${this.props.indexRow}`,
                                    });
                                  }}
                                  onMouseOut={() => {
                                    this.setState({ openPop: '' });
                                  }}
                                  className={`${item.icon} table-icon`}
                                  onClick={() => {
                                    item.action(
                                      this.props.__row,
                                      `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`,
                                      _key
                                    );
                                    this.setState({ openPop: '' });
                                  }}
                                ></i>
                              )
                            ) : this.props.__row.status != 'paid' ? (
                              item.svg ? (
                                <img
                                  key={_key}
                                  ref={`target-${this.props.indexRow}-${_key}`}
                                  onClick={() => {
                                    item.action(
                                      this.props.__row,
                                      `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`,
                                      _key
                                    );
                                    this.setState({ openPop: '' });
                                  }}
                                  onMouseOver={() => {
                                    this.setState({
                                      openPop: `pop-${_key}-${this.props.indexRow}`,
                                    });
                                  }}
                                  onMouseOut={() => {
                                    this.setState({ openPop: '' });
                                  }}
                                  className="table-icon svg-image"
                                  src={item.svg.icon}
                                  style={{ width: item.svg.width }}
                                />
                              ) : (
                                <i
                                  key={_key}
                                  ref={`target-${this.props.indexRow}-${_key}`}
                                  onClick={() => {
                                    item.action(
                                      this.props.__row,
                                      `${this.props.action_loading_key}-${this.props.indexRow}-${_key}`,
                                      _key
                                    );
                                    this.setState({ openPop: '' });
                                  }}
                                  onMouseOver={() => {
                                    this.setState({
                                      openPop: `pop-${_key}-${this.props.indexRow}`,
                                    });
                                  }}
                                  onMouseOut={() => {
                                    this.setState({ openPop: '' });
                                  }}
                                  className={`${item.icon} table-icon`}
                                ></i>
                              )
                            ) : null}
                          </div>
                        );
                      })
                    ) : titles.key == 'status' ? (
                      <div
                        style={{ justifyContent: 'center', display: 'flex' }}
                      >
                        <div style={{ paddingTop: 6, minWidth: 95 }}>
                          <div
                            style={{
                              float: 'left',
                              marginRight: 10,
                              zoom: '70%',
                            }}
                          >
                            <Switch
                              onChange={() => {
                                this.props.toggleSwitch(this.props.indexRow);
                              }}
                              id={`toggle${this.props.indexRow}`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#EDABAB'}
                              offHandleColor={'#D32F2F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              handleDiameter={20}
                              height={13}
                              width={33}
                              checked={this.props.__row.status == 'true'}
                            />
                          </div>
                          <div className="active-label-row-status">
                            {this.props.__row.status == 'true'
                              ? 'Ativado'
                              : 'Desativado'}
                          </div>
                        </div>
                      </div>
                    ) : titles.key == 'finance_status' ? (
                      <div
                        style={{ justifyContent: 'center', display: 'flex' }}
                      >
                        <div style={{ paddingTop: 6, minWidth: 100 }}>
                          <div
                            style={{
                              float: 'left',
                              marginRight: 10,
                              zoom: '70%',
                            }}
                          >
                            <Switch
                              onChange={() => {
                                this.props.toggleSwitch(this.props.indexRow);
                              }}
                              id={`toggle${this.props.indexRow}`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#EDABAB'}
                              offHandleColor={'#D32F2F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              handleDiameter={20}
                              height={13}
                              width={33}
                              checked={this.props.__row.status == 'paid'}
                            />
                          </div>
                          <div className="active-label-row-status">
                            {this.props.__row.status == 'paid'
                              ? this.props.__row.type == 'in'
                                ? 'Recebido'
                                : 'Pago'
                              : this.props.__row.type == 'in'
                              ? 'Não recebido'
                              : 'Não pago'}
                          </div>
                        </div>
                      </div>
                    ) : titles.key == 'date' ||
                      titles.key == 'expected_date' ? (
                      this.utils.parseDate(this.props.__row.created_at)
                    ) : titles.key == 'start' ? (
                      this.utils.parseDateUSA(this.props.__row.start)
                    ) : titles.key == 'udate' ? (
                      this.utils.parseDateUSA(this.props.__row.date)
                    ) : titles.key == 'session_date' ? (
                      this.props.__row[titles.key] ? (
                        this.utils.parseDateUSA(this.props.__row[titles.key])
                      ) : (
                        'Data não definida'
                      )
                    ) : titles.key == 'completed_at' ? (
                      this.props.__row[titles.key] ? (
                        this.utils.parseDate(this.props.__row[titles.key])
                      ) : (
                        'Não concluida'
                      )
                    ) : titles.key === 'name' || titles.key === 'group' ? (
                      this.imagemTitle(
                        this.props,
                        this.props.__row[titles.key],
                        titles.key
                      )
                    ) : titles.key === 'email' ? (
                      this.returnEmail(this.props, this.props.__row[titles.key])
                    ) : (
                      this.props.__row[titles.key] || ''
                    )}

                    {titles.key == '_status' ? (
                      this.props.__row['status'] == 'true' ? (
                        <div className="status-active" style={{ minWidth: 70 }}>
                          <span></span>
                          Ativo
                        </div>
                      ) : (
                        <div
                          className="status-inactive"
                          style={{ minWidth: 70 }}
                        >
                          <span></span>
                          Inativo
                        </div>
                      )
                    ) : null}

                    {titles.key == 'can_share' ? (
                      this.props.__row['can_share'] ? (
                        <div className="status-active">
                          <span></span>
                        </div>
                      ) : (
                        <div className="status-inactive">
                          <span></span>
                        </div>
                      )
                    ) : null}

                    {titles.key == 'partner' ? (
                      this.props.__row['partner'] ? (
                        <div className="status-active">
                          <span></span>
                        </div>
                      ) : (
                        <div className="status-inactive">
                          <span></span>
                        </div>
                      )
                    ) : null}

                    {titles.key == 'taks_status' ? (
                      this.props.__row['status'] == 'complete' ? (
                        <div className="status-active">
                          <span></span>
                        </div>
                      ) : (
                        <div className="status-inactive">
                          <span></span>
                        </div>
                      )
                    ) : null}

                    {titles.key == 'action_status'
                      ? this.props.__row['status'] == 'complete'
                        ? 'Concluída'
                        : 'Em andamento'
                      : null}

                    {titles.key == 'its_free' ? (
                      this.props.__row.its_free ? (
                        <div className="icon-free">
                          <i className="fas fa-check"></i>
                        </div>
                      ) : (
                        <div className="icon-free">
                          <i className="fas fa-times"></i>
                        </div>
                      )
                    ) : null}

                    {titles.key == '_action'
                      ? this.props.__row['action']
                      : null}

                    {titles.key == '_target'
                      ? this.props.__row['all_coachs'] &&
                        this.props.__row['all_coachees']
                        ? 'Coaches e Coachees'
                        : this.props.__row['all_coachs']
                        ? 'Coaches'
                        : this.props.__row['all_coachees']
                        ? 'Coachees'
                        : null
                      : null}

                    {titles.key == '_date' ? this.props.__row['date'] : null}

                    {titles.key == '_email' &&
                    this.props.__row['contact'] &&
                    this.props.__row['contact'][0]
                      ? this.props.__row['contact'][0]['email']
                      : null}

                    {titles.key == '_comercial' &&
                    this.props.__row['contact'] &&
                    this.props.__row['contact'][0]
                      ? this.props.__row['contact'][0]['comercial']
                      : null}

                    {titles.key == '_type'
                      ? this.props.__row.type == 'in'
                        ? 'Recebimento'
                        : this.props.__row.type == 'out'
                        ? 'Despesa'
                        : this.props.__row.type == 'seacher'
                        ? 'Buscador'
                        : this.props.__row.type == 'platform'
                        ? 'Plataforma'
                        : null
                      : null}

                    {titles.key === '_coachee' && this.props.__row.coachee ? (
                      <div className="row-image">
                        {this.props.__row.coachee.image &&
                        this.props.__row.coachee.image !== '' ? (
                          <img
                            src={this.utils.resolveURL(
                              this.props.__row.coachee.image,
                              this.service.getServer()
                            )}
                            className="image-coachee"
                            style={{ width: 35, height: 35 }}
                            alt="coachee"
                          />
                        ) : (
                          <Avatar
                            name={this.utils.resolveCompleteName(
                              this.props.__row.coachee.name,
                              ''
                            )}
                            size="35"
                            round="26px"
                            color={Avatar.getRandomColor(
                              this.utils.resolveCompleteName(
                                this.props.__row.coachee.name,
                                ''
                              ),
                              [
                                '#a6ce39',
                                '#2c8440',
                                '#303440',
                                '#a4cc3c',
                                '#043448',
                              ]
                            )}
                            fgColor="#FFFFFF"
                            maxInitials={2}
                            style={{
                              margin: '5px',
                              marginRight: '10px',
                              border: '1 solid #3f3f3f',
                            }}
                          />
                        )}
                        {this.props.__row.coachee.name}
                      </div>
                    ) : titles.key == '_coachee' &&
                      !this.props.__row.coachee ? (
                      <div>
                        {this.props.__row.name_oportunities &&
                        this.props.__row.name_oportunities !== ''
                          ? this.props.__row.name_oportunities
                          : 'Coachee não encontrado'}
                      </div>
                    ) : null}

                    {titles.key == '_from' && this.props.__row.from ? (
                      <div className="row-image">
                        {this.props.__row.from.name}
                      </div>
                    ) : titles.key == '_from' && !this.props.__row.from ? (
                      <div>Rementente não encontrado</div>
                    ) : null}

                    {titles.key == '_to' && this.props.__row.to ? (
                      <div className="row-image">
                        {this.props.__row.to.name}
                      </div>
                    ) : titles.key == '_to' && !this.props.__row.to ? (
                      <div>Destinatario não encontrado</div>
                    ) : null}

                    {titles.key === '_profile' ? (
                      <div className="row-image">
                        <Avatar
                          src={this.utils.resolveURL(
                            this.props.__row.image,
                            this.service.getServer()
                          )}
                          name={this.utils.resolveCompleteName(
                            this.props.__row.name,
                            this.props.__row.surname
                          )}
                          size="52"
                          round="26px"
                          color={Avatar.getRandomColor(
                            this.utils.resolveCompleteName(
                              this.props.__row.name,
                              this.props.__row.surname
                            ),
                            [
                              '#a6ce39',
                              '#2c8440',
                              '#303440',
                              '#a4cc3c',
                              '#043448',
                            ]
                          )}
                          fgColor="#FFFFFF"
                          maxInitials={2}
                          style={{
                            margin: '5px',
                            marginRight: '10px',
                            border: '1 solid #3f3f3f',
                          }}
                        />
                        {this.props.__row.name}
                      </div>
                    ) : null}

                    {titles.key == '_response' ? (
                      this.props.__row.status != 'emitted' ? (
                        <div style={{ float: 'right' }}>
                          Proposta não emitida
                        </div>
                      ) : (
                        <div className="content-response">
                          <div
                            className="bt-no"
                            onClick={() => {
                              this.props.response_actions[1].action(
                                this.props.__row._id
                              );
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                          <div
                            className="bt-yes"
                            onClick={() => {
                              this.props.response_actions[0].action(
                                this.props.__row._id
                              );
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </div>
                        </div>
                      )
                    ) : null}

                    {titles.key == '__response' ? (
                      this.props.__row.response == 'yes' ? (
                        <div style={{ float: 'right' }}>Aceita</div>
                      ) : this.props.__row.response == 'no' ? (
                        <div style={{ float: 'right' }}>Recusada</div>
                      ) : (
                        <div className="content-response">
                          <div
                            className="bt-no"
                            onClick={() => {
                              this.props.response_actions[1].action(
                                this.props.__row._id
                              );
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                          <div
                            className="bt-yes"
                            onClick={() => {
                              this.props.response_actions[0].action(
                                this.props.__row._id
                              );
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </div>
                        </div>
                      )
                    ) : null}

                    {titles.key == '_response_status' ? (
                      this.props.__row.status == 'complete' ? (
                        'Concluída'
                      ) : (
                        <div className="content-response">
                          <div
                            className="bt-yes"
                            onClick={() => {
                              this.props.response_actions[0].action(
                                this.props.__row
                              );
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </div>
                        </div>
                      )
                    ) : null}

                    {titles.key === '_response_status_answer' ? (
                      this.props.__row.status === 'complete' ? (
                        'Concluída'
                      ) : (
                        <div
                          className={
                            this.props.__row.status === 'answered'
                              ? 'content-response'
                              : 'content-response disabled'
                          }
                        >
                          <div
                            className="bt-yes"
                            onClick={() => {
                              this.props.response_actions[0].action(
                                this.props.__row
                              );
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </div>
                        </div>
                      )
                    ) : null}

                    {titles.key == 'statusProfile' && (
                      <div
                        style={{ justifyContent: 'center', display: 'flex' }}
                      >
                        <div
                          style={{
                            minWidth: 95,
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              float: 'left',
                              marginRight: 10,
                              zoom: '70%',
                            }}
                          >
                            <Switch
                              onChange={() => {
                                this.props.toggleSwitch(this.props.indexRow);
                              }}
                              id={`toggle${this.props.indexRow}`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#EDABAB'}
                              offHandleColor={'#D32F2F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              handleDiameter={20}
                              height={13}
                              width={33}
                              checked={this.props.__row.profile_active === true}
                            />
                          </div>
                          <div className="active-label-row-status">
                            {this.props.__row.profile_active === true
                              ? 'Habilitado'
                              : 'Desabilitado'}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            );
          })}
        </tr>
      </>
    );
  }

  imagemTitle = (props, title, key) => {
    if (title !== '') {
      if (!props.campoImagem) {
        return props.campoNome ? (
          <span
            className="linkCoachee"
            onClick={() => props.linkEdit(props.__row)}
          >
            {title || ''}
          </span>
        ) : (
          title || ''
        );
      }
      return (
        <div className="row-image">
          {props.__row.image && props.__row.image !== '' ? (
            <img
              src={this.utils.resolveURL(
                props.__row.image,
                this.service.getServer()
              )}
              className="image-coachee"
              style={{ width: 35, height: 35 }}
              alt="coachee"
            />
          ) : key === 'name' ? (
            <Avatar
              name={this.utils.resolveCompleteName(title, '')}
              size="35"
              round="26px"
              color={Avatar.getRandomColor(
                this.utils.resolveCompleteName(title, ''),
                ['#a6ce39', '#2c8440', '#303440', '#a4cc3c', '#043448']
              )}
              fgColor="#FFFFFF"
              maxInitials={2}
              style={{
                margin: '5px',
                marginRight: '10px',
                border: '1 solid #3f3f3f',
              }}
            />
          ) : (
            <img
              src={groupImage}
              className="image-coachee"
              style={{ width: 35, height: 35 }}
              alt="coachee"
            />
          )}

          {props.campoNome ? (
            <span
              className="linkCoachee"
              onClick={() => props.linkEdit(props.__row)}
            >
              {title || ''}
            </span>
          ) : (
            title || ''
          )}
        </div>
      );
    } else {
      return '';
    }
  };

  returnEmail = (props, title) => {
    return props.campoEmail ? (
      <span className="linkCoachee" onClick={() => props.linkEdit(props.__row)}>
        {title || ''}
      </span>
    ) : (
      title || ''
    );
  };
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableRow);
