import ApiService from '../../services/ApiService';

class Service extends ApiService {
  async login(_payload) {
    try {
      let response = await fetch(`${this.endPoint}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async loginFacebook(_payload) {
    try {
      let response = await fetch(`${this.endPoint}login/facebook`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async loginLinkedin(_payload) {
    try {
      let response = await fetch(`${this.endPoint}login/linkedin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async firstLoginAccess() {
    try {
      let response = await fetch(`${this.endPoint}firstloginaccess`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async myCard() {
    try {
      let response = await fetch(`${this.endPoint}mycard`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeSubscription() {
    try {
      let response = await fetch(`${this.endPoint}buy`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
