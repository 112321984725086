import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css'; 

import { simpleAction, updateImage } from '../../../actions/SimpleAction';

import ReactLoading from "react-loading";
 
import Utils from './../../../libs/Utils.js'; 

const typeDoc = [
  "xlsx", "docx", "pdf", "zip", "png", "PNG", "jpg", "JPG", "jpeg", "JPEG"
]

class CoacheeCreate extends Component { 

  constructor(props) {
    super(props); 

    this.state = {
      loading: false,
      sleepFile: "",
      validDoc: false
    };
    
    this.utils = new Utils();  
     
    this.openUpload = this.openUpload.bind(this)
    this.removeAttachment = this.removeAttachment.bind(this)
    this.showUpload = this.showUpload.bind(this)
    this.fileSleep = this.fileSleep.bind(this);
  }
 

  componentDidMount() { } 
  
  shouldComponentUpdate(nextProps) {
    if (this.props.statusSave !== nextProps.statusSave) {
      if (this.state.sleepFile !== "") {
        this.readFile(this.state.sleepFile);
      } else {
        this.props.saveContent();
      }
      return true;
    }
    if (this.props.doc_source !== nextProps.doc_source) {
      return true;
    }
    return false;
  }

  componentWillUnmount() { 
    clearInterval(this.timerID);
  }

  fileSleep(file) {
    let fileExtension = file.name;
    let filetype = file.name.split('.')
    filetype = filetype[filetype.length - 1];
    if (typeDoc.includes(filetype)) {
      let fileExtension = file.name;
      this.props.setState({ content: { ...this.props.state.content, [this.props.format_key]: fileExtension, [this.props.source_key]: "upload" }, validDoc: false });
    } else {
      this.setState({
        validDoc: true
      });
    }
    clearInterval(this.timerID);
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.setState(() => ({
        sleepFile: file
      }));
      this.timerID = setInterval(() => {
        this.fileSleep(file);
      }, 1000);
    }
  }
 
  readFile(file){
    this.setState({ loading:true });
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${ reader.result }`.split(',')[1]
      let image = {
        filename: file.name,
        filetype: file.type,
        source
      }
      let fileExtension = image.filename.split('.')
      fileExtension = fileExtension[ fileExtension.length - 1 ] 
      let thefile = this.prepareFile(image);
      let result = await this.props.postFile(thefile, false); 
      if(result && result.success){

        if(fileExtension=='docx'){
          let converted = await this.props.convertFileDocx({ url: result.source});
          if( converted && converted.success){
              await this.props.setState({ content:{ ...this.props.state.content, [this.props.html_key]:converted.html  } }) 
          }
        } 

        if(fileExtension=='pdf'){
          let converted = await this.props.convertFilePDF({ url: result.source});
          if( converted && converted.success){ 
              await this.props.setState({ content:{ ...this.props.state.content, [this.props.html_key]:converted.html } }) 
          }
        } 
        this.props.setState({ content:{ ...this.props.state.content, [this.props.format_key]:fileExtension, [this.props.source_key]:result.source } })  
        this.props.saveContent();
      } else {
        this.props.saveContent();
      }
      this.setState({ loading: false });
    };
    reader.readAsDataURL(file);
  }

  prepareFile(image){
    let fileExtension = image.filename.split('.')
    fileExtension = fileExtension[ fileExtension.length - 1 ] 
    let fileName = image.filename.replace(`.${ fileExtension }`,'')
    let btc = window.atob(image.source)
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
        btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: image.filetype } );
    return new File([boobs], `${ image.filename }.${ fileExtension }`)
  } 
  
  openUpload(){
    document.getElementById('filefile').click()
  }

  removeAttachment(){
    this.props.setState({ content: { ...this.props.content, [this.props.format_key]: "", [this.props.source_key]: "", sleepFile: "" } }) 
  }

  showUpload(){
    if (!this.props.doc_source || this.props.doc_source.length == 0) {
      return true
    }
    return false
  }

  render() { 
    return ( <div>
              { this.showUpload() ? 
                this.state.loading ? <ReactLoading type="bubbles" color="#B1CD49" /> :
              <>        
                <div className="uploadFile" onClick={() => { this.openUpload() }}>
                    <input type="file" accept=".xlsx,.docx,.pdf,.zip,.png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                      onChange={ ev => { this.onFileChange(ev) }} id="filefile" style={{ display:'none' }} />
                    <div className="uploadFile-content">
                      <img width="100" src={require('./../../../assets/images/dashboard/cloud_up.svg')} />
                      <div>
                        Arraste um arquivo ou clique Aqui
                      </div>
                    </div>
                </div>
                  {this.state.validDoc && (
                    <div style={{ marginTop: -30, color: "red" }}>Não pode subir arquivos diferentes dessas extensões: .xlsx,.docx,.pdf,.zip,.png, .PNG, .jpg, .JPG, .jpeg, .JPEG</div>
                  )}
              </>
                : 
                  <p className="box-title"> Anexo Selecionado: { this.props.doc_format } 
                      <i className="fas fa-times fright cpointer" onClick={ () => { this.removeAttachment() } }></i>
                  </p> 
              }
            </div> );
  }
}
const mapStateToProps = state => ({ 
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CoacheeCreate);