import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import moment from 'moment/min/moment-with-locales';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Service from '../../dashboard/Profile/Service';
import Utils from './../../../libs/Utils';

class Template7 extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      user: {},
    };
    this.utils = new Utils();
    this.service = new Service();
    this.goTo = this.goTo.bind(this);
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  componentDidMount() {
    moment.locale('pt-BR');
  }

  componentWillUnmount() { }

  openSocial(uri) {
    window.open(uri, 'social');
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  render() {
    return (
      // START WRAPPER ALL
      <div className="beny_tm_wrapper_all">
        {/* START PRELOADER */}
        <div className="beny_tm_preloader">
          <div className="spinner_wrap">
            <div className="spinner"></div>
          </div>
        </div>
        {/* AND PRELOADER */}

        <div id="beny_tm_popup_blog">
          <div className="container">
            <div className="inner_popup scrollable"></div>
          </div>
          <span className="close">
            <a></a>
          </span>
        </div>

        {/* Start Navbar */}
        <nav className="navbar navbar-expand-lg tipoMneu">
          <div className="container navDados">
            {/* Logo */}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            {/* navbar links */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link active" data-scroll-nav="0">
                    <AnchorLink href="#home">Home</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="1">
                    <AnchorLink href="#sobremim">Sobre mim</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="2">
                    <AnchorLink href="#experiencia">Experiência</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="3">
                    <AnchorLink href="#certificacoes">Certificações</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="4">
                    <AnchorLink href="#referencias">Referências</AnchorLink>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* End Navbar */}

        {/* START CONTENT */}
        <div className="beny_tm_content">
          <div className="beny_tm_leftpart_wrap">
            <div className="leftpart_inner">
              <div className="logo_wrap">
                {this.props.state.logo !== null &&
                  this.props.state.logo !== undefined &&
                  this.props.state.logo.trim() !== '' ? (
                    <div className="author_image_wrap">
                      <img
                        src={this.resolveImage(this.props.state.logo)}
                        alt=""
                      />
                    </div>
                  ) : (
                    ''
                  )}
              </div>
              <div className="author_name">
                <h3>{this.props.state.nomeCoach}</h3>
              </div>
              <div className="menu_list_wrap">
                <ul className="anchor_nav">
                  <li className="margemMenor">
                    <a>
                      <AnchorLink href="#home">Home</AnchorLink>
                    </a>
                  </li>
                  <li className="margemMenor">
                    <a>
                      <AnchorLink href="#sobremim">Sobre mim</AnchorLink>
                    </a>
                  </li>
                  <li className="margemMenor">
                    <a>
                      <AnchorLink href="#experiencia">Experiência</AnchorLink>
                    </a>
                  </li>
                  <li className="margemMenor">
                    <a>
                      <AnchorLink href="#certificacoes">
                        Certificações
                      </AnchorLink>
                    </a>
                  </li>
                  <li className="margemMenor">
                    <a>
                      <AnchorLink href="#referencias">Referências</AnchorLink>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="leftpart_bottom">
                <div className="social_wrap">
                  <ul>
                    {this.props.state.facebook === '' ||
                      this.props.state.facebook === null ||
                      this.props.state.facebook === undefined ||
                      !this.props.state.facebook.trim() ? null : (
                        <li>
                          <a href={this.props.state.facebook} target="_blank">
                            <i className="xcon-facebook"></i>
                          </a>
                        </li>
                      )}
                    {this.props.state.twitter === '' ||
                      this.props.state.twitter === null ||
                      this.props.state.twitter === undefined ||
                      !this.props.state.twitter.trim() ? null : (
                        <li>
                          <a href={this.props.state.twitter} target="_blank">
                            <i className="xcon-twitter"></i>
                          </a>
                        </li>
                      )}
                    {this.props.state.linkedin === '' ||
                      this.props.state.linkedin === null ||
                      this.props.state.linkedin === undefined ||
                      !this.props.state.linkedin.trim() ? null : (
                        <li>
                          <a href={this.props.state.linkedin} target="_blank">
                            <i className="xcon-linkedin"></i>
                          </a>
                        </li>
                      )}
                    {this.props.state.instagram === '' ||
                      this.props.state.instagram === null ||
                      this.props.state.instagram === undefined ||
                      !this.props.state.instagram.trim() ? null : (
                        <li>
                          <a href={this.props.state.instagram} target="_blank">
                            <i className="xcon-instagram"></i>
                          </a>
                        </li>
                      )}
                    {this.props.state.youtube === '' ||
                      this.props.state.youtube === null ||
                      this.props.state.youtube === undefined ||
                      !this.props.state.youtube.trim() ? null : (
                        <li>
                          <a href={this.props.state.youtube} target="_blank">
                            <i className="xcon-youtube-play"></i>
                          </a>
                        </li>
                      )}
                    {this.props.state.google_plus === '' ||
                      this.props.state.google_plus === null ||
                      this.props.state.google_plus === undefined ||
                      !this.props.state.google_plus.trim() ? null : (
                        <li>
                          <a href={this.props.state.google_plus} target="_blank">
                            <i className="xcon-google"></i>
                          </a>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="beny_tm_rightpart">
            {/* START home */}
            <div className="beny_tm_section" id="home">
              <div className="beny_tm_hero_header">
                <div className="beny_tm_universal_box_wrap">
                  <div className="bg_wrap">
                    <div
                      className="overlay_image hero jarallax"
                      data-speed="0"
                    ></div>
                    <div className="overlay_video"></div>
                    <div className="overlay_color hero"></div>
                  </div>
                  <div className="content hero">
                    <div className="container hero">
                      <div className="beny_tm_hero_title">
                        <p className="name">
                          <span>{this.props.state.nomeCoach}</span>
                        </p>
                        <div className="beny_tm_hero_share">
                          <ul>
                            {this.props.state.facebook === '' ||
                              this.props.state.facebook === null ||
                              this.props.state.facebook === undefined ||
                              !this.props.state.facebook.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.facebook}
                                    target="_blank"
                                  >
                                    <i className="xcon-facebook"></i>
                                  </a>
                                </li>
                              )}
                            {this.props.state.twitter === '' ||
                              this.props.state.twitter === null ||
                              this.props.state.twitter === undefined ||
                              !this.props.state.twitter.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.twitter}
                                    target="_blank"
                                  >
                                    <i className="xcon-twitter"></i>
                                  </a>
                                </li>
                              )}
                            {this.props.state.linkedin === '' ||
                              this.props.state.linkedin === null ||
                              this.props.state.linkedin === undefined ||
                              !this.props.state.linkedin.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.linkedin}
                                    target="_blank"
                                  >
                                    <i className="xcon-linkedin"></i>
                                  </a>
                                </li>
                              )}
                            {this.props.state.instagram === '' ||
                              this.props.state.instagram === null ||
                              this.props.state.instagram === undefined ||
                              !this.props.state.instagram.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.instagram}
                                    target="_blank"
                                  >
                                    <i className="xcon-instagram"></i>
                                  </a>
                                </li>
                              )}
                            {this.props.state.youtube === '' ||
                              this.props.state.youtube === null ||
                              this.props.state.youtube === undefined ||
                              !this.props.state.youtube.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.youtube}
                                    target="_blank"
                                  >
                                    <i className="xcon-youtube-play"></i>
                                  </a>
                                </li>
                              )}
                            {this.props.state.google_plus === '' ||
                              this.props.state.google_plus === null ||
                              this.props.state.google_plus === undefined ||
                              !this.props.state.google_plus.trim() ? null : (
                                <li>
                                  <a
                                    href={this.props.state.google_plus}
                                    target="_blank"
                                  >
                                    <i className="xcon-google"></i>
                                  </a>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      <div className="beny_tm_arrow_wrap bounce anchor">
                        <a>
                          <AnchorLink href="#sobremim">
                            <i className="xcon-angle-double-down"></i>
                          </AnchorLink>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* AND home */}

            {/* START sobremim */}
            <div className="beny_tm_section" id="sobremim">
              <div className="container">
                <div className="beny_tm_about_wrap">
                  <div className="author_wrap">
                    <div className="leftbox">
                      {this.props.state.photo !== null &&
                        this.props.state.photo !== undefined &&
                        this.props.state.photo.trim() !== '' ? (
                          <div className="image_wrap">
                            <img
                              src={this.resolveImage(this.props.state.photo)}
                              alt=""
                            />
                          </div>
                        ) : (
                          ''
                        )}
                    </div>
                    <div className="rightbox">
                      <div className="beny_tm_main_title_holder about">
                        <h3>Sobre mim</h3>
                      </div>
                      <div className="definition justText">
                        <p>{this.props.state.about_me}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* AND sobremim */}

            {/* START experiencia */}
            <div className="beny_tm_section" id="experiencia">
              <div className="typeCor">
                <div className="container">
                  <div className="inner_wrap">
                    <div className="">
                      <div className="beny_tm_main_title_holder">
                        <h3>Experiência</h3>
                      </div>
                      <p className="justText">{this.props.state.experience}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* AND experiencia */}

            {/* START TESTIMONIALS */}
            <div className="beny_tm_section" id="testimonials">
              <div className="beny_tm_testimonials_wrapper_all">
                <div className="beny_tm_universal_box_wrap">
                  <div className="bg_wrap">
                    <div
                      className="overlay_image testimonial jarallax"
                      data-speed="0"
                    ></div>
                    <div className="overlay_color testimonial"></div>
                  </div>
                  <div className="content testimonial"></div>
                </div>
              </div>
            </div>
            {/* AND TESTIMONIALS */}

            {/* START certificacoes */}
            <div className="beny_tm_section" id="certificacoes">
              <div className="beny_tm_about_wrap">
                <div className="container">
                  <div className="inner_wrap">
                    <div className="">
                      <div className="beny_tm_main_title_holder">
                        <h3>Certificações</h3>
                      </div>
                      <p className="justText">
                        {this.props.state.certifications}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* AND certificacoes */}

            {/* START referencias */}
            <div className="beny_tm_section" id="referencias">
              <div className="typeCor">
                <div className="container">
                  <div className="inner_wrap">
                    <div className="">
                      <div className="beny_tm_main_title_holder">
                        <h3>Referências</h3>
                      </div>
                      <p className="justText">{this.props.state.references}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* AND referencias */}

            {/* START FOOTER */}
            <div className="beny_tm_footer_total_wrap">
              <div className="beny_tm_address_holder_wrap"></div>
              <div className="beny_tm_footer_wrap">
                <p className="wow fadeIn" data-wow-duration="1.2s">
                  &copy; Copyright 2019 {this.props.state.nomeCoach}. All Rights
                  Reserved.
                </p>
              </div>
            </div>
            {/* AND FOOTER */}
          </div>
        </div>
        {/* AND CONTENT */}
        <a className="beny_tm_totop">
          <i className="xcon-angle-up"></i>
        </a>
      </div>
      // AND WRAPPER ALL
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Template7)
);
