import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from './../../sidebar/Sidebar';

import ReactLoading from 'react-loading';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import PeriodiceFilter from './../../components/PeriodiceFilter';

// import RowStats from './../../components/RowStats'

import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';

import Footer from './../../components/Footer';

import ClimbyEditor from './../../../screens_dashboard/components/Editor';

import { Editor } from 'react-draft-wysiwyg';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';

import { convertToHTML } from 'draft-convert';

import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import CurrencyInput from 'react-currency-input';

import { AreaChart, BarChart } from 'react-easy-chart';

class TemplateEditor extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      side: 'contents',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Gerar Proposta', link: 'proposals/proposal/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      contents: [],

      editorState: '',
      contentState: {},
      title: '',
      value: '',
      tag: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
      docFormat: '',
      docSource: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.content(_id);
    if (result && result.success) {
      let _text =
        result.content && result.content.html ? result.content.html : '';

      const editorState = _text; //this.utils.convertFromHtml(_text)

      // const editorState = EditorState.createWithContent(convertFromHTML(_text));

      if (result.content)
        this.setState({
          title: result.content.title,
          editorState,
          docFormat: result.content.doc_format,
          docSource: result.content.doc_source,
        });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    this.setState({ loading: true });
    // const rawDraftContentState = JSON.stringify( convertToRaw(this.state.editorState.getCurrentContent()) );
    // const html = convertToHTML(this.state.editorState.getCurrentContent());
    // const html = this.utils.convertToHTML(convertToRaw(this.state.editorState.getCurrentContent()));
    let _pay = {
      html: this.state.editorState,
      title: this.state.title != undefined ? this.state.title : '',
      doc_format: this.state.docFormat ? this.state.docFormat : '',
      doc_source: this.state.docSource ? this.state.docSource : '',
    };
    let result = this.state.editing
      ? await this.service.updateContent(_pay, this.state.editing_id)
      : await this.service.saveContent(_pay);
    if (result && result.success) {
      // this.goTo('dashboard/proposals'
      this.props.updateAlert('Salvo com sucesso');
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            {/*           <PrincipalMenu 
              principalOpen={ this.state.principalOpen } 
              view={ this.state.view } 
              setSidebarLinks={ this.setSidebarLinks }
              togglePrincipal={ this.togglePrincipal } /> */}

            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="form-input input-title">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={this.state.title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>

                    <div className="box-row-title row-center">
                      <div className="box-title">Editor de templates</div>
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <ClimbyEditor
                      setState={this.setState.bind(this)}
                      state={this.state}
                    />

                    {/* <Editor
                          state={this.state}
                          editorState={this.state.editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          toolbar={{
                              image: { uploadCallback: this.uploadImageCallBack.bind(this), alt: { present: true, mandatory: true } },
                            }}
                          onEditorStateChange={this.onEditorStateChange.bind(this)} 
                        />*/}

                    {/*<div className="form-input  max-320">
                            <label className="blue-label">Valor da proposta</label>
                             <CurrencyInput value={this.state.value}  className="blue-input"
                                onChangeEvent={  (e) => { this.setState({ value:e.target.value }) } } 
                                decimalSeparator="," thousandSeparator="." prefix="R$ "
                                placeholder="Valor da proposta" />
                          </div>
  
                          <div className="form-input max-320">
                            <label className="blue-label">TAG</label>
                            <input type="text" value={ this.state.tag } className="blue-input" placeholder="TAG"
                                  onChange={ (e) => { this.setState({ tag:e.target.value }) }} /> 
                          </div>  */}

                    <div className="row-center mtop-50 mbottom-50">
                      {/*<div className="bt-blue-outline" onClick={ () => { this.goTo('dashboard/proposals') } }>Cancelar</div>*/}
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className={true ? 'bt-blue' : 'bt-blue bt-disabled'}
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar ' : 'Salvar'}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateEditor)
);
