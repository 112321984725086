import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import Switch from 'react-switch';
import { withRouter } from 'react-router-dom';
import {
  updateImage,
  updateUser,
  updateAlert,
  modalStartTestPeriod,
  updateSession,
} from '../../actions/SimpleAction';
import Service from './../dashboard/Profile/Service.js';
import loginPlans from '../../consts/loginPlans';
import ReactLoading from 'react-loading';
import moment from 'moment';
import CalculateBasics from '../../services/CalculateBasics';

class SidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isVisible: false,
      isTestPeriodStarted: props.user.isTestPeriodStarted,
      user: {},
      isOpenPop: false,
      percentage_fields_filled: 0,
    };
    this.calculateBasics = new CalculateBasics();
    this.service = new Service();
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.calculateBasic = this.calculateBasic.bind(this);
  }

  componentDidMount() {
    this.loadUser();
    this.calculateBasic();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.calculateBasic();
    }
  }

  toggleSwitch() {
    const isVisible = !this.state.isVisible;
    this.save({ profile_active: isVisible }, 'engage');
    if (isVisible) {
      if (this.props.user.curation && this.props.user.curation.active) {
        this.engageAlert();
      } else {
        this.curationAlert();
      }
    } else {
      this.engageAlert();
    }
    this.setState({ isVisible });
  }

  engageAlert = () => {
    this.props.updateAlert(
      <div>
        <p>Seu perfil já foi atualizado no engage, o ambiente de negócios</p>
        <div className="btn-footer-modal">
          <div className="bt-blue" onClick={() => this.props.updateAlert('')}>
            OK
          </div>
        </div>
      </div>
    );
  };

  curationAlert() {
    this.props.updateAlert(
      <div className="postition-relative">
        <div className="mb-10">
          Gostaria de passar pela curadoria
          <i className="info-curation fas fa-info-circle">
            <div className="curation-hint">
              Curadoria climby é uma validação das informações apresentadas no
              seu perfil. Seu perfil receberá um selo de validação ao ser
              aprovado no processo.
            </div>
          </i>
          e receber o selo de validação de dados?
        </div>

        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => this.engageAlert()}
          >
            Não
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => this.handleCuration()}
          >
            Sim
          </div>
        </div>
      </div>
    );
  }

  handleCuration = async () => {
    if (!this.professionalDescriptionCompleted()) {
      // if (
      //   this.props.history.location.pathname !==
      //   '/dashboard/profile/professional/description'
      // ) {
      //   this.props.history.push('/dashboard/profile/professional/description', {
      //     professionalDescriptionIncomplete: true,
      //   });
      if (this.props.history.location.pathname !== '/dashboard/profile') {
        this.props.history.push('/dashboard/profile', {
          professionalDescriptionIncomplete: true,
        });
      } else {
        this.props.updateAlert(
          'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
        );
      }
    } else {
      const data = {
        active: true,
        date: new Date(),
        status: 'Solicitado',
        review: false,
      };
      const result = await this.service.save({
        curation: JSON.stringify(data),
      });
      if (result && result.success) {
        this.loadUser();
        this.props.updateAlert(
          <div>
            <p>
              Você autorizou a curadoria a avaliar seu perfil. Caso tenhamos
              alguma dúvida entraremos em contato. Ao ser aprovado seu perfil
              receberá o selo climby de validação.
            </p>
            <div className="btn-footer-modal">
              <div
                className="bt-blue"
                onClick={() => this.props.updateAlert('')}
              >
                OK
              </div>
            </div>
          </div>
        );
      }
    }
  };

  professionalDescriptionCompleted = () => {
    const { user } = this.props;
    let isFilled = true;
    if (user.professional_description) {
      if (
        !user.professional_description.experience ||
        !user.professional_description.methodology ||
        !user.professional_description.professional ||
        !user.professional_description.certificate.length
      ) {
        isFilled = false;
      }
    } else {
      isFilled = false;
    }
    return isFilled;
  };

  toggleTestSwitch() {
    this.save(
      { startTestPeriod: !this.props.user.isTestPeriodStarted },
      'testPeriod'
    );
    this.setState({
      isTestPeriodStarted: !this.state.isTestPeriodStarted,
    });
  }

  async loadUser() {
    let result = await this.service.me();
    if (result) {
      this.props.updateUser(result.user);
      this.setState({
        user: result.user,
        isVisible: result.user.profile_active || false,
        isTestPeriodStarted: result.user.isTestPeriodStarted,
      });
      this.firstLoginTrial();
    }
  }

  firstLoginTrial() {
    if (
      this.state.user.firstLoginPlan === loginPlans.TRIAL &&
      !this.state.isTestPeriodStarted &&
      this.props.showModalStartTestPeriod
    ) {
      this.confirmationModal();
    }
  }

  calculateBasic() {
    const total = this.calculateBasics.execute(this.props.user);
    this.setState({ percentage_fields_filled: total });
  }

  async save(newUser, typeRequest) {
    this.setState({ loading: true });
    let user = { coach_id: this.state.user._id, ...newUser };
    let result = await this.service.save(user);
    if (result && result.success) {
      this.loadUser();
    }
    this.setState({ loading: false });
    if (typeRequest === 'testPeriod' && result && result.success) {
      this.props.updateAlert(
        <div>
          <p>Período de teste iniciado!</p>
        </div>
      );
    }
  }

  userHasPlataformPlan() {
    if (
      this.props.user &&
      this.props.user.plans &&
      this.props.user.plans.platform
    ) {
      return true;
    }
    return false;
  }

  userIsOnTheTestPlan() {
    const {
      user: { isTestPeriodStarted, testPeriod },
    } = this.props;
    if (this.userHasPlataformPlan() && isTestPeriodStarted && testPeriod) {
      return true;
    }
    return false;
  }

  isLoadedUserPlans() {
    if (this.props.user && this.props.user.plans) {
      return true;
    }
    return false;
  }

  isTestPeriodExpired() {
    const {
      user: { isTestPeriodStarted, testPeriod },
    } = this.props;
    if (!this.userHasPlataformPlan() && isTestPeriodStarted && !testPeriod) {
      return true;
    }
    return false;
  }

  getDaysRemaining() {
    if (this.props.user && this.props.user.expiresTest) {
      const expiresTest = moment(this.props.user.expiresTest, 'DD/MM/YYYY');
      const daysRemaining = expiresTest.diff(moment().startOf('day'), 'days');
      return daysRemaining.toString();
    }
  }

  handleNotNowClick() {
    this.props.updateAlert('');
    this.props.modalStartTestPeriod(false);
  }

  logout = async () => {
    await this.service.logout();
    this.props.updateSession(undefined);
    this.props.logout();
  };

  confirmationModal() {
    this.props.updateAlert(
      <div>
        <p>
          Ao iniciar o período de teste, você terá 15 dias para experimentar os
          benefícios da plataforma.
        </p>
        <br />
        {this.state.loading ? (
          <div className="btn-footer-modal">
            <ReactLoading type="bubbles" color="#00838F" />
          </div>
        ) : (
          <div className="btn-footer-modal">
            <div
              className="bt-blue-outline"
              style={{ margin: '0 5px' }}
              onClick={() => this.handleNotNowClick()}
            >
              Agora não
            </div>
            <div
              className="bt-blue"
              style={{ margin: '0 5px' }}
              onClick={() => this.toggleTestSwitch()}
            >
              Iniciar agora
            </div>
          </div>
        )}
      </div>
    );
  }

  handleOpenPop(openPop) {
    this.setState({ isOpenPop: openPop });
  }

  enabledDashboardMenu() {
    if (this.isLoadedUserPlans && this.isTestPeriodExpired()) {
      return true;
    }
    return this.userHasPlataformPlan();
  }

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li
          className={`content-pop ${!this.enabledDashboardMenu() &&
            'disabled'}`}
          onClick={() => {
            this.props.setSidebarLinks('');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-0.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-0w.svg')}
            width="28"
            className="image-over"
          />
          <span>Dashboard</span>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Dashboard</div>
          </div>
        </li>
        {/* <li
          className={
            this.props.view == 'profile'
              ? 'active row-between-center flex1 content-pop'
              : 'row-between-center flex1 content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/profile');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-8.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-8w.svg')}
            width="28"
            className="image-over"
          />
          <div className="row-between-center flex1">
            <span>Dados Básicos</span>
            <div className="percentage-complete">
              {' '}
              {this.state.percentage_fields_filled}%{' '}
            </div>
          </div>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Dados Básicos</div>
          </div>
        </li> */}
        <li
          className={
            this.props.view === 'myprofile'
              ? 'active row-between-center flex1 content-pop'
              : 'row-between-center flex1 content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/profile');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-8.svg')}
            width="28"
            alt="profile"
          />
          <img
            src={require('./../../assets/images/menu/icon-8w.svg')}
            width="28"
            className="image-over"
            alt="profile"
          />
          <div className="row-between-center flex1">
            <span>Meu perfil</span>
            <div className="percentage-complete">
              {' '}
              {this.state.percentage_fields_filled}%{' '}
            </div>
          </div>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Meu perfil</div>
          </div>
        </li>
        {/* <li
          className={
            this.props.view == 'profile_professional'
              ? 'active row-between-center flex1 content-pop'
              : 'row-between-center flex1 content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/profile/professional');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-9.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-9w.svg')}
            width="28"
            className="image-over"
          />
          <div className="row-between-center flex1">
            <span>Dados Profissionais</span>
            <div className="percentage-complete">
              {' '}
              {this.props.user.professional_complete}%{' '}
            </div>
          </div>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Dados Profissionais</div>
          </div>
        </li> */}
        <li
          className={
            this.props.view == 'plans' ? 'active content-pop' : 'content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/profile/myplans');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-10.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-10w.svg')}
            width="28"
            className="image-over"
          />
          <span>Meus Planos</span>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Meus Planos</div>
          </div>
        </li>
        <li
          className={
            this.props.view == 'terms' ? 'active content-pop' : 'content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/terms');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-11.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-11w.svg')}
            width="28"
            className="image-over"
          />
          <span>Termos de Uso</span>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Termos de Uso</div>
          </div>
        </li>
        <li
          className={
            this.props.view == 'changepassword'
              ? 'active content-pop'
              : 'content-pop'
          }
          onClick={() => {
            this.props.goTo('dashboard/profile/changepassword');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-12.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-12w.svg')}
            width="28"
            className="image-over"
          />
          <span>Alterar Senha</span>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Alterar Senha</div>
          </div>
        </li>

        <li className="content-pop" onClick={this.logout}>
          <img
            src={require('./../../assets/images/menu/icon-13.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-13w.svg')}
            width="28"
            className="image-over"
          />
          <span>Logout</span>
          <div className="popover-container side-pop">
            <div className="triangle-border" />
            <div className="popover-content">Logout</div>
          </div>
        </li>

        {/* <div className="active-profile flex-information">
          <span>
            Perfil ativo no <span style={{ color: '#a6ce39' }}>engage</span>
          </span>
          <div className="icon-information-container">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.handleOpenPop(true)}
              onMouseOver={() => this.handleOpenPop(true)}
              onMouseLeave={() => this.handleOpenPop(false)}
            >
              <div
                className="popover-container-information"
                style={{ display: this.state.isOpenPop ? 'block' : 'none' }}
              >
                <div className="popover-content-information">
                  engage é o ambiente de negócios onde o seu perfil estará
                  disponível para o mercado.
                </div>
              </div>
              <i className="fas fa-info-circle table-icon padding"></i>
            </div>
          </div>
          <Switch
            onChange={() => {
              this.toggleSwitch();
            }}
            id={`toggle-visibility`}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={'#D8D8D8'}
            offHandleColor={'#9F9F9F'}
            onColor={'#96CBCF'}
            onHandleColor={'#00838F'}
            handleDiameter={20}
            height={13}
            width={33}
            checked={this.state.isVisible}
          />
        </div> */}
        {this.isLoadedUserPlans() &&
          !this.userHasPlataformPlan() &&
          !this.userIsOnTheTestPlan() &&
          !this.isTestPeriodExpired() && (
            <div className="active-profile flex1" style={{ padding: 0 }}>
              <div
                className="bt-blue-test-period"
                onClick={() => this.confirmationModal()}
              >
                <span className="active">Iniciar período de teste</span>
                <span className="disable">Teste</span>
              </div>
            </div>
          )}
        {this.isLoadedUserPlans() &&
          this.userIsOnTheTestPlan() &&
          !this.isTestPeriodExpired() && (
            <div
              className="active-profile flex1"
              style={{ padding: 5, marginLeft: 5 }}
            >
              <span>{`Você tem ${this.getDaysRemaining()} dia(s) restante(s) do seu período experimental.`}</span>
            </div>
          )}
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  user: state.simpleReducer.user,
  sessionId: state.simpleReducer.sessionId,
  showModalStartTestPeriod: state.simpleReducer.showModalStartTestPeriod,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateUser,
      updateAlert,
      modalStartTestPeriod,
      updateSession,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarProfile)
);
