import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction, updatePeriodicy } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { Dropdown } from 'reactjs-dropdown-component';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.state = {
      periodicy_options: ['Anual', 'Semestral', 'Trimestral'],
      open: false,
      periodicy: 'Semestral'
    }
  }

  componentDidMount(){  
      if( this.utils.getStorage( 'periodicy-filter' ) ){
        this.setPeriodicy( this.utils.getStorage( 'periodicy-filter' ) )
      }
  }

  componentWillUnmount() {  
  } 

  setPeriodicy(item){
    this.setState({ periodicy: item, open:false })
    this.utils.setStorage('periodicy-filter', item )
    this.props.updatePeriodicy(item)
    this.props.filtred()
  }
  
  

  render() {  
    return (  
        <div>
          <div className="dash-filter periodicy-filter" onClick={() => { this.setState({ open: !this.state.open }) }}>
            { this.state.periodicy }
            <i className="fas fa-filter"></i>
          </div>
          <div className={ this.state.open ? "periodicy-select" : "periodicy-select closed" }>
            {
              this.state.periodicy_options.map((item, key) => {
                return <div className="dash-filter periodicy-option" key={ key }
                   onClick={() => { this.setPeriodicy(item) }}>{ item }</div>
              })
            }
          </div>
        </div>
    );
  }
} 


const mapStateToProps = state => ({ 
  periodicy: state.simpleReducer.periodicy 
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ simpleAction, updatePeriodicy }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);