import ApiService from '../../services/ApiService';
 

class Service extends ApiService{  

	async verifyEmail(_payload) {
		try {
		    let response = await fetch(`${ this.endPoint }emailvalidate`, {
					method: 'POST',
					headers: { 
					    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 
					},
					body: this.parsePayload(_payload), 
				});
		    let responseJson = await response.json();
		    return responseJson;
		} catch (error) {
		    console.error(error);
		}
	}    
	
}

export default Service;