import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

// import Avatar from '../avatar/Avatar';
// import Logo from '../logo/Logo';


class SidebarFinances extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  render() {
    return ( 
      <ul className="side-menu menuLateral">
        <li className="content-pop" onClick={ () => { this.props.setSidebarLinks(''); } } >
          <img src={ require('./../../assets/images/menu/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Dashboard</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Dashboard
              </div> 
          </div>  
        </li>
        <li className="content-pop"
            onClick={ () => { this.props.setSidebarLinks('coachees'); } } >
          <img src={ require('./../../assets/images/menu/icon-1.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-1w.svg') } className="image-over"   width="28" /> 
          <span>Coachees</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Coachees
              </div> 
          </div>  
        </li>
        <li className={ this.props.side == 'general' ? "active content-pop" : "content-pop" } 
            onClick={ () => { this.props.setSidebarLinks('finances'); } } >
          <img src={ require('./../../assets/images/finances/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/finances/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Geral</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Geral
              </div> 
          </div>  
        </li>
        <li className={ this.props.side == 'register' ? "active content-pop" : "content-pop" }
            onClick={ () => { this.props.setSidebarLinks('finances/register/provider'); } }  >
          <img src={ require('./../../assets/images/finances/icon-1.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/finances/icon-1w.svg') } className="image-over"   width="28" /> 
          <span>Cadastro</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Cadastro
              </div> 
          </div>  
        </li> 
        <li className={ this.props.side == 'balance' ? "active content-pop" : "content-pop" }
            onClick={ () => { this.props.setSidebarLinks('finances/balance/in'); } }  >
          <img src={ require('./../../assets/images/finances/icon-2.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/finances/icon-2w.svg') } className="image-over"   width="28" /> 
          <span>Balanço</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Balanço
              </div> 
          </div>  
        </li> 
       {/* <li className={ this.props.side == 'integrations' ? "active content-pop" : "content-pop" } >
          <img src={ require('./../../assets/images/finances/icon-3.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/finances/icon-3w.svg') } className="image-over"   width="28" /> 
          <span>Integrações</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Integrações
              </div> 
          </div>  
        </li> */}
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFinances);