import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './Submenu';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';

class ProfileProfessional extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      loading_life: false,
      loading_professional: false,
      loading: false,
      view: 'profile_professional',
      subview: 'area',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados profissionais', link: '' },
        { title: 'Área de atuação', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      professional: [],
      life: [],
      presential: false,
      online: false,
      cities: '',
      selected_professional: [],
      selected_life: [],
      maxLength: 3660,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.tOnline = this.tOnline.bind(this);
    this.tPresential = this.tPresential.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.save = this.save.bind(this);

    this.select_professional = this.select_professional.bind(this);
    this.checked_professional = this.checked_professional.bind(this);
    this.select_life = this.select_life.bind(this);
    this.checked_life = this.checked_life.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  async tPresential() {
    if (this.state.presential) {
      await this.setState({ presential: false });
    } else {
      await this.setState({ presential: true });
    }
  }

  async tOnline() {
    if (this.state.online) {
      await this.setState({ online: false });
    } else {
      await this.setState({ online: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadProfessional();
    this.loadLife();
    this.load();

    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  formValid() {
    return true;
    // if( this.state.cities.length > 0 ){
    //   return true
    // }
    return false;
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  // for this page

  async loadProfessional() {
    this.setState({ loading_professional: true });
    let result = await this.service.options_professional();
    if (result) {
      this.setState({
        loading_professional: false,
        professional: result.options,
      });
    }
  }

  async loadLife() {
    this.setState({ loading_professional: true });
    let result = await this.service.options_life();
    if (result) {
      this.setState({ loading_professional: false, life: result.options });
    }
  }

  select_professional(e, id) {
    if (e.target.checked) {
      let selected_professional = this.state.selected_professional;
      selected_professional.push(id);
      this.setState({ selected_professional });
    } else {
      let selected_professional = this.state.selected_professional;
      selected_professional.splice(selected_professional.indexOf(id), 1);
      this.setState({ selected_professional });
    }
  }

  checked_professional(id) {
    return this.state.selected_professional.indexOf(id) !== -1;
  }

  select_life(e, id) {
    if (e.target.checked) {
      let selected_life = this.state.selected_life;
      selected_life.push(id);
      this.setState({ selected_life });
    } else {
      let selected_life = this.state.selected_life;
      selected_life.splice(selected_life.indexOf(id), 1);
      this.setState({ selected_life });
    }
  }

  checked_life(id) {
    return this.state.selected_life.indexOf(id) !== -1;
  }

  async save() {
    if (this.formValid()) {
      await this.setState({ loading: true });
      if (this.state.presential || this.state.online) {
        let professional_area = {
          professional: this.state.selected_professional,
          life: this.state.selected_life,
          presential: this.state.presential,
          online: this.state.online,
          cities: this.state.cities,
        };
        let user = { professional_area: JSON.stringify(professional_area) };
        let result = await this.service.save(user);
        if (result.success) {
          this.props.updateAlert('Salvo com sucesso');
          this.loadUser();
        }
      }
      await this.setState({ loading: false });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  async load() {
    this.setState({ loading: true });
    let result = await this.service.professional_area();
    if (result && result.professional_area != undefined) {
      this.setState({
        selected_life: result.professional_area.life,
        selected_professional: result.professional_area.professional,
        cities: result.professional_area.cities,
        presential: result.professional_area.presential,
        online: result.professional_area.online,
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <Submenu goTo={this.goTo} subview={this.state.subview} />

              <div className="dashboard-box box100-menu">
                <div className="box-row-title mobile-column">
                  <div className="box-title-lower">Área de atuação</div>
                  <UploadImage
                    className="up-image-resp-new img-refine"
                    user={this.state.user}
                    uuser={this.loadUser}
                    newOption={true}
                  />
                </div>

                <div className="row-form mobile-column">
                  <div className="content-check">
                    <div className="form-title">Profissional</div>
                    {this.state.loading_professional ? (
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    ) : (
                      this.state.professional.map((item, key) => {
                        return (
                          <label key={key} className="container-ckb">
                            <input
                              type="checkbox"
                              value={item.id}
                              checked={this.checked_professional(item.id)}
                              onChange={ev => {
                                this.select_professional(ev, item.id);
                              }}
                            />
                            <span className="chklbl">{item.option}</span>
                            <span className="checkmark"></span>
                          </label>
                        );
                      })
                    )}
                  </div>
                  <div className="content-check">
                    <div className="form-title mt-20">Vida</div>
                    {this.state.loading_life ? (
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    ) : (
                      this.state.life.map((item, key) => {
                        return (
                          <label key={key} className="container-ckb">
                            <input
                              type="checkbox"
                              value={item.id}
                              checked={this.checked_life(item.id)}
                              onChange={ev => {
                                this.select_life(ev, item.id);
                              }}
                            />
                            <span className="chklbl">{item.option}</span>
                            <span className="checkmark"></span>
                          </label>
                        );
                      })
                    )}
                  </div>
                </div>

                <div className="row-form">
                  <div className="content-check">
                    <div className="form-title mt-20">Tipo de Atendimento</div>
                    <div
                      className={
                        !this.state.presential
                          ? 'option-type'
                          : 'option-type active'
                      }
                      onClick={this.tPresential}
                    >
                      <img
                        src={require('./../../../assets/images/menu/personal/icon-5.svg')}
                        width="22"
                      />
                      <img
                        src={require('./../../../assets/images/menu/personal/icon-5b.svg')}
                        width="22"
                      />
                      Presenciais
                    </div>
                    <div
                      className={
                        !this.state.online
                          ? 'option-type'
                          : 'option-type active'
                      }
                      onClick={this.tOnline}
                    >
                      <img
                        src={require('./../../../assets/images/menu/personal/icon-6.svg')}
                        width="55"
                      />
                      <img
                        src={require('./../../../assets/images/menu/personal/icon-6b.svg')}
                        width="55"
                      />
                      Online
                    </div>
                  </div>
                </div>

                <div className="row-form">
                  <div className="content-check">
                    {!this.state.presential && !this.state.online && (
                      <span style={{ color: '#fa1e1e' }}>
                        Selecione no mínino um "Tipo de Atendimento"
                      </span>
                    )}
                  </div>
                </div>

                <div className="row-form">
                  <div className="form-textarea">
                    <label className="blue-label">Cidades que atua</label>
                    <textarea
                      value={this.state.cities}
                      className="blue-input"
                      placeholder="Cidades que atua"
                      maxLength={this.state.maxLength}
                      onChange={e => {
                        this.setState({ cities: e.target.value });
                      }}
                    ></textarea>
                    <span>
                      {this.state.cities.length}/{this.state.maxLength}
                    </span>
                  </div>
                </div>

                <div className="row-padding">
                  {this.state.loading ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <div
                      onClick={() => {
                        this.save();
                      }}
                      className={
                        !this.formValid()
                          ? 'bt-save hover-shadow'
                          : 'bt-save hover-shadow active'
                      }
                    >
                      Salvar Alterações
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileProfessional)
);
