import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col, Row } from 'reactstrap';
import { updateImage, updateSession } from '../../../actions/SimpleAction';
import SidebarSessions from './../../sidebar/SidebarSessions';
import CardGenericTableWithGroup from './../../components/CardGenericTableWithGroup';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import NotAllowed from './../../components/NotAllowed';
import Submenu from './SubmenuSession';
import ModalActions from './ModalActions';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import ModalActionDelete from './../../components/NewModalCoacheeDelete';

class ActionsRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'actions',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de ações', link: 'programs/coaching/actions' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      rows: [],
      tableTitles: [
        { title: 'Data ação', key: 'date_action' },
        { title: 'Ação', key: '_action' },
        { title: 'Descrição', key: 'description' },
        { title: 'Responsável', key: 'responsible' },
        { title: 'Data prevista', key: 'udate' },
        { title: 'Conclusão', key: 'completed_at' },
        { title: 'Status', key: 'action_status' },
        { title: 'Opções', key: 'options' },
        { title: 'Resposta', key: '_response_status' },
      ],
      tableActions: [
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.edit.bind(this),
        },
        {
          title: 'Deletar',
          icon: 'fas fa-trash',
          action: this.removeAction.bind(this),
        },
      ],
      isLoading: false,

      loading_coachee: false,
      coachees: [],

      loading_group: false,
      groups: [],

      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
      },
      sessionActions: [],

      showModalActions: false,
      responseActions: [{ action: this.taskYes.bind(this) }],
      isEditingAction: false,
      showModalActionDelete: false,
      actionToDelete: null,
      editingAction: null,
      selectedCoachees: [],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.toggleModalAction = this.toggleModalAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.removeAction = this.removeAction.bind(this);
  }

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  toggleModalAction() {
    const { showModalActions } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
    });
  }

  async saveAction(action) {
    const { sessionId } = this.props;
    const { selectedCoachees, session, editingAction } = this.state;

    if (sessionId) {
      const isEditing = editingAction;
      let result;

      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }
      if (isEditing) {
        const { meeting, coachee_id: coacheeId, action: actionNumber } = action;

        result = await this.service.updateSessionAction(
          sessionId,
          meeting,
          coacheeId,
          actionNumber,
          action
        );
      } else if (sendTo.length > 0) {
        result = await this.service.storeSessionActions(
          sessionId,
          'session',
          sendTo,
          action
        );
      }

      if (result && result.success) {
        await this.loadSessionActions(sessionId);
      }
    }

    this.toggleModalAction();
  }

  async edit(action) {
    this.setState(
      {
        isEditingAction: true,
        editingAction: action,
      },
      () => {
        this.toggleModalAction();
      }
    );
  }

  async removeAction(action) {
    this.setState(
      {
        actionToDelete: action,
      },
      () => {
        this.toggleModalDelete();
      }
    );
  }

  deleteAction = async () => {
    const { sessionId } = this.props;
    const { actionToDelete } = this.state;
    if (sessionId) {
      const {
        meeting,
        coachee_id: coacheeId,
        action: actionNumber,
      } = actionToDelete;

      const result = await this.service.destroySessionAction(
        sessionId,
        meeting,
        coacheeId,
        actionNumber
      );
      if (result && result.success) {
        await this.loadSessionActions(sessionId);
      }
    }

    this.toggleModalDelete();
  };

  toggleModalDelete() {
    const { showModalActionDelete } = this.state;
    this.setState({
      showModalActionDelete: !showModalActionDelete,
    });
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.resolveParams(),
        this.loadCoachees(),
      ]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de ações', link: 'programs/coaching/actions' },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  resolveParams = async () => {
    const {
      sessionId,
      match: { params },
    } = this.props;
    let session = {};

    if (params.sessionId) {
      const result = await this.service.sessionS(params.sessionId);

      if (result && result.success) {
        session = result.session;
        this.props.updateSession(session._id);
      }
    }

    this.loadSession(session._id || sessionId);
    this.loadSessionActions(session._id || sessionId);
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  parseSession = session => {
    const { coachee_id, project, group_id, its_group } = session;
    return { coachee_id, project, group_id, its_group };
  };

  loadSession = async sessionId => {
    if (!sessionId) {
      this.goTo('dashboard/programs/coaching');
      return;
    }

    this.setState({ loading: true });
    const result = await this.service.sessionS(sessionId);
    if (result && result.success && result.session) {
      if (result.session.its_free) {
        this.goTo('dashboard/programs/master');
        return;
      }

      this.setState({
        session: this.parseSession(result.session),
      });
    }
    this.setState({ loading: false });
  };

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      await this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  async loadSessionActions(sessionId) {
    this.setState({ loading: true });
    let result = await this.service.sessionActions(sessionId);
    if (result && result.success) {
      this.setState({ sessionActions: result.actions });
    }
    this.setState({ loading: false });
  }

  async taskYes(action) {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, coachee_id: coacheeId, action: actionNumber } = action;

      const result = await this.service.completeSessionAction(
        sessionId,
        meeting,
        coacheeId,
        actionNumber
      );

      if (result && result.success) {
        this.loadSessionActions(sessionId);
      }
    }
  }

  adicionarNovaAcao = () => {
    this.setState({ isEditingAction: false, editingAction: null }, () => {
      this.toggleModalAction();
    });
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      !session.its_group ||
      (session.its_group && this.userHasGroupSessionRight(user))
    );
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      session,
      loading,
      coachees,
      tableActions,
      responseActions,
      tableTitles,
      sessionActions,
      showModalActions,
      showModalActionDelete,
      editingAction,
      isEditingAction,
      groups,
      selectedCoachees,
      user,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <Submenu goTo={this.goTo} subview={subview} />
                  <div className="dashboard-box box100-menu">
                    <div className="box-row-title row-center">
                      <div className="box-title">Registro de Ações</div>
                    </div>
                    <Col
                      md={{ size: 4, offset: 8 }}
                      className="row-info-project mBottomReset"
                    >
                      <div className="">
                        {session.coachee_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Coachee</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.coachee_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Coachee</option>
                                {coachees.map(coachee => {
                                  return (
                                    <option
                                      key={coachee._id}
                                      value={coachee._id}
                                    >
                                      {' '}
                                      &nbsp; {coachee.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : session.group_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Grupo</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.group_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Grupo</option>
                                {groups.map(group => {
                                  return (
                                    <option key={group._id} value={group._id}>
                                      {' '}
                                      &nbsp; {group.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {session.project ? (
                          <div className="form-input">
                            <label className="blue-label">Projeto</label>
                            <input
                              disabled={true}
                              type="text"
                              value={session.project}
                              className="blue-input"
                              placeholder="Projeto"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <CardGenericTableWithGroup
                      noBox
                      id="session-actions-1"
                      title="Registro de Ações"
                      response_actions={responseActions}
                      actions={tableActions}
                      title_rows={[
                        ...(session.its_group
                          ? [
                              {
                                title: 'Coachee',
                                key: 'coacheeName',
                              },
                            ]
                          : []),
                        ...tableTitles,
                      ]}
                      groupBy="meetingTitle"
                      rows={sessionActions.map(action => {
                        const { meeting } = action;
                        let meetingTitle = '';
                        if (meeting === 'master') {
                          meetingTitle = 'Sessão Master';
                        } else if (meeting === 'session') {
                          meetingTitle = 'Ação Adicional';
                        } else {
                          meetingTitle = `Sessão ${meeting}`;
                        }
                        return {
                          ...action,
                          meetingTitle,
                          coacheeName: action.coachee.name,
                        };
                      })}
                    />
                  </div>

                  <Row>
                    <Col
                      md={{ size: 12, offset: 12 }}
                      className="mBottomReset"
                      style={{ marginLeft: '12px' }}
                    >
                      <div className="row-button">
                        {loading ? (
                          <ReactLoading type="bubbles" color="#00838F" />
                        ) : (
                          <div
                            className="bt-blue"
                            onClick={() => {
                              this.adicionarNovaAcao();
                            }}
                          >
                            <img
                              src={require('./../../../assets/images/plus.svg')}
                              style={{ marginTop: -3, marginRight: 14 }}
                            />
                            Adicionar Ação
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        <ModalActions
          toggleModalAction={this.toggleModalAction}
          saveAction={this.saveAction}
          modal_actions={showModalActions}
          typeAcao={isEditingAction}
          editingAction={editingAction}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalActionDelete
          close={() => {
            this.setState({ showModalActionDelete: false });
          }}
          open={showModalActionDelete}
          deleteContent={this.deleteAction}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsRegister)
);
