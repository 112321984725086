import React, { Component } from 'react';
import { connect } from 'react-redux'; 

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';  
 

import { simpleAction } from '../../../actions/SimpleAction' 
 
 
import Utils from './../../../libs/Utils.js';
  

class SubmenuSession extends Component { 

  constructor(props) {
    super(props);  
    this.state = { }; 
    this.utils = new Utils();
  }
 
  componentDidMount(){ 

  }

  componentWillUnmount() { 

  }
 

 
  render() { 

    return ( 
        <div className="submenu">
          <ul className="side-menu"> 
            <li className={ this.props.subview == 'register' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('coachees/dashboard/programs') } }>
              <img src={ require('./../../../assets/images/session/icon-2.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/session/icon-2w.svg') } width="28" className="image-over"  /> 
            </li> 
            <li className={ this.props.subview == 'actions' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('coachees/dashboard/programs/actions') } } >
              <img src={ require('./../../../assets/images/session/icon-3.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/session/icon-3w.svg') } width="28" className="image-over"  /> 
            </li> 
            <li className={ this.props.subview == 'contents' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('coachees/dashboard/programs/contents') } }>
              <img src={ require('./../../../assets/images/session/icon-4.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/session/icon-4w.svg') } width="28" className="image-over"  /> 
            </li>   
          </ul>
        </div> 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuSession);