import React, { Component } from 'react';
import moment from 'moment';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

export const chronometerInitialState = () => ({
  startTime: null,
  diff: null,
  suspended: 0,
  interval: null,
});

export const normalizeChronometerFromApi = data => {
  if (data) {
    return {
      ...data,
      diff: data.diff
        ? moment(moment(data.diff).utc() - data.startTime).utc()
        : null,
    };
  }
  return chronometerInitialState();
};

class Chronometer extends Component {
  constructor(props) {
    super(props);
  }

  start = async () => {
    if (this.props.chronometer.startTime) {
      return;
    }
    const chronometer = {
      startTime: moment().utc() - this.props.chronometer.suspended,
      interval: requestAnimationFrame(this.tick),
      suspended: 0,
      diff: null,
    };
    await this.props.handleChronometer(chronometer);
  };

  stop = async () => {
    cancelAnimationFrame(this.props.chronometer.interval);
    const chronometer = {
      ...this.props.chronometer,
      startTime: null,
      suspended: +this.props.chronometer.diff,
    };
    await this.props.handleChronometer(chronometer);
    await this.props.onSave();
  };

  reset = async () => {
    cancelAnimationFrame(this.props.chronometer.interval);
    await this.props.handleChronometer(chronometerInitialState());
    await this.props.onSave();
  };

  tick = () => {
    if (!this.props.chronometer.startTime) {
      return;
    }
    const chronometer = {
      ...this.props.chronometer,
      diff: moment(moment().utc() - this.props.chronometer.startTime).utc(),
      interval: requestAnimationFrame(this.tick),
    };
    this.props.handleChronometer(chronometer);
  };

  addZero = n => (n < 10 ? `0${n}` : n);

  render() {
    const diff = this.props.chronometer.diff;
    const hours = diff ? this.props.chronometer.diff.hours() : 0;
    const minutes = diff ? this.props.chronometer.diff.minutes() : 0;
    const seconds = diff ? this.props.chronometer.diff.second() : 0;
    const hundredths = diff
      ? Math.round(this.props.chronometer.diff.millisecond() / 10)
      : 0;

    return (
      <div className="chronometer-div">
        <label className="blue-label">
          Cronômetro{' '}
          {this.props.activeMeeting ? `Sessão ${this.props.activeMeeting}` : ''}
        </label>
        <div
          className={
            this.props.activeMeeting
              ? 'chronometer-info-sessions'
              : 'chronometer-info'
          }
        >
          <span>
            {this.addZero(hours)}:{this.addZero(minutes)}:
            {this.addZero(seconds)}:{this.addZero(hundredths)}
          </span>
        </div>
        <div
          className={
            this.props.activeMeeting
              ? 'chronometer-btn-options-sessions'
              : 'chronometer-btn-options'
          }
        >
          <div onClick={this.start} className="chronometer-btn">
            Iniciar
          </div>
          {this.props.chronometer.startTime ? (
            <div onClick={this.stop} className="chronometer-btn">
              Parar
            </div>
          ) : (
            <div onClick={this.reset} className="chronometer-btn">
              Zerar
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Chronometer;
