import React from 'react';

function ContentUploadConfirmation({ cancel, upload }) {
  return (
    <div>
      <p>
        Você não possui conteúdo em sua área. Faça um upload para adicionar
        conteúdo.
      </p>
      <br />
      <div
        className="bt-blue btoff"
        style={{ margin: '0 auto', marginBottom: 10 }}
        onClick={cancel}
      >
        Cancelar
      </div>
      <div className="bt-blue" style={{ margin: '0 auto' }} onClick={upload}>
        Upload
      </div>
    </div>
  );
}

export default ContentUploadConfirmation;
