import React from 'react';
import { TagPicker, Checkbox } from 'rsuite';

import './rsuite_wrapper.css';

export const TagPickerWrapper = props => {
  return <TagPicker {...props} />;
};

export const CheckboxWrapper = props => {
  return <Checkbox {...props}>{props.children}</Checkbox>;
};
