import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';

import Header from './../../includes/Header';

class RegisterSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="not-found-label">
          <p className="title-bold">Cadastro realizado com sucesso</p>
          <i className="far fa-envelope big-grey-icon"></i>
          <p className="text-register-success">
            Enviamos um email pra você, verifique sua caixa de entrada
            <b> - confirme seu cadastro e termine de preencher seus dados</b>
          </p>
        </div>
      </div>
    );
  }
}

export default connect()(RegisterSuccess);
