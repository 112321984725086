import React from 'react';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

export function HelpCenterBox() {
  return (
    <div className="help-center-box">
      <a
        href={`${process.env.REACT_APP_URL_WORDPRESS}suporte-ao-cliente/`}
        target="_blank"
      >
        Suporte ao cliente
      </a>
      <a
        href={`${process.env.REACT_APP_URL_WORDPRESS}suporte-ao-cliente/#TUTORIAL`}
        target="_blank"
      >
        Tutorial
      </a>
    </div>
  );
}

export default HelpCenterBox;
