import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return (  
        <div className="dash-filter">
          { this.props.filter }
          <i className="fas fa-filter"></i>
        </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);