import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../assets/images/logo-facebook.jpg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { updateAlert } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 
 

class ModalAlert extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.service = new Service();  

    this.state = { } 

    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount(){ }

  componentWillReceiveProps(nextProps){   }

  componentWillUnmount() {  
  }  

  closeModal(){
    this.props.updateAlert("")
  }

  render() {  
    return (   
          <div>
            {
              this.props.alert != "" ? 
              <div className="modal-alert">
                <div className="modal-alert-box">
                  <i className="fas fa-times icon-close" onClick={() => { this.closeModal() }}></i>
                  <div className="row-modal-alert">
                    <img src={ logo } width={100} />
                    {  
                      typeof this.props.alert  === 'string' && this.props.alert.indexOf('</') !== -1 ?
                        <div className="modal-alert-text" dangerouslySetInnerHTML={ { __html: this.props.alert }}></div>
                      : <div className="modal-alert-text">{ this.props.alert }</div>
                    }
                  </div>
                </div>
              </div> : null
            }
          </div>
    );
  }
}
const mapStateToProps = state => ({  
  alert: state.simpleReducer.alert
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);