import React, { Component } from 'react';
import { connect } from 'react-redux';
import { simpleAction } from '../../actions/SimpleAction';
import Utils from '../../libs/Utils';
import '../../styles/css/tutorial/styles.scss';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.utils = new Utils();
  }
  logout() {
    this.utils.setLogout();
    window.location.reload();
  }

  render() {
    return (
      <ul className="side-menu menuLateral tutorial-styles">
        <li
          className={
            this.props.side === 'dash' ? 'active content-pop' : 'content-pop'
          }
          onClick={() => this.props.setSidebarLinks('')}
        >
          <img
            src={require('./../../assets/images/menu/icon-0.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-0w.svg')}
            width="28"
            className="image-over"
          />
          <span>Dashboard</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Dashboard</div>
          </div>
        </li>
        <li
          className={
            this.props.side === 'coachees'
              ? 'active content-pop'
              : 'content-pop'
          }
          onClick={() => this.props.setSidebarLinks('coachees')}
        >
          <img
            src={require('./../../assets/images/menu/icon-1.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-1w.svg')}
            width="28"
            className="image-over"
          />
          <span>
            Coachees
            {this.props.simpleReducer.tutorial_status &&
              this.props.simpleReducer.tutorial_posicao >= 4 && (
                <span className="modalTutorial">2</span>
              )}
          </span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Coachees</div>
          </div>
        </li>
        <li
          className={
            this.props.side === 'calendar'
              ? 'active content-pop'
              : 'content-pop'
          }
          onClick={() => this.props.setSidebarLinks('calendar')}
        >
          <img
            src={require('./../../assets/images/menu/icon-2.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-2w.svg')}
            width="28"
            className="image-over"
          />
          <span>Agenda</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Agenda</div>
          </div>
        </li>
        <li
          className={
            this.props.side === 'messages'
              ? 'active content-pop'
              : 'content-pop'
          }
          onClick={() => this.props.setSidebarLinks('messages')}
        >
          <img
            src={require('./../../assets/images/menu/icon-3.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-3w.svg')}
            width="28"
            className="image-over"
          />
          <span>
            Mensagens
            {this.props.simpleReducer.tutorial_status &&
              this.props.simpleReducer.tutorial_posicao >= 5 && (
                <span className="modalTutorial">3</span>
              )}
          </span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Mensagens</div>
          </div>
        </li>

        <li
          className={
            this.props.side === 'marketing'
              ? 'active content-pop'
              : 'content-pop'
          }
          onClick={() => this.props.setSidebarLinks('marketing/site')}
        >
          <img
            src={require('./../../assets/images/menu/icon-5.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-5w.svg')}
            width="28"
            className="image-over"
          />
          <span>Marketing</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Marketing</div>
          </div>
        </li>
        <li className="content-pop" onClick={() => this.logout()}>
          <img
            src={require('./../../assets/images/menu/icon-13.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-13w.svg')}
            width="28"
            className="image-over"
          />
          <span>Sair</span>
          <div className="popover-container side-pop">
            <div className="triangle-border"></div>
            <div className="popover-content">Sair</div>
          </div>
        </li>
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
