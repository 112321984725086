import React, { Component } from 'react';
import { connect } from 'react-redux';

import Switch from 'react-switch';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { simpleAction } from '../../actions/SimpleAction';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js';
import TableRow from './TableRow';
import BoxOptions from './BoxOptions';

class CardGenericTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total: 'R$ 0,00',
      visible: true,
    };

    this.utils = new Utils();
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  componentDidMount() {
    if (this.isClosed(`${this.props.id}`)) {
      this.setState({ visible: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.finance == 'finance') this.getTotal(nextProps);
  }

  componentWillUnmount() {}

  getTotal(nextProps) {
    let total = 0;
    for (let _row of nextProps.rows) {
      let val = this.clearValue(_row.value);
      if (this.props.type == 'inout') {
        if (_row.type == 'in') {
          total += val;
        } else {
          total -= val;
        }
      } else {
        total += val;
      }
    }
    this.setState({ total: this.dirtyValue(total) });
  }

  clearValue(value) {
    let val = value.replace('R$ ', '');
    val = val.replace(/\./g, '');
    val = val.replace(',', '.');
    return parseFloat(val);
  }

  dirtyValue(value) {
    let _v = parseFloat(value);
    let parts = _v
      .toFixed(2)
      .toString()
      .split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    value = parts.join(',');
    return `R$ ${value}`;
    // return _v > 0 ? `R$ ${ value }` : 'R$ 0,00'
  }

  toggleVisibility() {
    if (!this.state.visible) {
      this.setClosed(`${this.props.id}`, '');
    } else {
      this.setClosed(`${this.props.id}`, 'closed');
    }
    this.setState({ visible: !this.state.visible });
  }

  isClosed(_k) {
    let key = `climby::${_k}`;
    try {
      return (
        localStorage.getItem(key) !== null &&
        localStorage.getItem(key) !== 'null' &&
        localStorage.getItem(key) !== ''
      );
    } catch (e) {
      return (
        sessionStorage.getItem(key) !== null &&
        sessionStorage.getItem(key) !== 'null' &&
        sessionStorage.getItem(key) !== ''
      );
    }
  }

  setClosed(_k, t) {
    let key = `climby::${_k}`;
    try {
      return localStorage.setItem(key, t);
    } catch (e) {
      return sessionStorage.setItem(key, t);
    }
  }

  testeLink = () => {
    console.log(`LINK`);
  };

  isSorted = (key, titles) => {
    return (
      this.props.sort &&
      key + 1 !== this.props.title_rows.length &&
      titles.key !== 'options' &&
      titles.key !== 'action' &&
      this.props.rows.length >= 2
    );
  };

  render() {
    return (
      <div
        className={
          this.props.noBox
            ? ` ${this.props.className} `
            : `dashboard-box box100 ${this.props.className} `
        }
      >
        <div className="box-row-title">
          <div className="box-title">{this.props.title}</div>
          <BoxOptions
            visible={this.state.visible}
            toggleVisibility={this.toggleVisibility.bind(this)}
          />
        </div>

        {this.props.finance == 'finance' ? (
          <Col xs={{ size: 12 }} className="content-finance-total">
            <div
              onClick={() => {
                this.props.changeRows('all');
              }}
              className={
                this.props.viewRows == 'all'
                  ? 'content-finance-total-button button-f-blue active'
                  : 'content-finance-total-button button-f-blue'
              }
            >
              TODOS
            </div>
            <div
              onClick={() => {
                this.props.changeRows('unreceived');
              }}
              className={
                this.props.viewRows == 'unreceived'
                  ? 'content-finance-total-button button-f-red active'
                  : 'content-finance-total-button button-f-red'
              }
            >
              VENCIDOS
            </div>
            <div
              onClick={() => {
                this.props.changeRows('toreceive');
              }}
              className={
                this.props.viewRows == 'toreceive'
                  ? 'content-finance-total-button button-f-white active'
                  : 'content-finance-total-button button-f-white'
              }
            >
              {this.props.type == 'out' ? 'A PAGAR' : 'A RECEBER'}
            </div>
            <div
              onClick={() => {
                this.props.changeRows('received');
              }}
              className={
                this.props.viewRows == 'received'
                  ? 'content-finance-total-button button-f-green active'
                  : 'content-finance-total-button button-f-green'
              }
            >
              {this.props.type == 'out' ? 'PAGO' : 'RECEBIDO'}
            </div>
          </Col>
        ) : null}

        {this.state.visible ? (
          <div
            className="box-row-table disable-scrollbars"
            style={{ overflowX: 'auto' }}
          >
            <table
              className="big-row"
              style={{ marginBottom: 5, width: '100%', margin: 0 }}
            >
              {this.props.rows.length > 0 ? (
                <thead>
                  <tr className={this.props.finance == 'finance' ? 'rfin' : ''}>
                    {this.props.title_rows.map((titles, key) => {
                      return (
                        <td key={key}>
                          <div
                            className={
                              key != 0 &&
                              key + 1 != this.props.title_rows.length &&
                              titles.key != '_response' &&
                              titles.key != '__response' &&
                              titles.key != 'action' &&
                              titles.key != 'options'
                                ? 'tcenter'
                                : key + 1 == this.props.title_rows.length ||
                                  titles.key == '_response' ||
                                  titles.key == '__response' ||
                                  titles.key == 'action' ||
                                  titles.key == 'options'
                                ? 'tright'
                                : ''
                            }
                            style={{
                              ...(this.isSorted(key, titles)
                                ? {
                                    marginRight: 10,
                                    paddingLeft: key === 0 ? 0 : 10,
                                  }
                                : {}),
                            }}
                          >
                            {titles.title}
                          </div>

                          {!this.isSorted(key, titles) ? null : (
                            <i
                              className={
                                this.props._sort == titles.key
                                  ? 'fas fa-sort-up'
                                  : 'fas fa-sort-down'
                              }
                              style={{
                                float: 'right',
                                marginTop: -15,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.props.sort(titles.key);
                              }}
                            ></i>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {this.props.link ? (
                  <tr className="" style={{ cursor: 'auto' }}>
                    <td className="" style={{ width: '100%' }}>
                      <div className="div-box-row-table">
                        <span className="mobile-label">Configuração: </span>
                        <div className="">Central de ajuda</div>
                      </div>
                    </td>
                    <td className="tright" style={{ width: '100%' }}>
                      <div className="div-box-row-table">
                        <span className="mobile-label">Opções: </span>
                        <div className="row-table-actions">
                          <div>
                            <div
                              className="popover-container"
                              style={{ display: 'none' }}
                            >
                              <div className="popover-content">Visualizar</div>
                            </div>
                            <i
                              className="fas fa-eye table-icon"
                              onClick={this.props.link}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}

                {this.props.rows.length > 0 ? (
                  this.props.rows.map((__row, indexRow) => {
                    return (
                      <TableRow
                        key={indexRow}
                        row_action={this.props.row_action}
                        action_loading={this.props.action_loading}
                        action_loading_key={this.props.action_loading_key}
                        indexRow={indexRow}
                        finance={this.props.finance}
                        proposal={this.props.proposal}
                        __row={__row}
                        title_rows={this.props.title_rows}
                        actions={this.props.actions}
                        toggleSwitch={this.props.toggleSwitch}
                        response_actions={this.props.response_actions}
                        linkEdit={this.props.edit}
                        campoImagem={this.props.campoImagem}
                        campoNome={this.props.campoNome}
                        campoEmail={this.props.campoEmail}
                        titleCard={this.props.title}
                      ></TableRow>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="register-empty"
                      colSpan={this.props.title_rows.length}
                    >
                      <i className="fas fa-exclamation-triangle"></i>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {this.props.finance == 'finance' ? (
              <Col xs={{ size: 12 }} className="content-total">
                <Col
                  xs={{ size: 5 }}
                  sm={{ size: 7 }}
                  style={{ float: 'left' }}
                >
                  Total
                </Col>
                <Col
                  xs={{ size: 5 }}
                  sm={{ size: 3 }}
                  style={{ float: 'left' }}
                >
                  {this.state.total}
                </Col>
              </Col>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardGenericTable);
