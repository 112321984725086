import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../assets/images/logo-facebook.jpg";
import "../../styles/css/style.css";
import "../../styles/css/dashboard.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styeModalCompact.css";

class ModalAlertTwo extends Component {
  render() {
    return (
      <div>
        {this.props.open ? (
          <div className="modal-alert" style={{ zIndex: 1000 }}>
            <div className="modal-alert-box">
              <i
                className="fas fa-times icon-close"
                onClick={() => {
                  this.props.closeAlert();
                }}
              ></i>
              <div className="row-modal-alert">
                <img src={logo} width={100} />
                <div>
                  <div className="modal-alert-text">
                    {" "}
                    {this.props.titleAlert}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  alert: state.simpleReducer.alert
});

const mapDispatchToProps = dispatch => bindActionCreators({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlertTwo);
