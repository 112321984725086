import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import ReactLoading from 'react-loading';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import { updateImage, updateSession } from '../../../actions/SimpleAction';

import SidebarSessions from './../../sidebar/SidebarSessions';
import CardGenericTableWithGroup from './../../../screens_dashboard/components/CardGenericTableWithGroup';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';

import ModalActions from './ModalActions';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class ActionsRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'actions',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de ações', link: 'programs/coaching/actions' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,

      tableTitles: [
        { title: 'Data da sessão', key: 'date_action' },
        { title: 'Ação', key: '_action' },
        { title: 'Descrição', key: 'description' },
        { title: 'Responsável', key: 'responsible' },
        { title: 'Data prevista', key: '_date' },
        { title: 'Opções', key: 'options' },
        { title: 'Concluir', key: '_response_status' },
      ],
      tableActions: [
        {
          title: 'Editar',
          icon: 'fas fa-pencil-alt',
          action: this.edit.bind(this),
        },
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.see.bind(this),
        },
      ],

      loadingActions: false,
      sessionActions: [],
      showModalActions: false,
      currentAction: null,
      isEditingAction: false,

      responseActions: [{ action: this.taskYes }],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.toggleModalAction = this.toggleModalAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    const {
      sessionId,
      match: { params },
    } = this.props;

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser(),
        this.loadSessionActions(params.sessionId || sessionId),
      ]).finally(() => this.setState({ isLoading: false }));
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.sessionId !== this.props.match.params.sessionId
    ) {
      this.loadSessionActions(this.props.match.params.sessionId);
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`coachees/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  mapApiToState = result => {
    const { actions, sessionId } = result;
    this.setState({
      sessionActions: actions.map(action => ({
        ...action,
        date: this.utils.parseDateUSA(action.date),
      })),
    });
    this.props.updateSession(sessionId);
  };

  loadSessionActions = async sessionId => {
    if (!sessionId) {
      this.goTo('coachees/dashboard/programs');
      return;
    }

    this.setState({ loadingActions: true });
    let result = await this.service.sessionActions(sessionId);

    if (result && result.success) {
      this.mapApiToState(result);
    } else {
    }
    this.setState({ loadingActions: false });
  };

  toggleModalAction() {
    const { showModalActions } = this.state;
    this.setState({ showModalActions: !showModalActions });
  }

  async saveAction(action) {
    const { sessionId } = this.props;
    const { isEditingAction } = this.state;

    if (isEditingAction && sessionId) {
      const { meeting, action: actionNumber } = action;

      const result = await this.service.updateSessionAction(
        sessionId,
        meeting,
        actionNumber,
        action
      );

      if (result && result.success) {
        this.loadSessionActions(sessionId);
      }
    }

    this.toggleModalAction();
  }

  see(action) {
    this.setState({ currentAction: action, isEditingAction: false }, () => {
      this.toggleModalAction();
    });
  }

  edit(action) {
    this.setState({ currentAction: action, isEditingAction: true }, () => {
      this.toggleModalAction();
    });
  }

  taskYes = async action => {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, coachee_id: coacheeId, action: actionNumber } = action;

      const result = await this.service.completeSessionAction(
        sessionId,
        meeting,
        coacheeId,
        actionNumber
      );

      if (result && result.success) {
        this.loadSessionActions(sessionId);
      }
    }
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      responseActions,
      tableActions,
      tableTitles,
      sessionActions,
      currentAction,
      showModalActions,
      isEditingAction,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={subview} />
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="box100-menu">
                    <div className="m--15">
                      <CardGenericTableWithGroup
                        id="coachees-action-register-1"
                        title="Registro de Ações"
                        response_actions={responseActions}
                        actions={tableActions}
                        title_rows={tableTitles}
                        groupBy="meetingTitle"
                        rows={sessionActions.map(action => {
                          const { meeting } = action;
                          let meetingTitle = '';
                          if (meeting === 'master') {
                            meetingTitle = 'Sessão Master';
                          } else if (meeting === 'session') {
                            meetingTitle = 'Ação Adicional';
                          } else {
                            meetingTitle = `Sessão ${meeting}`;
                          }
                          return {
                            ...action,
                            meetingTitle,
                          };
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ModalActions
          action={currentAction}
          toggleModalAction={this.toggleModalAction}
          saveAction={this.saveAction}
          actions={sessionActions}
          modal_actions={showModalActions}
          editing={isEditingAction}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsRegister)
);
