import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../assets/images/logo-facebook.jpg";
import "../../styles/css/style.css";
import "../../styles/css/dashboard.css";
import { updateAlert } from "../../actions/SimpleAction";
import Utils from "./../../libs/Utils.js";
import Service from "./../../services/ApiService.js";
import "react-datepicker/dist/react-datepicker.css";
//import './styeModalCompact.css';
import { FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";

class ModalLGPD extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.service = new Service();
    this.state = {};
  }

  componentDidMount() {}
  componentWillReceiveProps(nextProps) {}
  componentWillUnmount() {}

  render() {
    return (
      <div>
        {this.props.open ? (
          <div className="modal-alert" style={{ zIndex: 1000 }}>
            <div className="modal-alert-box">
              <i
                className="fas fa-times icon-close"
                onClick={() => {
                  this.props.close();
                }}
              ></i>
              <div className="row-modal-alert">
                <img src={logo} width={70} />
                <div>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.props.state.accepted}
                        onChange={async e => {
                          await this.props.setState({
                            accepted: e.target.checked
                          });
                        }}
                      />
                      <span style={{ marginLeft: "30px" }}>
                        Concordo com os{" "}
                        <Link to="../../terms" className="color-ggren">
                          Termos de uso
                        </Link>{" "}
                        e{" "}
                        <Link to="../../policity" className="color-ggren">
                          Política de privacidade
                        </Link>
                      </span>
                    </Label>
                  </FormGroup>

                  <div className="modal-alert-text btnComp">
                    <div
                      className="bt-blue"
                      onClick={() => {
                        this.props.close();
                      }}
                    >
                      Não
                    </div>
                    {this.props.state.accepted ? (
                      <div
                        className="bt-blue"
                        onClick={() => {
                          this.props.concordoTermos();
                        }}
                      >
                        Sim
                      </div>
                    ) : (
                      <div
                        className="bt-blue"
                        style={{ backgroundColor: "#CECECE" }}
                      >
                        Sim
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  alert: state.simpleReducer.alert
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalLGPD);
