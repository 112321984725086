import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

class RichText extends Component {
  constructor(props, context) {
    super(props);

    this.state = { content: 'content' };
  }

  componentDidMount() {}
  componentWillUnmount() {}

  onEditorStateChange(editorState) {
    this.updateContent(editorState);
    if (this.props.onChange) this.props.onChange(editorState);
  }

  onEditorBlur() {
    if (this.props.onBlur) this.props.onBlur(this.state.content);
  }

  updateContent = value => {
    this.setState({ content: value });
  };

  /**
   * @property Jodit jodit instance of native Jodit
   */
  jodit;
  setRef = jodit => (this.jodit = jodit);

  config = {
    enableDragAndDropFileToEditor: false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    minHeight: this.props.minHeight ? this.props.minHeight : 130,
    height: this.props.height ? this.props.height : 130,
    width: this.props.width ? this.props.width : null,
    minWidth: this.props.minWidth ? this.props.minWidth : null,
    style: {
      border: 'none',
    },
    limitChars: this.props.maxLength ? this.props.maxLength : false,
    language: 'pt_br',
    enter: 'P',
    defaultMode: '1',
    toolbarStickyOffset: null,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbarButtonSize: this.props.toolbarButtonSize
      ? this.props.toolbarButtonSize
      : 'small',
    placeholder: this.props.placeholder ? this.props.placeholder : '',
    fullsize: false,
    askBeforePasteHTML: false,
    allowResizeX: false,
    allowResizeY: false,
    toolbar: this.props.showToolbar ? true : false,
    readonly: this.props.readonly ? true : false,
    toolbarAdaptive: false,
    buttons: [
      'bold',
      'italic',
      'underline',
      {
        name: 'dots',
        mode: 3,
        list: {
          strikethrough: 'strikethrough',
          font: 'font',
          fontsize: 'fontsize',
          brush: 'brush',
          align: 'align',
          ul: 'ul',
          ol: 'ol',
        },
      },
    ],
  };

  render() {
    return (
      <JoditEditor
        tabIndex={1}
        editorRef={this.setRef}
        value={this.props.value ? this.props.value : ''}
        config={this.config}
        onChange={this.onEditorStateChange.bind(this)}
        onBlur={this.onEditorBlur.bind(this)}
      />
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RichText);
