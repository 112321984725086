import React from 'react';

import './BannerEngage.css';

const BannerEngage = ({ classContainer, show = false, ...props }) => {
  const showCss = show ? '' : ' hide';
  const classNameContainer = classContainer
    ? classContainer + showCss
    : `bnreng-container${showCss}`;
  return (
    <div className={classNameContainer}>
      <div className="bnreng-container-left">
        <div className="bnreng-container-first">
          <div className="bnreng-elipse-icon">
            <img
              src={require('./../../../assets/images/menu/profile/icone_climby.svg')}
              width="42"
              alt="climby"
            />
          </div>
          <div className="container-text-publish">
            <span className="text-publish">
              PUBLIQUE AGORA SEU PERFIL NO&nbsp;
              <span className="text-publish-engage">ENGAGE</span>
            </span>
          </div>
        </div>
        <div className="bnreng-flex-column">
          <div className="bnreng-empty-space"></div>
          <div className="container-text-opportunities">
            <span className="text-opportunities">
              E fique disponível agora mesmo para novas oportunidades.
            </span>
          </div>
        </div>
      </div>

      <div className="bnreng-container-right">
        <span
          className="text-opportunities other-hour"
          onClick={() => {
            props.onClickIgnore();
          }}
        >
          Talvez outra hora.
        </span>
        <div
          className="bt-save hover-shadow active bnreng-margin"
          onClick={e => {
            props.onClickPublish();
          }}
        >
          SALVAR E PUBLICAR!
        </div>
      </div>
    </div>
  );
};

export default BannerEngage;
