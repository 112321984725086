import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 
                <div className="dashboard-box box70">
                  <div className="box-row-title">
                    <div className="box-title">Tarefas em andamento</div>
                    <div className="box-options">
                      <img src={ require('./../../assets/images/dashboard/i1.svg') } className="box-options-image" />
                      <img src={ require('./../../assets/images/dashboard/i2.svg') } className="box-options-image minor" />
                      <img src={ require('./../../assets/images/dashboard/i3.svg') } className="box-options-image" />
                    </div>
                  </div>
                  <div className="box-row-table">
                    <table className="big-row">
                      <tr>
                        <td><div>Coachee</div></td>
                        <td><div className="tcenter">Data Ação</div></td>
                        <td><div className="tcenter">Data Limite</div></td>
                        <td className="tleft ml40"><div>Responsável</div></td>
                      </tr>

                      {
                        this.props.tasks.map( task => {
                          return <tr>
                                  <td>
                                    <div className="row-image">
                                    <span className="mobile-label">Coachee: </span>
                                    <img src={ task.image  } className="image-coach" />
                                    { task.name }
                                  </div>
                                  </td>
                                  <td className="tcenter"><div> 
                                    <span className="mobile-label">Data Ação: </span>
                                    { task.date }
                                  </div>
                                  </td>
                                  <td className="tcenter"><div> 
                                    <span className="mobile-label">Data Limite: </span>
                                    { task.date_limit }
                                  </div>
                                  </td>
                                  <td className="tleft ml40"><div> 
                                    <span className="mobile-label">Responsável: </span>
                                    { task.responsible } 
                                  </div>
                                  </td>
                                </tr>
                        })
                      } 

                    </table>
                  </div>
                </div>
 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);