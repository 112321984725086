import React from 'react';
import { ContentState, EditorState } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import XLSX from 'xlsx';

class Utils {
  getStorage(key) {
    return localStorage.getItem(`climb-app::${key}`);
  }

  getStorageParsed(key) {
    return JSON.parse(localStorage.getItem(`climb-app::${key}`));
  }

  setStorage(key, value) {
    return localStorage.setItem(`climb-app::${key}`, JSON.stringify(value));
  }

  isLogged() {
    let token = this.getStorage('atoken');
    if (token && token.length > 0 && token != 'undefined' && token != 'null') {
      return true;
    }
    return false;
  }

  getUser() {
    return this.getStorageParsed('logged_user');
  }

  setLogout() {
    localStorage.removeItem('climby::logged_user');
    localStorage.removeItem('climby::atoken');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  parseDate(tmstmp) {
    // alert(tmstmp)
    let d = new Date();
    d.setTime(tmstmp);
    let _y = d.getFullYear();
    let _d = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    let _m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

    return `${_d}/${_m}/${_y}`;
  }

  parseDatetime(tmstmp) {
    // alert(tmstmp)
    let d = new Date();
    d.setTime(tmstmp);
    let _y = d.getFullYear();
    let _d = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    let _m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

    let _h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    let _i = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    let _s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();

    return `${_d}/${_m}/${_y} ${_h}:${_i}`;
  }

  parseDateUSA(tmstmp) {
    // alert(tmstmp)
    let d = new Date(tmstmp);
    let _y = d.getFullYear();
    let _d = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    let _m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

    let _h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    let _i = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    let _s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();

    return `${_d}/${_m}/${_y}`;
  }

  parseDatetimeUSA(tmstmp) {
    // alert(tmstmp)
    let d = new Date(tmstmp);
    let _y = d.getFullYear();
    let _d = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    let _m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

    let _h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    let _i = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    let _s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();

    return `${_d}/${_m}/${_y} ${_h}:${_i}:${_s}`;
  }

  parseDateUsaBD(date) {
    let _y = date.getFullYear();
    let _m = date.getMonth() + 1;
    let _d = date.getDate();
    return `${_y}-${_m < 10 ? `0${_m}` : _m}-${_d < 10 ? `0${_d}` : _d}`;
  }

  parseDateBR() {
    let date = new Date();
    let _y = date.getFullYear();
    let _m = date.getMonth() + 1;
    let _d = date.getDate();
    return `${_d < 10 ? `0${_d}` : _d}/${_m < 10 ? `0${_m}` : _m}/${_y}`;
  }

  getStorage(_k) {
    let key = `climby::${_k}`;
    try {
      return localStorage.getItem(key) !== null &&
        localStorage.getItem(key) !== 'null' &&
        localStorage.getItem(key) !== ''
        ? localStorage.getItem(key)
        : false;
    } catch (e) {
      return sessionStorage.getItem(key) !== null &&
        sessionStorage.getItem(key) !== 'null' &&
        sessionStorage.getItem(key) !== ''
        ? sessionStorage.getItem(key)
        : false;
    }
  }

  setStorage(_k, t) {
    let key = `climby::${_k}`;
    try {
      return localStorage.setItem(key, t);
    } catch (e) {
      return sessionStorage.setItem(key, t);
    }
  }

  clearValue(value) {
    let val = value ? value.replace('R$ ', '') : '';
    val = val.replace(/\./g, '');
    val = val.replace(',', '.');
    return val;
  }

  dirtyValue(value) {
    let _v = 0;
    if (value) {
      _v = value;
      let parts = value
        .toFixed(2)
        .toString()
        .split('.');
      parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
      value = parts.join(',');
    }
    return value && _v > 0 ? `R$ ${value}` : 'R$ 0,00';
  }

  firstUpperCase = texto => {
    let text = texto.toLowerCase();
    return text
      .substring(0, 1)
      .toUpperCase()
      .concat(text.substring(1));
  };

  parseDateSave(date) {
    let _y = date.getFullYear();
    let _m = date.getMonth() + 1;
    let _d = date.getDate();

    let _h = date.getHours();
    let _i = date.getMinutes();
    let _s = date.getSeconds();

    _m = _m < 10 ? `0${_m}` : _m;
    _d = _d < 10 ? `0${_d}` : _d;

    return `${_y}-${_m}-${_d} ${_h}:${_i}:${_s}`;
  }

  resolveSessionDate(date) {
    if (date == '') {
      return new Date();
    } else {
      if (typeof date === 'string' && date.indexOf('/') !== -1) {
        let _dt = date.split('/');
        return new Date(`${_dt[2]}-${_dt[1]}-${_dt[0]}T15:00:00.000Z`);
      } else {
        return new Date(date);
      }
    }
  }

  convertToHTML(rawContentState) {
    // const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    return markup;
  }

  convertFromHtml(_text) {
    const blocksFromHtml = htmlToDraft(_text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);

    return editorState;

    // return stateFromHTML(_text)
    // const decorator = new CompositeDecorator([
    //          {
    //            strategy: findLinkEntities,
    //            component: Link,
    //          },
    //          {
    //            strategy: findImageEntities,
    //            component: Image,
    //          },
    //        ]);

    // console.log(_text)

    // if(_text){
    //        const blocksFromHTML = convertFromHTML(_text);
    //        let state = false
    //        if( blocksFromHTML && blocksFromHTML != null && blocksFromHTML != 'null'){
    //        	try{
    //         state = ContentState.createFromBlockArray(
    //           blocksFromHTML.contentBlocks,
    //           blocksFromHTML.entityMap,
    //         );
    //        	}catch(e){ state = false ;}
    //        }

    //        const editorState = state ? EditorState.createWithContent(
    //            state,
    //            decorator,
    //          ) : EditorState.createWithContent("")
    //        return editorState;
    //    }else{
    //    	return EditorState.createWithContent("")
    //    }
  }

  validateEmail(email) {
    let er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}/;
    if (!er.exec(email) || !email) {
      return false;
    }
    return true;
  }

  validateCPF = value => {
    const strCPF = value.replace(/\D/g, ''); // Only numbers
    if (strCPF.length > 11) {
      return false;
    }
    let Soma = 0;
    let Resto;
    if (strCPF === '00000000000') return false;
    if (strCPF === '11111111111') return false;
    if (strCPF === '22222222222') return false;
    if (strCPF === '33333333333') return false;
    if (strCPF === '44444444444') return false;
    if (strCPF === '55555555555') return false;
    if (strCPF === '66666666666') return false;
    if (strCPF === '77777777777') return false;
    if (strCPF === '88888888888') return false;
    if (strCPF === '99999999999') return false;
    if (strCPF === '12345678909') return false;

    for (let i = 1; i <= 9; i += 1)
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i += 1)
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    return !(Resto !== parseInt(strCPF.substring(10, 11), 10));
  };

  excelToJson(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = ev => {
        //Load the XLS/XLSX data
        const workBook = XLSX.read(ev.target.result, {
          type: 'binary',
          cellDates: true,
        });

        //Get the first sheet and read it as JSON
        const name = workBook.SheetNames[0];
        const result = XLSX.utils.sheet_to_json(workBook.Sheets[name], {
          dateNF: 'dd/mm/yyyy',
        });

        //The final format would be something like [{"header1": "value1", ...}, {"header1": "value1", ...}]
        resolve(result);
      };
      fileReader.onerror = reject;

      fileReader.readAsBinaryString(file);
    });
  }

  jsonToExcel(type, object, sheetname) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(object);
    XLSX.utils.book_append_sheet(wb, ws, sheetname);
    const wbout = XLSX.write(wb, { bookType: type, type: 'array' });

    return new Blob([wbout], { type: 'application/octet-stream' });
  }

  resolveURL(path, basePath) {
    return new URL(path, basePath).href;
  }

  resolveCompleteName(name, surname) {
    return `${name} ${surname ? surname : ''}`;
  }

  strip(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  removeAllWhiteSpace(text) {
    return text.replaceAll(/\s/g, '');
  }

  getLengthStringOfHtml(html) {
    const text = this.strip(html);
    return this.removeAllWhiteSpace(text)
      ? this.removeAllWhiteSpace(text).length
      : 0;
  }

  fileNameFromUrl(url) {
    const filename = url
      .split('#')
      .shift()
      .split('?')
      .shift()
      .split('/')
      .pop();
    if (filename && !filename.includes('.')) return '';
    return filename;
  }

  getExtensionFile(filename) {
    return filename.split('.').pop();
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'IMAGE'
    );
  }, callback);
}

const Image = props => {
  const { height, src, width } = props.contentState
    .getEntity(props.entityKey)
    .getData();

  return (
    <figure>
      <img src={src} height={height} width={width} />
    </figure>
  );
};

const styles = {
  root: {
    fontFamily: "'Helvetica', sans-serif",
    padding: 20,
    width: 600,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
};

export function removeTrailingSlashes(url) {
  return url.replace(/\/+$/, ''); //Removes one or more trailing slashes from URL
}

export function getSitePath() {
  return removeTrailingSlashes(
    new URL(process.env.REACT_APP_URL_SITE).pathname
  );
}

export default Utils;
