import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../../sidebar/Sidebar';
import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';

class Form extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'notification',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Notificações', link: 'notifications' },
        { title: 'Cadastrar Notificação', link: 'notifications/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [
        { label: 'Mensagem', key: 'text', type: 'textarea' },
        {
          label: 'Enviar para Coachees',
          offlabel: 'Não enviar para Coachees',
          key: 'all_coachees',
          type: 'checkbox',
        },
        {
          label: 'Enviar para Coaches',
          offlabel: 'Não enviar para Coaches',
          key: 'all_coachs',
          type: 'checkbox',
        },
        { label: 'Rótulo da Ação', key: 'action_label', type: 'text' },
        {
          label: 'Ação',
          key: 'action',
          type: 'select',
          options: [
            { name: 'Link externo', _id: 'external_link' },
            { name: 'Link interno', _id: 'internal_link' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'name',
        },
        {
          label: 'Conteádo da Ação',
          key: 'content',
          type: 'text',
          hide: { key: 'action', value: 'internal_link' },
        },
        {
          label: 'Conteádo da Ação',
          key: 'content',
          type: 'select',
          options: [
            { name: 'Mensagens', _id: 'messages' },
            { name: 'Agenda', _id: 'calendar' },
            { name: 'Propostas', _id: 'proposals' },
            { name: 'Perfil', _id: 'profile' },
            { name: 'Termos', _id: 'terms' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'name',
          show: { key: 'action', value: 'internal_link' },
        },
        { label: 'Número Ação', key: 'action_number', type: 'number' },
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Coach',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.removePassword();
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      this.setState({ content: result[this.state.view] });
    }
    this.setState({ loading_contents: false });
  }

  async save() {
    this.setState({ loading: true });
    let _pay = this.state.content;
    let result = this.state.editing
      ? await this.service.update(_pay, this.state.editing_id)
      : await this.service.create(_pay);

    if (result && result.success) {
      this.setSidebarLinks(this.state.breadcrumbs[1].link);
    }
    this.setState({ loading: false });
  }

  removePassword() {
    let items = this.state.items;
    for (let _k in items) {
      if (items[_k].type == 'password') {
        items.splice(_k, 1);
      }
    }
    this.setState({ items });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                    />

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
