import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';
 
import { Button } from 'reactstrap';

import Header from './../../includes/Header'
import ApiService from '../../services/ApiService';

import { Link } from 'react-router-dom'

class Terms extends Component {

  state = {
    content:""
  }

  service = new ApiService();

  componentDidMount(){
    this.loadContent()
  }
  
  async loadContent(){
    let result = await this.service.config('termos');
    if( result && result.success ){
        this.setState({ content: result.config.value })
    }
  }

  render() {
    return ( 
      <div>
      	  <Header />
  	      <div className="container-climb">
            <p className="title-bold">Termos de uso</p>  

            { this.state.content.split('\n').map((item, key) => {
              return <p key={key} className="text-blue">{ item }</p>
            })  } 

            {/*Enviamos um email pra você, verifique sua caida de entrada 
              <b> - clique no link e crie sua nova senha</b>
            </p> */}
  	      </div>
      </div>
    );
  }
}

export default connect()(Terms);