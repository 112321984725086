import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

import './styles.css';
import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';

import { Col } from 'reactstrap';

import { updateImage, updateAlert } from '../../../../actions/SimpleAction';

import Sidebar from './../../../sidebar/Sidebar';

import ReactLoading from 'react-loading';

import Wheel from '../../../../components/Wheel';
import Header from './../../../components/Header';
import Breadcrumbs from './../../../components/Breadcrumbs';

import Footer from './../../../components/Footer';

import Service from './../../Service.js';
import Utils from './../../../../libs/Utils.js';

const DragHandle = sortableHandle(({ children, className }) => (
  <div className={className}>{children}</div>
));

const WheelArea = sortableElement(({ sortIndex, areaLabel, deleteArea }) => (
  <div className="row-options-item wheel-area">
    <DragHandle className="certificate-title flex1 wheel-drag">
      {areaLabel}
    </DragHandle>
    <div className="bt-blueicon" onClick={e => deleteArea(sortIndex)}>
      <i className="fas fa-times"></i>
    </div>
  </div>
));

const AREAS_MAX_LENGTH = 15;
const WheelAreas = sortableContainer(({ areas, deleteArea, addArea }) => {
  const [newAreaLabel, setNewAreaLabel] = useState('');

  return (
    <div className="wheel-areas-container">
      <label className="form-title">Características</label>
      <div className="wheel-areas-container">
        {areas.map((area, index) => (
          <WheelArea
            key={`wheel-area-${index}`}
            index={index}
            sortIndex={index}
            areaLabel={area.label}
            deleteArea={deleteArea}
          />
        ))}
      </div>
      {areas.length < AREAS_MAX_LENGTH && (
        <div className="form-input" style={{ marginTop: 20 }}>
          <input
            type="array-text"
            value={newAreaLabel}
            className="blue-input"
            name="areas"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                addArea(newAreaLabel);
                setNewAreaLabel('');
              }
            }}
            style={{ paddingRight: '60px' }}
            onChange={e => setNewAreaLabel(e.target.value)}
            placeholder="Adicionar característica"
          />
          <div
            className="bt-blue button-add"
            onClick={() => {
              addArea(newAreaLabel);
              setNewAreaLabel('');
            }}
          >
            <i className="fas fa-check" style={{ margin: 0 }}></i>
          </div>
        </div>
      )}
      <span>
        {areas.length}/{AREAS_MAX_LENGTH}
      </span>
    </div>
  );
});

class WheelEditor extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'contents',
      side: 'contents',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Editor de Rodas', link: 'wheel-editor' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      title: '',
      description: '',
      areas: [],
      maximumScore: 10,

      maxLength: 3660,
      isEditing: false,
      contentId: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      const contentId = params.id;
      this.setState({
        isEditing: true,
        contentId,
      });
      this.load(contentId);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.content(_id);

    if (result && result.success) {
      if (result.content) {
        this.setState({
          title: result.content.title,
          description: result.content.data.description,
          areas: result.content.data.areas,
          maximumScore: result.content.data.maximumScore,
        });
      }
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    this.setState({ loading: true });
    const { title, description, areas, maximumScore } = this.state;
    let _pay = {
      title,
      doc_source: '',
      doc_format: 'wheel',
      tag: 'Rodas',
      category: 'Rodas',
      data: JSON.stringify({
        description,
        areas,
        maximumScore,
      }),
    };

    let result = this.state.isEditing
      ? await this.service.updateContent(_pay, this.state.contentId)
      : await this.service.saveContent(_pay);
    if (result && result.success) {
      this.setSidebarLinks(`contents`);
    }
    this.setState({ loading: false });
  }

  addArea = newAreaLabel => {
    const { areas, maximumScore } = this.state;
    this.setState({
      areas: [...areas, { label: newAreaLabel, value: maximumScore }],
      newAreaLabel: '',
    });
  };

  deleteArea = index => {
    const { areas } = this.state;
    const newAreas = [...areas];
    newAreas.splice(index, 1);
    this.setState({ areas: newAreas });
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { areas } = this.state;
    const newAreas = [...areas];
    const [area] = newAreas.splice(oldIndex, 1);
    newAreas.splice(newIndex, 0, area);
    this.setState({ areas: newAreas });
  };

  updateAreasValues = () => {
    const { maximumScore, areas } = this.state;
    const newAreas = areas.map(area => ({
      label: area.label,
      value: maximumScore,
    }));
    this.setState({ areas: newAreas });
  };

  render() {
    const {
      sideOpen,
      side,
      breadcrumbs,
      title,
      description,
      areas,
      maximumScore,
      maxLength,
      loading,
      isEditing,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-box box100">
                <div className="box-row-title">
                  <div className="box-title">{`${
                    isEditing ? 'Editar' : 'Criar'
                  } Roda`}</div>
                </div>
                <div
                  className="mobile-column"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="content-form"
                    style={{ width: 'calc(40% - 27px)' }}
                  >
                    <div className="form-input">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>
                    <div
                      className="form-textarea clear-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Descrição</label>
                      <textarea
                        value={description}
                        className="blue-input"
                        placeholder="Descrição"
                        maxLength={maxLength}
                        onChange={e => {
                          this.setState({ description: e.target.value });
                        }}
                      ></textarea>
                      <span>
                        {description.length}/{maxLength}
                      </span>
                    </div>
                    <WheelAreas
                      onSortEnd={this.handleSortEnd}
                      areas={areas}
                      deleteArea={this.deleteArea}
                      addArea={this.addArea}
                      useDragHandle
                    />
                    <div className="form-input">
                      <label className="blue-label">Nota Máxima</label>

                      <input
                        type="number"
                        min="1"
                        value={maximumScore}
                        className="blue-input"
                        onChange={e =>
                          this.setState(
                            {
                              maximumScore:
                                parseInt(e.target.value, 10) < 1
                                  ? 1
                                  : parseInt(e.target.value, 10),
                            },
                            () => this.updateAreasValues()
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="content-form"
                    style={{ width: 'calc(60% - 27px)' }}
                  >
                    <Wheel areas={areas} maxScore={maximumScore} />
                  </div>
                </div>
                <div
                  className="content-form"
                  style={{ width: 'calc(40% - 27px)' }}
                >
                  <div
                    className="row-between mtop-20 mbottom-50"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div
                      className="bt-blue-outline"
                      onClick={() => {
                        this.setSidebarLinks(`contents`);
                      }}
                    >
                      Cancelar
                    </div>
                    {loading ? (
                      <ReactLoading type="bubbles" color="#00838F" />
                    ) : (
                      <div
                        className="bt-blue"
                        onClick={() => {
                          this.save();
                        }}
                      >
                        {isEditing ? 'Salvar' : 'Cadastrar'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WheelEditor)
);
