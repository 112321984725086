import React, { Component } from 'react';
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

class ModalAlertCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {this.props.alert !== '' ? (
          <div className="modal-alert">
            <div className="modal-alert-box">
              <i
                className={
                  this.props.showCloseIcon
                    ? 'fas fa-times icon-close'
                    : 'hidden-icon-close'
                }
                onClick={() => {
                  this.props.close();
                }}
              ></i>
              <div className="row-modal-alert">
                <img src={logo} width={100} alt="climby" />
                {typeof this.props.alert === 'string' &&
                this.props.alert.indexOf('</') !== -1 ? (
                  <div
                    className="modal-alert-text"
                    dangerouslySetInnerHTML={{ __html: this.props.alert }}
                  ></div>
                ) : (
                  <div className="modal-alert-text">{this.props.alert}</div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ModalAlertCustom;
