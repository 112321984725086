import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

// import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../../sidebar/Sidebar';
import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import Utils, { getSitePath } from './../../../libs/Utils.js';

class List extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'coachs',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Coach', link: 'coachs' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      loading_contents: false,
      me: {},
      coach: {},
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  see(item) {
    this.setSidebarLinks(`${this.state.view}/form/${item._id}`);
  }

  seeCoachees(item) {
    this.setSidebarLinks(`coachees/coach/${item._id}`);
  }

  seeContents(item) {
    this.setSidebarLinks(`contents/coach/${item._id}`);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.resolveParams();
    this.loadProfessional();
    this.loadLife();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  geraURL(certificate) {
    if (certificate) {
      return new URL(certificate, this.service.getServer()).href;
    }
    return '';
  }

  async load(coach_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readCoachInfos({ coach_id });
    if (result && result.success) {
      let certificate = {};
      let coach = {};
      if (
        result.user &&
        result.user.professional_description &&
        result.user.professional_description.certificate
      ) {
        certificate = result.user.professional_description.certificate.map(
          cert => ({
            ...cert,
            urlcertificate: this.geraURL(cert.certificate),
          })
        );
      }

      coach = result.user;
      if (
        coach.professional_description &&
        coach.professional_description.certificate
      ) {
        coach.professional_description.certificate = certificate;
      }

      let me = result.me;
      if (me.professional_present) {
        me.professional_presenturl = this.geraURL(
          JSON.parse(me.professional_present).doc_source
        );
      }

      let marketing = {};
      if (result.marketing) {
        marketing = result.marketing;
        marketing.website_name_url = result.marketing.website_name
          ? `${getSitePath()}/climby/${result.marketing.website_name}`
          : '';
      }

      this.setState({
        me,
        coach,
        marketing,
      });
    }
    this.setState({ loading_contents: false });
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.load(params.id);
    }
  }

  async loadProfessional() {
    this.setState({ loading_professional: true });
    let result = await this.service.options_professional();
    if (result) {
      this.setState({
        loading_professional: false,
        professional: result.options,
      });
    }
  }

  async loadLife() {
    this.setState({ loading_professional: true });
    let result = await this.service.options_life();
    if (result) {
      this.setState({ loading_professional: false, life: result.options });
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <h3>{this.state.me.name}</h3>
                  <h4>{this.state.me.email}</h4>
                  <h4>
                    {this.state.me.contact && this.state.me.contact[0]
                      ? this.state.me.contact[0].phone
                      : ''}
                  </h4>
                  <h5>
                    {this.state.me.location && this.state.me.location[0]
                      ? `${this.state.me.location[0].city}/${this.state.me.location[0].state}`
                      : ''}
                  </h5>
                  <h3>
                    {this.state.coach.professional_value &&
                    this.state.coach.professional_value.price
                      ? this.state.coach.professional_value.price
                      : ''}
                  </h3>
                  <br />

                  <h5>Areas de Atuacao</h5>
                  <ul>
                    {this.state.professional &&
                    this.state.coach.professional_area &&
                    this.state.coach.professional_area.professional
                      ? this.state.professional
                          .filter(item =>
                            this.state.coach.professional_area.professional.includes(
                              item.id
                            )
                          )
                          .map((item, key) => {
                            return <li>{item.option}</li>;
                          })
                      : null}
                    {this.state.life &&
                    this.state.coach.professional_area &&
                    this.state.coach.professional_area.life
                      ? this.state.life
                          .filter(item =>
                            this.state.coach.professional_area.life.includes(
                              item.id
                            )
                          )
                          .map((item, key) => {
                            return <li>{item.option}</li>;
                          })
                      : null}
                  </ul>
                  <br />

                  <h5>Tipos de Atendimento</h5>
                  <ul>
                    {this.state.coach.professional_area &&
                    this.state.coach.professional_area.presential ? (
                      <li>Presencial</li>
                    ) : (
                      ''
                    )}
                    {this.state.coach.professional_area &&
                    this.state.coach.professional_area.online ? (
                      <li>Online</li>
                    ) : (
                      ''
                    )}
                  </ul>
                  <br />

                  <h5>Descricao Profissional</h5>
                  <p>
                    {this.state.coach.professional_description &&
                    this.state.coach.professional_description.professional
                      ? this.state.coach.professional_description.professional
                      : ''}
                  </p>
                  <br />

                  <h5>Descricao de Experiencia</h5>
                  <p>
                    {this.state.coach.professional_description &&
                    this.state.coach.professional_description.experience
                      ? this.state.coach.professional_description.experience
                      : ''}
                  </p>
                  <br />

                  <h5>Metodologia</h5>
                  <p>
                    {this.state.coach.professional_description &&
                    this.state.coach.professional_description.methodology
                      ? this.state.coach.professional_description.methodology
                      : ''}
                  </p>
                  <br />

                  <h5>Certificados</h5>
                  <ul>
                    {this.state.coach.professional_description &&
                    this.state.coach.professional_description.certificate
                      ? this.state.coach.professional_description.certificate.map(
                        (item, key) => {
                          return (
                            <li key={key}>
                              {item.graduate}, {item.institute}/{item.year} <a href={item.urlcertificate ? item.urlcertificate : ''} target="new">{item.urlcertificate ? '- Certificado' : ''}</a>
                            </li>
                          );
                        }
                      )
                      : null}
                  </ul>
                  <h5>Badges</h5>
                  <ul>

                    {this.state.coach.professional_description &&
                      this.state.coach.professional_description.badgesICF
                      ? this.state.coach.professional_description.badgesICF.map(
                        (item, key) => {
                          return (
                            <li key={key}>
                              <img
                                src={item.badgesSrc}
                                height={"30px"}
                                width={"30px"}
                              />
                              <a href={item.IcfPublicURL ? item.IcfPublicURL : ''} target="new">{item.IcfPublicURL ? ' ICF URL Publica' : ''}</a>
                            </li>
                          );
                        }
                      )
                      : null}
                  </ul>
                  <br />

                  <h5>URL Video de Apresentacao</h5>
                  <p>
                    <a href={this.state.me.professional_video} target="new">
                      {this.state.me.professional_video}
                    </a>
                  </p>
                  <br />

                  <h5>Arquivo PDF de apresentacao</h5>
                  <p>
                    <a
                      href={
                        this.state.me.professional_presenturl
                          ? `${this.state.me.professional_presenturl}`
                          : ''
                      }
                      target="new"
                    >
                      {this.state.me.professional_presenturl
                        ? `${this.state.me.professional_presenturl}`
                        : ''}
                    </a>
                  </p>
                  <br />

                  <h5>URL endereco do website climby</h5>
                  <a
                    href={
                      this.state.marketing &&
                      this.state.marketing.website_name_url
                        ? `${this.state.marketing.website_name_url}`
                        : ''
                    }
                    target="new"
                  >
                    {this.state.marketing &&
                    this.state.marketing.website_name_url
                      ? `${this.state.marketing.website_name_url}`
                      : ''}
                  </a>
                  <br />
                  <br />

                  <h5>Redes Sociais do Coach</h5>
                  <p>
                    {this.state.marketing && this.state.marketing.facebook ? (
                      <i
                        className="fab fa-facebook-square iconbig"
                        onClick={() => {
                          window.open(this.state.marketing.facebook, 'social');
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                    {this.state.marketing && this.state.marketing.instagram ? (
                      <i
                        className="fab fa-instagram iconbig"
                        onClick={() => {
                          window.open(this.state.marketing.instagram, 'social');
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                    {this.state.marketing &&
                    this.state.marketing.google_plus ? (
                      <i
                        className="fab fa-google-plus iconbig"
                        onClick={() => {
                          window.open(
                            this.state.marketing.google_plus,
                            'social'
                          );
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                    {this.state.marketing && this.state.marketing.twitter ? (
                      <i
                        className="fab fa-twitter iconbig"
                        onClick={() => {
                          window.open(this.state.marketing.twitter, 'social');
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                    {this.state.marketing && this.state.marketing.linkedin ? (
                      <i
                        className="fab fa-linkedin iconbig"
                        onClick={() => {
                          window.open(this.state.marketing.linkedin, 'social');
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                    {this.state.marketing && this.state.marketing.youtube ? (
                      <i
                        className="fab fa-youtube iconbig"
                        onClick={() => {
                          window.open(this.state.marketing.youtube, 'social');
                        }}
                      ></i>
                    ) : (
                      ''
                    )}
                  </p>
                  <br />

                  <h5>
                    Plano Buscador:{' '}
                    {this.state.me.plans &&
                    this.state.me.plans.seacher &&
                    this.state.me.plans.seacher.plan
                      ? this.state.me.plans.seacher.plan.title
                      : ''}{' '}
                    (
                    {this.state.me.plans &&
                    this.state.me.plans.seacher &&
                    this.state.me.plans.seacher.plan
                      ? this.state.me.plans.seacher.plan.periodice == '1m'
                        ? 'Mensal'
                        : 'Anual'
                      : ''}
                    )
                  </h5>
                  <h5>
                    Plano Plataforma:{' '}
                    {this.state.me.plans &&
                    this.state.me.plans.platform &&
                    this.state.me.plans.platform.plan
                      ? this.state.me.plans.platform.plan.title
                      : ''}{' '}
                    (
                    {this.state.me.plans &&
                    this.state.me.plans.platform &&
                    this.state.me.plans.platform.plan
                      ? this.state.me.plans.platform.plan.periodice == '1m'
                        ? 'Mensal'
                        : 'Anual'
                      : ''}
                    )
                  </h5>
                  <br />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));
