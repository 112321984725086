import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

// import Avatar from '../avatar/Avatar';
// import Logo from '../logo/Logo';

class SidebarSessions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li onClick={ () => { this.props.setSidebarLinks(''); } } >
          <img src={ require('./../../assets/images/menu/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Dashboard</span>
        </li> 
        <li 
            onClick={ () => { this.props.setSidebarLinks('programs'); } } 
            className={ this.props.side == 'painel' ? "active" : "" } >
          <img src={ require('./../../assets/images/programs/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/programs/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Sessão coaching</span>
        </li> 
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSessions);