import React, { useState, useMemo, useEffect } from 'react';
import ReactLoading from 'react-loading';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import cartIcon from '../../assets/images/carrinho.png';
import creditCardIcon from './../../assets/images/credit-card.svg';
import Utils from './../../libs/Utils';

const utils = new Utils();

const CouponInfo = ({ coupon, initialPrice, fullPrice }) => {
  const couponInitialInfoCase = useMemo(
    () => ({
      '1m':
        coupon.cycles === 1
          ? `Valor no primeiro mês`
          : `Valor nos primeiros ${coupon.cycles} meses`,
      '12m': `Valor no primeiro ano`,
    }),
    [coupon]
  );

  const couponAfterInfoCase = useMemo(
    () => ({
      '1m': `Valor a partir do ${coupon.cycles + 1}º mês`,
      '12m': `Valor a partir do segundo ano`,
    }),
    [coupon]
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderBottom: '1px solid black',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ fontFamily: 'latoregular', fontSize: 12 }}>
            {couponInitialInfoCase[coupon.plan_periodice]}
          </div>
          <div style={{ fontFamily: 'latoregular', fontSize: 12 }}>
            {utils.dirtyValue(initialPrice)}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ fontFamily: 'latoregular', fontSize: 12 }}>
            {couponAfterInfoCase[coupon.plan_periodice]}
          </div>
          <div style={{ fontFamily: 'latoregular', fontSize: 12 }}>
            {utils.dirtyValue(fullPrice)}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalEventPedido = ({
  plans,
  open,
  combos,
  finishPurchase,
  cancelPurchase,
  registerNewCreditCard,
  creditCard,
  haveCombo,
  loadingModal,
  applyCoupon,
  removeCoupon,
  coupon,
  couponError,
  hasSubscription,
}) => {
  const [couponCode, setCouponCode] = useState(coupon ? coupon.code : '');

  const selectedPlans = plans.filter(item => item.selected);
  const signedPlans = plans.filter(item => item.signedPlan);
  let textSignedPlans = '';
  let textSelectedPlans = '';
  let selectedPlansTotal = 0;
  let comboTotal = 0;

  if (signedPlans.length) {
    textSignedPlans =
      signedPlans.length > 1
        ? `${signedPlans[0].title} + ${signedPlans[1].title} / ${getPeriodType(
            signedPlans[0].periodice
          )}`
        : `${signedPlans[0].title} / ${getPeriodType(
            signedPlans[0].periodice
          )}`;

    const signedPlatformPlan = plans.find(
      item => item.signedPlan && item.type === 'platform'
    );
    const signedSearchPlan = plans.find(
      item => item.signedPlan && item.type === 'seacher'
    );

    combos.forEach(combo => {
      if (
        signedPlatformPlan &&
        signedSearchPlan &&
        signedPlatformPlan._id === combo.platformPlanId &&
        signedSearchPlan._id === combo.searcherPlanId
      ) {
        textSignedPlans = `${combo.title} / ${getPeriodType(
          signedPlatformPlan.periodice
        )}`;
      }
    });
  }

  if (haveCombo) {
    textSelectedPlans = `${haveCombo.title} / ${getPeriodType(
      haveCombo.periodice
    )}`;
    comboTotal =
      parseFloat(utils.clearValue(haveCombo.searcherPrice)) +
      parseFloat(utils.clearValue(haveCombo.platformPrice));
  } else {
    const selectedPlatformPlan = plans.find(
      item => item.selected && item.type === 'platform'
    );
    const selectedSearchPlan = plans.find(
      item => item.selected && item.type === 'seacher'
    );
    if (selectedSearchPlan && selectedPlatformPlan) {
      textSelectedPlans = `${selectedSearchPlan.title} + ${
        selectedPlatformPlan.title
      } / ${getPeriodType(selectedSearchPlan.periodice)}`;
    }
    if (selectedSearchPlan) {
      textSelectedPlans = `${selectedSearchPlan.title} / ${getPeriodType(
        selectedSearchPlan.periodice
      )}`;
    }
  }
  selectedPlans.forEach(item => {
    selectedPlansTotal += parseFloat(utils.clearValue(item.value));
  });

  function getPeriodType(value) {
    if (!value) {
      return '';
    }
    if (value === '1m' || value === 'mês') {
      return 'mensal';
    }
    return 'anual';
  }

  useEffect(() => {
    if (!coupon || couponError) setCouponCode('');
  }, [coupon, couponError]);

  return (
    <div>
      {open ? (
        <div className="modal-alert" style={{ zIndex: 1000 }}>
          <div
            className="modal-alert-box disable-scrollbars"
            style={{
              padding: '0px 10px',
              maxHeight: '100vh',
              overflowY: 'auto',
            }}
          >
            <div className="row-modal-alert">
              <div
                className="modal-alert-text"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid black',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'latoregular',
                      fontSize: 14,
                    }}
                  >
                    Seu pedido
                  </div>
                  <img src={cartIcon} width={20} height={20} />
                </div>

                <div>
                  <div style={{ fontSize: 14, fontFamily: 'latoregular' }}>
                    Você solicitou a troca da sua assinatura
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      fontFamily: 'latoregular',
                    }}
                  >
                    {textSignedPlans}
                  </div>
                  <div style={{ fontSize: 14, fontFamily: 'latoregular' }}>
                    para
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      fontFamily: 'latoregular',
                      borderBottom: '1px solid black',
                      marginBottom: 10,
                      paddingBottom: 5,
                    }}
                  >
                    {textSelectedPlans}
                  </div>
                </div>

                {selectedPlans.map(item => (
                  <div key={item.title}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid black',
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontFamily: 'latoregular',
                            fontSize: 14,
                          }}
                        >
                          {item.type === 'seacher'
                            ? 'Plano Buscador'
                            : 'Plano Plataforma'}
                        </div>
                        <div
                          style={{
                            fontFamily: 'latoregular',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Valor(R$)
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{ fontFamily: 'latoregular', fontSize: 14 }}
                        >
                          {utils.firstUpperCase(item.title)}
                        </div>
                        <div
                          style={{ fontFamily: 'latoregular', fontSize: 14 }}
                        >
                          {haveCombo ? `de ${item.value}` : item.value}/
                          {item.periodice === '1m' ? 'mês' : 'ano'}
                        </div>
                      </div>
                      {haveCombo && item.type === 'seacher' && (
                        <div
                          style={{
                            fontFamily: 'latoregular',
                            fontSize: 14,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {`por ${haveCombo.searcherPrice}/${haveCombo.periodice}`}
                        </div>
                      )}
                      {haveCombo && item.type === 'platform' && (
                        <div
                          style={{
                            fontFamily: 'latoregular',
                            fontSize: 14,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {`por ${haveCombo.platformPrice}/${haveCombo.periodice}`}
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid black',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'latoregular',
                        fontSize: 14,
                      }}
                    >
                      Subtotal
                    </div>
                    <div style={{ fontFamily: 'latoregular', fontSize: 14 }}>
                      {haveCombo
                        ? `de ${utils.dirtyValue(selectedPlansTotal)}`
                        : utils.dirtyValue(selectedPlansTotal)}
                    </div>
                  </div>
                  {haveCombo && (
                    <div
                      style={{
                        fontFamily: 'latoregular',
                        fontSize: 14,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      por {utils.dirtyValue(comboTotal)}
                    </div>
                  )}
                </div>
                {coupon && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid black',
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'latoregular',
                          fontSize: 14,
                        }}
                      >
                        Desconto {coupon.code}
                      </div>
                      <div
                        style={{
                          fontFamily: 'latoregular',
                          fontSize: 14,
                          color: '#92d050',
                        }}
                      >
                        - {utils.dirtyValue(coupon.discount)}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid black',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'latoregular',
                        fontSize: 14,
                      }}
                    >
                      Total
                    </div>
                    <div style={{ fontFamily: 'latoregular', fontSize: 14 }}>
                      {haveCombo
                        ? `${utils.dirtyValue(
                            comboTotal - ((coupon && coupon.discount) || 0)
                          )}`
                        : utils.dirtyValue(
                            selectedPlansTotal -
                              ((coupon && coupon.discount) || 0)
                          )}
                    </div>
                  </div>
                </div>

                {coupon && (
                  <CouponInfo
                    coupon={coupon}
                    initialPrice={
                      haveCombo
                        ? comboTotal - ((coupon && coupon.discount) || 0)
                        : selectedPlansTotal -
                          ((coupon && coupon.discount) || 0)
                    }
                    fullPrice={haveCombo ? comboTotal : selectedPlansTotal}
                  />
                )}

                {!hasSubscription && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        fontWeight: 'bold',
                        fontFamily: 'latoregular',
                        fontSize: 14,
                        marginBottom: 5,
                      }}
                    >
                      Cupom de desconto:
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <input
                        placeholder="Digite seu cupom de desconto"
                        type="text"
                        value={couponCode}
                        style={{
                          width: '100%',
                          border: '1px solid black',
                          borderRadius: 5,
                          padding: '4px 8px',
                          marginRight: 5,
                          marginBottom: 10,
                          fontFamily: 'latoregular',
                          outline: 'none',
                        }}
                        readOnly={!!coupon}
                        onChange={e =>
                          setCouponCode(e.target.value.toUpperCase())
                        }
                      />

                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          border: '1px solid #92d050',
                          color: '#92d050',
                          fontWeight: 'bold',
                          fontFamily: 'latoregular',
                          fontSize: 14,
                          padding: 5,
                          marginBottom: 10,
                          borderRadius: 5,
                        }}
                        title={coupon ? 'Remover Cupom' : 'Aplicar Cupom'}
                        alt={coupon ? 'Remover Cupom' : 'Aplicar Cupom'}
                        onClick={() => {
                          if (coupon) {
                            removeCoupon();
                            setCouponCode('');
                            return;
                          }

                          applyCoupon(couponCode);
                        }}
                      >
                        {coupon ? 'Remover' : 'Aplicar'}
                      </div>
                    </div>
                    {couponError && (
                      <span
                        style={{
                          color: '#fa1e1e',
                          alignSelf: 'flex-start',
                          marginTop: -5,
                          fontFamily: 'latoregular',
                          fontSize: 14,
                        }}
                      >
                        {couponError}
                      </span>
                    )}
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 'bold',
                    fontFamily: 'latoregular',
                    fontSize: 14,
                    marginBottom: 5,
                  }}
                >
                  Forma de pagamento:
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'latoregular',
                        fontSize: 14,
                      }}
                    >
                      Cartão
                    </div>

                    <div
                      style={{
                        cursor: 'pointer',
                        color: '#3333ff',
                        textDecoration: 'underline',
                      }}
                      onClick={() => registerNewCreditCard(true)}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'latoregular',
                          fontSize: 14,
                        }}
                        title="Novo cartão"
                        alt="Novo cartão"
                      >
                        Novo cartão
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: '1px solid black',
                    borderRadius: 5,
                    padding: '4px 8px',
                    marginBottom: 10,
                  }}
                >
                  {creditCard.id ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'latoregular',
                          fontWeight: 'bold',
                          fontSize: 14,
                        }}
                      >
                        <img
                          src={creditCardIcon}
                          style={{ width: 25, marginRight: 10 }}
                        />
                        {`**** **** **** ${creditCard.lastDigits}`}
                      </div>

                      <div className="checkbox-div-card">
                        <label className="checkbox-label-card">
                          <input type="checkbox" checked={true} />
                          <span className="checkbox-span-card"></span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'latoregular',
                          fontWeight: 'bold',
                          fontSize: 14,
                        }}
                      >
                        Nenhum cartão cadastrado
                      </div>
                    </div>
                  )}
                </div>

                {loadingModal ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReactLoading type="bubbles" color="#00838F" />
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        cursor: creditCard.id ? 'pointer' : 'not-allowed',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginBottom: 10,
                        backgroundColor: creditCard.id ? '#92d050' : '#757575',
                        color: '#fff',
                      }}
                      onClick={
                        creditCard.id ? () => finishPurchase() : () => {}
                      }
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'latoregular',
                          fontSize: 14,
                          margin: 5,
                        }}
                        title="Concluir compra"
                        alt="Concluir compra"
                      >
                        Finalizar compra
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'latoregular',
                          cursor: 'pointer',
                          fontSize: 14,
                        }}
                        title="Sair"
                        alt="Sair"
                        onClick={() => cancelPurchase()}
                      >
                        Cancelar
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ModalEventPedido;
