import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import * as queryString from 'query-string';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Alert,
} from 'reactstrap';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import Header from './../../includes/Header';
import './../../styles/css/index.css';

class NewPass extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      token: '',
      email: '',
      password: '',
      cpassword: '',
      password_type: 'password',
      cpassword_type: 'password',
      pass_visible: false,
      cpass_visible: false,
      created: false,
      success: false,
      loading: false,
    };

    this.service = new Service();
    this.utils = new Utils();
    this.createPass = this.createPass.bind(this);
  }

  componentDidMount() {
    this.verifyLogin();
    const parsed = queryString.parse(this.props.location.search);
    this.setState({
      email: parsed.email,
      token: parsed.token,
    });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  async createPass() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let user = {
        email: this.state.email,
        token: this.state.token,
        password: this.state.password,
      };
      let result = await this.service.createPass(user);
      if (result && result.status == 'Success') {
        this.setState({ created: true, success: true, loading: false });
      } else {
        this.setState({ created: true, success: false, loading: false });
        // this.resolveError(result)
      }
    } else {
      alert('Preencha todos os campos');
    }
  }

  formValid() {
    if (
      this.state.password.length > 0 &&
      this.state.password == this.state.cpassword
    ) {
      return true;
    }
    return false;
  }

  resolveError(result) {
    if (result && result.messages && result.messages.length > 0) {
      let messages = '';
      result.messages.map((message, mkey) => {
        messages += `${message}\n`;
      });
      alert(messages);
    } else {
      alert('Tenta novamente mais tarde');
    }
    this.setState({ loading: false });
  }

  verifyLogin() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard');
    }
  }

  togglePassword() {
    if (this.state.pass_visible) {
      this.setState({ pass_visible: false });
    } else {
      this.setState({ pass_visible: true });
    }
  }

  toggleCPassword() {
    if (this.state.cpass_visible) {
      this.setState({ cpass_visible: false });
    } else {
      this.setState({ cpass_visible: true });
    }
  }

  handleLogin() {    
    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <Header />

        {this.state.created ? (
          <div className="not-found-label">
            <p className="title-bold">
              {this.state.success
                ? 'Senha criada com sucesso'
                : `Link expirado ou usuário já cadastrado. \nFavor entrar com seu login.`}
            </p>
            <i
              className={
                this.state.success
                  ? 'fas fa-check big-grey-icon'
                  : 'fas fa-times big-grey-icon'
              }
              style={{ marginTop: 30, marginBottom:30 }}
            ></i>
            <div onClick={() => {this.handleLogin()}} className='bt-confirm hover-shadow avaiable'><p className="bt-label-o">Login</p></div>
          </div>
        ) : (
          <Col xs="12" className="mtop30">
            <div className="card-shadow">
              <p className="card-title">Crie sua senha</p>

              <input
                type={this.state.pass_visible ? 'text' : 'password'}
                placeholder="SENHA"
                value={this.state.password}
                className="cool-input input-password"
                onChange={e => {
                  this.setState({ password: e.target.value });
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.login();
                  }
                }}
              />
              <i
                className={
                  this.state.pass_visible
                    ? 'far fa-eye-slash icon-password'
                    : 'far fa-eye icon-password'
                }
                onClick={() => {
                  this.togglePassword();
                }}
              ></i>

              <input
                type={this.state.cpass_visible ? 'text' : 'password'}
                placeholder="CONFIRMAR SENHA"
                value={this.state.cpassword}
                className="cool-input input-password"
                onChange={e => {
                  this.setState({ cpassword: e.target.value });
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.login();
                  }
                }}
              />
              <i
                className={
                  this.state.cpass_visible
                    ? 'far fa-eye-slash icon-password'
                    : 'far fa-eye icon-password'
                }
                onClick={() => {
                  this.toggleCPassword();
                }}
              ></i>

              <div className="termos" style={{ marginLeft: 15 }}>
                <Link to="login" className="color-ggren">
                  Cancelar
                </Link>
              </div>
            </div>
            <div className="container-bt-confirm">
              {this.state.loading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : (
                <div
                  onClick={() => {
                    this.createPass();
                  }}
                  className={
                    !this.formValid()
                      ? 'bt-confirm hover-shadow'
                      : 'bt-confirm hover-shadow avaiable'
                  }
                >
                  <p className="bt-label-o">Criar</p>
                </div>
              )}
            </div>
          </Col>
        )}
      </div>
    );
  }
}

export default withRouter(connect()(NewPass));
