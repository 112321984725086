import openSocket from 'socket.io-client';

class ApiService {
  endPoint = process.env.REACT_APP_END_POINT;
  urlSite = process.env.REACT_APP_URL_SITE;

  parsePayload(_payload) {
    return Object.keys(_payload)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join('&');
  }

  getServer() {
    return this.endPoint;
  }

  getSite() {
    return this.urlSite;
  }

  isLogged() {
    try {
      return (
        localStorage.getItem('token') !== null &&
        localStorage.getItem('token') !== 'null' &&
        localStorage.getItem('token') !== ''
      );
    } catch (e) {
      return (
        sessionStorage.getItem('token') !== null &&
        sessionStorage.getItem('token') !== 'null' &&
        sessionStorage.getItem('token') !== ''
      );
    }
  }

  doLogout() {
    try {
      localStorage.setItem('token', 'null');
      localStorage.setItem('user', 'null');
    } catch (e) {
      sessionStorage.setItem('token', 'null');
      sessionStorage.setItem('user', 'null');
    }
  }

  getToken() {
    try {
      return localStorage.getItem('token');
    } catch (e) {
      return sessionStorage.getItem('token');
    }
  }

  setToken(t) {
    try {
      return localStorage.setItem('token', t);
    } catch (e) {
      return sessionStorage.setItem('token', t);
    }
  }

  getLoggedUser() {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (e) {
      return JSON.parse(sessionStorage.getItem('user'));
    }
  }

  setLoggedUser(u) {
    try {
      return localStorage.setItem('user', JSON.stringify(u));
    } catch (e) {
      return sessionStorage.setItem('user', JSON.stringify(u));
    }
  }

  async logout() { 
    window.location.reload();
    try {
      let response = await fetch(`${this.endPoint}logout`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async me(periodicy = false, getClicks = false) {
    try {
      // console.log('periodicy', periodicy);
      // console.log('getClicks', getClicks);
      // console.log('getClicks && periodicy', getClicks && periodicy);

      let response = await fetch(
        `${this.endPoint}users${periodicy ? `?periodicy=${periodicy}` : ''}${
          getClicks && periodicy
            ? `&clicks=${getClicks}`
            : getClicks
            ? `?clicks=${getClicks}`
            : ''
        }`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async meCoachee() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/users`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notificationMe() {
    try {
      let response = await fetch(`${this.endPoint}notifications/me/unreaded`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notificationMeCoachee() {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/notifications/me/unreaded`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notificationSee(_id) {
    try {
      let response = await fetch(
        `${this.endPoint}notifications/me/see/${_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notificationSeeCoachee(_id) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/notifications/me/see/${_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async meAdmin() {
    try {
      let response = await fetch(`${this.endPoint}admins/me`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async config(type) {
    try {
      let response = await fetch(`${this.endPoint}configs/see/${type}`, {
        method: 'GET',
        headers: {},
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  createSocket(query) {
    const apiUrl = new URL(this.getServer());
    const path = `${apiUrl.pathname}socket.io`;

    return openSocket(apiUrl.origin, { path, query });
  }

  async removeCert(_payload) {
    try {
      let response = await fetch(`${this.endPoint}upload/certificate/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify({ certificates: _payload }),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default ApiService;
