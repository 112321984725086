import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';
import ReactLoading from 'react-loading';

import * as queryString from 'query-string';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import Header from './../../includes/Header';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifing: true,
      success_verify: false,
      email: '',
      token: '',
    };
    this.service = new Service();
    this.utils = new Utils();
  }

  async componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    await this.setState({
      email: parsed.email,
      token: parsed.token,
    });
    this.verifyEmail();
  }

  async verifyEmail() {
    this.setState({ verifing: true });
    let user = {
      email: this.state.email,
      token: this.state.token,
    };
    let result = await this.service.verifyEmail(user);
    if (result && result.status == 'Success') {
      this.setState({ verifing: false, success_verify: true });
    } else {
      this.setState({ verifing: false, success_verify: false });
      // this.resolveError(result)
    }
  }

  handleLogin() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <Header />
        {!this.state.verifing ? (
          <div className="not-found-label">
            <p className="title-bold">
              {this.state.success_verify
                ? 'Email verificado com sucesso'
                : `Link expirado ou usuário já cadastrado. \nFavor entrar com seu login.`}
            </p>
            <i
              className={
                this.state.success_verify
                  ? 'fas fa-check big-grey-icon'
                  : 'fas fa-times big-grey-icon'
              }
              style={{ marginTop: 30, marginBottom: 30 }}
            ></i>
            <div
              onClick={() => {
                this.handleLogin();
              }}
              className="bt-confirm hover-shadow avaiable"
            >
              <p className="bt-label-o">Login</p>
            </div>
          </div>
        ) : (
          <div className="not-found-label">
            <p className="title-bold" style={{ marginBottom: 30 }}>
              Verificando email
            </p>
            <ReactLoading type="bubbles" color="#00838F" />
          </div>
        )}
      </div>
    );
  }
}

export default connect()(VerifyEmail);
