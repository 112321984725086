import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import moment from 'moment/min/moment-with-locales';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class Template4 extends Component {
  constructor(props, context) {
    super(props);

    this.utils = new Utils();
    this.service = new Service();

    this.goTo = this.goTo.bind(this);
    this.myRef = React.createRef();

    this.state = {
      offcanvas: '',
      active: '',
      toggle: true,
    };
  }

  handleScrollToElement(event) {
    window.scrollTo(0, this.myRef.current.offsetTop);
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  componentDidMount() {
    moment.locale('pt-BR');
  }

  componentWillUnmount() { }

  openSocial(uri) {
    window.open(uri, 'social');
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  toggle = async () => {
    await this.setState({
      toggle: !this.state.toggle,
    });

    await this.setState({
      offcanvas: !this.state.toggle ? 'offcanvas' : '',
      active: !this.state.toggle ? 'active' : '',
    });
  };

  render() {
    return (
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="50"
        className={this.state.offcanvas}
      >
        <div id="addo-page">
          <a
            className={`js-addo-nav-toggle addo-nav-toggle ${this.state.active}`}
            onClick={() => this.toggle()}
          >
            <i></i>
          </a>
          {/* Sidebar Section */}
          <aside id="addo-aside">
            {/* Logo */}
            {this.props.state.logo !== null &&
              this.props.state.logo !== undefined &&
              this.props.state.logo.trim() !== '' ? (
                <div className="addo-logo">
                  <div className="image_template4">
                    <img src={this.resolveImage(this.props.state.logo)} alt="" />
                  </div>
                </div>
              ) : (
                ''
              )}
            {/* Menu */}
            <nav id="addo-main-menu">
              <ul>
                <li>
                  <AnchorLink href="#home">Home</AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#sobremim">Sobre mim</AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#experiencia">Experiência</AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#certificacoes">Certificações</AnchorLink>
                </li>
                <li>
                  <AnchorLink href="#referencias">Referências</AnchorLink>
                </li>
              </ul>
            </nav>
            {/* Sidebar Footer */}
            <div className="addo-footer">
              <ul style={{ marginTop: -92 }}>
                {this.props.state.facebook === '' ||
                  this.props.state.facebook === null ||
                  this.props.state.facebook === undefined ||
                  !this.props.state.facebook.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.facebook);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-facebook font-14px black-icon"
                      ></i>
                    </li>
                  )}
                {this.props.state.twitter === '' ||
                  this.props.state.twitter === null ||
                  this.props.state.twitter === undefined ||
                  !this.props.state.twitter.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.twitter);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-twitter-alt font-14px black-icon"
                      ></i>
                    </li>
                  )}
                {this.props.state.instagram === '' ||
                  this.props.state.instagram === null ||
                  this.props.state.instagram === undefined ||
                  !this.props.state.instagram.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.instagram);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-instagram font-14px black-icon"
                      ></i>
                    </li>
                  )}
                {this.props.state.linkedin === '' ||
                  this.props.state.linkedin === null ||
                  this.props.state.linkedin === undefined ||
                  !this.props.state.linkedin.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.linkedin);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-linkedin font-14px black-icon"
                      ></i>
                    </li>
                  )}
                {this.props.state.youtube === '' ||
                  this.props.state.youtube === null ||
                  this.props.state.youtube === undefined ||
                  !this.props.state.youtube.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.youtube);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-youtube font-14px black-icon"
                      ></i>
                    </li>
                  )}
                {this.props.state.google_plus === '' ||
                  this.props.state.google_plus === null ||
                  this.props.state.google_plus === undefined ||
                  !this.props.state.google_plus.trim() ? null : (
                    <li
                      onClick={() => {
                        this.openSocial(this.props.state.google_plus);
                      }}
                    >
                      <i
                        style={{ cursor: 'pointer', padding: 10 }}
                        className="ti-google font-14px black-icon"
                      ></i>
                    </li>
                  )}
              </ul>
              <p>
                <small>&copy; 2019 {this.props.state.nomeCoach}</small>
              </p>
            </div>
          </aside>

          {/* Main Section */}
          <div id="addo-main">
            {/* Home Section */}
            <div id="home" className="section">
              <div className="addo-hero js-fullheight">
                <div className="flexslider js-fullheight">
                  <ul className="slides">
                    <li id="slidesBackgroud">
                      <div className="overlay"></div>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 js-fullheight slider-bottom">
                            <div className="slider-text-inner">
                              <div className="desc">
                                <h1>{this.props.state.nomeCoach}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* sobremim Section */}
            <div id="sobremim" className="section">
              <div className="addo-about">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h2
                        className="addo-heading animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        Sobre mim
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {this.props.state.photo !== null &&
                      this.props.state.photo !== undefined &&
                      this.props.state.photo.trim() !== '' ? (
                        <div className="col-md-5">
                          <img
                            src={this.resolveImage(this.props.state.photo)}
                            className="img-fluid mb-30 animate-box"
                            data-animate-effect="fadeInLeft"
                            alt=""
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    <div
                      className="col-md-7 animate-box justText"
                      data-animate-effect="fadeInLeft"
                    >
                      <span className="heading-meta">SOBRE MIM</span>
                      <p>
                        <h3>{this.props.state.about_me}</h3>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* experiencia Section */}
            <div id="experiencia" className="section">
              <div className="addo-services">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h2
                        className="addo-heading animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        Experiência
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 animate-box justText"
                      data-animate-effect="fadeInLeft"
                    >
                      <p>
                        <h3>{this.props.state.experience}</h3>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* certificacoes Section */}
            <div id="certificacoes" className="section">
              <div className="addo-references">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h2
                        className="addo-heading animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        Certificações
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 animate-box justText"
                      data-animate-effect="fadeInLeft"
                    >
                      <p>
                        <h3>{this.props.state.certifications}</h3>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* references Section */}
            <div id="referencias" className="section">
              <div className="addo-references">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h2
                        className="addo-heading animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        Referências
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 animate-box justText"
                      data-animate-effect="fadeInLeft"
                    >
                      <p>
                        <h3>{this.props.state.references}</h3>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div id="addo-footer2" className="section">
              <div className="addo-narrow-content">
                <div className="row">
                  <div
                    className="col-md-4 animate-box fadeInLeft animated"
                    data-animate-effect="fadeInLeft"
                  >
                    <p className="addo-lead">
                      © 2019 {this.props.state.nomeCoach}. All rights reserved
                    </p>
                  </div>
                  <div
                    className="col-md-4 animate-box fadeInLeft animated"
                    data-animate-effect="fadeInLeft"
                  >
                    <h2 className="text-center">
                      {this.props.state.nomeCoach}
                    </h2>
                  </div>
                  <div
                    className="col-md-12 animate-box fadeInLeft animated"
                    data-animate-effect="fadeInLeft"
                  >
                    <ul className="social-network">
                      {this.props.state.facebook === '' ||
                        this.props.state.facebook === null ||
                        this.props.state.facebook === undefined ||
                        !this.props.state.facebook.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.facebook);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-facebook font-14px white-icon"
                            ></i>
                          </li>
                        )}
                      {this.props.state.twitter === '' ||
                        this.props.state.twitter === null ||
                        this.props.state.twitter === undefined ||
                        !this.props.state.twitter.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.twitter);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-twitter-alt font-14px white-icon"
                            ></i>
                          </li>
                        )}
                      {this.props.state.instagram === '' ||
                        this.props.state.instagram === null ||
                        this.props.state.instagram === undefined ||
                        !this.props.state.instagram.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.instagram);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-instagram font-14px white-icon"
                            ></i>
                          </li>
                        )}
                      {this.props.state.linkedin === '' ||
                        this.props.state.linkedin === null ||
                        this.props.state.linkedin === undefined ||
                        !this.props.state.linkedin.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.linkedin);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-linkedin font-14px white-icon"
                            ></i>
                          </li>
                        )}
                      {this.props.state.youtube === '' ||
                        this.props.state.youtube === null ||
                        this.props.state.youtube === undefined ||
                        !this.props.state.youtube.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.youtube);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-youtube font-14px white-icon"
                            ></i>
                          </li>
                        )}
                      {this.props.state.google_plus === '' ||
                        this.props.state.google_plus === null ||
                        this.props.state.google_plus === undefined ||
                        !this.props.state.google_plus.trim() ? null : (
                          <li
                            onClick={() => {
                              this.openSocial(this.props.state.google_plus);
                            }}
                          >
                            <i
                              style={{ cursor: 'pointer', padding: 10 }}
                              className="ti-google font-14px white-icon"
                            ></i>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Template4)
);
