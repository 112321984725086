import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import '../../styles/css/datePicker/style.scss';
import { updateAlert } from '../../actions/SimpleAction';
import Utils from './../../libs/Utils.js';
import Service from './../../services/ApiService.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class ModalAlert extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.service = new Service();

    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="datepicker-style">
        {this.props.open ? (
          <div className="modal-alert" style={{ zIndex: 1000 }}>
            <div className="modal-alert-box">
              <i
                className="fas fa-times icon-close"
                onClick={() => {
                  this.props.close();
                }}
              ></i>
              <div className="row-modal-alert">
                <img src={logo} width={70} />
                <div>
                  <div className="modal-alert-text"> {this.props.title} </div>
                  <div className="form-input">
                    <label className="blue-label">Título</label>
                    <input
                      type="text"
                      value={this.props.state.title}
                      className="blue-input"
                      onChange={e => {
                        this.props.setState({ title: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <div className="form-input" style={{ marginRight: 10 }}>
                      <label className="blue-label">Início</label>
                      <DatePicker
                        selected={this.props.state.date_start}
                        onChange={e => {
                          this.props.setState({ date_start: e });
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="d/MM/yyyy HH:mm"
                        timeCaption="time"
                        className="blue-input"
                      />
                    </div>

                    <div className="form-input" style={{ marginLeft: 10 }}>
                      <label className="blue-label">Final</label>
                      <DatePicker
                        selected={this.props.state.date_end}
                        onChange={e => {
                          this.props.setState({ date_end: e });
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="d/MM/yyyy HH:mm"
                        timeCaption="time"
                        className="blue-input"
                      />
                    </div>
                  </div>

                  {!this.props.state.statusDate && (
                    <span className="campObrigatorio">
                      Os campos "Início e Final" são obrigatórios
                    </span>
                  )}

                  <div
                    className="bt-blue"
                    style={{ margin: '5px auto -5px auto' }}
                    onClick={() => {
                      this.props.save();
                    }}
                  >
                    Salvar
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  alert: state.simpleReducer.alert,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAlert);
