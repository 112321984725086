import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo-facebook.jpg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import moment from 'moment/min/moment-with-locales';
import EnvConfig from './../../../libs/EnvConfig';
import Template4 from './Template4';
import Template5 from './Template5';
import Template6 from './Template6';
import Template7 from './Template7';

class Landpage extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      website_name: '',
      about_me: '',
      experience: '',
      certifications: '',
      references: '',

      facebook: '',
      google_plus: '',
      linkedin: '',

      instagram: '',
      twitter: '',
      youtube: '',

      layout_model: '',
      publicated: false,
      editing: false,

      logo: '',
      photo: '',

      maxLength: 3660,

      coachee: {},
      imagePerfil: '',
      nomeCoach: '',
    };

    this.utils = new Utils();
    this.service = new Service();

    this.goTo = this.goTo.bind(this);
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  componentDidMount() {
    this.resolveParams();
    moment.locale('pt-BR');
  }

  componentWillUnmount() {}

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.slug) {
      this.loadMarketing(params.slug, params.preview);
    } else {
      this.goTo('');
    }
  }

  async loadMarketing(slug, preview) {
    let result = preview
      ? await this.service.marketingSlugPreview(slug)
      : await this.service.marketingSlug(slug);
    if (result && result.success) {
      if (result.marketing && result.marketing != 'null') {
        this.setState({
          imagePerfil: result.coach.image,
          website_name: result.marketing.website_name,
          about_me: result.marketing.about_me,
          experience: result.marketing.experience,
          certifications: result.marketing.certifications,
          references: result.marketing.references,
          facebook: result.marketing.facebook,
          instagram: result.marketing.instagram,
          google_plus: result.marketing.google_plus,
          twitter: result.marketing.twitter,
          linkedin: result.marketing.linkedin,
          youtube: result.marketing.youtube,
          wesite_available:
            result.marketing.website_name &&
            result.marketing.website_name.length > 0,

          layout_model: result.marketing.layout_model,
          publicated: result.marketing.publicated,
          logo: result.marketing.logo,
          photo: result.marketing.photo,

          editing_id: result.marketing._id,
          editing: true,
          nomeCoach: `${result.coach.engage_name}`,
        });
      } else {
        this.setState({ show: true });
      }
    }
  }

  openSocial(uri) {
    window.open(uri, 'social');
  }

  render() {
    return (
      <section className="is-preload">
        {this.state.layout_model == 0 ? (
          <>
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/animate.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/et-lineicons.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/themify-icons.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/bootstrap.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/flexslider.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template1/css/style.css`}
            />
          </>
        ) : this.state.layout_model == 1 ? (
          <>
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template2/css/plugins.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template2/css/style.css`}
            />
          </>
        ) : this.state.layout_model == 2 ? (
          <>
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/fontawesome-all.min.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/themify-icons.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/bootstrap.min.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/owl.carousel.min.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/magnific-popup.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/component.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/styles.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template3/css/default.css`}
            />
          </>
        ) : this.state.layout_model == 3 ? (
          <>
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template4/css/plugins.css`}
            />
            <link
              rel="stylesheet"
              href={`${EnvConfig}/css/landpages/Template4/css/style.css`}
            />
          </>
        ) : null}

        {!this.state.editing ? (
          <div id="page-wrapper">
            <section id="banner">
              <div className="inner">
                <div
                  className="logo-logo"
                  style={{
                    background: `url(${logo}) no-repeat center center / contain`,
                  }}
                />
                <h2>Página não publicada ou não encontrada</h2>
              </div>
            </section>
          </div>
        ) : this.state.layout_model == 0 ? (
          <Template4 state={this.state} />
        ) : this.state.layout_model == 1 ? (
          <Template5 state={this.state} />
        ) : this.state.layout_model == 2 ? (
          <Template6 state={this.state} />
        ) : this.state.layout_model == 3 ? (
          <Template7 state={this.state} />
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Landpage)
);
