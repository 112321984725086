import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

const SimpleModal = props => {
  return (
    <div>
      {props.visible ? (
        <div className="simple-modal">
          <div className="simple-modal-box">
            {props.iconClose && (
              <i
                className="fas fa-times icon-close"
                onClick={() => props.close()}
              ></i>
            )}
            <div className="simple-modal-row">
              {props.haveLogo && <img src={logo} width={100} />}
              {props.title && <div className={props.classNameTitle}>{props.title}</div>}
              {typeof props.content === 'string' &&
              props.content.indexOf('</') !== -1 ? (
                <div
                  className={props.classNameText}
                  dangerouslySetInnerHTML={{ __html: props.content }}
                ></div>
              ) : (
                <div className={props.classNameText}>{props.content || ''}</div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

SimpleModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  iconClose: PropTypes.bool,
  haveLogo: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameText: PropTypes.string,
};

SimpleModal.defaultProps = {
  iconClose: true,
  haveLogo: true,
  close: () => {},
  title: '',
  classNameTitle: 'simple-modal-title',
  classNameText: 'simple-modal-text',
};

export default SimpleModal;
