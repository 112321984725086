import { combineReducers } from 'redux'
import { simpleReducer } from './SimpleReducer'
import { DefineSidebarReducer } from './DefineSidebarReducer'

/**
 * Root reducer
 */
export default combineReducers({
  simpleReducer,
  DefineSidebarReducer
})