import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../assets/images/logo-facebook.jpg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { updateAlert } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 
 
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import ReactLoading from "react-loading";

class ModalAlert extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.service = new Service();  

    this.state = { }  
  }

  componentDidMount(){ }

  componentWillReceiveProps(nextProps){   }

  componentWillUnmount() {   }   

  render() {  
    return (   
          <div>
            {
              this.props.open ? 
              <div className="modal-alert" style={{ zIndex:1000 }}>
                <div className="modal-alert-box">
                  <i className="fas fa-times icon-close" onClick={() => { this.props.close() }}></i>
                    <div className="row-modal-alert">
                      <img src={ logo } width={70} /> 
                      <div> 
                       <div className="modal-alert-text"> { this.props.title} </div>
                       
                       {/*<div className="form-input">
                          <label className="blue-label">Titulo</label>
                          <input type="text" value={ this.props.state.title } className="blue-input"
                                onChange={ (e) => { this.props.setState({ title:e.target.value }) }} /> 
                      </div>*/} 

                      <div style={{ display:"flex", justifyContent:"space-around" }}>

                          
                          <div className="form-input">
                            <label className="blue-label">Selecione o coachee</label>
                            {
                              this.props.state.loading_coachee ? 
                              <ReactLoading type="bubbles" color="#B1CD49" />
                              : <select value={ this.props.state.coachee_id } className="blue-input" 
                                     onChange={ (e) => { this.props.changeCoachee(e) }}>
                                <option value=""> &nbsp; Coachee</option>
                                {
                                    this.props.state.coachees.map( (item, key) => {
                                        return <option key={ key } value={ item._id }> &nbsp; { item.name }</option>
                                    })
                                }
                              </select> 
                            }
                          </div>
                           

                      </div>



                    <div className="bt-blue" style={{ margin:'5px auto -5px auto' }} onClick={ () => { this.props.save() } }> 
                        Salvar
                      </div>


                    </div> 
                  </div>
                </div>
              </div> : null
            }
          </div>
    );
  }
}
const mapStateToProps = state => ({  
  alert: state.simpleReducer.alert
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);