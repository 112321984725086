import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import { updateImage } from '../../../actions/SimpleAction';

import ReactLoading from 'react-loading';

import Service from '../Service.js';
import Utils from '../../../libs/Utils.js';

import DropToUpload from 'react-drop-to-upload';

const typeDoc = [
  'xlsx',
  'docx',
  'pdf',
  'zip',
  'png',
  'PNG',
  'jpg',
  'JPG',
  'jpeg',
  'JPEG',
];

class NewUploadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      over: false,
      original_name: '',
      loading: false,
      sleepFile: '',
      validDoc: false,
      withError: false,
      isTooLarge: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.removeAttachment = this.removeAttachment.bind(this);
    this.showUpload = this.showUpload.bind(this);
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  fileSleep(file) {
    let fileExtension = file.name;
    let filetype = file.name.split('.');
    filetype = filetype[filetype.length - 1];
    if (typeDoc.includes(filetype)) {
      this.props.setState(() => ({
        doc_source: 'upload',
        doc_format: fileExtension,
        original_name: fileExtension,
        validDoc: false,
        withError: false,
        isTooLarge: false,
      }));
    } else {
      this.setState({
        validDoc: true,
      });
    }
    clearInterval(this.timerID);
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readFile(file);
    }
  }

  readFile(file) {
    this.setState({ loading: true });
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${reader.result}`.split(',')[1];
      let image = {
        filename: file.name,
        filetype: file.type,
        source,
      };
      this.props.setState({ original_name: file.name });
      let fileExtension = image.filename.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      let thefile = this.prepareFile(image);
      let result = await this.service.postFile(thefile, this.props.notConvert);
      if (result && result.success) {
        if (!this.props.notConvert) {
          if (fileExtension === 'docx') {
            let converted = await this.service.convertFileDocx({
              url: result.source,
            });
            if (converted && converted.success) {
              this.props.setState({ html: converted.html });
            }
          }

          if (fileExtension === 'pdf') {
            let converted = await this.service.convertFilePDF({
              url: result.source,
            });
            if (converted && converted.success) {
              this.props.setState({ html: converted.html });
            }
          }
        }
        this.props.setState({
          doc_source: result.source,
          doc_format: fileExtension,
        });
        this.setState({ loading: false });
      } else {
        if (result && result.status === 413) {
          this.setState({ isTooLarge: true });
        } else {
          this.setState({ withError: true });
        }
        this.setState({ loading: false });
        return;
      }
    };
    reader.readAsDataURL(file);
  }

  prepareFile(image) {
    let fileExtension = image.filename.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    let btc = window.atob(image.source);
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
      btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: image.filetype });
    return new File([boobs], `${image.filename}.${fileExtension}`);
  }

  openUpload(param) {
    document.getElementById('filefile').click();
  }

  async removeAttachment() {
    const file = { file: this.props.doc_source };
    await this.service.removeFile(file);
    this.props.setState({
      doc_source: '',
      doc_format: '',
      original_name: '',
      sleepFile: '',
    });
  }

  showUpload() {
    if (this.props.doc_source.length === 0) {
      return true;
    }
    return false;
  }

  onDrop(item) {
    this.readFile(item[0]);
    this.setState({ over: false });
  }

  onOver() {
    this.setState({ over: true });
  }

  onLeave() {
    this.setState({ over: false });
  }

  render() {
    return (
      <DropToUpload
        onDrop={this.onDrop.bind(this)}
        onLeave={this.onLeave.bind(this)}
        onOver={this.onOver.bind(this)}
      >
        {this.state.loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
            <ReactLoading type="spinningBubbles" color="#B1CD49" />
          </div>
        ) : this.state.over ? (
          <div className="uploadFile">
            <div className="uploadFile-content">
              <i
                className="fas fa-check"
                style={{ color: 'white', fontSize: 35, marginBottom: 20 }}
              ></i>
              <div>Solte aqui</div>
            </div>
          </div>
        ) : this.showUpload() ? (
          <>
            <div
              className="uploadFile"
              onClick={() => {
                this.openUpload(false);
              }}
            >
              <input
                type="file"
                accept={
                  this.props.acceptTypes
                    ? this.props.acceptTypes
                    : '.xlsx,.docx,.pdf,.zip,.png, .PNG, .jpg, .JPG, .jpeg, .JPEG'
                }
                onChange={ev => {
                  this.onFileChange(ev);
                }}
                id="filefile"
                style={{ display: 'none' }}
              />
              <div className="uploadFile-content">
                <img
                  width={'100'}
                  src={require('./../../../assets/images/dashboard/cloud_up.svg')}
                  alt="icone"
                />
                <div>Arraste um arquivo ou clique Aqui</div>
              </div>
            </div>
            {this.state.validDoc && (
              <div style={{ marginTop: -30, color: 'red' }}>
                Não pode subir arquivos diferentes dessas extensões: .pdf{' '}
              </div>
            )}

            {this.state.isTooLarge && (
              <div style={{ marginTop: -30, color: 'red' }}>
                Este arquivo é maior do que o suportado pelo servidor, não será
                possível fazer o upload.
              </div>
            )}

            {this.state.withError && (
              <div style={{ marginTop: -30, color: 'red' }}>
                Ocorreu um erro ao efetuar o upload.
              </div>
            )}
          </>
        ) : (
          <p
            className="box-subtitle"
            style={{
              marginBottom: 10,
            }}
          >
            <img
              src={require('./../../../assets/images/contents/icon-0.svg')}
              style={{
                marginRight: 10,
                marginLeft: 10,
                marginTop: -4,
              }}
              width="14"
              alt="icone"
            />
            {this.props.state.original_name}
            <i
              className="fas fa-times fright cpointer"
              onClick={() => {
                this.removeAttachment();
              }}
            ></i>
          </p>
        )}
      </DropToUpload>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUploadFile);
