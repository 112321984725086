import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 



 
              <div className="dash-stats-content">

                <div className="dash-stats">
                  <div className="stats-title">Coaches</div>
                  <div className="stats-info">
                    <div>{ this.props.stats && this.props.stats.coachs ? this.props.stats.coachs : "0" }</div>
                    <div><img src={ require('./../../assets/images/menu/icon-8.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Coachees</div>
                  <div className="stats-info">
                    <div>{ this.props.stats && this.props.stats.coachees ? this.props.stats.coachees : "0" }</div>
                    <div><img src={ require('./../../assets/images/dashp/4.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Sessões Coaching</div>
                  <div className="stats-info">
                    <div>{ this.props.stats && this.props.stats.sessions ? this.props.stats.sessions : "0" }</div>
                    <div><img src={ require('./../../assets/images/dash/2.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Planos</div>
                  <div className="stats-info">
                    <div>{ this.props.stats && this.props.stats.plans ? this.props.stats.plans : "0" }</div>
                    <div><img src={ require('./../../assets/images/dash/3.svg') } className="stats-image" /></div>
                  </div>
                </div> 
 
              </div>

 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);