import { 
  RENDER_SIDEBAR_CONTENTS,
  RENDER_SIDEBAR_FINANCES,
  RENDER_SIDEBAR_PROPOSES,
  RENDER_SIDEBAR_REPORTS,
  RENDER_SIDEBAR_SESSIONS,
} from '../actions/DefineSidebarAction'

/**
 * Define Sidebar Reducer
 */

export const DefineSidebarReducer = (state = {}, action) => {
  switch (action.type) {
    case RENDER_SIDEBAR_CONTENTS:
      return {
        result: action.payload
      }
    case RENDER_SIDEBAR_FINANCES:
      return {
        result: action.payload
      }
    case RENDER_SIDEBAR_PROPOSES:
      return {
        result: action.payload
      }
    case RENDER_SIDEBAR_REPORTS:
      return {
        result: action.payload
      }
    case RENDER_SIDEBAR_SESSIONS:
      return {
        result: action.payload
      }
    default:
      return state
  }
}