import React, { Component } from 'react';
import { connect } from 'react-redux'; 

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';  
 

import { simpleAction } from '../../../actions/SimpleAction' 
 
 
import Utils from './../../../libs/Utils.js';
  

class Submenu extends Component { 

  constructor(props) {
    super(props);  
    this.state = { }; 
    this.utils = new Utils();
  }
 
  componentDidMount(){ 

  }

  componentWillUnmount() { 

  }
 

 
  render() { 

    return (  
        <div className="submenu submenu-profile">
          <ul className="side-menu">
            <li className={ this.props.subview == 'area' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/profile/professional/area') } } >
              <img src={ require('./../../../assets/images/menu/personal/icon-1.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/menu/personal/icon-1w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Área de Atuação
                  </div> 
              </div>
            </li>
            <li className={ this.props.subview == 'description' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/profile/professional/description') } }>
              <img src={ require('./../../../assets/images/menu/personal/icon-2.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/menu/personal/icon-2w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Descrição Profissional
                  </div> 
              </div>
            </li> 
            <li className={ this.props.subview == 'session' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/profile/professional/session') } } >
              <img src={ require('./../../../assets/images/menu/personal/icon-3.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/menu/personal/icon-3w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Sessão Gratuita
                  </div> 
              </div>
            </li> 
            <li className={ this.props.subview == 'value' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/profile/professional/value') } }>
              <img src={ require('./../../../assets/images/menu/personal/icon-4.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/menu/personal/icon-4w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Valores de Referência
                  </div> 
              </div>
            </li>   
          </ul>
        </div> 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Submenu);