import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Utils from './../../../libs/Utils.js';
import './css/MyPlans.css';

class MyPlans extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'plans',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: 'profile' },
        { title: 'Meus Planos', link: 'profile/myplans' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      number: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.loadCard();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let location = [
        { state: this.state.user.state, city: this.state.user.city },
      ];
      let contact = [
        { phone: this.state.user.phone, site: this.state.user.site },
      ];
      let user = {
        name: this.state.user.name,
        email: this.state.user.email,
        surname: this.state.user.surname,
        location: JSON.stringify(location),
        contact: JSON.stringify(contact),
      };
      let result = await this.service.save(user);
      if (result.success) {
        this.props.updateAlert('Salvo com sucesso');
      }
      this.setState({ loading: false });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  formValid() {
    return true;
  }

  calculate(type) {
    if (
      this.state.user &&
      this.state.user.limits &&
      this.state.user.limits[type]
    ) {
      let total = this.state.user.limits[type].total;
      let used = this.state.user.limits[type].used;
      return `${parseInt(used > total ? 100 : (used * 100) / total)}%`;
    } else {
      return '0%';
    }
  }

  async loadCard() {
    let result = await this.service.myCard();
    if (result && result.paymentMethod) {
      this.setState({
        number: `${result.paymentMethod.data.last_digits}`,
      });
    }
  }

  getSubscriptionPeriodMessage() {
    if (!this.state.user.subscription) return '';

    const {
      hasSubscription,
      isRenewConfirmationPending,
      isSuspended,
      isExpired,
    } = this.state.user.subscription;
    if (!hasSubscription) return '';

    if (isRenewConfirmationPending) {
      return 'Aguardando confirmação do pagamento/renovação da assinatura.';
    }

    if (isExpired) {
      return `Sua assinatura expirou em ${this.state.user.renew_date}.`;
    }

    if (isSuspended) {
      return `Sua assinatura irá expirar em ${this.state.user.renew_date}.`;
    }

    return `Sua assinatura será renovada automaticamente em ${this.state.user.renew_date}.`;
  }

  getTestPeriodMessage() {
    if (!this.state.user.testPeriod) return '';

    return `Seu período de teste da plataforma terminará em ${this.state.user.expiresTest}.`;
  }

  renderPlanExpiresMessage() {
    const subscriptionPeriodMessage = this.getSubscriptionPeriodMessage();
    const testPeriodMessage = this.getTestPeriodMessage();
    return (
      <>
        {subscriptionPeriodMessage}
        {subscriptionPeriodMessage && testPeriodMessage && <br />}
        {testPeriodMessage}
      </>
    );
  }

  async suspendSubscription() {
    let result = await this.service.suspendSubscription();
    if (result && result.success) {
      this.loadUser();
    }
    this.props.updateAlert('');
  }

  cancelSubscriptionOpen() {
    this.props.updateAlert(
      <div>
        <p>
          Ao cancelar a assinatura, você poderá acessar as funcionalidades do
          plano contratado até {this.state.user.renew_date}
        </p>
        <br />
        <div
          className="bt-blue"
          style={{ margin: '0 auto', marginBottom: 10 }}
          onClick={() => {
            this.props.updateAlert('');
          }}
        >
          Não quero cancelar
        </div>
        <div
          className="bt-blue"
          style={{ margin: '0 auto', background: '#f06161' }}
          onClick={() => this.suspendSubscription()}
        >
          Realmente quero cancelar
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {this.state.user && this.state.user != null ? (
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 10 }}>
                    <div className="box-row-title">
                      <div className="box-title">Meus planos</div>
                    </div>
                    <div className="box-row-title mtop10">
                      <div className="box-title no-upper">
                        Informações sobre forma de pagamento
                      </div>
                    </div>

                    <div className="content-payment-method-box">
                      <div className="content-payment-method">
                        {this.state.number != '' ? (
                          <div className="row-between">
                            <div className="box-subtitle">
                              Você está pagando via cartão de crédito final{' '}
                              {this.state.number}
                            </div>
                            <div
                              className="bt-link"
                              onClick={() => {
                                this.goTo('dashboard/profile/creditcard');
                              }}
                            >
                              Alterar forma de pagamento
                            </div>
                          </div>
                        ) : null}
                        <div className="payment-plans-content">
                          <div className="payment-plans-row">
                            <div className="payment-plans-option">
                              <img
                                src={require('./../../../assets/images/menu/icon-search.svg')}
                                width="19"
                              />
                              Plano do buscador
                            </div>
                            <div className="payment-plans-option max170">
                              <u>
                                {this.state.user &&
                                this.state.user.plans &&
                                this.state.user.plans.seacher &&
                                this.state.user.plans.seacher.plan
                                  ? this.state.user.plans.seacher.plan.title
                                  : ''}
                              </u>
                            </div>
                            <div className="box-plan-limits-options spaceButton hidden-xs">
                              <div
                                className="button-big-blue"
                                onClick={() => {
                                  this.setSidebarLinks('profile/plans');
                                }}
                              >
                                Alterar plano
                              </div>
                            </div>
                            {this.state.user &&
                              this.state.user.subscription &&
                              this.state.user.subscription.hasSubscription &&
                              !this.state.user.subscription.isSuspended && (
                                <div className="box-plan-limits-options spaceButton hidden-xs">
                                  <div
                                    className="button-big-red"
                                    onClick={() =>
                                      this.cancelSubscriptionOpen()
                                    }
                                  >
                                    Cancelar Assinatura
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="payment-plans-row">
                            <div className="payment-plans-option">
                              <img
                                src={require('./../../../assets/images/menu/icon-dash.svg')}
                                width="19"
                              />
                              Plano da plataforma
                            </div>
                            <div className="payment-plans-option max170">
                              <u>
                                {this.state.user &&
                                this.state.user.plans &&
                                this.state.user.plans.platform &&
                                this.state.user.plans.platform.plan
                                  ? this.state.user.plans.platform.plan.title
                                  : this.state.user &&
                                    this.state.user.testPeriod
                                  ? 'Período de Teste'
                                  : ''}
                              </u>
                            </div>
                          </div>
                          <div className="box-plan-limits-options spaceButton  visible-xs site-content">
                            <div
                              className="button-big-blue-two"
                              onClick={() => {
                                this.setSidebarLinks('profile/plans');
                              }}
                            >
                              Alterar plano
                            </div>
                            {this.state.user &&
                              this.state.user.subscription &&
                              this.state.user.subscription.hasSubscription &&
                              !this.state.user.subscription.isSuspended && (
                                <div
                                  className="button-big-red-two"
                                  onClick={() => this.cancelSubscriptionOpen()}
                                >
                                  Cancelar Assinatura
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row-between">
                          <div className="box-subtitle">
                            <small>{this.renderPlanExpiresMessage()}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={{ size: 12 }} lg={{ size: 10 }}>
                    <div className="box-row-title mtop30">
                      <div className="box-title no-upper">
                        Limites do plano contratados
                      </div>
                    </div>

                    <div className="payment-plan-limits-content">
                      {this.state.user.limits ? (
                        <div className="box-subtitle">
                          <small>{this.state.user.limits.period}</small>
                        </div>
                      ) : null}

                      <div className="row-between">
                        <div className="box-plan-limits">
                          <div className="row-plan-limits">
                            <div className="w230">
                              Quantidade de Coachees contratados
                            </div>
                            <div className="plan-limit-consume-box">
                              <div
                                className="plan-limit-consume-box-fill"
                                style={{ width: this.calculate('coachees') }}
                              />
                            </div>
                            <div className="w80">
                              {this.state.user.limits
                                ? this.state.user.limits.coachees.used
                                : 0}
                              /
                              {this.state.user.limits
                                ? this.state.user.limits.coachees.total
                                : 0}
                            </div>
                          </div>

                          <div className="row-plan-limits">
                            <div className="w230">Capacidade consumida</div>
                            <div className="plan-limit-consume-box-red">
                              <div
                                className="plan-limit-consume-box-red-fill"
                                style={{ width: this.calculate('storage') }}
                              />
                            </div>
                            <div className="w80">
                              {this.state.user.limits
                                ? this.state.user.limits.storage.used.toFixed(2)
                                : 0}
                              /
                              {this.state.user.limits
                                ? this.state.user.limits.storage.total
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              ) : null}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyPlans)
);
