import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/profile/stylesUploadImg.scss';
import { simpleAction, updateImage } from '../../../actions/SimpleAction';
import Utils from './../../../libs/Utils.js';
import Service from './Service.js';
import DropToUpload from 'react-drop-to-upload';
import NewModalCoacheeDelete from '../../components/NewModalCoacheeDelete';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_modal_delete: false,
      displayRemove: 'displayNone',
    };
    this.utils = new Utils();
    this.service = new Service();
    this.onFileChange = this.onFileChange.bind(this);
    this.openUpload = this.openUpload.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readFile(file);
    }
    event.target.value = '';
  }

  async saveImage(image) {
    let user = {
      image,
    };
    let result = await this.service.save(user);
    if (result && result.success) {
      this.props.updateImage(image);
      this.props.uuser();
    }
  }

  openUpload() {
    document.getElementById('fileimage').click();
  }

  readFile(file) {
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${reader.result}`.split(',')[1];
      let image = {
        filename: file.name,
        filetype: file.type,
        source,
      };
      let thefile = this.prepareImageFile(image);
      let result = await this.service.postImage(thefile);
      if (result.success) {
        this.saveImage(result.source);
      }
    };
    reader.readAsDataURL(file);
  }

  prepareImageFile(image) {
    let btc = window.atob(image.source);
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
      btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: 'image/png' });
    const filenameText = image.filename.replace(/\.[^/.]+$/, '');
    return new File([boobs], `${filenameText}.png`);
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  onDrop(item) {
    this.readFile(item[0]);
    this.setState({ over: false });
  }

  onOver() {
    this.setState({ over: true });
  }

  onLeave() {
    this.setState({ over: false });
  }

  toggleModalDelete() {
    this.setState({ new_modal_delete: !this.state.new_modal_delete });
  }

  deleteContent = async () => {
    let result = await this.service.removeImage(this.props.user._id);
    if (result && result.success) {
      this.props.updateImage(null);
      this.props.uuser();
    }
    this.toggleModalDelete();
  };

  displayOver = () => {
    this.setState({ displayRemove: 'displayBlock' });
  };

  displayOut = () => {
    this.setState({ displayRemove: 'displayNone' });
  };

  removerImagem = async () => {
    this.toggleModalDelete();
  };

  render() {
    if (this.props.newOption) {
      return (
        <>
          <div className="profile-styles-upload-img imgGrup">
            {this.props.user && this.props.user != null ? (
              <div>
                <DropToUpload
                  onDrop={this.onDrop.bind(this)}
                  onLeave={this.onLeave.bind(this)}
                  onOver={this.onOver.bind(this)}
                  className="up-image-resp"
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={ev => {
                      this.onFileChange(ev);
                    }}
                    id="fileimage"
                    style={{ display: 'none' }}
                  />
                  {this.props.user.image && !this.state.over ? (
                    <div
                      className="container-imagem"
                      onMouseOver={() => this.displayOver()}
                      onMouseOut={() => this.displayOut()}
                    >
                      <div
                        className="container-remover"
                        style={{ height: 20 }}
                        onClick={() => {
                          this.removerImagem();
                        }}
                      >
                        <span
                          className={`btn-remover ${this.state.displayRemove}`}
                        >
                          X
                        </span>
                      </div>
                      <img
                        src={this.resolveImage(this.props.user.image)}
                        style={{
                          padding: 0,
                          zIndex: 10,
                          backgroundSize: 'contain, cover',
                        }}
                        className="hover-shadow imgPerfil"
                        onClick={() => {
                          this.openUpload();
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.openUpload();
                      }}
                      className="up-image hover-shadow"
                    >
                      <img
                        src={require('./../../../assets/images/camera.svg')}
                        width="70"
                      />
                      <p>
                        Arraste o arquivo .jpg ou .png
                        <br />
                        ou
                        <br />
                        Clique aqui
                        <br />
                        Max 10mb
                      </p>
                    </div>
                  )}
                </DropToUpload>
              </div>
            ) : null}

            <NewModalCoacheeDelete
              close={() => {
                this.setState({ new_modal_delete: false });
              }}
              state={this.state}
              open={this.state.new_modal_delete}
              deleteContent={this.deleteContent.bind(this)}
              title={`Deseja remover esta foto`}
            />
          </div>
        </>
      );
    } else {
      return this.props.user && this.props.user != null ? (
        <div
          className="profile-styles-upload-img"
          onClick={() => {
            this.openUpload();
          }}
        >
          <DropToUpload
            onDrop={this.onDrop.bind(this)}
            onLeave={this.onLeave.bind(this)}
            onOver={this.onOver.bind(this)}
            className="up-image hover-shadow"
            style={
              this.props.user.image && !this.state.over
                ? {
                    background: `url(${this.resolveImage(
                      this.props.user.image
                    )}) no-repeat center center / cover`,
                    padding: 0,
                    zIndex: 10,
                  }
                : this.state.over
                ? {
                    background: `#d5d5d5 url(${require('./../../../assets/images/aspect-ratio-drop.png')}) no-repeat center center / contain`,
                    zIndex: 10,
                    padding: 0,
                  }
                : { zIndex: 10 }
            }
          >
            <input
              type="file"
              accept="image/*"
              onChange={ev => {
                this.onFileChange(ev);
              }}
              id="fileimage"
              style={{ display: 'none' }}
            />
            {this.props.user.image ? (
              <img
                src={require('./../../../assets/images/aspect-ratio.png')}
                width="100%"
                style={{ visibility: 'hidden', margin: 0 }}
              />
            ) : (
              <div>
                <img
                  src={require('./../../../assets/images/camera.svg')}
                  width="70"
                />
                <p>
                  Arraste o arquivo .jpg ou .png
                  <br />
                  ou
                  <br />
                  Clique aqui
                  <br />
                  Max 10mb
                </p>
              </div>
            )}
          </DropToUpload>
        </div>
      ) : null;
    }
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
  updateImage: () => dispatch(updateImage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImage);
