import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../sidebar/Sidebar';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

import Footer from './../components/Footer';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

import BigCalendar from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';

import moment from 'moment/min/moment-with-locales';
import ApiCalendar from 'react-google-calendar-api';

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'calendar',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Agenda', link: 'dashboard/calendar' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      events: [],
      messages: {
        allDay: 'Todo dia',
        previous: '<',
        next: '>',
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Hora',
        event: 'Evento',
        showMore: total => `+ Mostrar mais (${total})`,
      },
      sign: ApiCalendar.sign,
      title: '',
      title_modal: '',
      title_modal_event: '',
      body: '',
      date_start: '',
      date_end: '',
      open: false,
      open_event: false,
      eev: '',
      have_google: false,
    };

    ApiCalendar.onLoad(() => {
      this.setState({ sign: ApiCalendar.sign });
      if (ApiCalendar.sign) {
        this.readGoogleEvents();
      }
      ApiCalendar.listenSign(sign => {
        this.setState({ sign });
        if (sign) {
          this.readGoogleEvents();
        }
      });
    });

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCalendar();
    window.addEventListener('resize', this.handleResize);
    moment.locale('pt-BR');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`coachees/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCalendar() {
    let result = await this.service.calendar();
    if (result && result.success) {
      let events = this.parseEventsDate(result.events);
      this.setState({ events: events });
    }
  }

  parseEventsDate(events) {
    for (let _evk in events) {
      events[_evk].end = new Date(events[_evk].end);
      events[_evk].start = new Date(events[_evk].start);
    }
    return events;
  }

  selectEvent(e) {
    console.log('selectEvent', e);

    let message = (
      <div>
        <div>{e.title}</div>
        <div
          style={{
            float: 'right',
            fontSize: 12,
            marginRight: '-50px',
            marginBottom: '-20px',
            marginTop: '10px',
          }}
        >
          {this.utils.parseDate(e.start)}
        </div>
      </div>
    );

    this.props.updateAlert(message);
  }

  syncGoogleCalendar() {
    if (!this.state.sign) {
      console.log('Need Auth');
      ApiCalendar.handleSignoutClick();
      ApiCalendar.handleAuthClick();
    } else {
      console.log('NO NEED Auth');
      this.readGoogleEvents();
    }
  }

  desyncGoogleCalendar() {
    if (this.state.sign) {
      ApiCalendar.handleSignoutClick();
      // ApiCalendar.handleAuthClick();
    }
  }

  readGoogleEvents() {
    this.setState({ have_google: true });
    ApiCalendar.listUpcomingEvents(250, 'primary').then(({ result }) => {
      // console.log('listUpcomingEvents', result.items);
      this.parseGoogleEvents(result.items);
    });
  }

  parseGoogleEvents(_events) {
    let events = this.state.events;
    for (let _ev of _events) {
      let _pay = {
        action: '',
        allDay: false,
        coachee_id: '',
        end: this.parseGoogleDate(_ev.end.dateTime),
        responsible: '',
        start: this.parseGoogleDate(_ev.start.dateTime),
        title: _ev.summary,
        _from: 'google',
      };
      if (events.indexOf(_pay) === -1) {
        events.push(_pay);
      }
    }
    this.setState({ events });
  }

  parseGoogleDate(date) {
    let _d = date.split('T');
    return new Date(`${_d[0]} ${_d[1].split('-')[0]}`);
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <div className="content-calendar">
                    <BigCalendar
                      messages={this.state.messages}
                      components={{ toolbar: CalendarToolbar }}
                      localizer={localizer}
                      events={this.state.events}
                      views={['day', 'week', 'month']}
                      defaultView="month"
                      startAccessor="start"
                      endAccessor="end"
                      onSelectEvent={e => {
                        this.selectEvent(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row-button">
                {!this.state.sign ? (
                  <div
                    className="bt-blue"
                    onClick={() => {
                      this.syncGoogleCalendar();
                    }}
                  >
                    <img
                      src={require('./../../assets/images/buttons/sync.svg')}
                      style={{ width: 20, marginRight: 10 }}
                    />
                    Sincronizar Agenda
                  </div>
                ) : (
                  <div
                    className="bt-blue grey"
                    onClick={() => {
                      this.desyncGoogleCalendar();
                    }}
                  >
                    <img
                      src={require('./../../assets/images/buttons/sync.svg')}
                      style={{ width: 20, marginRight: 10 }}
                    />
                    Sair da agenda Google
                  </div>
                )}
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class CalendarToolbar extends Toolbar {
  componentDidMount() {
    const view = this.props.view;
    console.log(view);
  }

  render() {
    return (
      <div className="calendar-header">
        <div className="rbc-btn-group">
          <button
            type="button"
            className="bt-calendar-green"
            onClick={() => this.navigate('TODAY')}
          >
            Hoje
          </button>
        </div>
        <div className="calendar-center">
          <button
            type="button"
            className="mright-20 bt-calendar-navigate"
            onClick={() => this.navigate('PREV')}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className="rbc-toolbar-label">{this.props.label}</div>
          <button
            type="button"
            className="mleft-20 bt-calendar-navigate"
            onClick={() => this.navigate('NEXT')}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <div className="rbc-btn-group">
          <button
            type="button"
            className={
              this.props.view == 'day'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'day')}
          >
            Dia
          </button>
          <button
            type="button"
            className={
              this.props.view == 'week'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'week')}
          >
            Semana
          </button>
          <button
            type="button"
            className={
              this.props.view == 'month'
                ? 'bt-calendar-green'
                : 'bt-calendar-green btoff'
            }
            onClick={this.view.bind(null, 'month')}
          >
            Mês
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Calendar)
);
