import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import BoxOptions from './BoxOptions'; 

import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';  

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.state = {
      visible: true
    }
    this.utils = new Utils();
    this.widthBar = this.widthBar.bind(this) 
  }

  componentWillReceiveProps(nextProps){
    let _f = nextProps.finance;
    this.calcWith(_f)
  }

   componentDidMount(){ 
      if( this.isClosed( `${ this.props.id }` ) ){
        this.setState({ visible:false })
      } 
    this.widthBar(); 
    window.addEventListener('resize', this.widthBar);
  }

  componentWillUnmount() { 
    window.removeEventListener('resize', this.widthBar);
  } 
 
  widthBar(){
    let _f = this.props.finance;
    this.calcWith(_f)
  }

  calcWith(_f){ 
    let _m = window.innerWidth - (( window.innerWidth * 60 )/100) 
    let _b = _f.inb > _f.outb && _f.inb > _f.toinb ? _f.inb 
               :  _f.outb > _f.toinb ?  _f.outb
               :  _f.toinb ;

    _f.inw =  ( _f.inb * _m) / _b 
    _f.outw =  ( _f.outb * _m) / _b 
    _f.toinw =  ( _f.toinb * _m) / _b 

    this.setState({ finance: _f })
  }

 
  toggleVisibility(){
    if( !this.state.visible ){
      this.setClosed(`${ this.props.id }`, '')
    }else{
      this.setClosed(`${ this.props.id }`, 'closed')
    }
    this.setState({ visible: !this.state.visible })
  }

  clearValue(value){ 
      let val = value ? value.replace('R$ ','') : ''
      val = val.replace(/\./g,'') 
      val = val.replace(',','.') 
      return val
  }

  dirtyValue(value){
    let _v = 0;
    if(value){ 
      _v = value
      let parts = (value.toFixed(2)).toString().split(".");
      parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      value = parts.join(",");
    }
    return value && _v > 0 ? `R$ ${ value }` : 'R$ 0,00'
  }

    isClosed(_k){ 
    let key = `climby::${ _k }`
    try {
      return ( localStorage.getItem(key) !== null 
           && localStorage.getItem(key) !== 'null'
           && localStorage.getItem(key) !== '' )
      } catch (e) {
        return ( sessionStorage.getItem(key) !== null 
           && sessionStorage.getItem(key) !== 'null'
           && sessionStorage.getItem(key) !== '' )
      } 
  }
    
  setClosed(_k, t){
      let key = `climby::${ _k }`
        try {
          return localStorage.setItem(key, t);
      } catch (e) {
        return sessionStorage.setItem(key, t);
      }
  }


  render() {  
    return (



                <div className="dashboard-box box100">
                  <div className="box-row-title">
                    <div className="box-title">Controle Financeiro</div> 
                    <BoxOptions visible={ this.state.visible } toggleVisibility={ this.toggleVisibility.bind(this) } /> 
                  </div> 
                   { this.state.visible ? 
                    <div className="box-bar-chart">
                      <div className="bar-chart">
                          
                        <div 
                          className="bar-bar-chart bar-blue"
                          style={{width: this.props.finance.inw}} >
                          { this.dirtyValue(this.props.finance.inb) }
                        </div>
                        <div className="bar-bar-chart bar-pink"
                          style={{width: this.props.finance.outw}} >
                          { this.dirtyValue(this.props.finance.outb) }
                        </div>
                        <div className="bar-bar-chart bar-green"
                          style={{width: this.props.finance.toinw}} >
                          { this.dirtyValue(this.props.finance.toinb) }
                        </div>

                      </div>
                        <ul>
                          <li>
                            <div className="before bar-blue"></div>
                            Receitas
                          </li>
                          <li>
                            <div className="before bar-pink"></div>
                            Despesas
                          </li>
                          <li>
                            <div className="before bar-green"></div>
                            Receber
                          </li> 
                        </ul>
                    </div> : null }
                </div>


    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);