import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/profile/stylesUploadImg.scss';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../../actions/SimpleAction';

import Utils from './../../../libs/Utils.js';
import Service from './Service.js';

import DropToUpload from 'react-drop-to-upload';
import logo from '../../../assets/images/logo-facebook.jpg';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_modal_delete: false,
      displayRemove: 'displayNone',
    };
    this.utils = new Utils();
    this.service = new Service();
    this.onFileChange = this.onFileChange.bind(this);
    this.openUpload = this.openUpload.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readFile(file);
    }
    event.target.value = '';
  }

  async saveImage(image) {
    let user = {
      image,
    };
    let result = await this.service.save(user);
    if (result && result.success) {
      this.props.updateImage(image);
      this.props.uuser();
    }
  }

  openUpload() {
    document.getElementById('fileimage').click();
  }

  readFile(file) {
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${reader.result}`.split(',')[1];
      let image = {
        filename: file.name,
        filetype: file.type,
        source,
      };
      let thefile = this.prepareImageFile(image);
      let result = await this.service.postImage(thefile);
      if (result.success) {
        this.saveImage(result.source);
      }
    };
    reader.readAsDataURL(file);
  }

  prepareImageFile(image) {
    let btc = window.atob(image.source);
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
      btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: 'image/png' });
    const filenameText = image.filename.replace(/\.[^/.]+$/, '');
    return new File([boobs], `${filenameText}.png`);
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  onDrop(item) {
    this.readFile(item[0]);
    this.setState({ over: false });
  }

  onOver() {
    this.setState({ over: true });
  }

  onLeave() {
    this.setState({ over: false });
  }

  removerImagem = async () => {
    this.toggleModalDelete();
  };

  toggleModalDelete() {    
    this.handleDeletePhoto();
  }

  deleteContent = async () => {
    let result = await this.service.removeImage(this.props.user._id);
    if (result && result.success) {
      this.props.updateImage(null);
      this.props.uuser();
    }    
  };

  displayOver = () => {
    this.setState({ displayRemove: 'displayBlock' });
  };

  displayOut = () => {
    this.setState({ displayRemove: 'displayNone' });
  };

  handleDeletePhoto = () => {
    this.props.updateAlert(
      <div className="modal-alert">
        <div className="modal-alert-box">
          <i
            className="fas fa-times icon-close"
            onClick={() => {
              this.props.updateAlert('');
            }}
          ></i>
          <div className="row-modal-alert">
            <img src={logo} width={70} alt="logo" />

            <div className="modal-alert-text">Deseja remover esta foto?</div>

            <div className="modal-alert-text btnComp">
              <div
                className="bt-blue"
                onClick={() => {
                  this.props.updateAlert('');
                }}
              >
                Não
              </div>
              <div
                className="bt-blue"
                onClick={() => {
                  this.deleteContent();
                  this.props.updateAlert('');
                }}
              >
                Sim
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.newOption) {
      return (
        <>
          <div className="profile-styles-upload-img" style={{ width: '100%' }}>
            {this.props.user && this.props.user != null ? (
              <div>
                <DropToUpload
                  onDrop={this.onDrop.bind(this)}
                  onLeave={this.onLeave.bind(this)}
                  onOver={this.onOver.bind(this)}
                  className={
                    this.props.className
                      ? this.props.className
                      : 'up-image-resp'
                  }
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={ev => {
                      this.onFileChange(ev);
                    }}
                    id="fileimage"
                    style={{ display: 'none' }}
                  />
                  {this.props.user.image && !this.state.over ? (
                    <div
                      className="container-imagem"
                      onMouseOver={() => this.displayOver()}
                      onMouseOut={() => this.displayOut()}
                    >
                      <div
                        className="container-remover"
                        style={{ height: 20 }}
                        onClick={() => {
                          this.removerImagem();
                        }}
                      >
                        <span
                          className={`btn-remover ${this.state.displayRemove}`}
                        >
                          X
                        </span>
                      </div>
                      <img
                        src={this.resolveImage(this.props.user.image)}
                        style={{
                          padding: 0,
                          zIndex: 10,
                          backgroundSize: 'contain, cover',
                          maxHeight: '165px',
                          objectFit: 'cover',
                        }}
                        className="hover-shadow imgPerfil"
                        onClick={() => {
                          this.openUpload();
                        }}
                        alt="user"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.openUpload();
                      }}
                      className={
                        this.props.classNameEmptyImg
                          ? this.props.classNameEmptyImg
                          : 'up-image hover-shadow'
                      }
                    >
                      <img
                        src={require('./../../../assets/images/camera.svg')}
                        width="70"
                        alt="camera"
                      />
                      <p>
                        Arraste o arquivo .jpg ou .png
                        <br />
                        ou
                        <br />
                        Clique aqui
                        <br />
                        Max 10mb
                      </p>
                    </div>
                  )}

                </DropToUpload>
              </div>
            ) : null}

          </div>
        </>
      );
    } else {
      return this.props.user && this.props.user != null ? (
        <div
          className="profile-styles-upload-img"
          onClick={() => {
            this.openUpload();
          }}
        >
          <DropToUpload
            onDrop={this.onDrop.bind(this)}
            onLeave={this.onLeave.bind(this)}
            onOver={this.onOver.bind(this)}
            className="up-image hover-shadow"
            style={
              this.props.user.image && !this.state.over
                ? {
                    background: `url(${this.resolveImage(
                      this.props.user.image
                    )}) no-repeat center center / cover`,
                    padding: 0,
                    zIndex: 10,
                  }
                : this.state.over
                ? {
                    background: `#d5d5d5 url(${require('./../../../assets/images/aspect-ratio-drop.png')}) no-repeat center center / contain`,
                    zIndex: 10,
                    padding: 0,
                  }
                : { zIndex: 10 }
            }
          >
            <input
              type="file"
              accept="image/*"
              onChange={ev => {
                this.onFileChange(ev);
              }}
              id="fileimage"
              style={{ display: 'none' }}
            />
            {this.props.user.image ? (
              <img
                src={require('./../../../assets/images/aspect-ratio.png')}
                width="100%"
                style={{ visibility: 'hidden', margin: 0 }}
              />
            ) : (
              <div>
                <img
                  src={require('./../../../assets/images/camera.svg')}
                  width="70"
                />
                <p>
                  Arraste o arquivo .jpg ou .png
                  <br />
                  ou
                  <br />
                  Clique aqui
                  <br />
                  Max 10mb
                </p>
              </div>
            )}
          </DropToUpload>
        </div>
      ) : null;
    }
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ simpleAction, updateImage, updateAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImage);
