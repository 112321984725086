import React, { useState, useMemo, useCallback } from 'react';
import { Polar } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import 'chartjs-plugin-datalabels';

import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import './drag';

const MIN_VALUE = 1;

function Wheel({ areas, maxScore, onChange, readOnly = false }) {
  const [chartRef, setChartRef] = useState();

  const handleChartRef = useCallback(ref => setChartRef(ref));

  const data = useMemo(
    () => ({
      datasets: [{ data: areas.map(area => area.value) }],
      labels: areas.map(area => area.label),
    }),
    [areas]
  );

  const options = useMemo(() => {
    if (!chartRef) return;

    const chart = chartRef.chartInstance;

    const LAYOUT_PADDING = 20;
    return {
      dragData: !readOnly,
      dragDataRound: 0,
      onDrag: function(e) {
        e.target.style.cursor = 'grabbing';
        e.preventDefault();
      },
      onDragEnd: function(e, datasetIndex, index) {
        e.target.style.cursor = 'default';
        if (chart.data.datasets[datasetIndex].data[index] < MIN_VALUE) {
          chart.data.datasets[datasetIndex].data[index] = MIN_VALUE;
          chart.update();
        }

        if (onChange) {
          onChange(
            chart.data.labels.map((label, index) => ({
              label,
              value: chart.data.datasets[datasetIndex].data[index],
            }))
          );
        }
      },
      hover: {
        onHover: function(e) {
          const point = this.getElementAtEvent(e);
          if (point.length && !readOnly) e.target.style.cursor = 'grab';
          else e.target.style.cursor = 'default';
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scale: {
        ticks: {
          display: false,
          beginAtZero: true,
          min: 0,
          max: maxScore,
          stepSize: 1,
        },
      },
      layout: {
        padding: LAYOUT_PADDING,
      },
      plugins: {
        datalabels: {
          labels: {
            title: {
              formatter: function(value, context) {
                return context.chart.data.labels[context.dataIndex];
              },
              anchor: 'start',
              align: 'end',
              offset: chart.height / 2 - LAYOUT_PADDING,
              font: {
                family: 'latoregular',
                size: 14,
              },
            },
            value: {
              color: 'white',
              font: {
                family: 'latoregular',
                size: 12,
              },
            },
          },
        },
        colorschemes: {
          scheme: Tableau10,
        },
      },
      onResize: function() {
        chart.options.plugins.datalabels.labels.title.offset =
          chart.height / 2 - LAYOUT_PADDING;
        chart.update();
      },
    };
  }, [chartRef, maxScore, readOnly]);

  return <Polar ref={handleChartRef} data={data} options={options} />;
}

export default Wheel;
