import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/marketing/stylesMessages.scss';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../../sidebar/Sidebar';

import ReactLoading from 'react-loading';
import Switch from 'react-switch';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import PeriodiceFilter from './../../components/PeriodiceFilter';
import ModalHtml from './../../components/ModalHtml';

import NotAllowed from './../../components/NotAllowed';

import RowStats from './../../components/RowStats';

import CardGenericTable from './../../components/CardGenericTable';

import Footer from './../../components/Footer';

import Submenu from './Submenu.js';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

import BigCalendar from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';

import moment from 'moment/min/moment-with-locales';
import UploadImage from './UploadImage';

const localizer = BigCalendar.momentLocalizer(moment);

class Messages extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      subview: 'messages',
      side: 'marketing',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Marketing', link: 'dashboard/marketing/messages' },
        {
          title: 'Mensagens Personaliadas',
          link: 'dashboard/marketing/messages',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      website_name: '',
      about_me: '',
      experience: '',
      certifications: '',
      references: '',
      facebook: '',
      google_plus: '',
      linkedin: '',
      instagram: '',
      twitter: '',
      youtube: '',
      layout_model: '',
      publicated: false,
      logo: '',
      photo: '',
      message_sessions: '',
      message_proposals: '',
      message_welcome: '',
      message_share_infos: '',
      message_share_report: '',
      message_sessions_active: false,
      message_proposals_active: true,
      message_welcome_active: true,
      message_share_infos_active: true,
      message_share_report_active: true,
      alert: '',

      maxLength: 3660,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadMarketing();
    window.addEventListener('resize', this.handleResize);
    moment.locale('pt-BR');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadMarketing() {
    let result = await this.service.marketingMe();
    if (result && result.success) {
      if (result.marketing && result.marketing != 'null') {
        this.setState({
          website_name: result.marketing.website_name,
          about_me: result.marketing.about_me,
          experience: result.marketing.experience,
          certifications: result.marketing.certifications,
          references: result.marketing.references,
          facebook: result.marketing.facebook,
          google_plus: result.marketing.google_plus,
          linkedin: result.marketing.linkedin,
          instagram: result.marketing.instagram,
          twitter: result.marketing.twitter,
          youtube: result.marketing.youtube,
          layout_model: result.marketing.layout_model,
          publicated: result.marketing.publicated,

          message_sessions: result.marketing.message_sessions,
          message_proposals: result.marketing.message_proposals,
          message_welcome: result.marketing.message_welcome,
          message_share_infos: result.marketing.message_share_infos,
          message_share_report: result.marketing.message_share_report,

          message_sessions_active: result.marketing.message_sessions_active,
          message_proposals_active: result.marketing.message_proposals_active,
          message_welcome_active: result.marketing.message_welcome_active,
          message_share_infos_active:
            result.marketing.message_share_infos_active,
          message_share_report_active:
            result.marketing.message_share_report_active,

          logo: result.marketing.logo,
          photo: result.marketing.photo,

          editing_id: result.marketing._id,
          editing: true,
        });
      } else {
        this.setState({ show: true });
      }
    }
  }

  async save(typeUpdate = '') {
    let _pay = {
      website_name: this.state.website_name,
      about_me: this.state.about_me,
      experience: this.state.experience,
      certifications: this.state.certifications,
      references: this.state.references,
      facebook: this.state.facebook,
      google_plus: this.state.google_plus,
      linkedin: this.state.linkedin,
      instagram: this.state.instagram,
      twitter: this.state.twitter,
      youtube: this.state.youtube,
      layout_model: this.state.layout_model,
      publicated: this.state.publicated,

      message_sessions: this.state.message_sessions
        ? this.state.message_sessions
        : '',
      message_proposals: this.state.message_proposals
        ? this.state.message_proposals
        : '',
      message_welcome: this.state.message_welcome
        ? this.state.message_welcome
        : '',
      message_share_infos: this.state.message_share_infos
        ? this.state.message_share_infos
        : '',
      message_share_report: this.state.message_share_report
        ? this.state.message_share_report
        : '',
      message_sessions_active: this.state.message_sessions_active,
      message_proposals_active: this.state.message_proposals_active,
      message_welcome_active: this.state.message_welcome_active,
      message_share_infos_active: this.state.message_share_infos_active,
      message_share_report_active: this.state.message_share_report_active,
      logo: this.state.logo,
      photo: this.state.photo,
      typeUpdate,
      typeMarketing: 'messages',
    };

    let result = !this.state.editing
      ? await this.service.saveMarketing(_pay)
      : await this.service.updateMarketing(_pay, this.state.editing_id);

    if (result && result.success && !this.state.editing) {
      this.setState({
        editing: true,
        editing_id: result.inserted_id,
      });
      // this.props.updateAlert("Salvo com sucesso")
    }
  }

  saveLogo(logo) {
    this.setState({ logo });
    this.save('logo');
  }

  savePhoto(photo) {
    this.setState({ photo });
    this.save('photo');
  }

  async showMessage(message, typeMessage) {
    let result = await this.service.marketingTemplate({ message, typeMessage });
    if (result && result.success) {
      this.setState({ alert: result.template });
    }
  }

  loadViewImg = type => {
    if (type === 'photo') {
      this.setState({ photo: '' });
    }
    if (type === 'logo') {
      this.setState({ logo: '' });
    }
  };

  render() {
    return (
      <div className="marketing-styles-messages main-content zoomresolve">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />

                {(this.state.user &&
                  this.state.user.plans &&
                  !this.state.user.plans.platform) ||
                (this.state.user &&
                  this.state.user.plans &&
                  this.state.user.plans.platform &&
                  !this.state.user.plans.platform.plan.marketing_messages) ? (
                  <NotAllowed
                    go={this.goTo.bind(this)}
                    user={this.state.user}
                  />
                ) : (
                  <div className="dashboard-box box100-menu">
                    <Col sm={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">
                          Mensagens Personalizadas
                        </div>
                      </div>

                      <div className="modal-marketing-title">
                        Personalize suas mensagens automáticas que serão
                        enviadas para seus clientes via email,
                        profissionalizando ainda mais seu serviço.
                      </div>
                    </Col>
                    {/* Comentando conforme solicitacao via email do Marcos Lemos - 30/04/2020 */}
                    {/* <Col className="mtop-20" sm={{ size:12 }} md={{ size:8, offset:2 }}>  
                        <Col className="mtop-20" sm={{ size:12 }} md={{ size:6 }}>
                          <div className="row-center">
                            <div className="box-title">Foto</div>
                          </div>
                          <UploadImage image={ this.state.photo } loadViewImg={this.loadViewImg} idMarketing={ this.state.editing_id } saveImage={ this.savePhoto.bind(this) } upref="photo" />
                        </Col>
                        <Col className="mtop-20" sm={{ size:12 }} md={{ size:6 }}>
                          <div className="row-center">
                            <div className="box-title">Logotipo</div>  
                          </div>
                          <UploadImage image={ this.state.logo } loadViewImg={this.loadViewImg} idMarketing={ this.state.editing_id } saveImage={ this.saveLogo.bind(this) } upref="logo" />
                        </Col>  
                      </Col>

                      <Col className="mtop-20" sm={{ size:12 }} md={{ size:4, offset:4 }}>  
                        <div className="modal-marketing-title mtop-20">
                           O seu logo ou foto serão incluídas nas mensagens enviadas por email.
                        </div>  
                      </Col> */}

                    {/* <Col
                      className="mtop-30"
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                    >
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div className="modal-marketing-title mtop-10">
                          Envio de dados da Sessão Master gratuita para seu
                          potencial cliente
                        </div>
                        <div className="active-site row-center-center flex1">
                          <span>Habilitar</span>
                          <Switch
                            onChange={() => {
                              this.setState({
                                message_sessions_active: !this.state
                                  .message_sessions_active,
                              });
                              setTimeout(() => {
                                this.save();
                              }, 500);
                            }}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={"#D8D8D8"}
                            offHandleColor={"#9F9F9F"}
                            onColor={"#96CBCF"}
                            onHandleColor={"#00838F"}
                            width={40}
                            height={20}
                            checked={this.state.message_sessions_active}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 6 }}>
                        <div className="row-form mtop-10">
                          <div className="form-textarea clear-textarea">
                            <label className="blue-label" />
                            <textarea
                              value={this.state.message_sessions}
                              className="blue-input"
                              placeholder=""
                              maxLength={this.state.maxLength}
                              onBlur={() => {
                                this.save();
                              }}
                              onChange={(e) => {
                                this.setState({
                                  message_sessions: e.target.value,
                                });
                              }}
                            />
                            <span>
                              {this.state.message_sessions
                                ? this.state.message_sessions.length
                                : 0}
                              /{this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div
                          className="marketing-pre"
                          onClick={() => {
                            this.showMessage(
                              this.state.message_sessions,
                              "message_sessions"
                            );
                          }}
                        >
                          Pré-visualização
                        </div>
                      </Col>
                    </Col> */}

                    <Col
                      className="mtop-30"
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                    >
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div className="modal-marketing-title mtop-20">
                          Email para envio de proposta para seu cliente
                        </div>
                        <div className="active-site row-center-center flex1">
                          <span>Habilitar</span>
                          <Switch
                            onChange={() => {
                              this.setState({
                                message_proposals_active: !this.state
                                  .message_proposals_active,
                              });
                              setTimeout(() => {
                                this.save();
                              }, 500);
                            }}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            width={40}
                            height={20}
                            checked={this.state.message_proposals_active}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 6 }}>
                        <div className="row-form mtop-10">
                          <div className="form-textarea clear-textarea">
                            <label className="blue-label" />
                            <textarea
                              value={this.state.message_proposals}
                              className="blue-input"
                              placeholder=""
                              maxLength={this.state.maxLength}
                              onBlur={() => {
                                this.save();
                              }}
                              onChange={e => {
                                this.setState({
                                  message_proposals: e.target.value,
                                });
                              }}
                            />
                            <span>
                              {this.state.message_proposals
                                ? this.state.message_proposals.length
                                : 0}
                              /{this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div
                          className="marketing-pre"
                          onClick={() => {
                            this.showMessage(
                              this.state.message_proposals,
                              'message_proposals'
                            );
                          }}
                        >
                          Pré-visualização
                        </div>
                      </Col>
                    </Col>

                    <Col
                      className="mtop-30"
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                    >
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div className="modal-marketing-title mtop-20">
                          Email de Boas vindas para seu Coachee
                        </div>
                        <div className="active-site row-center-center flex1">
                          <span>Habilitar</span>
                          <Switch
                            onChange={() => {
                              this.setState({
                                message_welcome_active: !this.state
                                  .message_welcome_active,
                              });
                              setTimeout(() => {
                                this.save();
                              }, 500);
                            }}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            width={40}
                            height={20}
                            checked={this.state.message_welcome_active}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 6 }}>
                        <div className="row-form mtop-10">
                          <div className="form-textarea clear-textarea">
                            <label className="blue-label" />
                            <textarea
                              value={this.state.message_welcome}
                              className="blue-input"
                              placeholder=""
                              maxLength={this.state.maxLength}
                              onBlur={() => {
                                this.save();
                              }}
                              onChange={e => {
                                this.setState({
                                  message_welcome: e.target.value,
                                });
                              }}
                            />
                            <span>
                              {this.state.message_welcome
                                ? this.state.message_welcome.length
                                : 0}
                              /{this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div
                          className="marketing-pre"
                          onClick={() => {
                            this.showMessage(
                              this.state.message_welcome,
                              'message_welcome'
                            );
                          }}
                        >
                          Pré-visualização
                        </div>
                      </Col>
                    </Col>

                    <Col
                      className="mtop-30"
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                    >
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div className="modal-marketing-title mtop-20">
                          Compartilhamento de informações e/ou conteúdos
                        </div>
                        <div className="active-site row-center-center flex1">
                          <span>Habilitar</span>
                          <Switch
                            onChange={() => {
                              this.setState({
                                message_share_infos_active: !this.state
                                  .message_share_infos_active,
                              });
                              setTimeout(() => {
                                this.save();
                              }, 500);
                            }}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            width={40}
                            height={20}
                            checked={this.state.message_share_infos_active}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 6 }}>
                        <div className="row-form mtop-10">
                          <div className="form-textarea clear-textarea">
                            <label className="blue-label" />
                            <textarea
                              value={this.state.message_share_infos}
                              className="blue-input"
                              placeholder=""
                              maxLength={this.state.maxLength}
                              onBlur={() => {
                                this.save();
                              }}
                              onChange={e => {
                                this.setState({
                                  message_share_infos: e.target.value,
                                });
                              }}
                            />
                            <span>
                              {this.state.message_share_infos
                                ? this.state.message_share_infos.length
                                : 0}
                              /{this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div
                          className="marketing-pre"
                          onClick={() => {
                            this.showMessage(
                              this.state.message_share_infos,
                              'message_share_infos'
                            );
                          }}
                        >
                          Pré-visualização
                        </div>
                      </Col>
                    </Col>

                    <Col
                      className="mtop-30 mbottom-20"
                      sm={{ size: 12 }}
                      md={{ size: 12 }}
                    >
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div className="modal-marketing-title mtop-20">
                          Compartilhamento de Relatório Final
                        </div>
                        <div className="active-site row-center-center flex1">
                          <span>Habilitar</span>
                          <Switch
                            onChange={() => {
                              this.setState({
                                message_share_report_active: !this.state
                                  .message_share_report_active,
                              });
                              setTimeout(() => {
                                this.save();
                              }, 500);
                            }}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            width={40}
                            height={20}
                            checked={this.state.message_share_report_active}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 6 }}>
                        <div className="row-form mtop-10">
                          <div className="form-textarea clear-textarea">
                            <label className="blue-label" />
                            <textarea
                              value={this.state.message_share_report}
                              className="blue-input"
                              placeholder=""
                              maxLength={this.state.maxLength}
                              onBlur={() => {
                                this.save();
                              }}
                              onChange={e => {
                                this.setState({
                                  message_share_report: e.target.value,
                                });
                              }}
                            />
                            <span>
                              {this.state.message_share_report
                                ? this.state.message_share_report.length
                                : 0}
                              /{this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 12 }} md={{ size: 3 }}>
                        <div
                          className="marketing-pre"
                          onClick={() => {
                            this.showMessage(
                              this.state.message_share_report,
                              'message_share_report'
                            );
                          }}
                        >
                          Pré-visualização
                        </div>
                      </Col>
                    </Col>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ModalHtml
          alert={this.state.alert}
          close={() => {
            this.setState({ alert: '' });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
