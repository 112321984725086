import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';

import Header from './../../includes/Header';

class ForgotSuccess extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="not-found-label">
          <p className="title-bold">
            Recuperação de senha iniciada com sucesso
          </p>
          <i className="far fa-envelope big-grey-icon"></i>
          <p className="text-register-success">
            Enviamos um email pra você, verifique sua caixa de entrada
            <b> - clique no link e crie sua nova senha</b>
          </p>
        </div>
      </div>
    );
  }
}

export default connect()(ForgotSuccess);
