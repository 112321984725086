import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class TableAdd extends Component {
  // params required
  /*

    modal_contents
    list_contents
    hasContent
    toggleContent
    toggleModalContent
  */

  constructor(props, context) {
    super(props);
    this.state = {};

    this.service = new Service();
    this.utils = new Utils();

    this.hasContent = this.hasContent.bind(this);
    this.see = this.see.bind(this);
  }

  componentDidMount() { }

  componentWillUnmount() { }

  hasContent(item) {
    return this.props.contents.indexOf(item) !== -1;
  }

  see(item) {
    let url;
    if (item.doc_format.toLowerCase() === 'link') {
      url = item.doc_source;
    } else {
      url = this.utils.resolveURL(item.doc_source, this.service.getServer());
    }
    window.open(url);
  }

  render() {
    return (
      <div className="top-textarea">
        <div className="box-title top-textarea mb0">{this.props.title}</div>
        <table className="big-row table-session">
          <thead>
            <tr>
              {this.props.rows.map((item, k) => {
                return (
                  <td key={k}>
                    <div
                      className={
                        k == 0
                          ? ''
                          : k + 1 == this.props.rows.length
                            ? 'tright'
                            : 'tcenter'
                      }
                    >
                      {item.title}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.props.values.map((item, key) => {
              return (
                <tr key={key}>
                  {this.props.rows.map((ritem, k) => {
                    return ritem.key == 'remove' ? (
                      <td key={k} className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">{ritem.title}: </span>
                          <i
                            className="fas fa-times"
                            onClick={() => {
                              this.props.remove(item);
                            }}
                          ></i>
                        </div>
                      </td>
                    ) : (
                        <td key={k} className={k == 0 ? '' : 'tcenter'}>
                          <div className="div-box-row-table">
                            <span className="mobile-label">{ritem.title}: </span>
                            {this.props.linked != 'content' ? (
                              ritem.key == 'date' ? (
                                item[ritem.key] ? (
                                  this.utils.parseDateUSA(item[ritem.key])
                                ) : (
                                    'Data não definida'
                                  )
                              ) : (
                                  item[ritem.key]
                                )
                            ) : (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.see(item);
                                  }}
                                >
                                  {ritem.key == 'date'
                                    ? item[ritem.key]
                                      ? this.utils.parseDateUSA(item[ritem.key])
                                      : 'Data não definida'
                                    : item[ritem.key]}
                                </div>
                              )}
                          </div>
                        </td>
                      );
                  })}
                </tr>
              );
            })}
            <tr
              onClick={() => {
                this.props.toggle();
              }}
            >
              <td colSpan={this.props.rows.length} className="session-add">
                <i className={this.props.button_icon}></i>
                {this.props.button_label}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableAdd);
