import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import { simpleAction } from '../../../actions/SimpleAction';

class Submenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="submenu" style={{ marginTop: 20 }}>
        <ul className="side-menu">
          <li
            className={this.props.subview == 'coaches' ? 'active' : ''}
            onClick={() => {
              this.props.goTo('admin/dashboard/coachs');
            }}
          >
            <img
              src={require('./../../../assets/images/menu/icon-1.svg')}
              width="28"
            />
            <img
              src={require('./../../../assets/images/menu/icon-1w.svg')}
              width="28"
              className="image-over"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Coaches</div>
            </div>
          </li>

          <li
            className={this.props.subview == 'analytics' ? 'active' : ''}
            onClick={() => {
              this.props.goTo('admin/dashboard/analytics');
            }}
          >
            <img
              src={require('./../../../assets/images/menu/icon-analytics.svg')}
              width="28"
              alt="icon-analytics"
            />
            <img
              src={require('./../../../assets/images/menu/icon-analyticsw.svg')}
              width="28"
              className="image-over"
              alt="icon-analytics"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Analytics</div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Submenu);
