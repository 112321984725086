import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ModalContents extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      sort: '',
      selectAllCoachees: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  hasContent = item => {
    return !!this.props.selectedContents.find(
      content => content._id === item._id
    );
  };

  isCoacheeSelected = item => {
    return !!this.props.selectedCoachees.find(
      coachee => coachee._id === item._id
    );
  };

  render() {
    return (
      <div
        className={this.props.show ? 'modal-content active' : 'modal-content'}
      >
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">Selecione o conteúdo</div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.setState({ selectAllCoachees: false });
                this.props.toggleModalContent();
              }}
            ></i>
          </div>
          <div className="top-textarea modal-table-box">
            <table className="big-row table-session">
              <thead>
                <tr>
                  <td>
                    <div className="">Título</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props.sortField == 'title'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('title');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Formato</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props.sortField == 'doc_format'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('doc_format');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Categoria</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props.sortField == 'category'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('category');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Modelo</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props.sortField == 'tag'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('tag');
                        }}
                      ></i>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.props.contentList.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      className={
                        this.hasContent(item)
                          ? 'modal-select-content active'
                          : 'modal-select-content'
                      }
                      onClick={() => {
                        this.props.toggleContent(item);
                      }}
                    >
                      <td className="">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Título: </span>
                          {item.title}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Formato: </span>
                          {item.doc_format}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Categoria: </span>
                          {item.category}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Modelo: </span>
                          {item.tag}
                        </div>
                      </td>
                    </tr>
                  );
                })}

                {/*  */}
              </tbody>
            </table>
          </div>
          {this.props.coachees && this.props.coachees.length > 0 && (
            <>
              <div className="modal-row-start">
                <div className="box-title mtop-10">Adicionar para</div>
                <label className="container-ckb">
                  <input
                    type="checkbox"
                    checked={this.state.selectAllCoachees}
                    onChange={() => {
                      this.setState(
                        {
                          selectAllCoachees: !this.state.selectAllCoachees,
                        },
                        () => {
                          if (this.state.selectAllCoachees) {
                            this.props.selectAllCoachee();
                          } else {
                            this.props.selectNoneCoachee();
                          }
                        }
                      );
                    }}
                  />
                  <span className="chklbl">Selecionar Todos</span>
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="checkbox-columns-container">
                {this.props.coachees.map(coachee => (
                  <label key={coachee._id} className="container-ckb">
                    <input
                      type="checkbox"
                      checked={this.isCoacheeSelected(coachee)}
                      onChange={() => {
                        this.setState({ selectAllCoachees: false });
                        this.props.toggleCoachee(coachee);
                      }}
                    />
                    <span className="chklbl">{coachee.name}</span>
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
            </>
          )}
          <div className="modal-row-actions">
            <div
              className="bt-blue"
              onClick={() => {
                this.setState({ selectAllCoachees: false });

                this.props.share
                  ? this.props.share()
                  : this.props.toggleModalContent(true);
              }}
            >
              <img
                src={require('./../../../assets/images/attach_file.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />
              {this.props.share
                ? 'Compartilhar conteúdo'
                : 'Adicionar conteúdo'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContents);
