import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import { simpleAction } from '../../../actions/SimpleAction';

class Submenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="submenu">
        <ul className="side-menu">
          <li
            className={this.props.subview == 'site' ? 'active' : ''}
            onClick={() => this.props.goTo('dashboard/marketing/site')}
          >
            <img
              src={require('./../../../assets/images/marketing/sub-0.svg')}
              width="28"
            />
            <img
              src={require('./../../../assets/images/marketing/sub-0w.svg')}
              width="28"
              className="image-over"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Site</div>
            </div>
          </li>
          <li
            className={this.props.subview == 'messages' ? 'active' : ''}
            onClick={() => this.props.goTo('dashboard/marketing/messages')}
          >
            <img
              src={require('./../../../assets/images/marketing/sub-1.svg')}
              width="28"
            />
            <img
              src={require('./../../../assets/images/marketing/sub-1w.svg')}
              width="28"
              className="image-over"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Mensagens</div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Submenu);
