import React, { useState } from 'react';

import './styles.css';

function Accordion({ title, children, style, initialIsOpened = false }) {
  const [isOpened, setIsOpened] = useState(initialIsOpened);

  return (
    <div className="accordion-container" style={style}>
      <div
        className="accordion-title-container"
        onClick={() => setIsOpened(!isOpened)}
      >
        <i className={`${isOpened ? 'isOpened' : ''} fas fa-sort-down`} />
        <p className="accordion-title">{title}</p>
      </div>
      <div className={`accordion-content ${isOpened ? 'isOpened' : ''}`}>
        {children}
      </div>
    </div>
  );
}

export default Accordion;
