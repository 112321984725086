import React, { Component } from 'react';
import { connect } from 'react-redux';
import { simpleAction } from '../../actions/SimpleAction';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li
          className={this.props.side === 'dash' ? 'active' : ''}
          onClick={() => this.props.setSidebarLinks('')}
        >
          <img
            src={require('./../../assets/images/menu/icon-0.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-0w.svg')}
            width="28"
            className="image-over"
          />
          <span>Dashboard</span>
        </li>
        <li
          className={this.props.side === 'calendar' ? 'active' : ''}
          onClick={() => this.props.setSidebarLinks('calendar')}
        >
          <img
            src={require('./../../assets/images/menu/icon-2.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-2w.svg')}
            width="28"
            className="image-over"
          />
          <span>Agenda</span>
        </li>
        <li
          className={this.props.side === 'messages' ? 'active' : ''}
          onClick={() => this.props.setSidebarLinks('messages')}
        >
          <img
            src={require('./../../assets/images/menu/icon-3.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-3w.svg')}
            width="28"
            className="image-over"
          />
          <span>Mensagens</span>
        </li>
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
