import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/datePicker/style.scss';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { startOfToday, subYears } from 'date-fns';
// import ReactTooltip from 'react-tooltip';

import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
  modalTutorialStatus,
  modalTutorialPosicao,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';
import ModalEventTutorial from '../../components/ModalTutorial';

const InputError = ({ error, children }) => {
  return (
    <>
      {children}
      {error && <span className="campObrigatorio">{error}</span>}
    </>
  );
};

class Profile extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'profile',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados básicos', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      states: [],
      cities: [],
      loading_state: false,
      loading_cities: false,

      open_event: false,
      loadingModal: false,
      typeTela:
        localStorage.getItem('typeTela') !== undefined &&
        localStorage.getItem('typeTela') !== null
          ? parseInt(localStorage.getItem('typeTela'))
          : 1,
      validateEmail: true,

      cpfError: '',
      birthdayError: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadCities = this.loadCities.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    this.acaoModalSair();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.loadStates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadStates() {
    this.setState({ loading_state: true });
    let result = await this.service.states();
    if (result) this.setState({ loading_state: false, states: result.states });
  }

  async loadCities(state) {
    this.setState({ user: { ...this.state.user, state } });
    this.setState({ loading_cities: true });
    let result = await this.service.cities(state);
    if (result) this.setState({ loading_cities: false, cities: result.cities });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }

    this.props.updateUser(result.user);

    let user = {
      ...result.user,
      // cpf: result.user.cpf || '',
      birthday: result.user.birthday
        ? new Date(this.utils.resolveSessionDate(result.user.birthday))
        : '',
      engage_name:
        result.user.engage_name ||
        `${result.user.name || ''} ${result.user.surname || ''}`,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
      this.loadCities(user.state);
    }

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  save = async () => {
    const {
      name,
      email,
      surname,
      engage_name,
      // cpf,
      city,
      state,
      birthday,
      phone,
      site,
    } = this.state.user;

    if (this.formValid()) {
      this.setState({ loading: true });
      await this.validateEmail(email);

      if (this.state.validateEmail) {
        let user = {
          name,
          email,
          surname,
          engage_name: engage_name || '',
          location: JSON.stringify([{ state, city }]),
          contact: JSON.stringify([{ phone, site }]),
          // cpf,
          birthday: this.utils.parseDateUSA(birthday),
        };
        let result = await this.service.save(user);
        if (result.success) {
          this.props.updateAlert('Salvo com sucesso');
          this.loadUser();
        }
        if (result.status === 'Error') {
          this.props.updateAlert(result.messages[0]);
        }
      }

      this.setState({ loading: false });
    } else {
      const allFieldsFilled =
        // name && email && surname && cpf && birthday && city && state;
        name && email && surname && birthday && city && state;

      if (!allFieldsFilled) {
        this.props.updateAlert('Preencha todos os campos');
        return;
      }

      // const isCpfValid = this.utils.validateCPF(cpf);
      const isBirthdayValid = this.validateDate(birthday);

      // if (!isCpfValid) this.setState({ cpfError: 'CPF Inválido' });

      if (!isBirthdayValid)
        this.setState({ birthdayError: 'Data de Nascimento Inválida' });
    }
  };

  validateDate = date => {
    const parsedDate = new Date(
      this.utils.resolveSessionDate(this.utils.parseDateUSA(date))
    );

    return parsedDate instanceof Date && !isNaN(parsedDate);
  };

  formValid() {
    const {
      name,
      surname,
      // cpf,
      birthday,
      city,
      state,
      engage_name,
    } = this.state.user;
    const allFieldsFilled =
      // name && surname && cpf && birthday && city && state && engage_name;
      name && surname && birthday && city && state && engage_name;

    if (!allFieldsFilled) return false;

    // const isCpfValid = this.utils.validateCPF(cpf);
    const isBirthdayValid = this.validateDate(birthday);

    return isBirthdayValid;

    // return isCpfValid && isBirthdayValid;
  }

  /*******************************************************************************************************************/

  acaoModalNegativo = async () => {
    await this.setState({ open_event: false, loadingModal: false });
  };

  acaoModalSair = async () => {
    this.props.modalTutorialStatus(false);
    await this.setState({ open_event: false, loadingModal: false });
  };

  acaoModalSeguir = async () => {
    let result = this.state.typeTela + 1;
    this.props.modalTutorialPosicao(result);
    localStorage.setItem('typeTela', result);
    await this.setState({ typeTela: result });
  };

  acaoModalVoltar = async () => {
    if (this.state.typeTela >= 1) {
      let result = this.state.typeTela - 1;
      this.props.modalTutorialPosicao(result);
      localStorage.setItem('typeTela', result);
      await this.setState({ typeTela: result });
    }
  };

  preencherPerfil = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
    this.goTo('dashboard/profile');
  };

  cadastrarCoachee = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
  };

  enviarMensagem = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
  };

  realizandoUpload = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
  };

  realizandoSessao = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
  };

  escolherUpload = () => {
    this.props.modalTutorialStatus(false);
    this.setState({ open_event: false });
  };

  /*******************************************************************************************************************/

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  handleFocus = event => {
    event.target.placeholder = '';
    event.target.select();
  };

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <ModalEventTutorial
              close={() => {
                this.setState({ open_event: false });
              }}
              open={this.state.open_event}
              loadingModal={this.state.loadingModal}
              acaoModalNegativo={this.acaoModalNegativo}
              acaoModalPositivo={this.acaoModalPositivo}
              acaoModalSair={this.acaoModalSair}
              typeTela={this.state.typeTela}
              acaoModalVoltar={this.acaoModalVoltar}
              acaoModalSeguir={this.acaoModalSeguir}
              preencherPerfil={this.preencherPerfil}
              cadastrarCoachee={this.cadastrarCoachee}
              enviarMensagem={this.enviarMensagem}
              realizandoUpload={this.realizandoUpload}
              realizandoSessao={this.realizandoSessao}
              escolherUpload={this.escolherUpload}
            />

            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {this.state.user && this.state.user != null ? (
                <div className="dashboard-box box100">
                  <div className="box-row-title mobile-column">
                    <div className="box-title">Dados pessoais</div>
                    <UploadImage
                      className="up-image-resp-new"
                      user={this.state.user}
                      uuser={this.loadUser}
                      newOption={true}
                    />
                  </div>
                  <div className="content-form">
                    <div className="form-input">
                      <label className="blue-label">Nome</label>
                      <input
                        type="text"
                        value={this.state.user.name}
                        className="blue-input"
                        onChange={e => {
                          this.setState({
                            user: { ...this.state.user, name: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <div className="form-input">
                      <label className="blue-label">Sobrenome</label>
                      <input
                        type="text"
                        value={this.state.user.surname}
                        className="blue-input"
                        onChange={e => {
                          this.setState({
                            user: {
                              ...this.state.user,
                              surname: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    {/* <div className="form-input">
                      <InputError error={this.state.cpfError}>
                        <label
                          className="blue-label"
                          style={{ cursor: 'pointer' }}
                          data-tip
                          data-for="tooltip"
                        >
                          CPF <i className="fas fa-info-circle"></i>
                        </label>
                        <ReactTooltip
                          id="tooltip"
                          type="light"
                          effect="solid"
                          textColor="#757575"
                          borderColor="#B1B1B1"
                          border={true}
                        >
                          <p style={{ maxWidth: 200 }}>
                            Solicitamos seu CPF para que tenhamos um ambiente de
                            negócios mais confiável para quem procura um coach e
                            também para garantir um ambiente de igualdade entre
                            todos os participantes evitando com isso perfis
                            duplicados. Para maiores detalhes visite nossa
                            política de privacidade
                          </p>
                        </ReactTooltip>
                        <InputMask
                          mask="999.999.999-99"
                          value={this.state.user.cpf}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                cpf: e.target.value.replace(/\D/g, ''),
                              },
                              cpfError: '',
                            })
                          }
                          readOnly={this.state.user.validatedCPF}
                        >
                          {inputProps => (
                            <input
                              type="text"
                              className="blue-input"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </InputError>
                    </div> */}
                    <div className="form-input">
                      <InputError error={this.state.birthdayError}>
                        <label className="blue-label">Data de Nascimento</label>
                        <DatePicker
                          selected={this.state.user.birthday}
                          minDate={subYears(startOfToday(), 150)}
                          maxDate={startOfToday()}
                          onChange={e =>
                            this.setState({
                              user: { ...this.state.user, birthday: e },
                              birthdayError: '',
                            })
                          }
                          dateFormat="dd/MM/yyyy"
                          className="blue-input"
                          customInput={<InputMask mask="99/99/9999" />}
                        />
                      </InputError>
                    </div>
                    <div className="form-input">
                      <label className="blue-label">Email</label>
                      <input
                        type="text"
                        value={this.state.user.email}
                        className="blue-input"
                        onChange={e => {
                          this.setState({
                            user: { ...this.state.user, email: e.target.value },
                          });
                          this.validateEmail(e.target.value);
                        }}
                      />
                      {!this.state.validateEmail && (
                        <span className="campObrigatorio">
                          Campo "Email" é obrigatório e precisa ser válido
                        </span>
                      )}
                    </div>

                    <div className="form-input">
                      <label className="blue-label">
                        Como quer que seu nome apareça no engage?
                      </label>
                      <input
                        type="text"
                        value={this.state.user.engage_name}
                        placeholder="Informe o nome que irá aparecer no engage"
                        className="blue-input"
                        onFocus={this.handleFocus}
                        onBlur={e =>
                          (e.target.placeholder =
                            'Informe o nome que irá aparecer no engage')
                        }
                        onChange={e => {
                          this.setState({
                            user: {
                              ...this.state.user,
                              engage_name: e.target.value,
                            },
                          });
                        }}
                      />
                      {!this.state.user.engage_name && (
                        <span className="campObrigatorio">
                          Campo "Como quer que seu nome apareça no engage?" é
                          obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="box-row-title">
                    <div className="box-title">Localidade</div>
                  </div>
                  <div className="content-form">
                    <div className="form-input">
                      {this.state.loading_state ? (
                        <ReactLoading type="bubbles" color="#B1CD49" />
                      ) : (
                        <div className="form-input">
                          <label className="blue-label">Estado</label>
                          <select
                            value={this.state.user.state}
                            className="blue-input"
                            onChange={e => {
                              this.loadCities(e.target.value);
                            }}
                          >
                            <option value=""> &nbsp; Estado</option>
                            {this.state.states.map((item, key) => {
                              return (
                                <option key={key} value={item.sigla_uf}>
                                  {' '}
                                  &nbsp; {item.nome_uf}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="form-input">
                      {this.state.loading_cities ? (
                        <ReactLoading type="bubbles" color="#B1CD49" />
                      ) : (
                        <div className="form-input">
                          <label className="blue-label">Cidade</label>
                          <select
                            value={this.state.user.city}
                            className="blue-input"
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  city: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">&nbsp; Cidade</option>
                            {this.state.cities.map((item, key) => {
                              return (
                                <option key={key} value={item.nome_municipio}>
                                  {' '}
                                  &nbsp; {item.nome_municipio}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="box-row-title">
                    <div className="box-title">Contato</div>
                  </div>
                  <div className="content-form">
                    <div className="form-input">
                      <label className="blue-label">Telefone</label>
                      <InputMask
                        {...this.props}
                        mask="(99) 999999999"
                        value={this.state.user.phone}
                        className="blue-input"
                        placeholder="Telefone"
                        onChange={e => {
                          this.setState({
                            user: { ...this.state.user, phone: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <div className="form-input">
                      <label className="blue-label">Site</label>
                      <input
                        type="url"
                        value={this.state.user.site}
                        className="blue-input"
                        placeholder="Complete aqui com seu website caso você já possua um"
                        onChange={e => {
                          this.setState({
                            user: { ...this.state.user, site: e.target.value },
                          });
                        }}
                      />
                    </div>

                    {this.state.loading ? (
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    ) : (
                      <div
                        onClick={() => {
                          this.save();
                        }}
                        className={
                          !this.formValid()
                            ? 'bt-save hover-shadow'
                            : 'bt-save hover-shadow active'
                        }
                      >
                        Salvar Alterações
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateUser,
      updateAlert,
      modalTutorialStatus,
      modalTutorialPosicao,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
