import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import * as queryString from 'query-string';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FormGroup, Label, Input, Col } from 'reactstrap';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import Header from './../../includes/Header';
import './../../styles/css/index.css';
import ModalPolicyTerms from '../ModalPolicyTerms';

class Register extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      name: '',
      surname: '',
      email: '',
      password: '',
      cpassword: '',
      password_type: 'password',
      accepted: false,
      pass_visible: false,
      cpass_visible: false,
      loading: false,
      firstLoginPlan: 'free',
      showModalPolicy: false,
      showModalTerms: false,
    };

    this.service = new Service();
    this.utils = new Utils();
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    this.verifyLogin();
    const { plano } = queryString.parse(this.props.location.search);
    if (plano) {
      this.setState({ firstLoginPlan: plano });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  async register() {
    if (this.formValid()) {
      if (this.state.accepted) {
        this.setState({ loading: true });
        let user = {
          name: this.state.name,
          surname: this.state.surname,
          email: this.state.email,
          password: this.state.password,
          firstLoginPlan: this.state.firstLoginPlan,
        };
        let result = await this.service.register(user);
        if (result && result.status === 'Success') {
          this.goTo('register-success');
        } else {
          this.resolveError(result);
        }
      } else {
        alert('Concorde com os termos de uso e a política de privacidade');
      }
    } else {
      alert('Preencha todos os campos');
    }
  }

  async login() {
    if (this.formValid()) {
      let user = {
        email: this.state.email,
        password: this.state.password,
      };
      let result = await this.service.login(user);
      this.doLogin(result);
    } else {
      alert('Preencha todos os campos');
    }
  }

  doLogin(result, socialLogin) {
    if (result && result.success) {
      this.service.setToken(result.token);
      this.utils.setStorage('atoken', 'a');
      this.utils.setStorage('logged_user', result.user);
      if (!socialLogin) this.goTo('register-success');
      else this.goTo(`login?hash=${result.user.id}`);
    } else {
      this.resolveError(result);
      this.setState({ loading: false });
    }
  }

  formValid() {
    if (
      this.state.name.length > 0 &&
      this.state.surname.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.cpassword
    ) {
      return true;
    }
    return false;
  }

  resolveError(result) {
    if (result && result.messages && result.messages.length > 0) {
      let messages = '';
      result.messages.map((message, mkey) => {
        messages += `${message}\n`;
      });
      alert(messages);
    } else {
      alert('Tenta novamente mais tarde');
    }
    this.setState({ loading: false });
  }

  verifyLogin() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard');
    }
  }

  togglePassword() {
    if (this.state.pass_visible) {
      this.setState({ pass_visible: false });
    } else {
      this.setState({ pass_visible: true });
    }
  }

  toggleCPassword() {
    if (this.state.cpass_visible) {
      this.setState({ cpass_visible: false });
    } else {
      this.setState({ cpass_visible: true });
    }
  }

  responseFacebook = async response => {
    const success = response && response.accessToken;
    if (success) {
      const { firstLoginPlan } = this.state;
      let _payload = {
        access_token: response.accessToken,
        firstLoginPlan,
        isRegister: true,
      };
      let result = await this.service.loginFacebook(_payload);
      this.doLogin(result, true);
    }
  };

  responseLinkedIn = async response => {
    const { firstLoginPlan } = this.state;
    let _payload = {
      access_token: response.code,
      firstLoginPlan,
      isRegister: true,
    };
    let result = await this.service.loginLinkedin(_payload);
    this.doLogin(result, true);
  };

  toggleModalPolicy = () => {
    const { showModalPolicy } = this.state;
    this.setState({ showModalPolicy: !showModalPolicy });
  };

  toggleModalTerms = () => {
    const { showModalTerms } = this.state;
    this.setState({ showModalTerms: !showModalTerms });
  };

  render() {
    return (
      <div>
        <Header />
        <Col xs="12" className="mtop30">
          <div className="card-shadow">
            <p className="card-title">cadastro</p>

            <LinkedIn
              clientId={process.env.REACT_APP_LINKEDIN_ID}
              onSuccess={this.responseLinkedIn}
              redirectUri={`${process.env.REACT_APP_URL_SITE}linkedin`}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <div
                  className="bt-linkedin hover-shadow"
                  onClick={() => {
                    if (this.state.accepted) {
                      onClick();
                    } else {
                      alert(
                        'Concorde com os termos de uso e a política de privacidade'
                      );
                    }
                  }}
                  disabled={disabled}
                >
                  <p className="bt-icon">in</p>
                  <p className="bt-label">Cadastrar com Linkedin</p>
                  <p className="bt-label-hide">Linkedin</p>
                </div>
              )}
            />

            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_ID}
              callback={this.responseFacebook}
              disableMobileRedirect={true}
              render={renderProps => (
                <div
                  className="bt-facebook hover-shadow"
                  onClick={() => {
                    if (this.state.accepted) {
                      renderProps.onClick();
                    } else {
                      alert(
                        'Concorde com os termos de uso e a política de privacidade'
                      );
                    }
                  }}
                >
                  <p className="bt-icon">f</p>
                  <p className="bt-label">Cadastrar com facebook</p>
                  <p className="bt-label-hide">facebook</p>
                </div>
              )}
            />

            <input
              type="text"
              placeholder="NOME"
              value={this.state.name}
              className="cool-input"
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
            />

            <input
              type="text"
              placeholder="SOBRENOME"
              value={this.state.surname}
              className="cool-input"
              onChange={e => {
                this.setState({ surname: e.target.value });
              }}
            />

            <input
              type="email"
              placeholder="EMAIL"
              value={this.state.email}
              className="cool-input"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />

            <input
              type={this.state.pass_visible ? 'text' : 'password'}
              placeholder="SENHA"
              value={this.state.password}
              className="cool-input input-password"
              onChange={e => {
                this.setState({ password: e.target.value });
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.register();
                }
              }}
            />
            <i
              className={
                this.state.pass_visible
                  ? 'far fa-eye-slash icon-password'
                  : 'far fa-eye icon-password'
              }
              onClick={() => {
                this.togglePassword();
              }}
            />

            <input
              type={this.state.cpass_visible ? 'text' : 'password'}
              placeholder="CONFIRME SUA SENHA"
              value={this.state.cpassword}
              className="cool-input input-password"
              onChange={e => {
                this.setState({ cpassword: e.target.value });
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.register();
                }
              }}
            />
            <i
              className={
                this.state.cpass_visible
                  ? 'far fa-eye-slash icon-password'
                  : 'far fa-eye icon-password'
              }
              onClick={() => {
                this.toggleCPassword();
              }}
            />

            <div className="termos">
              <FormGroup check inline>
                <span className="agree-terms" style={{ marginLeft: '30px' }}>
                  <Label check>
                    <Input
                      style={{ marginRight: '10px' }}
                      type="checkbox"
                      value={this.state.accepted}
                      onChange={() => {
                        this.setState({ accepted: !this.state.accepted });
                      }}
                    />
                    Concordo com os
                  </Label>
                  <span
                    onClick={() => {
                      this.toggleModalTerms();
                    }}
                    className="policy highlight"
                  >
                    Termos de uso
                  </span>
                  e
                  <span
                    onClick={() => {
                      this.toggleModalPolicy();
                    }}
                    className="policy highlight"
                  >
                    Política de privacidade
                  </span>
                </span>
              </FormGroup>
            </div>
          </div>
          <div className="container-bt-confirm">
            {this.state.loading ? (
              <ReactLoading type="bubbles" color="#B1CD49" />
            ) : (
              <div
                onClick={() => {
                  this.register();
                }}
                className={
                  !this.formValid() || !this.state.accepted
                    ? 'bt-confirm hover-shadow'
                    : 'bt-confirm hover-shadow avaiable'
                }
              >
                <p className="bt-label-o">Cadastrar</p>
              </div>
            )}
          </div>
        </Col>
        <ModalPolicyTerms
          show={this.state.showModalPolicy}
          toggle={this.toggleModalPolicy}
          title={'Política de privacidade'}
          type={'politica'}
        />

        <ModalPolicyTerms
          show={this.state.showModalTerms}
          toggle={this.toggleModalTerms}
          title={'Termos de uso'}
          type={'termos'}
        />
      </div>
    );
  }
}

export default withRouter(connect()(Register));
