import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import ReactLoading from 'react-loading';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import ModalLGPD from './../components/ModalLGPD';
import './../../styles/css/index.css';

const qs = require('query-string');

class Dashboard extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      loading: true,
      messages: [],
      new_modal_user_lgpd: false,
      accepted: false,
      dataCoachee: [],
      statusCoachee: false,
      sessionParam: '',
    };
    this.utils = new Utils();
    this.service = new Service();
  }

  componentDidMount() {
    let _query = this.props.location.search;
    let params = qs.parse(_query);
    setTimeout(() => {
      this.verifyLogin(params);
    }, 1000);
  }

  componentWillUnmount() {}

  verifyLogin(params) {
    this.login(params.e, params.hash, params.s);
  }

  async login(email, password, session) {
    let result = await this.service.coacheeLogin({ email, password });
    if (result && result.success) {
      await this.setState({
        dataCoachee: result,
        statusCoachee: true,
        sessionParam: session,
      });
      this.service.setToken(result.token);
      this.utils.setStorage('atoken', 'a');
      this.utils.setStorage('logged_user', result.user);
      if (result.user && result.user.accepted) {
        this.goTo(
          session
            ? `coachees/dashboard/programs/${session}`
            : 'coachees/dashboard'
        );
      } else {
        this.toggleModalLGPD();
      }
    } else {
      if (result && result.messages && result.messages.length > 0) {
        let messages = [];
        result.messages.map((message, mkey) => {
          messages.push(message);
        });
        await this.setState({ messages, loading: false, statusCoachee: false });
      }
    }
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  concordoTermos = async () => {
    if (this.state.accepted) {
      await this.setState({ loading: true });
      this.toggleModalLGPD();
      if (
        Object.entries(this.state.dataCoachee).length > 0 &&
        this.state.dataCoachee.user
      ) {
        let result = await this.service.coacheeLoginTermo({
          accepted: this.state.accepted,
          email: this.state.dataCoachee.user.email,
          id: this.state.dataCoachee.user.id,
        });
        if (result && result.status) {
          this.goTo(
            this.state.sessionParam
              ? `coachees/dashboard/programs/${this.state.sessionParam}`
              : 'coachees/dashboard'
          );
        }
      }
    }
  };

  toggleModalLGPD = async () => {
    await this.setState({
      new_modal_user_lgpd: !this.state.new_modal_user_lgpd,
    });
  };

  termoUso = () => {
    this.toggleModalLGPD();
  };

  render() {
    return (
      <div className="main-content">
        <div className="content-login-coachee">
          <img src={logo} width={250} alt="logo" />
          {this.state.loading && this.state.accepted ? (
            <div>Aguarde, estamos autenticando você..</div>
          ) : (
            this.state.messages.map((item, key) => {
              return <div key={key}>{item}</div>;
            })
          )}
          {!this.state.accepted && this.state.statusCoachee ? (
            <>
              <p style={{ textAlign: 'center' }}>
                Para dar continuidade ao seu acesso leia e valide nossa Política
                de Privacidade e Termo de uso usando o botão abaixo.
              </p>
              <div className="container-bt-confirm" style={{ width: '20%' }}>
                <div
                  className="bt-confirm hover-shadow avaiable"
                  onClick={() => this.termoUso()}
                  style={{ width: '100%' }}
                >
                  <p className="bt-label-o">
                    Política de privacidade e Termo de uso
                  </p>
                </div>
              </div>
            </>
          ) : null}
          {this.state.loading && this.state.accepted ? (
            <ReactLoading type="bubbles" color="#00838F" />
          ) : null}
        </div>

        <ModalLGPD
          close={() => {
            this.setState({
              new_modal_user_lgpd: false,
              loading: false,
              accepted: false,
            });
          }}
          state={this.state}
          setState={this.setState.bind(this)}
          open={this.state.new_modal_user_lgpd}
          concordoTermos={this.concordoTermos.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
