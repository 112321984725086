import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import SidebarProposes from '../sidebar/SidebarProposes';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';
import RowStats from './../components/GenericRowStats';
import CardGenericArea from './../components/CardGenericArea';
import CardGenericTable from './../components/CardGenericTable';
import NotAllowed from './../components/NotAllowed';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import ModalProposalsModels from './ModalProposalsModels';
import NewModalCoacheeDelete from './../components/NewModalCoacheeDelete';
import ModalAlertTwo from './../components/ModalAlertTwo';

class Proposals extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'painel',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Painel de Oportunidades', link: 'proposals' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 30) / 100,
      areaData: [],
      user: {},
      rows: [],
      title_rows: [
        { title: 'Data', key: 'date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Título da proposta', key: 'description' },
        { title: 'Origem', key: 'origin' },
        { title: 'Ações', key: 'options' },
      ],
      actions: [
        {
          title: 'Promover para sessão master',
          svg: {
            icon: require('./../../assets/images/tables/follow.svg'),
            width: 18,
          },
          action: this.promove.bind(this),
        },
        {
          title: 'Gerar uma proposta',
          svg: {
            icon: require('./../../assets/images/tables/edit_p.svg'),
            width: 18,
          },
          action: this.generateProposal.bind(this),
        },
        // {
        //   title: "Editar",
        //   svg: {
        //     icon: require("./../../assets/images/tables/edit.svg"),
        //     width: 18
        //   },
        //   action: this.edit.bind(this)
        // },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeOportunity.bind(this),
        },
      ],
      rows2: [],
      title_rows2: [
        { title: 'Data', key: 'date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Nº da proposta', key: 'number' },
        // { title: "Email", key: "email" },
        // { title: "Telefone", key: "phone" },
        { title: 'Título da proposta', key: 'title' },
        { title: 'Valor', key: 'value' },
        { title: 'Origem', key: 'origin' },
        { title: 'Ações', key: 'options' },
        { title: 'Resposta', key: '_response' },
      ],
      actions2: [
        {
          title: 'Visualizar',
          svg: {
            icon: require('./../../assets/images/tables/eye.svg'),
            width: 18,
          },
          action: this.editProposal.bind(this),
        },
        // { title:"Abrir no Editor", svg:{ icon: require('./../../assets/images/tables/editor.svg'), width:18 }, action: this.editProposal.bind(this) },
        // {
        //   title: "Editar",
        //   svg: {
        //     icon: require("./../../assets/images/tables/edit.svg"),
        //     width: 18
        //   },
        //   action: this.editProposalOp.bind(this)
        // },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeProposal.bind(this),
        },
      ],
      response_actions2: [
        { action: this.proposalYes.bind(this) },
        { action: this.proposalNo.bind(this) },
      ],
      rows3: [],
      title_rows3: [
        { title: 'Data', key: 'date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Nº da proposta', key: 'number' },
        // { title: "Email", key: "email" },
        // { title: "Telefone", key: "phone" },
        { title: 'Título da proposta', key: 'title' },
        { title: 'Valor', key: 'value' },
        { title: 'Origem', key: 'origin' },
        { title: 'Ações', key: 'options' },
      ],
      actions3: [
        {
          title: 'Cadastrar Coachee',
          svg: {
            icon: require('./../../assets/images/tables/add_coachee.svg'),
            width: 22,
          },
          action: this.createCoachee.bind(this),
          hidden: 'coachee',
        },
        {
          title: 'Ver PDF',
          svg: {
            icon: require('./../../assets/images/tables/pef.svg'),
            width: 17,
          },
          action: this.seePDF.bind(this),
        },
        // {
        //   title: "Editar",
        //   svg: {
        //     icon: require("./../../assets/images/tables/edit.svg"),
        //     width: 18
        //   },
        //   action: this.editProposalOp.bind(this)
        // },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeProposal.bind(this),
        },
      ],
      rows_sessions: [],
      title_rows_sessions: [
        { title: 'Data', key: 'expected_date' },
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Projeto', key: 'project' },
        { title: 'Ações', key: 'options' },
      ],
      actions_sessions: [
        {
          title: 'Gerar uma proposta',
          svg: {
            icon: require('./../../assets/images/tables/edit_p.svg'),
            width: 18,
          },
          action: this.generateProposalOportunity.bind(this),
        },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeSession.bind(this),
        },
      ],

      loading_contents: false,
      list_contents: [],
      contents: [],
      oportunity_id: '',
      session_id: '',
      paction_loading: '',
      sort: '',
      sort1: '',
      sort2: '',
      sort3: '',

      new_modal_coachee_delete: false,
      content_share: [],
      nameCoachee: '',
      removeType: 0,
      statusModalAlert: false,
      statusGenerateProposal: false,
      statusGenerateOportunity: false,
      titleAlert: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.toggleModalContent = this.toggleModalContent.bind(this);
    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadOportunities();
    this.loadProposals();
    this.loadSessions();

    this.loadContents();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  edit(item) {
    this.setSidebarLinks(`proposals/oportunity/register/${item._id}`);
  }

  editProposalOp(item) {
    this.setSidebarLinks(`proposals/oportunity/register/${item.oportunity_id}`);
  }

  editProposal(item) {
    this.setSidebarLinks(`proposals/proposal/register/${item._id}`);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let periodicy = this.utils.getStorage('periodicy-filter')
      ? this.utils.getStorage('periodicy-filter')
      : this.props.periodicy;
    let result = await this.service.me(periodicy, true);

    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadOportunities() {
    let result = await this.service.oportunities();
    if (result && result.success) {
      this.setState({ rows: result.oportunities });
    }
  }

  async loadSessions() {
    let result = await this.service.sessions();
    if (result && result.success) {
      let rows_sessions = result.sessions.filter(item => {
        return item.its_free;
      });
      this.setState({ rows_sessions });
    }
  }

  async loadProposals() {
    let result = await this.service.proposals();
    if (result && result.success) {
      let generated = result.proposals;
      generated = generated.filter(item => {
        return (
          (item.status == 'emitted' || item.status == 'opened') &&
          (item.response == '' || item.response == 'no')
        );
      });
      let owns = result.proposals;
      owns = owns.filter(item => {
        return item.response == 'yes';
      });
      this.setState({ rows2: generated, rows3: owns });
    }
  }

  async loadContents() {
    this.setState({ loading_contents: true });
    let result = await this.service.modelProposals();
    if (result && result.success) {
      this.setState({ list_contents: result.models_proposals });
    }
    this.setState({ loading_contents: false });
  }

  async removeOportunity(item) {
    await this.setState({
      content_share: item,
      nameCoachee: item.name,
      removeType: 1,
    });
    this.toggleModalDelete();
  }

  async removeProposal(item) {
    await this.setState({
      content_share: item,
      nameCoachee: item.name,
      removeType: 2,
    });
    this.toggleModalDelete();
  }

  async removeSession(item) {
    await this.setState({
      content_share: item,
      nameCoachee: item.name,
      removeType: 3,
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    if (this.state.removeType === 1) {
      let result = await this.service.removeOportunity(
        this.state.content_share._id
      );
      if (result && result.success) {
        this.loadOportunities();
      }
    } else if (this.state.removeType === 2) {
      let result = await this.service.removeProposal(
        this.state.content_share._id
      );
      if (result && result.success) {
        this.loadProposals();
      }
    } else if (this.state.removeType === 3) {
      let result = await this.service.removeSession(
        this.state.content_share._id
      );
      if (result && result.success) {
        this.loadSessions();
      }
    }
    this.loadUser();
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      new_modal_coachee_delete: !this.state.new_modal_coachee_delete,
    });
  }

  async proposalYes(id) {
    let _pay = {
      response: 'yes',
    };
    let result = await this.service.updateProposal(_pay, id);
    if (result && result.success) {
      this.loadProposals();
      this.loadSessions();
      this.loadUser();
    }
    this.props.updateAlert(
      'Parabéns! Seu cliente aceitou a proposta e agora foi promovido para o último passo: Propostas Ganhas!!'
    );
  }

  async proposalNo(id) {
    let _pay = {
      response: 'no',
    };
    let result = await this.service.updateProposal(_pay, id);
    if (result && result.success) {
      this.loadProposals();
      this.loadSessions();
      this.loadUser();
    }
    this.props.updateAlert(
      'Que pena que seu cliente não aceitou a proposta. Ele será mantido neste passo até que você decida deletá-lo.'
    );
  }

  async promove(item, action_loading) {
    await this.setState({ action_loading });

    await this.service.createSession({
      coachee_id: '',
      oportunity_id: item._id,
      its_free: true,
    });

    this.loadOportunities();
    this.loadSessions();
    this.loadUser();

    this.setState({ action_loading: false });
    this.props.updateAlert(
      'Seu cliente agora está no próximo passo do funil de vendas: Sessão Master Gratuita'
    );
  }

  async generateProposal(item, action_loading) {
    if (this.state.list_contents.length > 0) {
      this.toggleModalContent();
      this.setState({
        oportunity_id: item._id,
        origin: item.origin,
        paction_loading: action_loading,
      });
    } else {
      this._saveProposal({ oportunity_id: item._id, origin: item.origin });
    }
    await this.setState({
      titleAlert:
        'Seu cliente avançou 2 passos no funil e agora encontra-se em Propostas Emitidas!',
    });
  }

  async generateProposalOportunity(item, action_loading) {
    if (this.state.list_contents.length > 0) {
      this.toggleModalContent();
      this.setState({
        oportunity_id: item.oportunity_id,
        paction_loading: action_loading,
        session_id: item._id,
      });
    } else {
      this._saveProposal({
        oportunity_id: item.oportunity_id,
        origin: item.origin,
      });
    }
    await this.setState({
      titleAlert:
        'Seu cliente avançou no funil de vendas e agora encontra-se em Propostas Emitidas!',
    });
  }

  async _generateProposal() {
    if (this.state.statusGenerateProposal) {
      if (this.state.contents.length > 0) {
        await this.setState({ action_loading: this.state.paction_loading });
        let models_proposal_id = this.state.contents[0]._id;
        let _pay = {
          oportunity_id: this.state.oportunity_id,
          models_proposal_id,
          origin: this.state.origin,
        };
        if (this.state.session_id) {
          _pay['session_id'] = this.state.session_id;
        }
        this._saveProposal(_pay);
        await this.setState({
          contents: [],
          session_id: '',
          statusGenerateProposal: false,
        });
      }
      this.toggleModalContent();
    } else {
      await this.setState({
        statusModalAlert: true,
      });
    }
  }

  async _saveOportunity(_pay) {
    let result = await this.service.saveOportunity(_pay);
    if (result && result.success) {
      return result;
    }
    return false;
  }

  getProposalByOportunityId(oportunity_id) {
    for (let _op of this.state.rows2) {
      if (_op.oportunity_id == oportunity_id) {
        return _op;
      }
    }
    return false;
  }

  async _saveProposal(_pay) {
    let proposal = this.getProposalByOportunityId(_pay.oportunity_id);

    let result = !proposal
      ? await this.service.saveProposal(_pay)
      : await this.service.updateProposal(_pay, proposal._id);

    if (result && result.success) {
      let _id = !proposal ? result.inserted_id : proposal._id;
      this.goTo(`dashboard/proposals/proposal/register/${_id}`);
    }
  }

  toggleContent(item) {
    let contents = [];
    contents.push(item);
    this.setState({ contents });
  }

  toggleModalContent() {
    this.setState({ modal_contents: !this.state.modal_contents });
  }

  async createCoachee(item, action_loading) {
    await this.setState({ action_loading });

    let _pay = {
      name: item.name,
      email: item.email,
      phone: item.phone,
      address: '',
      birthday: '',
      status: true,
      promove: item.oportunity_id,
    };
    let result = await this.service.saveCoachee(_pay);
    this.setState({ action_loading: false });
    if (result && result.success) {
      this.props.updateAlert(
        'Seu cliente agora é seu coachee!!! O link para que ele possa acessar a plataforma foi enviado! Sucesso no seu processo!!'
      );
    } else {
      const message = result.email_already_registered
        ? 'E-mail já cadastrado para outro coachee'
        : 'Você atingiu o limite máximo de cadastros permitidos para o seu plano, aumente seu plano ou remova um coachee para continuar';
      this.props.updateAlert(
        <div>
          <p>{message}</p>
          {!result.email_already_registered && (
            <div>
              <br />
              <div
                className="bt-blue"
                style={{ margin: '0 auto' }}
                onClick={() => {
                  this.goTo('dashboard/profile/plans');
                }}
              >
                Alterar plano
              </div>
            </div>
          )}
        </div>
      );
    }

    this.loadProposals();
    this.loadUser();

    // this.goTo(`dashboard/coachees/create` )
  }

  seePDF(item) {
    window.open(`${this.service.getServer()}pdf/proposals/${item._id}`);
  }

  sort(key) {
    let order = this.state.sort == '' || this.state.sort == key ? '-down' : '';

    let list_contents = this.state.list_contents.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort: `${key}${order}`, list_contents });
  }
  sort1(key) {
    let order =
      this.state.sort1 == '' || this.state.sort1 == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort1: `${key}${order}`, rows });
  }
  sort2(key) {
    let order =
      this.state.sort2 == '' || this.state.sort2 == key ? '-down' : '';

    let rows2 = this.state.rows2.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort2: `${key}${order}`, rows2 });
  }
  sort3(key) {
    let order =
      this.state.sort3 == '' || this.state.sort3 == key ? '-down' : '';

    let rows3 = this.state.rows3.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort3: `${key}${order}`, rows3 });
  }

  sort4(key) {
    let order =
      this.state.sort4 == '' || this.state.sort4 == key ? '-down' : '';

    let rows_sessions = this.state.rows_sessions.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort4: `${key}${order}`, rows_sessions });
  }

  closeAlert = async () => {
    await this.setState({
      statusModalAlert: false,
      statusGenerateProposal: true,
      titleAlert: '',
    });
    this._generateProposal();
  };

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs
                  breadcrumbs={this.state.breadcrumbs}
                  customClass={'dash-breadcrumbs custom'}
                />
                <PeriodiceFilter
                  filtred={() => {
                    this.loadUser();
                  }}
                  filter={'Semestral'}
                />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan
                  .use_oportunities_proposals) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div>
                  <RowStats
                    user={this.state.user}
                    folder={'dash'}
                    stats={
                      this.state.user.stats_proposals
                        ? this.state.user.stats_proposals
                        : []
                    }
                  />

                  <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericArea
                          id="area-dashboard-1"
                          title="Desempenho na busca"
                          labels={[
                            'Aparições nas busca',
                            'Cliques no perfil',
                            'Cliques no contato',
                          ]}
                          windowWidth={this.state.windowWidth}
                          areaData={
                            this.state.user.clicks ? this.state.user.clicks : []
                          }
                        />
                      </div>
                    </Col>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="proposal-dashboard-1"
                          sort={this.sort1.bind(this)}
                          _sort={this.state.sort1}
                          action_loading={this.state.action_loading}
                          action_loading_key={'oportunities'}
                          title="Oportunidades"
                          actions={this.state.actions}
                          title_rows={this.state.title_rows}
                          rows={this.state.rows}
                          edit={this.edit.bind(this)}
                          campoImagem={false}
                          campoNome={true}
                          campoEmail={true}
                        />
                      </div>
                    </Col>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="proposal-dashboard-2"
                          sort={this.sort4.bind(this)}
                          _sort={this.state.sort4}
                          action_loading={this.state.action_loading}
                          action_loading_key={'sessions'}
                          title="Sessão master gratuita"
                          actions={this.state.actions_sessions}
                          title_rows={this.state.title_rows_sessions}
                          rows={this.state.rows_sessions}
                          edit={this.editProposalOp.bind(this)}
                          campoImagem={false}
                          campoNome={true}
                          campoEmail={true}
                        />
                      </div>
                    </Col>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="proposal-dashboard-3"
                          sort={this.sort2.bind(this)}
                          _sort={this.state.sort2}
                          action_loading={this.state.action_loading}
                          action_loading_key={'proposals_generated'}
                          title="Propostas Geradas"
                          proposal="proposal"
                          actions={this.state.actions2}
                          response_actions={this.state.response_actions2}
                          title_rows={this.state.title_rows2}
                          rows={this.state.rows2}
                          edit={this.editProposalOp.bind(this)}
                          campoImagem={false}
                          campoNome={true}
                          campoEmail={true}
                        />
                      </div>
                    </Col>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="proposal-dashboard-4"
                          sort={this.sort3.bind(this)}
                          _sort={this.state.sort3}
                          action_loading={this.state.action_loading}
                          action_loading_key={'proposals_owned'}
                          title="Propostas Ganhas"
                          actions={this.state.actions3}
                          title_rows={this.state.title_rows3}
                          rows={this.state.rows3}
                          edit={this.editProposalOp.bind(this)}
                          campoImagem={false}
                          campoNome={true}
                          campoEmail={true}
                        />
                      </div>
                    </Col>
                  </div>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        <ModalProposalsModels
          state={this.state}
          sort={this.sort.bind(this)}
          _sort={this.state.sort}
          modal_contents={this.state.modal_contents}
          list_contents={this.state.list_contents}
          contents={this.state.contents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          useProposal={this._generateProposal.bind(this)}
        />

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_coachee_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_coachee_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />

        <ModalAlertTwo
          closeAlert={this.closeAlert.bind(this)}
          open={this.state.statusModalAlert}
          titleAlert={`${this.state.titleAlert}`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Proposals)
);
