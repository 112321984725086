import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 



 
              <div className="dash-stats-content">

                <div className="dash-stats">
                  <div className="stats-title">Sessões realizadas</div>
                  <div className="stats-info">
                    <div>{ this.props.user.stats ? this.props.user.stats.sessions : 0 }</div>
                    <div><img src={ require('./../../assets/images/dash/2.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Aparições na Busca</div>
                  <div className="stats-info">
                    <div>{ this.props.user.stats ? this.props.user.stats.views : 0 }</div>
                    <div><img src={ require('./../../assets/images/dashboard/2.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Tarefas em andamento</div>
                  <div className="stats-info">
                    <div>{ this.props.user.stats ? this.props.user.stats.tasks : 0 }</div>
                    <div><img src={ require('./../../assets/images/dash/3.svg') } className="stats-image" /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Coachees</div>
                  <div className="stats-info">
                    <div>{ this.props.user.stats ? this.props.user.stats.coachees : 0 }</div>
                    <div><img src={ require('./../../assets/images/menu/icon-1.svg') } className="stats-image" style={{ width:32, marginTop:3 }} /></div>
                  </div>
                </div>

                <div className="dash-stats">
                  <div className="stats-title">Coachees Ativos</div>
                  <div className="stats-info">
                    <div>{ this.props.user.stats ? this.props.user.stats.active_coachees : 0 }</div>
                    <div><img src={ require('./../../assets/images/dashp/1.svg') } className="stats-image" /></div>
                  </div>
                </div>
 
              </div>

 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);