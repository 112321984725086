import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Alert,
} from 'reactstrap';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import Header from './../../includes/Header';
import './../../styles/css/index.css';

class Forgot extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      loading: false,
    };

    this.service = new Service();
    this.utils = new Utils();
    this.forgot = this.forgot.bind(this);
  }

  componentDidMount() {
    this.verifyLogin();
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  async forgot() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let user = {
        email: this.state.email,
      };
      let result = await this.service.forgot(user);
      if (result && result.status == 'Success') {
        this.goTo('forgot-success');
      } else {
        this.resolveError(result);
        this.setState({ loading: false });
      }
    } else {
      alert('Preencha todos os campos');
    }
  }

  formValid() {
    if (this.state.email.length > 0) {
      return true;
    }
    return false;
  }

  resolveError(result) {
    if (result && result.messages && result.messages.length > 0) {
      let messages = '';
      result.messages.map((message, mkey) => {
        messages += `${message}\n`;
      });
      alert(messages);
    } else {
      alert('Tenta novamente mais tarde');
    }
    this.setState({ loading: false });
  }

  verifyLogin() {
    if (this.utils.isLogged()) {
      this.goTo('dashboard');
    }
  }

  togglePassword() {
    if (this.state.pass_visible) {
      this.setState({ pass_visible: false });
    } else {
      this.setState({ pass_visible: true });
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Col xs="12" className="mtop30">
          <div className="card-shadow">
            <p className="card-title">Recuperar senha</p>

            <input
              type="email"
              placeholder="EMAIL"
              value={this.state.email}
              className="cool-input"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />

            <div className="termos" style={{ marginLeft: 15 }}>
              <Link to="login" className="policy highlight">
                Voltar
              </Link>
            </div>
          </div>
          <div className="container-bt-confirm">
            {this.state.loading ? (
              <ReactLoading type="bubbles" color="#B1CD49" />
            ) : (
              <div
                onClick={() => {
                  this.forgot();
                }}
                className={
                  !this.formValid()
                    ? 'bt-confirm hover-shadow'
                    : 'bt-confirm hover-shadow avaiable'
                }
              >
                <p className="bt-label-o">Recuperar</p>
              </div>
            )}
          </div>
        </Col>
      </div>
    );
  }
}

export default withRouter(connect()(Forgot));
