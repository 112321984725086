import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction, updateImage, updateMenu } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 
import Service from  './../../services/ApiService.js'; 

class NotificationBox extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
    this.service = new Service();  

    this.state = { }
 
  }

  componentDidMount(){
  }

  componentWillReceiveProps(nextProps){   
  }

  componentWillUnmount() {  
  } 

  render() {  
    return (    
                    <div className="box-notifications">

                      {
                        this.props.notifications ? 
                          this.props.notifications.length > 0 ?
                            this.props.notifications.map((item, key) =>{
                              return  <div key={ key } className="notification-item">
                                        <div style={{ textAlign:'right', cursor: 'pointer' }} onClick={ () => this.props.closeNotification(item) }>x</div>
                                        <div className="row-notification">
                                          {
                                            item.action_number ?
                                              <div className="ball-notification">
                                                <div style={{ fontSize:20 }}>{ item.action_number }</div>
                                                {/*<div style={{ fontSize:14 , marginTop:-5}}>dias</div>*/}
                                              </div> : null
                                          }
                                          <div className="text-notification">
                                            { item.text }
                                          </div>
                                        </div>
                                        <div className="row-notification-action">
                                          <div className="notification-action" onClick={ () => { this.props.open(item) } }>{ item.action_label }</div>
                                        </div>
                                      </div>
                            })
                          : <div className="notification-item">
                              <div className="row-notification"> 
                                <div className="ball-notification">
                                  <div style={{ fontSize:20 }}>0</div> 
                                </div>
                                <div className="text-notification">
                                  Nenhuma novidade
                                </div>
                              </div> 
                            </div> 
                        : <div className="notification-item">
                              <div className="row-notification"> 
                                <div className="ball-notification">
                                  <div style={{ fontSize:20 }}>0</div> 
                                </div>
                                <div className="text-notification">
                                  Nenhuma novidade
                                </div>
                              </div> 
                            </div>  
                      } 

                    </div>
 
    );
  }
}
const mapStateToProps = state => ({ 
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu
})
 
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBox);