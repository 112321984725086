import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import ServicePlans from './../Plans/Service.js';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';

import { Col } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';

import Sidebar from '../../sidebar/Sidebar';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';

class Form extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.servicePlans = new ServicePlans();

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'combo',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Combos de Planos', link: 'combos' },
        { title: 'Cadastrar Combo', link: 'combos/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [],
      plans: [],
      periodice: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  getItems = () => [
    { label: 'Título', key: 'title', type: 'text' },
    {
      label: 'Identificador do combo (Checkout)',
      key: 'iugu_plan_identifier',
      type: 'text',
    },
    {
      label: 'Periodicidade',
      key: 'periodice',
      type: 'select',
      options: [
        { _id: '1m', name: 'Mensal' },
        { _id: '12m', name: 'Anual' },
      ],
      loading: false,
      opt_key: '_id',
      opt_value: 'name',
    },
    {
      label: 'Plano Buscador',
      key: 'searcher_plan_id',
      type: 'select',
      options: this.getPlans('seacher', '1m'),
      loading: false,
      opt_key: '_id',
      opt_value: 'title',
      show: { key: 'periodice', value: '1m' },
    },
    {
      label: 'Plano Buscador',
      key: 'searcher_plan_id',
      type: 'select',
      options: this.getPlans('seacher', '12m'),
      loading: false,
      opt_key: '_id',
      opt_value: 'title',
      show: { key: 'periodice', value: '12m' },
    },
    { label: 'Preço Buscador', key: 'searcher_price', type: 'money-mask' },
    {
      label: 'Plano Plataforma',
      key: 'platform_plan_id',
      type: 'select',
      options: this.getPlans('platform', '1m'),
      loading: false,
      opt_key: '_id',
      opt_value: 'title',
      show: { key: 'periodice', value: '1m' },
    },
    {
      label: 'Plano Plataforma',
      key: 'platform_plan_id',
      type: 'select',
      options: this.getPlans('platform', '12m'),
      loading: false,
      opt_key: '_id',
      opt_value: 'title',
      show: { key: 'periodice', value: '12m' },
    },
    { label: 'Preço Plataforma', key: 'platform_price', type: 'money-mask' },
    { label: 'Preço Total', key: 'total_price', type: 'money-mask' },
    {
      label: 'Ativo',
      offlabel: 'Inativo',
      key: 'active',
      type: 'checkbox',
    },
  ];

  getPlans(type, periodice) {
    return this.state.plans
      .filter(item => item.type === type && item.periodice === periodice)
      .map(plan => ({
        _id: plan._id,
        title: plan.title,
      }));
  }

  loadPlans = async () => {
    let result = await this.servicePlans.read();
    if (result && result.success) {
      this.setState({
        plans: result.plans,
      });
    }
  };

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Combo de Plano',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.removePassword();
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    } else {
      this.setState({
        content: { periodice: '1m', active: true, total_price: 0 },
      });
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
    this.loadPlans();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  calcTotalPrice(searcherPrice = 'R$ 0,00', platformPrice = 'R$ 0,00') {
    return (
      parseFloat(this.utils.clearValue(searcherPrice)) +
      parseFloat(this.utils.clearValue(platformPrice))
    );
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      const combo = result[this.state.view];
      this.setState({
        content: {
          _id: combo._id,
          title: combo.title,
          periodice: combo.searcher.plan.periodice,
          iugu_plan_identifier: combo.iugu_plan_identifier,
          searcher_plan_id: combo.searcher.plan_id,
          searcher_price: combo.searcher.price,
          platform_plan_id: combo.platform.plan_id,
          platform_price: combo.platform.price,
          total_price: this.calcTotalPrice(
            combo.searcher.price,
            combo.platform.price
          ),
          active: combo.active,
        },
      });
    }
    this.setState({ loading_contents: false });
  }

  async save() {
    this.setState({ loading: true });
    let _pay = { ...this.state.content };

    _pay.title = _pay.title ? _pay.title : '';
    _pay.iugu_plan_identifier = _pay.iugu_plan_identifier
      ? _pay.iugu_plan_identifier
      : '';
    _pay.searcher_price = _pay.searcher_price ? _pay.searcher_price : 'R$ 0,00';
    _pay.platform_price = _pay.platform_price ? _pay.platform_price : 'R$ 0,00';

    let result = this.state.editing
      ? await this.service.update(_pay, this.state.editing_id)
      : await this.service.create(_pay);

    if (result && result.success) {
      this.setSidebarLinks(this.state.breadcrumbs[1].link);
    }
    this.setState({ loading: false });
  }

  removePassword() {
    let items = this.state.items;
    for (let _k in items) {
      if (items[_k].type == 'password') {
        items.splice(_k, 1);
      }
    }
    this.setState({ items });
  }

  componentDidUpdate(prevProps, prevState) {
    const totalPrice = this.calcTotalPrice(
      this.state.content.searcher_price,
      this.state.content.platform_price
    );
    if (prevState.content.total_price !== totalPrice) {
      this.setState({
        content: { ...this.state.content, total_price: totalPrice },
      });
    }

    const plansChanged =
      JSON.stringify(this.state.plans) !== JSON.stringify(prevState.plans);
    if (plansChanged) {
      this.setState({ items: this.getItems() });
    }

    const periodiceChanged =
      this.state.content.periodice &&
      this.state.content.periodice !== this.state.periodice;
    if (periodiceChanged && this.state.plans.length > 0) {
      const searcherPlans = this.getPlans(
        'seacher',
        this.state.content.periodice
      );
      const searcherPlan = searcherPlans.find(
        plan => plan._id === this.state.content.searcher_plan_id
      );
      const platformPlans = this.getPlans(
        'platform',
        this.state.content.periodice
      );
      const platformPlan = platformPlans.find(
        plan => plan._id === this.state.content.platform_plan_id
      );
      const searcher_plan_id =
        (searcherPlan && searcherPlan._id) ||
        (searcherPlans[0] && searcherPlans[0]._id);
      const platform_plan_id =
        (platformPlan && platformPlan._id) ||
        (platformPlans[0] && platformPlans[0]._id);
      this.setState({
        periodice: this.state.content.periodice,
        content: {
          ...this.state.content,
          searcher_plan_id,
          platform_plan_id,
        },
      });
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                    />

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
