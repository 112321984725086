import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { simpleAction, updateImage, updateMenu, updateAlert } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js';
import Service from './../../services/ApiService.js';

import NotificationBox from './../../screens_dashboard/components/NotificationBox'
import ModalAlert from './../../screens_dashboard/components/ModalAlert'

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.service = new Service();

    this.state = {
      expression: "",
      visible: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.search = this.search.bind(this)
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      expression: nextProps.expression
    })
  }

  componentWillUnmount() {
    this.props.updateAlert('')
  }

  toggleMenu() {
    this.props.updateMenu(!this.props.menu)
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  search() {
    if (this.props.search) {
      setTimeout(() => {
        this.props.search()
      }, 100)
    }
    // this.props.goTo(`admin/dashboard/search/${ this.state.expression }`) 
  }

  toggleNotifications() {
    // this.setState({ visible: !this.state.visible })
  }

  render() {
    return (
      <div className='header-climb menuClimby' >
        <div className={this.props.menu ? "header-logo-closed" : "header-logo"}>
          {this.props.menu ?
            <img
              src={require('./../../assets/images/header-logo-close.png')}
              className="header-web-container" />
            :
            <img
              src={require('./../../assets/images/header-logo-open.png')}
              className="header-web-container" />
          }
          <img
            src={require('./../../assets/images/header-logo-close.png')}
            className="header-mobile-container" />
        </div>
        <div className={this.props.menu ? "header-content-closed" : "header-content"} >
          <div className="hcenter header-web-container">
            {this.props.menu ?
              <img
                src={require('./../../assets/images/header-chevron-close.png')}
                onClick={() => { this.toggleMenu() }} />
              :
              <img
                src={require('./../../assets/images/header-chevron-open.png')}
                onClick={() => { this.toggleMenu() }} />
            }
            <span className="header-title">Admin</span>
          </div>
          <div className="hcenter header-web-minor">
            {/*<input type="text" 
                placeholder="Pesquisar" 
                className="header-search"
                name="search"
                value={ this.state.expression } 
                onChange={ (e) => { this.setState({ expression: e.target.value }) } }
                            onKeyPress={ (e) => { if (e.key === 'Enter') { this.search() }}} />
              <i className="fas fa-search header-search-icon" onClick={ () => { this.search() } }></i> */}
          </div>
          <div className="hcenter header-user-content">
            {/*{
                false ? <div className="notification-badge">2</div> : null
              }
              <i className="far fa-bell header-bell-icon" onClick={ () => { this.toggleNotifications() } }></i>*/}
            <div
              onClick={() => { this.props.goTo('admin/dashboard/profile') }}
              className="header-user"
              style={
                {
                  background: `url(${!this.props.image ?
                    require('./../../assets/images/header-user.png')
                    : this.resolveImage(this.props.image)
                    }) center center / cover`
                }

              } ></div>


            {this.state.visible ? <NotificationBox /> : null}

          </div>
        </div>
        <ModalAlert />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateMenu, updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);