import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateAlert, updateImage } from '../../../actions/SimpleAction';
import SidebarProposes from './../../sidebar/SidebarProposes';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import 'react-datepicker/dist/react-datepicker.css';
import DropToUpload from 'react-drop-to-upload';

const validTypes = '.xls,.xlsx,.XLS,.XLSX';
const validTypesArray = ['xls', 'xlsx', 'XLS', 'XLSX'];

class OportunityCreateBatch extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'register',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        {
          title: 'Importação',
          link: 'proposals/oportunity/create_batch',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,
      fileData: null,
      importErrors: null,
    };

    this.utils = new Utils();
    this.service = new Service();
  }

  toggleMenu = () => {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  };

  togglePrincipal = () => {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  };

  goTo = page => {
    this.props.history.push(`/${page}`);
  };

  verifyLogin = () => {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  };

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks = view => {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  };

  handleResize = () => {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  };

  logout = () => {
    this.utils.setLogout();
    this.goTo('');
  };

  loadUser = async () => {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  };

  onFileChange = event => {
    if (event.target.files && event.target.files.length > 0) {
      this.parseFile(event.target.files[0]);
    }
  };

  parseFile = async file => {
    this.setState({ loading: true });

    const nextState = { loading: false }; //To return in the end

    let fileExtension = file.name.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];

    if (validTypesArray.includes(fileExtension)) {
      const imported = await this.utils.excelToJson(file);
      const startLine = 1; //remove example
      const oportunities = [];
      const importErrors = [];

      imported.slice(startLine).forEach((i, index) => {
        const opportunity = this.toOpportunity(i, index + 3, importErrors); //line = index + 3 (line starts in 3 in Excel because of the header and example)
        if (opportunity) {
          oportunities.push(opportunity);
        }
      });

      nextState.fileData = oportunities.length > 0 ? oportunities : null;
      nextState.importErrors = importErrors.length > 0 ? importErrors : null;
    }

    this.setState(nextState);
  };

  toOpportunity = (json, line, importErrors) => {
    const errors = [];

    //Check the name is set and doesn't contain numbers (note: special and accented characters are valid)
    const regex = /\d/;
    const name = json['Nome do cliente'];
    if (!name || regex.test(name)) {
      const error = !name
        ? 'Nome é obrigatório'
        : `Nome não pode conter números: ${name}`;
      errors.push({ line, error });
    }

    const email = json['E-mail'];
    if (!email || !this.utils.validateEmail(email)) {
      const error = !email
        ? 'E-mail não preenchido'
        : `E-mail em formato errado: ${email}`;
      errors.push({ line, error });
    }

    const phone = json['Telefone'];
    if (!!phone && !/^\(\d{2}\) \d{8,9}$/.test(phone)) {
      //Validate phone is in (XX) XXXXXXXXX format
      errors.push({ line, error: `Telefone em formato errado: ${phone}` });
    }

    const description = json['Descrição da necessidade'];
    if (description && description.length > 500) {
      errors.push({
        line,
        error: 'Descrição da necessidade contem mais que 500 caracteres',
      });
    }

    if (errors.length > 0) {
      importErrors.push(...errors);
      return null;
    } else {
      return {
        line,
        name,
        email,
        phone,
        description,
      };
    }
  };

  onDrop = items => {
    this.parseFile(items[0]);
  };

  openUpload = () => {
    document.getElementById('file').click();
  };

  send = async () => {
    this.setState({ loading: true });

    //Remove the line (as it is used for internal control only)
    const payload = this.state.fileData.map(opportunity => {
      return { ...opportunity, line: undefined };
    });

    const linesWithSuccess = [];
    const linesWithError = [];

    let response = await this.service.saveOpportunitiesBatch(payload);
    if (response) {
      response.forEach(item => {
        let data = this.state.fileData[item.index];

        if (item.error || !item.result.success) {
          //Error in the backend
          linesWithError.push(data.line);
        } else {
          linesWithSuccess.push(data.line);
        }
      });

      let backendResponse;
      if (linesWithError.length === 0) {
        backendResponse = (
          <div>
            Cadastro efetuado com sucesso!
            <br />
            {linesWithSuccess.length} registro(s) cadastrado(s).
          </div>
        );
      } else if (linesWithSuccess.length > 0) {
        backendResponse = (
          <div>
            Alguns registros não puderam ser importados. Linhas:{' '}
            {linesWithError}.<br />
            Verifique se os dados inseridos atendem aos critérios mencionados e
            tente novamente.
            <br />
            {linesWithSuccess.length} registro(s) cadastrado(s).
          </div>
        );
      } else {
        backendResponse = (
          <div>
            Erro na validação dos campos, nenhum registro foi cadastrado.
            <br />
            Verifique se os dados inseridos atendem aos critérios mencionados ou
            se seu plano permite novas oportunidades e tente novamente.
          </div>
        );
      }

      this.props.updateAlert(backendResponse);

      this.setState({
        loading: false,
        fileData: null,
        importErrors: null,
      });
    }
  };

  clearForm = () => {
    this.setState({
      fileData: null,
      importErrors: null,
    });
  };

  render() {
    let uploadComponent;
    if (this.state.loading) {
      //Loading the file or sending the data to the backend
      uploadComponent = (
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <ReactLoading type="spinningBubbles" color="#B1CD49" />
        </div>
      );
    } else if (this.state.fileData) {
      //File loaded and ok
      uploadComponent = '';
    } else {
      //Not loaded yet or not valid
      uploadComponent = (
        <div
          className="row-between mtop-30"
          style={{ justifyContent: 'center' }}
        >
          <div className="uploadFile" onClick={this.openUpload}>
            <DropToUpload
              onDrop={this.onDrop}
              className="up-image-resp flex-upload"
            >
              <input
                type="file"
                accept={validTypes}
                onChange={this.onFileChange}
                id="file"
                style={{ display: 'none' }}
              />

              <div className="uploadFile-content">
                <img
                  width="100"
                  src={require('./../../../assets/images/dashboard/cloud_up.svg')}
                  alt="Enviar arquivo"
                />
                <div>Arraste um arquivo ou clique aqui</div>
              </div>
            </DropToUpload>
          </div>
        </div>
      );
    }

    let importedData;
    if (this.state.fileData) {
      importedData = (
        <div className="mtop-20">
          <div>Os seguintes dados serão enviados:</div>
          <table className="table fixed-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Descrição da Necessidade</th>
              </tr>
            </thead>
            <tbody>
              {this.state.fileData.map(opportunity => (
                <tr key={opportunity.line}>
                  <td>{opportunity.name}</td>
                  <td>{opportunity.email}</td>
                  <td>{opportunity.phone}</td>
                  <td>{opportunity.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cpointer mbottom-20" onClick={this.clearForm}>
            <i className="fas fa-times" /> Remover
          </div>
        </div>
      );
    } else {
      importedData = '';
    }

    let errors;
    if (this.state.importErrors) {
      errors = (
        <div className="mtop-20">
          <div>
            As linhas a seguir apresentavam erros e não serão importadas:
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Linha</th>
                <th>Erro</th>
              </tr>
            </thead>
            <tbody>
              {this.state.importErrors.map(error => (
                <tr key={`${error.line}-${error.error}`}>
                  <td>{error.line}</td>
                  <td>{error.error}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      errors = null;
    }

    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />

        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>

          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">
                        Importação de oportunidades
                      </div>
                    </div>

                    <ol>
                      <li>
                        Faça o download do{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${this.service.getServer()}templates/create_opportunities_batch.xlsx`}
                        >
                          template
                        </a>
                        .
                      </li>
                      <li>
                        Preencha o template com os dados das oportunidades a
                        serem cadastradas, começando da linha 3 da planilha.
                      </li>
                      <li>Faça o upload do template preenchido.</li>
                    </ol>

                    <div
                      className="mtop-20 mbottom-30"
                      style={{ justifyContent: 'center' }}
                    >
                      Os dados inseridos devem atender aos critérios:
                      <ul>
                        <li>Nome não deve conter números</li>
                        <li>
                          E-mail deve ser válido e deve estar preenchido
                          obrigatoriamente
                        </li>
                        <li>
                          Telefone deve conter no mínimo 10 e no máximo 11
                          caracteres numéricos (incluindo o DDD), no formato
                          (XX) XXXXXXXXX
                        </li>
                        <li>
                          Descrição da necessidade deve conter no máximo 500
                          caracteres
                        </li>
                      </ul>
                    </div>

                    {uploadComponent}
                    {importedData}
                    {errors}

                    <div
                      className="row-between mbottom-50"
                      style={{ justifyContent: 'center' }}
                    >
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.goTo('dashboard/proposals/oportunity/register');
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className={`bt-blue ${
                            this.state.fileData ? '' : 'bt-disabled'
                          }`}
                          onClick={() => {
                            if (this.state.fileData) {
                              this.send();
                            }
                          }}
                        >
                          Enviar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OportunityCreateBatch)
);
