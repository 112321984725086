class CalculateBasicsService {
  execute = user => {
    let total = 21;
    let filled = 0;
    if (user.image && user.image.length > 0) {
      filled++;
    }
    if (user.name && user.name.length > 0) {
      filled++;
    }
    if (user.surname && user.surname.length > 0) {
      filled++;
    }
    if (user.birthday && user.birthday.length > 0) {
      filled++;
    }
    if (user.email && user.email.length > 0) {
      filled++;
    }
    if (user.engage_name && user.engage_name.length > 0) {
      filled++;
    }

    if (
      user.location &&
      user.location.length > 0 &&
      user.location[0].country &&
      user.location[0].country.length > 0
    ) {
      filled++;
    }
    if (
      user.location &&
      user.location.length > 0 &&
      user.location[0].state &&
      user.location[0].state.length > 0
    ) {
      filled++;
    }
    if (
      user.location &&
      user.location.length > 0 &&
      user.location[0].city &&
      user.location[0].city.length > 0
    ) {
      filled++;
    }

    if (
      user.contact &&
      user.contact.length > 0 &&
      user.contact[0].ddi &&
      user.contact[0].ddi.length > 0
    ) {
      filled++;
    }
    if (
      user.contact &&
      user.contact.length > 0 &&
      user.contact[0].phone &&
      user.contact[0].phone.length > 0
    ) {
      filled++;
    }
    if (user.professional_area) {
      if (
        (user.professional_area.professional &&
          user.professional_area.professional.length > 0) ||
        (user.professional_area.life && user.professional_area.life.length > 0)
      ) {
        filled++;
      }

      if (user.professional_area.presential || user.professional_area.online) {
        filled++;
      }

      if (
        user.professional_area.cities &&
        user.professional_area.cities.length > 0
      ) {
        filled++;
      }
    }

    if (user.professional_session) {
      if (user.professional_session.master) {
        filled++;
      }
    }

    if (user.professional_value) {
      if (
        user.professional_value.currency &&
        user.professional_value.currency.length > 0
      ) {
        filled++;
      }

      if (
        user.professional_value.price &&
        user.professional_value.price.length > 0
      ) {
        filled++;
      }
    }

    if (user.professional_description) {
      if (
        user.professional_description.experience &&
        user.professional_description.experience.length > 0
      ) {
        filled++;
      }

      if (
        user.professional_description.professional &&
        user.professional_description.professional.length > 0
      ) {
        filled++;
      }

      if (
        user.professional_description.methodology &&
        user.professional_description.methodology.length > 0
      ) {
        filled++;
      }
      if (
        user.professional_description.certificate &&
        user.professional_description.certificate.length > 0
      ) {
        filled++;
      }
    }

    return parseInt((filled * 100) / total);
  };
}

export default CalculateBasicsService;
