import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { Col, Row } from 'reactstrap';
import Switch from 'react-switch';
import { updateImage } from '../../../../actions/SimpleAction';
import SidebarContents from '../../../sidebar/SidebarContents';
import ReactLoading from 'react-loading';
import Header from '../../../components/Header';
import PrincipalMenu from '../../../components/PrincipalMenu';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NotAllowed from '../../../components/NotAllowed';
import Footer from '../../../components/Footer';
import Service from '../../Service.js';
import Utils from '../../../../libs/Utils.js';
import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';
import './styles.css';

const questionTypes = [
  {
    type: 'text',
    text: 'Texto',
  },
  {
    type: 'date',
    text: 'Data',
  },
  {
    type: 'multiple-choice',
    text: 'Múltipla escolha',
  },
  {
    type: 'checkboxes',
    text: 'Caixas de seleção',
  },
  {
    type: 'ranking',
    text: 'Ranking',
  },
  {
    type: 'evaluation',
    text: 'Avaliação',
  },
  {
    type: 'likert',
    text: 'Likert',
  },
];

const DragHandle = sortableHandle(({ children }) => children);

const OptionArea = sortableElement(
  ({
    itemOption,
    optionIndex,
    questionIndex,
    questionType,
    haveOthers,
    removeOption,
    handleOption,
    optionsLength,
  }) => (
    <div key={optionIndex} className="row-center z-index">
      {haveOthers && (
        <div className="form-option-div">
          <input
            className="form-option cursor-auto"
            type={questionType === 'multiple-choice' ? 'radio' : 'checkbox'}
            name="choices"
            value={false}
            checked={false}
            readOnly={true}
          />
        </div>
      )}
      <DragHandle>
        <div className="form-input label-drag-handle">
          <label className="blue-label label-drag-handle">{`Opção ${optionIndex +
            1}`}</label>
          <input
            type="text"
            value={itemOption}
            className="blue-input"
            placeholder={`Opção ${optionIndex + 1}`}
            onChange={e =>
              handleOption(questionIndex, optionIndex, e.target.value)
            }
          />
        </div>
      </DragHandle>
      {optionsLength > 1 ? (
        <div
          className="bt-blueicon form-rmv-option"
          onClick={() => removeOption(questionIndex, optionIndex)}
        >
          <i className="fas fa-times"></i>
        </div>
      ) : (
        <div style={{ width: 36 }} />
      )}
    </div>
  )
);

const OptionAreas = sortableContainer(
  ({
    question,
    removeOption,
    addNewOption,
    handleOption,
    handleForm,
    setQuestionIndex,
    questionIndex,
    haveOthers,
  }) => {
    return (
      <Col
        xs={{ size: 12 }}
        md={{ size: 8 }}
        onClick={() => setQuestionIndex(questionIndex)}
      >
        {question.options.map((item, optionIndex) => (
          <OptionArea
            key={`form-option-${optionIndex}`}
            index={optionIndex}
            optionIndex={optionIndex}
            itemOption={item.option}
            questionIndex={questionIndex}
            questionType={question.type}
            haveOthers={haveOthers}
            removeOption={removeOption}
            handleOption={handleOption}
            optionsLength={question.options.length}
          />
        ))}
        {haveOthers && question.others && question.others.actived && (
          <div className="row-center">
            <div className="form-option-div">
              <input
                className="form-option cursor-auto"
                type={
                  question.type === 'multiple-choice' ? 'radio' : 'checkbox'
                }
                name="choices"
                value={false}
                checked={false}
                readOnly={true}
              />
            </div>
            <div className="form-input">
              <label className="blue-label">Outros</label>
              <input
                type="text"
                className="blue-input"
                placeholder="Outros..."
                disabled
              />
            </div>
            <div
              className="bt-blueicon form-rmv-option"
              onClick={() => handleForm('others', questionIndex, {})}
            >
              <i className="fas fa-times"></i>
            </div>
          </div>
        )}
        <div className="row-center justify-flex-start">
          <div
            className="add-option-bt-blue"
            onClick={() => addNewOption(questionIndex)}
          >
            Adicionar opção
          </div>
          {haveOthers && question.others && !question.others.actived && (
            <div
              style={{ marginLeft: 10 }}
              className="add-option-bt-blue"
              onClick={() =>
                handleForm('others', questionIndex, {
                  answer: '',
                  actived: true,
                  checked: false,
                })
              }
            >
              Adicionar "outro"
            </div>
          )}
        </div>
      </Col>
    );
  }
);

class FormEditor extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      sideOpen: false,
      principalOpen: true,
      view: 'contents',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Formulários', link: 'form-editor' },
      ],
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,
      formData: [
        {
          question: '',
          type: 'text',
          mandatory: false,
          options: [],
          others: {},
          evaluationLevels: '',
          likert: {
            rows: [],
            columns: [],
          },
          answer: '',
        },
      ],
      title: '',
      editing: false,
      contentId: '',
      setQuestionIndex: null,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      const contentId = params.id;
      this.setState({
        editing: true,
        contentId,
      });
      this.load(contentId);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    const result = await this.service.content(_id);
    if (result && result.success) {
      if (result.content) {
        this.setState({
          title: result.content.title,
          formData: [...result.content.data],
        });
      }
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async save() {
    this.setState({ loading: true });
    const { title, formData, editing, contentId } = this.state;
    let _pay = {
      title,
      doc_source: '',
      doc_format: 'form',
      tag: 'Formulários',
      category: 'Formulários',
      data: JSON.stringify([...formData]),
    };

    let result = editing
      ? await this.service.updateContent(_pay, contentId)
      : await this.service.saveContent(_pay);
    if (result && result.success) {
      this.setSidebarLinks(`contents`);
    }
    this.setState({ loading: false });
    this.setState({ loading: false });
  }

  renderEvaluationLevels = evaluationLevels => {
    const levels = [];
    for (let i = 1; i <= parseInt(evaluationLevels); i++) {
      levels.push(i);
    }
    return levels;
  };

  renderResponseTypes(question, questionIndex) {
    switch (question.type) {
      case 'text': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 8 }}>
            <div className="form-answers-text">Texto de resposta</div>
          </Col>
        );
      }
      case 'date': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 8 }}>
            <div className="form-answers-text">Dia / Mês / Ano</div>
          </Col>
        );
      }
      case 'multiple-choice':
      case 'checkboxes': {
        return (
          <OptionAreas
            onSortEnd={this.handleSortEnd}
            question={question}
            removeOption={this.removeOption}
            addNewOption={this.addNewOption}
            handleOption={this.handleOption}
            handleForm={this.handleForm}
            setQuestionIndex={this.setQuestionIndex}
            questionIndex={questionIndex}
            haveOthers={true}
            useDragHandle
          />
        );
      }
      case 'ranking': {
        return (
          <OptionAreas
            onSortEnd={this.handleSortEnd}
            onSortMove={() => {
              this.setQuestionIndex(questionIndex);
            }}
            question={question}
            removeOption={this.removeOption}
            addNewOption={this.addNewOption}
            handleOption={this.handleOption}
            handleForm={this.handleForm}
            setQuestionIndex={this.setQuestionIndex}
            questionIndex={questionIndex}
            haveOthers={false}
            useDragHandle
          />
        );
      }
      case 'evaluation': {
        return (
          <>
            <Col xs={{ size: 12 }} md={{ size: 4 }}>
              <div className="row-center">
                <div className="form-input">
                  <label className="blue-label">Níveis</label>
                  <input
                    type="number"
                    min="2"
                    max="10"
                    value={question.evaluationLevels}
                    className="blue-input"
                    onChange={e =>
                      this.handleForm(
                        'evaluationLevels',
                        questionIndex,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </Col>
            <Col xs={{ size: 12 }} md={{ size: 12 }}>
              <div className="row-center flex-wrap mb-15">
                {this.renderEvaluationLevels(question.evaluationLevels).map(
                  item => (
                    <div key={item} className="form-evaluation-levels-div">
                      <span className="form-evaluation-levels-span">
                        {item}
                      </span>
                      <input
                        className="form-option cursor-auto"
                        type="radio"
                        name="evaluation"
                        value={false}
                        checked={false}
                        readOnly={true}
                      />
                    </div>
                  )
                )}
              </div>
            </Col>
          </>
        );
      }
      case 'likert': {
        return (
          <>
            {/* ROW INPUTS */}
            <Col xs={{ size: 12 }} md={{ size: 4 }}>
              {question.likert.rows.map((row, rowIndex) => (
                <div key={rowIndex} className="row-center">
                  <div className="form-input">
                    <label className="blue-label">{`Linha ${rowIndex +
                      1}`}</label>
                    <input
                      value={row.text}
                      className="blue-input"
                      placeholder={`Linha ${rowIndex + 1}`}
                      onChange={e =>
                        this.handleRowOrColumn(
                          questionIndex,
                          'rows',
                          rowIndex,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  {question.likert.rows.length > 1 ? (
                    <div
                      className="bt-blueicon form-rmv-option"
                      onClick={() =>
                        this.removeRowOrColumn(questionIndex, 'rows', rowIndex)
                      }
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  ) : (
                    <div style={{ width: 36 }} />
                  )}
                </div>
              ))}
              <div className="row-center">
                <div
                  className="add-option-bt-blue"
                  onClick={() => this.addRowOrColumn(questionIndex, 'rows')}
                >
                  Adicionar linha
                </div>
              </div>
            </Col>
            {/* COLUMNS INPUTS */}
            <Col xs={{ size: 12 }} md={{ size: 4 }}>
              {question.likert.columns.map((column, columnIndex) => (
                <div key={columnIndex} className="row-center">
                  <div className="form-input">
                    <label className="blue-label">{`Coluna ${columnIndex +
                      1}`}</label>
                    <input
                      value={column.text}
                      className="blue-input"
                      placeholder={`Coluna ${columnIndex + 1}`}
                      onChange={e =>
                        this.handleRowOrColumn(
                          questionIndex,
                          'columns',
                          columnIndex,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  {question.likert.columns.length > 1 ? (
                    <div
                      className="bt-blueicon form-rmv-option"
                      onClick={() =>
                        this.removeRowOrColumn(
                          questionIndex,
                          'columns',
                          columnIndex
                        )
                      }
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  ) : (
                    <div style={{ width: 36 }} />
                  )}
                </div>
              ))}
              {question.likert.columns.length < 6 && (
                <div className="row-center">
                  <div
                    className="add-option-bt-blue"
                    onClick={() =>
                      this.addRowOrColumn(questionIndex, 'columns')
                    }
                  >
                    Adicionar coluna
                  </div>
                </div>
              )}
            </Col>
            {/* LIKERT REPRESENTATION */}
            <Col
              xs={{ size: 12 }}
              md={{ size: 12 }}
              style={{ margin: '10px 0' }}
            >
              <div className="form-likert-columns">
                {question.likert.columns.map((column, columnIndex) => {
                  return (
                    <span
                      key={columnIndex}
                      className="column-text likert-vertical-text"
                    >
                      {column.text || `Coluna ${columnIndex + 1}`}
                    </span>
                  );
                })}
              </div>
              {question.likert.rows.map((row, rowIndex) => {
                return (
                  <div
                    key={rowIndex}
                    className={`form-likert-rows ${
                      rowIndex % 2 ? 'bc-gray' : 'bc-light-gray'
                    }`}
                  >
                    <span className="row-text likert-vertical-text">
                      {row.text || `Linha ${rowIndex + 1}`}
                    </span>
                    <div className="likert-rows-circle">
                      {question.likert.columns.map((_, columnIndex) => (
                        <input
                          key={columnIndex}
                          className="form-option cursor-auto"
                          type="radio"
                          name="likert"
                          value={false}
                          checked={false}
                          readOnly={true}
                        />
                      ))}
                    </div>
                  </div>
                );
              })}
            </Col>
          </>
        );
      }
      default:
        return null;
    }
  }

  handleRowOrColumn = (questionIndex, type, typeIndex, value) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].likert[type][typeIndex].text = value;
    this.setState({ formData: newFormData });
  };

  addRowOrColumn = (questionIndex, type) => {
    const newFormData = [...this.state.formData];
    if (type === 'rows') {
      newFormData[questionIndex].likert[type].push({ text: '', answer: '' });
    }
    if (type === 'columns') {
      newFormData[questionIndex].likert[type].push({ text: '' });
    }
    this.setState({ formData: newFormData });
  };

  removeRowOrColumn = (questionIndex, type, typeIndex) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].likert[type] = newFormData[questionIndex].likert[
      type
    ].filter((_, index) => index !== typeIndex);
    this.setState({ formData: newFormData });
  };

  setQuestionIndex = setQuestionIndex => {
    if (setQuestionIndex !== this.state.setQuestionIndex) {
      this.setState({ setQuestionIndex });
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    setTimeout(() => {
      const { setQuestionIndex } = this.state;
      if (setQuestionIndex !== null) {
        const newFormData = [...this.state.formData];
        const [option] = newFormData[setQuestionIndex].options.splice(
          oldIndex,
          1
        );
        newFormData[setQuestionIndex].options.splice(newIndex, 0, option);
        this.setState({ formData: newFormData });
      }
    }, 30);
  };

  handleOption = (questionIndex, optionIndex, value) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].options[optionIndex].option = value;
    this.setState({ formData: newFormData });
  };

  addNewOption = questionIndex => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].options.push({
      option: '',
      checked: false,
    });
    this.setState({ formData: newFormData });
  };

  removeOption = (questionIndex, optionIndex) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].options = newFormData[
      questionIndex
    ].options.filter((_, index) => index !== optionIndex);
    this.setState({ formData: newFormData });
  };

  handleForm = (field, index, value) => {
    const newFormData = [...this.state.formData];
    if (field === 'type') {
      newFormData[index].options = [];
      newFormData[index].others = {};
      if (
        value === 'multiple-choice' ||
        value === 'checkboxes' ||
        value === 'ranking'
      ) {
        this.addNewOption(index);
      }
      if (value === 'evaluation') {
        newFormData[index].evaluationLevels = '2';
      } else {
        newFormData[index].evaluationLevels = '';
      }
      if (value === 'likert') {
        newFormData[index].likert = {
          rows: [{ text: '', answer: '' }],
          columns: [{ text: '' }],
        };
      } else {
        newFormData[index].likert = {
          rows: [],
          columns: [],
        };
      }
    }
    if ((field === 'evaluationLevels' && value < 2) || value > 10) {
      return;
    }
    newFormData[index][field] = value;
    this.setState({ formData: newFormData });
  };

  addNewQuestion = () => {
    const newFormData = [...this.state.formData];
    newFormData.push({
      question: '',
      type: 'text',
      mandatory: false,
      options: [],
      others: {},
      evaluationLevels: '',
      likert: {
        rows: [],
        columns: [],
      },
      answer: '',
    });
    this.setState({ formData: newFormData });
  };

  removeQuestion = questionIndex => {
    const newFormData = this.state.formData.filter(
      (_, index) => index !== questionIndex
    );
    this.setState({ formData: newFormData });
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      user,
      title,
      loading,
      editing,
      formData,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarContents
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>

              {(user && user.plans && !user.plans.platform) ||
              (user &&
                user.plans &&
                user.plans.platform &&
                !user.plans.platform.plan.use_customize_contents) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100">
                    <Col xs={{ size: 12 }} md={{ size: 12 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">{`${
                          editing ? 'Editar' : 'Criar'
                        } Formulário`}</div>
                      </div>

                      <div className="form-div">
                        <div className="form-input">
                          <label className="blue-label">Título</label>
                          <input
                            type="text"
                            value={title}
                            className="blue-input"
                            placeholder="Título"
                            onChange={e =>
                              this.setState({ title: e.target.value })
                            }
                          />
                        </div>

                        {formData.map((item, index) => (
                          <div key={index} className="form-new-question">
                            <label className="form-question-title">
                              {`${index + 1}. Questão `}
                              {item.mandatory && (
                                <span style={{ color: '#AE1515' }}>*</span>
                              )}
                            </label>
                            <Row>
                              <Col xs={{ size: 12 }} md={{ size: 8 }}>
                                <div className="form-input">
                                  <label className="blue-label">Pergunta</label>
                                  <input
                                    type="text"
                                    value={item.question}
                                    className="blue-input"
                                    placeholder="Pergunta"
                                    onChange={e =>
                                      this.handleForm(
                                        'question',
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                              <Col xs={{ size: 12 }} md={{ size: 4 }}>
                                <div className="form-input">
                                  <label className="blue-label">Tipo</label>
                                  <select
                                    value={item.type}
                                    className="blue-input"
                                    onChange={e =>
                                      this.handleForm(
                                        'type',
                                        index,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {questionTypes.map(item => (
                                      <option key={item.type} value={item.type}>
                                        &nbsp; {item.text}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>{this.renderResponseTypes(item, index)}</Row>
                            <div className="form-new-question-footer">
                              {formData.length > 1 && (
                                <img
                                  onClick={() => this.removeQuestion(index)}
                                  className="form-footer-trash"
                                  src={require('./../../../../assets/images/tables/trash.svg')}
                                />
                              )}
                              <div className="">
                                <span>Obrigatória</span>
                                <Switch
                                  onChange={() =>
                                    this.handleForm(
                                      'mandatory',
                                      index,
                                      !item.mandatory
                                    )
                                  }
                                  className="form-footer-switch"
                                  id={`toggle-visibility`}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  offColor={'#D8D8D8'}
                                  offHandleColor={'#9F9F9F'}
                                  onColor={'#96CBCF'}
                                  onHandleColor={'#00838F'}
                                  width={40}
                                  height={20}
                                  checked={item.mandatory}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row-center">
                        <div className="bt-blue" onClick={this.addNewQuestion}>
                          Adicionar pergunta
                        </div>
                      </div>
                    </Col>
                    {loading ? (
                      <div className="row-center mtop-50 mbottom-50">
                        <ReactLoading type="bubbles" color="#00838F" />
                      </div>
                    ) : (
                      <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                        <div className="row-center mtop-50 mbottom-50">
                          <div
                            className="bt-blue-outline"
                            onClick={() => this.setSidebarLinks('contents')}
                          >
                            Cancelar
                          </div>

                          <div
                            className={true ? 'bt-blue' : 'bt-blue bt-disabled'}
                            onClick={() => this.save()}
                          >
                            {editing ? 'Salvar' : 'Cadastrar'}
                          </div>
                        </div>
                      </Col>
                    )}
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditor)
);
