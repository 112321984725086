import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

// import Avatar from '../avatar/Avatar';
// import Logo from '../logo/Logo';

class SidebarProposes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li className="content-pop" onClick={ () => { this.props.setSidebarLinks(''); } } >
          <img src={ require('./../../assets/images/menu/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Dashboard</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Dashboard
              </div> 
          </div>  
        </li>
        <li className="content-pop"
            onClick={ () => { this.props.setSidebarLinks('coachees'); } } >
          <img src={ require('./../../assets/images/menu/icon-1.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-1w.svg') } className="image-over"   width="28" /> 
          <span>Coachees</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Coachees
              </div> 
          </div>  
        </li>
        <li onClick={ () => { this.props.goTo('dashboard/proposals') } }  className={ this.props.side == 'painel' ?  "active content-pop" : "content-pop"} >
          <img src={ require('./../../assets/images/proposal/icon-0.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/proposal/icon-0w.svg') } className="image-over"   width="28" /> 
          <span>Painel de Oportunidades</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Painel de Oportunidades
              </div> 
          </div>  
        </li>
        <li onClick={ () => { this.props.goTo('dashboard/proposals/oportunity/register') } } className={ this.props.side == 'register' ?  "active content-pop" : "content-pop"}>
          <img src={ require('./../../assets/images/finances/icon-1.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/finances/icon-1w.svg') } className="image-over"   width="28" /> 
          <span>Cadastrar Oportunidade</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Cadastrar Oportunidade
              </div> 
          </div>  
        </li> 
        <li onClick={ () => { this.props.goTo('dashboard/proposals/proposal/register') } } className={ this.props.side == 'generate' ?  "active content-pop" : "content-pop"}>
          <img src={ require('./../../assets/images/proposal/icon-2.svg') }   width="28" /> 
          <img src={ require('./../../assets/images/proposal/icon-2w.svg') } className="image-over"   width="28" /> 
          <span>Gerar Proposta</span>
          <div className="popover-container side-pop"> 
              <div className="triangle-border"></div>
              <div className="popover-content">
                  Gerar Proposta
              </div> 
          </div>  
        </li> 
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProposes);