import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { Col, Row } from 'reactstrap';
import { updateImage } from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import NotAllowed from './../components/NotAllowed';
import PeriodiceFilter from './../components/PeriodiceFilter';
import RowStats from './../components/RowStats';
import CardFinance from './../components/CardFinance';
import CardGenericTable from './../components/CardGenericTable';
import CardGenericArea from './../components/CardGenericArea';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import SimpleModal from './../components/SimpleModal';
import tutorialVideo from './../../assets/videos/first-login.mp4';

class Dashboard extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      tutorialVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'dash',
      breadcrumbs: [{ title: 'Dashboard', link: '' }],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 30) / 100,
      user: {},
      finance: {
        inb: 0,
        outb: 0,
        toinb: 0,
        inw: 0,
        outw: 0,
        toinw: 0,
      },
      areaData: [],
      coachees: [],
      rows: [],
      titles: [
        { title: 'Coachee', key: '_coachee' },
        { title: 'Data', key: 'date' },
        { title: 'Título', key: 'title' },
        //{ title: "Status", key: "taks_status" }
      ],
      rows1: [],
      titles1: [
        { title: 'Coachee/Grupo', key: '_coachee' },
        { title: 'Data', key: 'start' },
        { title: 'Assunto', key: 'title' },
      ],
      rows2: [],
      titles2: [
        { title: 'Enviado de', key: '_from' },
        { title: 'Data', key: 'date' },
        { title: 'Enviado a', key: '_to' },
        { title: 'Assunto', key: 'message' },
      ],
      rows3: [],
      titles3: [
        { title: 'Coachee', key: '_coachee' },
        { title: 'Data Limite', key: 'udate' },
        { title: 'Ação', key: 'title' },
      ],
      rows4: [],
      titles4: [
        { title: '', key: 'name' },
        { title: '', key: '_status' },
      ],
      rows5: [],
      titles5: [
        { title: '', key: 'group' },
        { title: '', key: 'typeService' },
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadDash();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  userHasPlataformPlan(user) {
    return user && user.plans && user.plans.platform;
  }

  async loadUser() {
    let periodicy = this.utils.getStorage('periodicy-filter')
      ? this.utils.getStorage('periodicy-filter')
      : this.props.periodicy;
    let result = await this.service.me(periodicy, true);

    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    await this.setState({ user });

    if (user && this.userHasPlataformPlan(user)) {
      if (user.plans.platform.plan.group_sessions) {
        this.loadGroups();
      }
      if (!user.view_first_access) {
        this.setState({ tutorialVisible: true });
        await this.service.firstAccess();
        await this.insertMarketingDefault();
      }
    }
  }

  async insertMarketingDefault() {
    const _pay = {
      website_name: '',
      about_me: '',
      experience: '',
      certifications: '',
      references: '',
      facebook: '',
      google_plus: '',
      linkedin: '',
      instagram: '',
      twitter: '',
      youtube: '',
      layout_model: '',
      publicated: false,
      message_sessions: '',
      message_proposals: '',
      message_welcome: '',
      message_share_infos: '',
      message_share_report: '',
      message_sessions_active: false,
      message_proposals_active: true,
      message_welcome_active: true,
      message_share_infos_active: true,
      message_share_report_active: true,
      logo: '',
      photo: '',
      typeUpdate: '',
      typeMarketing: 'messages',
    };
    const result = await this.service.marketingMe();
    if (result && result.success && result.marketing === null) {
      await this.service.saveMarketing(_pay);
    }
  }

  async loadDash() {
    let periodicy = this.utils.getStorage('periodicy-filter')
      ? this.utils.getStorage('periodicy-filter')
      : this.props.periodicy;
    let result = await this.service.dashboard(periodicy);
    if (result && result.success) {
      this.setState({
        rows: result.dash.tasks,
        rows1: result.dash.week,
        rows2: result.dash.messages,
        rows3: result.dash.last,
        rows4: result.dash.coachees,
        finance: result.dash.balances,
      });
    }
  }

  async loadGroups() {
    let result = await this.service.groups();
    if (result && result.success) {
      const groups = result.groups.map(item => ({
        ...item,
        group: item.name,
        typeService: this.getTypeService(item),
      }));
      this.setState({ rows5: groups });
    }
  }

  getTypeService = item => (
    <div className="display-flex-end">
      <div
        style={{ width: 85 }}
        className={
          !item.presential
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-5.svg')}
          width="12"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-5b.svg')}
          width="12"
        />
        Presenciais
      </div>
      <div
        style={{ width: 85 }}
        className={
          !item.online
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-6.svg')}
          width="30"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-6b.svg')}
          width="30"
        />
        Online
      </div>
    </div>
  );

  isLoadedUserPlans() {
    if (this.state.user && this.state.user.plans) {
      return true;
    }
    return false;
  }

  isTestPeriodExpired() {
    const { isTestPeriodStarted, testPeriod } = this.state.user;
    if (
      !this.userHasPlataformPlan(this.state.user) &&
      isTestPeriodStarted &&
      !testPeriod
    ) {
      return true;
    }
    return false;
  }

  showDashboard() {
    if (this.isLoadedUserPlans && this.isTestPeriodExpired()) {
      return true;
    }

    if (
      (this.state.user &&
        this.state.user.plans &&
        !this.state.user.plans.platform) ||
      (this.state.user &&
        this.state.user.plans &&
        this.state.user.plans.platform &&
        this.state.user.plans.platform.plan &&
        !this.state.user.plans.platform.plan.dashboard_area)
    ) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <div
        className={`main-content zoomresolve ${this.state.tutorialVisible &&
          'position-fixed'}`}
      >
        <div
          style={{
            zIndex: this.state.tutorialVisible ? -1 : 999999,
            position: 'fixed',
            backgroundColor: 'white',
          }}
        >
          <Header
            goTo={this.goTo}
            sideOpen={this.state.sideOpen}
            toggleMenu={this.toggleMenu}
            canShowTutorial
          />
        </div>
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={
              this.props.menu
                ? 'content-dash conteudoDash closed'
                : 'content-dash conteudoDash'
            }
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            {!this.showDashboard() ? (
              <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
            ) : (
              <div>
                <div className="dash-content dash-fixed">
                  <div className="dash-crumb-filter">
                    <Breadcrumbs
                      breadcrumbs={this.state.breadcrumbs}
                      customClass={'dash-breadcrumbs custom'}
                    />
                    <PeriodiceFilter
                      filtred={() => {
                        this.loadDash();
                        this.loadUser();
                      }}
                      filter={'Semestral'}
                    />
                  </div>
                  <RowStats user={this.state.user} />
                  <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                    <Col md={{ size: '12' }} lg={{ size: '12' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericArea
                          id="area-dashboard-2"
                          title="Desempenho na busca"
                          labels={[
                            'Aparições nas busca',
                            'Cliques no perfil',
                            'Cliques no contato',
                          ]}
                          windowWidth={this.state.windowWidth}
                          areaData={
                            this.state.user.clicks ? this.state.user.clicks : []
                          }
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardFinance
                          finance={this.state.finance}
                          id="finance-dashboard-1"
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '5' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-1"
                          className="scrollOp2"
                          title="Coachees"
                          state={this.state}
                          title_rows={this.state.titles4}
                          rows={this.state.rows4}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-5"
                          title="Últimas ações"
                          className="scrollOp"
                          state={this.state}
                          title_rows={this.state.titles3}
                          rows={this.state.rows3}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '5' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-6"
                          className="scrollOp2"
                          title="Grupos"
                          state={this.state}
                          title_rows={this.state.titles5}
                          rows={this.state.rows5}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-2"
                          title="Conteúdo compartilhado"
                          className="scrollOp"
                          state={this.state}
                          title_rows={this.state.titles}
                          rows={this.state.rows}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '5' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-3"
                          title="Agenda da semana"
                          className="scrollOp2"
                          state={this.state}
                          title_rows={this.state.titles1}
                          rows={this.state.rows1}
                          campoImagem={true}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>

                    <Col md={{ size: '12' }} lg={{ size: '7' }}>
                      <div className="m--15 normalize-card">
                        <CardGenericTable
                          id="dash-dashboard-4"
                          title="Mensagens"
                          state={this.state}
                          title_rows={this.state.titles2}
                          rows={this.state.rows2}
                          campoImagem={false}
                          campoNome={false}
                          campoEmail={false}
                        />
                      </div>
                    </Col>
                  </div>
                  <Footer />
                </div>
              </div>
            )}
            <SimpleModal
              visible={this.state.tutorialVisible}
              title="Bem-vindo à climby!"
              content={
                <div>
                  <div style={{ marginBottom: 15 }}>
                    Precisa de ajuda para navegar na plataforma?
                  </div>
                  <div>
                    Você pode acessar a qualquer momento os tutoriais ensinando
                    os principais fluxos da plataforma.
                  </div>
                  <div className="tutorial-video">
                    <video
                      width="100%"
                      height="100%"
                      // controls
                      autoplay="autoplay"
                      controlsList="nodownload"
                      disablePictureInPicture
                    >
                      <source src={tutorialVideo} type="video/mp4" />
                    </video>
                  </div>
                  <Col
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 12 }}
                    className="display-flex-center"
                  >
                    <Row className="display-flex-center">
                      <div
                        className="bt-blue-outline"
                        style={{ margin: '5px 10px' }}
                        onClick={() =>
                          this.setState({ tutorialVisible: false })
                        }
                      >
                        Agora não
                      </div>
                      <a
                        href={`${process.env.REACT_APP_URL_WORDPRESS}suporte-ao-cliente/#TUTORIAL`}
                        target="_blank"
                        className="bt-blue"
                        style={{ margin: '5px 10px' }}
                      >
                        Tutorial
                      </a>
                    </Row>
                  </Col>
                </div>
              }
              classNameTitle="tutorial-title"
              classNameText="tutorial-content"
              haveLogo={false}
              iconClose={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  periodicy: state.simpleReducer.periodicy,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
