import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';
import ApiService from '../../services/ApiService';

class PolicyTerms extends Component {
  state = {
    content: '',
  };

  service = new ApiService();

  componentDidMount() {
    this.loadContent();
  }

  async loadContent() {
    let result = await this.service.config(this.props.type);
    if (result && result.success) {
      this.setState({ content: result.config.value });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  render() {
    return (
      <div>
        <div className="modal-content active">
          <div className="modal-box">
            <div className="modal-row-between">
              <div className="container-climb">
                <p className="title-bold">{this.props.title}</p>
                {this.state.content.split('\n').map((item, key) => {
                  return (
                    <p key={key} className="text-blue">
                      {item}
                    </p>
                  );
                })}
                <div className="modal-row-actions">
                  <div
                    className="bt-blue"
                    onClick={() => {
                      this.props.toggle(false);
                    }}
                  >
                    Fechar
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ModalPolicyTerms = ({ show, ...rest }) => {
  return show && <PolicyTerms {...rest} />;
};

export default connect()(ModalPolicyTerms);
