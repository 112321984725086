import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction, updateImage } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarFinances from './../../sidebar/SidebarFinances';

import ReactLoading from 'react-loading';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import PeriodiceFilter from './../../components/PeriodiceFilter';

import RowStats from './../../components/RowStats';

import CardGenericArea from './../../components/CardGenericArea';

import Footer from './../../components/Footer';

import Submenu from './SubmenuFinance';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

class CategoryCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'finances',
      subview: 'category',
      side: 'register',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Cadastros', link: 'finances' },
        { title: 'Categorias', link: 'finances/register/category' },
        { title: 'Nova Categoria', link: 'finances/register/category/create' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_options: false,
      options: [],

      category: '',
      add_to: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);

    this.changeOption = this.changeOption.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.loadOptions();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.finCategory(_id);
    if (result && result.success) {
      this.setState({
        category: result.category.category,
        add_to: result.category.add_to,
      });
    }
    this.setState({ loading: false });
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    this.setState({ loading: true });

    let _pay = {
      category: this.state.category,
      add_to: this.state.add_to,
    };

    let result = !this.state.editing
      ? await this.service.saveFinCategory(_pay)
      : await this.service.updateFinCategory(_pay, this.state.editing_id);

    if (result && result.success) {
      this.goTo('dashboard/finances/register/category');
    }

    this.setState({ loading: false });
  }

  clearForm() {
    this.setState({
      category: '',
      add_to: '',
    });
  }

  async loadOptions() {
    this.setState({ loading_options: true });
    let result = await this.service.optionsFinCategory();
    if (result && result.success) {
      this.setState({
        options: result.options,
      });
    }
    this.setState({ loading_options: false });
  }

  changeOption(value) {
    this.setState({ add_to: value });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarFinances
              goTo={this.goTo}
              setSidebarLinks={this.setSidebarLinks}
              side={this.state.side}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />

                <div className="dashboard-box box100-menu">
                  <Col xs={{ size: 12 }}>
                    <div className="box-row-title">
                      <div className="box-title">
                        {this.state.editing ? 'Editar ' : 'Cadastrar '}{' '}
                        Categoria
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 5 }}>
                    <div className="form-input">
                      <label className="blue-label">Categoria</label>
                      <input
                        type="text"
                        value={this.state.category}
                        className="blue-input"
                        placeholder="Categoria"
                        onChange={e => {
                          this.setState({ category: e.target.value });
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 3 }}>
                    <div className="form-input">
                      <label className="blue-label">Adicionar em</label>
                      {this.state.loading_options ? (
                        <ReactLoading type="bubbles" color="#B1CD49" />
                      ) : (
                        <select
                          value={this.state.add_to}
                          className="blue-input"
                          onChange={e => {
                            this.changeOption(e.target.value);
                          }}
                        >
                          <option value=""> &nbsp; Adicionar em</option>
                          {this.state.options.map((item, key) => {
                            return (
                              <option key={key} value={item}>
                                {' '}
                                &nbsp; {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(`finances/register/category`);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar' : 'Cadastrar'}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryCreate)
);
