import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 
                <div className="dashboard-box box30">
                  <div className="box-row-title">
                    <div className="box-title">Agenda da Semana</div>
                    <div className="box-options">
                      <img src={ require('./../../assets/images/dashboard/i1.svg') } className="box-options-image" />
                      <img src={ require('./../../assets/images/dashboard/i2.svg') } className="box-options-image minor" />
                      <img src={ require('./../../assets/images/dashboard/i3.svg') } className="box-options-image" />
                    </div>
                  </div>
                  <div className="box-row-table">
                    <table>
                      <tr>
                        <td><div>Data</div></td>
                        <td><div>Horário</div></td>
                        <td className="tleft ml20"><div>Assunto</div></td>
                      </tr>

                      {
                        this.props.week_calendar.map( wc_c => {
                          return <tr>
                                  <td><div>
                                    <span className="mobile-label">Data: </span>
                                    { wc_c.date }
                                  </div></td>
                                  <td className="tcenter"><div> 
                                    <span className="mobile-label">Horário: </span>
                                    { wc_c.hour }
                                  </div></td>
                                  <td className="tleft ml20"><div> 
                                    <span className="mobile-label">Assunto: </span>
                                    { wc_c.subject }
                                  </div></td>
                                </tr>
                        })
                      } 

                    </table>
                  </div>
                </div> 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);