import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

// import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarProfile from '../../sidebar/SidebarProfile';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';
import UploadImage from './UploadImage';

import Utils from './../../../libs/Utils.js';

class Terms extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'terms',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Termos de uso', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      states: [],
      cities: [],
      loading_state: false,
      loading_cities: false,

      termos: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadCities = this.loadCities.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.loadStates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadStates() {
    this.setState({ loading_state: true });
    let result = await this.service.states();
    if (result) this.setState({ loading_state: false, states: result.states });
  }

  async loadCities(state) {
    this.setState({ user: { ...this.state.user, state } });
    this.setState({ loading_cities: true });
    let result = await this.service.cities(state);
    if (result) this.setState({ loading_cities: false, cities: result.cities });
  }

  async loadUser() {
    let termos = await this.service.config('termos');
    if (termos && termos.success) {
      this.setState({ termos: termos.config.value });
    }

    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
      this.loadCities(user.state);
    }

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  formValid() {
    return true;
    // if( this.state.user.email
    // &&  this.state.user.email.length > 0
    // &&  this.state.user.name
    // &&  this.state.user.name.length > 0
    // &&  this.state.user.surname
    // &&  this.state.user.surname.length > 0
    // &&  this.state.user.state
    // &&  this.state.user.state.length > 0
    // &&  this.state.user.city
    // &&  this.state.user.city.length > 0
    // &&  this.state.user.phone
    // &&  this.state.user.phone.length > 0
    // &&  this.state.user.site
    // &&  this.state.user.site.length > 0 ){
    //   return true
    // }
    // return false;
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-box box100">
                <div className="box-row-title">
                  <div className="box-title">Termos de uso</div>
                </div>
                <p className="text-blue mleft-40">
                  {this.state.termos.split('\n').map((item, key) => {
                    return (
                      <p key={key} className="text-blue">
                        {item}
                      </p>
                    );
                  })}
                </p>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terms));
