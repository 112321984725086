import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './Submenu';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';

const SPVALUE = 'Valor especifico';

class ProfileProfessional extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'profile_professional',
      subview: 'value',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados profissionais', link: '' },
        { title: 'Valores de referência', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      price_options: [
        'até R$150',
        'de R$151 a até R$300',
        'de R$301 a até R$500',
        'mais de R$500',
        'Sob consulta',
      ],
      specif_value: '',
      price: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.load();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  formValid() {
    return true;
    // if( this.state.price.length > 0 ){
    //   if(this.state.price == SPVALUE){
    //     if( this.state.specif_value.length < 1 ){
    //       return false;
    //     }
    //   }
    //   return true
    // }
    // return false;
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  changePrice(e) {
    this.setState({ price: e.currentTarget.value });
  }

  async save() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let specif_value =
        this.state.price == SPVALUE ? this.state.specif_value : '';
      let professional_value = {
        price: this.state.price,
        specif_value,
      };
      let user = { professional_value: JSON.stringify(professional_value) };
      let result = await this.service.save(user);
      if (result.success) {
        this.props.updateAlert('Salvo com sucesso');
        this.loadUser();
      }
      this.setState({ loading: false, specif_value });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  async load() {
    await this.setState({ loading: true });
    let result = await this.service.professional_value();
    if (result && result.professional_value != undefined) {
      await this.setState({
        price: result.professional_value.price,
        specif_value: result.professional_value.specif_value,
      });
    } else {
      await this.setState({ price: this.state.price_options[4] });
    }
    await this.setState({ loading: false });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <Submenu goTo={this.goTo} subview={this.state.subview} />

              <div className="dashboard-box box100-menu box-flex">
                <div>
                  <div className="box-row-title mobile-column">
                    <div className="box-title">Valores de referência</div>
                    <UploadImage
                      className="up-image-resp-new"
                      user={this.state.user}
                      uuser={this.loadUser}
                      newOption={true}
                    />
                  </div>
                  <div className="radius-content">
                    <div className="form-title">Custo por sessão</div>
                    {this.state.price_options.map((price, key) => {
                      return (
                        <p key={key}>
                          <input
                            type="radio"
                            name="sessions_price"
                            id={`rad${key}`}
                            value={price}
                            checked={this.state.price === price}
                            onChange={this.changePrice}
                          />
                          <label htmlFor={`rad${key}`}>{price}</label>
                        </p>
                      );
                    })}
                    <p>
                      <input
                        type="radio"
                        id="rad-vspe"
                        name="sessions_price"
                        value={SPVALUE}
                        checked={this.state.price === SPVALUE}
                        onChange={this.changePrice}
                      />
                      <label htmlFor="rad-vspe">Valor especifico:</label>
                      <input
                        type="text"
                        className="sbline-text"
                        value={this.state.specif_value}
                        onChange={e => {
                          this.setState({ specif_value: e.target.value });
                        }}
                      />
                    </p>
                  </div>
                </div>

                <div className="row-padding">
                  {this.state.loading ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <div
                      onClick={() => {
                        this.save();
                      }}
                      className={
                        !this.formValid()
                          ? 'bt-save hover-shadow'
                          : 'bt-save hover-shadow active'
                      }
                    >
                      Salvar Alterações
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileProfessional)
);
