import React, { Component } from 'react';
import { connect } from 'react-redux'; 

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';  
 

import { simpleAction } from '../../../actions/SimpleAction' 
 
 
import Utils from './../../../libs/Utils.js';
  

class SubmenuFinance extends Component { 

  constructor(props) {
    super(props);  
    this.state = { }; 
    this.utils = new Utils();
  }
 
  componentDidMount(){ 

  }

  componentWillUnmount() { 

  }
 

 
  render() { 

    return ( 
        <div className="submenu">
          <ul className="side-menu">
            <li className={ this.props.subview == 'provider' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/finances/register/provider') } } >
              <img src={ require('./../../../assets/images/finances/sub-0.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/finances/sub-0w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Fornecedores
                  </div> 
              </div>
            </li>
            <li className={ this.props.subview == 'service' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/finances/register/service') } } >
              <img src={ require('./../../../assets/images/finances/sub-1.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/finances/sub-1w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Serviços
                  </div> 
              </div>
            </li>
            <li className={ this.props.subview == 'category' ? 'active' : '' } 
                onClick={ () => { this.props.goTo('dashboard/finances/register/category') } }>
              <img src={ require('./../../../assets/images/finances/sub-2.svg') } width="28" /> 
              <img src={ require('./../../../assets/images/finances/sub-2w.svg') } width="28" className="image-over"  /> 
              <div className="popover-container"> 
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                      Categorias
                  </div> 
              </div>
            </li> 
          </ul>
        </div> 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuFinance);