import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import { Col, Row } from 'reactstrap';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
  updateFrom,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Cards from 'react-credit-cards';
import Utils from './../../../libs/Utils.js';

class CreditCard extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'plans',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: 'profile' },
        { title: 'Meu Cartão', link: 'profile/creditcard' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      cpf: '',
      name: '',
      number: '',
      cvc: '',
      expiry: '',
      focused: '',
      preview: false,
      adding: false,
      cardFlag: '',
      creditCardIsValid: '',
      cpfIsValid: '',
      validateExpirationIsValid: '',
      cvcIsValid: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  async componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.loadCard();
  }

  componentWillUnmount() {
    this.props.updateFrom('');
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;
    this.setState({ windowWidth: _ww });
  }

  async loadCard() {
    const { planFlow } = this.props;
    let result = await this.service.myCard();
    if (result && result.paymentMethod) {
      this.setState({
        adding: planFlow ? true : false,
        preview: planFlow ? false : true,
        name: planFlow ? '' : result.paymentMethod.data.holder_name,
        number: planFlow
          ? ''
          : `**** **** **** ${result.paymentMethod.data.last_digits}`,
        expiry: planFlow
          ? ''
          : `${
              result.paymentMethod.data.month
            }/${result.paymentMethod.data.year.toString().substring(2, 4)}`,
      });
    } else {
      this.setState({ adding: true });
    }
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  formValid() {
    return true;
  }

  async save() {
    const { Iugu } = window;
    const {
      // cpfIsValid,
      creditCardIsValid,
      cvcIsValid,
      validateExpirationIsValid,
      // cpf,
      number,
      cvc,
      expiry,
      name,
      cardFlag,
    } = this.state;
    if (
      creditCardIsValid === 'error' ||
      // || !cpfIsValid === 'error'
      cvcIsValid === 'error' ||
      validateExpirationIsValid === 'error' ||
      // || !cpf
      !number ||
      !cvc ||
      !expiry ||
      !name
    ) {
      this.props.updateAlert('Preencha todos os campos corretamente.');
      return;
    }
    this.setState({ loading: true });

    Iugu.setAccountID(process.env.REACT_APP_IUGU_ID);
    if (process.env.REACT_APP_IUGU_ENV === 'development') {
      Iugu.setTestMode(true);
    }
    const iuguCreditCard = Iugu.CreditCard(
      number.replace(/\D/g, ''), // Card number
      expiry.split('/')[0], // Expiration Month
      expiry.split('/')[1], // Expiration year
      name.substring(0, name.split(' ')[0].length), // Person`s first name
      name.replace(name.substring(0, name.split(' ')[0].length), ''), // surname
      cvc // CVC
    );

    await Iugu.createPaymentToken(iuguCreditCard, async response => {
      if (response.errors) {
        this.props.updateAlert('Não foi possível cadastrar o cartão.');
      } else {
        const data = {
          token: response.id,
          description: cardFlag,
        };
        const result = await this.service.saveCard(data);

        if (result && result.success) {
          if (!this.props.planFlow) {
            this.setState({
              adding: false,
              preview: false,
              name: '',
              number: '',
              expiry: '',
              cvc: '',
            });
            this.loadCard();
            if (this.props._from == 'plans') {
              this.props.updateFrom('');
              this.goTo('dashboard/profile/plans');
            }
            const { isSubscriptionCreated } = result;
            if (isSubscriptionCreated) {
              this.props.updateAlert(
                <>
                  Cartão cadastrado com sucesso!
                  <br /> Sua assinatura foi reativada!
                </>
              );
            } else {
              this.props.updateAlert('Cartão cadastrado com sucesso!');
            }
          } else {
            this.props.handleCreditCard(result.paymentMethod);
            this.props.updateAlert(
              <div>
                <p>Cartão cadastrado com sucesso!</p>
                <br />
                <div
                  className="bt-blue"
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    this.props.goBackToPlans();
                  }}
                >
                  Voltar para o carrinho
                </div>
              </div>
            );
          }
        }
      }
      this.setState({ loading: false });
    });
  }

  // handleCpf = (value) => {
  //   const cpf = value.replace(/\D/g, '');
  //   let cpfIsValid = '';
  //   if (cpf.length === 11) {
  //     cpfIsValid = this.utils.validateCPF(cpf) ? '' : 'error';
  //   }
  //   this.setState({ cpf: value, cpfIsValid })
  // }

  validateCard = (cardFlag, isValid) => {
    const { Iugu } = window;
    const { number, cvc } = this.state;
    let creditCardIsValid = '';
    const cardNumber = number.replace(/\D/g, '');
    if (cardNumber.length === 16) {
      creditCardIsValid =
        isValid && Iugu.utils.validateCreditCardNumber(cardNumber)
          ? ''
          : 'error';
      if (cvc) {
        this.handleCvc(cvc);
      }
    }
    this.setState({
      creditCardIsValid,
      cardFlag: cardFlag.issuer === 'unknown' ? '' : cardFlag.issuer,
    });
  };

  handleValidateExpiration = value => {
    const { Iugu } = window;
    let validateExpirationIsValid = '';
    if (value.replace(/\D/g, '').length === 4) {
      const isValid = Iugu.utils.validateExpirationString(value);
      validateExpirationIsValid = isValid ? '' : 'error';
    }
    this.setState({ expiry: value, validateExpirationIsValid });
  };

  handleCvc = value => {
    const { cardFlag } = this.state;
    const { Iugu } = window;
    let cvcIsValid = '';
    if (cardFlag && value.length > 2) {
      const isValid = Iugu.utils.validateCVV(value, cardFlag);
      cvcIsValid = isValid ? '' : 'error';
    }
    this.setState({ cvc: value, cvcIsValid });
  };

  openAdd() {
    this.setState({
      adding: true,
      preview: false,
      name: '',
      number: '',
      expiry: '',
    });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-box box100">
                <Col md={{ size: 12 }} lg={{ size: 12 }}>
                  <div
                    className="box-row-title mtop10"
                    style={{ justifyContent: 'unset' }}
                  >
                    <div className="box-title no-upper">
                      {this.state.adding
                        ? 'Cadastro de novo cartão'
                        : 'Meu Cartão'}
                    </div>
                  </div>

                  <Col md={{ size: 12 }} lg={{ size: 6, offset: 3 }}>
                    <div className="m30auto">
                      <Cards
                        number={this.state.number}
                        name={this.state.name}
                        expiry={this.state.expiry}
                        cvc={this.state.cvc}
                        focused={this.state.focused}
                        preview={this.state.preview}
                        callback={this.validateCard}
                        placeholders={{ name: 'NOME DO TITULAR' }}
                        locale={{ valid: 'VALIDADE' }}
                      />
                    </div>

                    {!this.state.adding && !this.props.planFlow ? (
                      <div
                        className="bt-blue"
                        style={{ margin: '20px auto' }}
                        onClick={() => this.openAdd()}
                      >
                        Novo Cartão
                      </div>
                    ) : (
                      <div>
                        {/* <div className="form-input">
                            <label className={`blue-label ${this.state.cpfIsValid}`}>CPF</label>
                            <InputMask
                              state={this.state}
                              mask="999.999.999-99"
                              value={this.state.cpf}
                              className={`blue-input ${this.state.cpfIsValid}`}
                              placeholder="CPF"
                              onChange={(e) => this.handleCpf(e.target.value)}
                              onFocus={() => this.setState({ focused: 'name' })}
                            />
                            {this.state.cpfIsValid === 'error'
                              && <span className="error-span" >CPF inválido</span>}
                          </div> */}

                        <div className="form-input">
                          <label className="blue-label">Nome</label>
                          <input
                            type="text"
                            value={this.state.name}
                            className="blue-input"
                            placeholder="Nome"
                            onChange={e =>
                              this.setState({
                                name: e.target.value.toUpperCase(),
                              })
                            }
                            onFocus={() => this.setState({ focused: 'name' })}
                          />
                        </div>

                        <div className="form-input">
                          <label
                            className={`blue-label ${this.state.creditCardIsValid}`}
                          >
                            Número
                          </label>
                          <InputMask
                            state={this.state}
                            mask="9999 9999 9999 9999"
                            value={this.state.number}
                            className={`blue-input ${this.state.creditCardIsValid}`}
                            placeholder="Número"
                            onChange={e =>
                              this.setState({ number: e.target.value })
                            }
                            onFocus={() => this.setState({ focused: 'number' })}
                          />
                          {this.state.creditCardIsValid === 'error' && (
                            <span className="error-span">Número inválido</span>
                          )}
                        </div>

                        <Col
                          xs={{ size: 12 }}
                          sm={{ size: 8 }}
                          md={{ size: 8 }}
                          className="remove-paddings"
                        >
                          <div className="form-input">
                            <label
                              className={`blue-label ${this.state.validateExpirationIsValid}`}
                            >
                              Validade
                            </label>
                            <InputMask
                              state={this.state}
                              mask="99/99"
                              value={this.state.expiry}
                              className={`blue-input ${this.state.validateExpirationIsValid}`}
                              placeholder="Validade"
                              onChange={e =>
                                this.handleValidateExpiration(e.target.value)
                              }
                              onFocus={() =>
                                this.setState({ focused: 'expiry' })
                              }
                            />
                            {this.state.validateExpirationIsValid ===
                              'error' && (
                              <span className="error-span">Data inválida</span>
                            )}
                          </div>
                        </Col>

                        <Col
                          xs={{ size: 12 }}
                          sm={{ size: 4 }}
                          md={{ size: 4 }}
                          className="remove-paddings pdl-10"
                        >
                          <div className="form-input">
                            <label
                              className={`blue-label ${this.state.cvcIsValid}`}
                            >
                              CVC
                            </label>
                            <InputMask
                              state={this.state}
                              mask="9999"
                              maskChar={null}
                              value={this.state.cvc}
                              className={`blue-input ${this.state.cvcIsValid}`}
                              placeholder="CVC"
                              onChange={e => this.handleCvc(e.target.value)}
                              onFocus={() => this.setState({ focused: 'cvc' })}
                              onBlur={() => this.setState({ focused: 'name' })}
                            />
                            {this.state.cvcIsValid === 'error' && (
                              <span className="error-span">CVC inválido</span>
                            )}
                          </div>
                        </Col>
                      </div>
                    )}
                  </Col>
                </Col>
                {this.state.loading ? (
                  <div className="row-loading">
                    <ReactLoading type="bubbles" color="#00838F" />
                  </div>
                ) : (
                  <Col
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 12 }}
                    className="remove-paddings"
                  >
                    <Row className="btn-footer-credit-card">
                      {this.props.planFlow && (
                        <div
                          className="bt-blue-outline"
                          onClick={() => this.props.goBackToPlans()}
                        >
                          Voltar
                        </div>
                      )}
                      {this.state.adding && (
                        <div className="bt-blue" onClick={() => this.save()}>
                          Salvar
                        </div>
                      )}
                    </Row>
                  </Col>
                )}
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  _from: state.simpleReducer._from,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateImage, updateUser, updateAlert, updateFrom },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditCard)
);
