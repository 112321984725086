import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateImage } from '../../actions/SimpleAction';

import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Alert,
} from 'reactstrap';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import './../../styles/css/index.css';

import logo from '../../assets/images/logo-facebook.jpg';

class Auth extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      password_type: 'password',
      pass_visible: false,
      loading: false,
      loading_facebook: false,
    };

    this.service = new Service();
    this.utils = new Utils();

    this.login = this.login.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }

  componentDidMount() {
    this.verifyLogin();
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  async login() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let user = {
        email: this.state.email,
        password: this.state.password,
      };
      let result = await this.service.loginAdmin(user);
      this.doLogin(result);
    } else {
      alert('Preencha todos os campos');
    }
  }

  doLogin(result) {
    if (result && result.success) {
      this.service.setToken(result.token);
      this.utils.setStorage('atoken', 'a');
      this.utils.setStorage('logged_user', result.user);
      this.props.updateImage('');
      this.goTo('admin/dashboard');
    } else {
      this.resolveError(result);
      this.setState({ loading: false });
    }
  }

  formValid() {
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      return true;
    }
    return false;
  }

  resolveError(result) {
    if (result && result.messages && result.messages.length > 0) {
      let messages = '';
      result.messages.map((message, mkey) => {
        messages += `${message}\n`;
      });
      alert(messages);
    } else {
      alert('Tenta novamente mais tarde');
    }
    this.setState({ loading: false });
  }

  verifyLogin() {
    if (this.utils.isLogged()) {
      // this.goTo('dashboard')
    }
  }

  togglePassword() {
    if (this.state.pass_visible) {
      this.setState({ pass_visible: false });
    } else {
      this.setState({ pass_visible: true });
    }
  }

  async responseFacebook(response) {
    this.setState({ loading_facebook: true });
    let _payload = { access_token: response.accessToken };
    let result = await this.service.loginFacebook(_payload);
    this.doLogin(result);
    this.setState({ loading_facebook: false });
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <img src={logo} width={160} style={{ margin: '70px auto 0px' }} />
        </div>
        <Col xs="12" className="mtop30">
          <div className="card-shadow">
            <p className="card-title">Área administrativa</p>

            <input
              type="email"
              placeholder="EMAIL"
              value={this.state.email}
              className="cool-input"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />

            <input
              type={this.state.pass_visible ? 'text' : 'password'}
              placeholder="SENHA"
              value={this.state.password}
              className="cool-input input-password"
              onChange={e => {
                this.setState({ password: e.target.value });
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.login();
                }
              }}
            />
            <i
              className={
                this.state.pass_visible
                  ? 'far fa-eye-slash icon-password'
                  : 'far fa-eye icon-password'
              }
              onClick={() => {
                this.togglePassword();
              }}
            ></i>

            <div className="termos" style={{ textAlign: 'center' }}>
              <Link to="dashboard" className="color-ggren">
                Voltar
              </Link>
            </div>
          </div>
          <div className="container-bt-confirm">
            {this.state.loading ? (
              <ReactLoading type="bubbles" color="#B1CD49" />
            ) : (
              <div
                onClick={() => {
                  this.login();
                }}
                className={
                  !this.formValid()
                    ? 'bt-confirm hover-shadow'
                    : 'bt-confirm hover-shadow avaiable'
                }
              >
                <p className="bt-label-o">Entrar</p>
              </div>
            )}
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
);
