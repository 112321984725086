import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import { saveAs } from 'file-saver';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import Sidebar from '../../sidebar/Sidebar';
import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Utils from './../../../libs/Utils.js';
import Submenu from './Submenu';

class Analytics extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'analytics',
      side: 'coachs',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Analytics', link: 'analytics' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      loading_contents: false,
      list_contents: [],

      title_rows: [
        { title: 'Nome', key: '_profile' },
        { title: 'Email', key: 'email' },
        { title: 'Último Login', key: 'last_login' },
        { title: 'Último(a) Logout/Atividade', key: 'last_logout' },
        { title: 'Tempo Total de Uso (Min)', key: 'activity_total_time' },
        { title: 'Tempo restante de teste', key: 'test_status' },
        { title: 'Coachees ativos', key: 'num_coachees' },
        {
          title: 'Quantidade de Propostas Emitidas',
          key: 'num_emitted_proposals',
        },
        {
          title: 'Quantidade de espaço consumido (MB)',
          key: 'consumed_storage',
        },
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.load();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  getTestStatus = coach => {
    if (coach.test_details.isTestExpired) return 'Concluído';
    if (!coach.test_details.isTestStarted) return 'NI';

    const expiresTest = moment(coach.test_details.testExpiresAt);
    const daysRemaining = expiresTest.diff(moment().startOf('day'), 'days');
    return `${daysRemaining}`;
  };

  async load() {
    this.setState({ loading_contents: true });
    let result = await this.service.read();
    if (result && result.success) {
      this.setState({
        list_contents: result.coachs.map(coach => ({
          ...coach,
          last_login:
            parseInt(coach.login_logout.login_at, 10) === 0
              ? '-'
              : format(
                  parseInt(coach.login_logout.login_at, 10),
                  'dd/MM/yyyy HH:mm'
                ),
          last_logout:
            parseInt(coach.login_logout.last_activity_or_logout.at, 10) === 0
              ? '-'
              : `${format(
                  parseInt(coach.login_logout.last_activity_or_logout.at, 10),
                  'dd/MM/yyyy HH:mm'
                )} ${
                  coach.login_logout.last_activity_or_logout.type === 'activity'
                    ? '(A)'
                    : '(L)'
                }`,
          activity_total_time: coach.activity_total_time || '0',
          num_coachees: coach.num_coachees || '0',
          num_emitted_proposals: coach.num_emitted_proposals || '0',
          test_status: this.getTestStatus(coach),
          consumed_storage: coach.consumed_storage.toFixed(2) || '0',
        })),
      });
    }
    this.setState({ loading_contents: false });
  }

  async exportContentsToExcel() {
    if (!this.state.list_contents) return;
    const blob = this.utils.jsonToExcel(
      'xlsx',
      this.state.list_contents.map(coach => ({
        email: coach.email,
        last_login: coach.last_login,
        last_logout: coach.last_logout,
        activity_total_time: parseInt(coach.activity_total_time, 10),
        test_status: coach.test_status,
        num_coachees: parseInt(coach.num_coachees, 10),
        num_emitted_proposals: parseInt(coach.num_emitted_proposals, 10),
        engage_plan: coach.searcher,
        platform_plan: coach.platform,
        consumed_storage: parseFloat(coach.consumed_storage).toFixed(2),
      })),
      'coachs'
    );
    saveAs(blob, 'climby_analytics.xlsx');
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview="analytics" />
                <div className="dashboard-boxes zoomed dashboard-boxes-with-margin-left">
                  <CardGenericTable
                    id="admin-dashboard-coachs-1"
                    title={this.state.breadcrumbs[1].title}
                    state={this.state}
                    title_rows={this.state.title_rows}
                    rows={this.state.list_contents}
                  />
                </div>
              </div>
              <div className="row-start">
                <div className="row-button">
                  <div
                    className="bt-blue margin-left-25"
                    onClick={() => this.exportContentsToExcel()}
                  >
                    <img
                      src={require('./../../../assets/images/buttons/add_plus.svg')}
                      style={{ marginTop: -3, marginRight: 14 }}
                      alt="add_plus"
                    />
                    Exportar
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics)
);
