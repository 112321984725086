import ApiService from "../../../services/ApiService";

class Service extends ApiService {
  async states() {
    try {
      let response = await fetch(`${this.endPoint}location/state`, {
        method: "GET"
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async cities(state) {
    try {
      let response = await fetch(`${this.endPoint}location/${state}/cities`, {
        method: "GET"
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async save(_payload) {
    try {
      let response = await fetch(`${this.endPoint}admins/me`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: `JWT ${this.getToken()}`
        },
        body: this.parsePayload(_payload)
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveInstitute(_payload) {
    try {
      let response = await fetch(`${this.endPoint}options/institutes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: `JWT ${this.getToken()}`
        },
        body: this.parsePayload(_payload)
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postImage(fileToUpload) {
    const formData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    try {
      let response = await fetch(`${this.endPoint}admins/upload/image`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${this.getToken()}`
        },
        body: formData
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async institutes() {
    try {
      let response = await fetch(`${this.endPoint}options/institutes`, {
        method: "GET"
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async options_professional() {
    try {
      let response = await fetch(`${this.endPoint}options/professional`, {
        method: "GET"
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async options_life() {
    try {
      let response = await fetch(`${this.endPoint}options/life`, {
        method: "GET"
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_area() {
    try {
      let response = await fetch(`${this.endPoint}user/me/professional/area`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${this.getToken()}`
        }
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_description() {
    try {
      let response = await fetch(
        `${this.endPoint}user/me/professional/description`,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${this.getToken()}`
          }
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_session() {
    try {
      let response = await fetch(
        `${this.endPoint}user/me/professional/session`,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${this.getToken()}`
          }
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_value() {
    try {
      let response = await fetch(`${this.endPoint}user/me/professional/value`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${this.getToken()}`
        }
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeImage(idUser) {
    try {
      let response = await fetch(`${this.endPoint}admins/delete/image`, {
        method: "DELETE",
        headers: {
          Authorization: `JWT ${this.getToken()}`
        }
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
