import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';

import { Col } from 'reactstrap';

import { updateImage, updateAlert } from '../../../actions/SimpleAction';

import UploadFile from '../Create/UploadFile';

import Utils from '../../../libs/Utils';
import Service from '../Service';

class ModalContentUpload extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      loading_category: false,
      loading: false,
      categories: [],
      islink: false,
      tag: '',
      title: '',
      link: 'https://',
      source: '',
      doc_source: '',
      doc_format: '',
      html: '',
      original_name: '',
      maxLength: 3660,
      notConvert: false,
      statusSave: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.goTo = this.goTo.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.save = this.save.bind(this);
    this.openUpload = this.openUpload.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.loadCategory();
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  selectCategory(category) {
    this.setState({ category });
  }

  formValid() {
    if (
      this.state.islink &&
      this.state.link !== '' &&
      this.state.link !== 'https://' &&
      this.isValidUrl(this.state.link)
    ) {
      return true;
    }

    if (
      this.state.doc_source !== '' &&
      this.state.doc_format !== '' &&
      this.state.original_name !== ''
    ) {
      return true;
    }
    return false;
  }

  isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

  async save() {
    if (!this.formValid()) {
      alert(
        'Por favor selecione um arquivo para upload ou informe um link válido.'
      );
      return;
    }
    if (this.state.user && !this.state.user.limits.storage.canUpload) {
      this.props.toggle(false);
      this.props.updateAlert(
        <div>
          <p>
            Limite de upload do plano atingido, aumente seu plano ou apague
            conteúdos para continuar
          </p>
          <br />{' '}
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    } else {
      this.setState({ loading: true });
      if (this.state.islink) {
        this.saveContent();
      } else {
        this.setState(() => ({
          statusSave: true,
        }));
      }
    }
  }

  saveContent = async () => {
    let _pay = {
      title: this.state.title !== '' ? this.state.title : 'Título',
      category:
        this.state.category === undefined ? 'Ferramentas' : this.state.category,
      original_name: this.state.original_name,
      tag: this.state.tag !== '' ? this.state.tag : 'Modelo 1',
      personal: true,
      doc_source: this.state.islink ? this.state.link : this.state.doc_source,
      doc_format: this.state.islink ? 'LINK' : this.state.doc_format,
      html: this.state.html,
      status: true,
    };

    const result = await this.service.saveContent(_pay);

    if (result && result.success) {
      this.props.toggle(true);
    }
    this.setState({ loading: false });
  };

  errorContent = async () => {
    this.setState({ loading: false });
    this.setState(() => ({
      statusSave: false,
    }));
    this.removeAttachment();
  };

  async loadCategory() {
    this.setState({ loading_category: true });
    let result = await this.service.categories();
    if (result && result.success) {
      this.setState({ categories: result.categories });
    }
    this.setState({ loading_category: false });
  }

  resolveFile(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  openUpload() {
    document.getElementById('filefile').click();
  }

  removeAttachment() {
    this.setState({ doc_source: '', doc_format: '' });
  }

  render() {
    return (
      <div className="modal-content active">
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">Upload de Conteúdo</div>
            <i
              className="fas fa-times"
              onClick={() => this.props.toggle(false)}
            ></i>
          </div>
          <div className="top-textarea" style={{ overflow: 'auto' }}>
            <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
              <div className="form-input">
                <label className="blue-label">Título</label>
                <input
                  type="text"
                  value={this.state.title}
                  className="blue-input"
                  placeholder="Título"
                  onChange={e => {
                    this.setState({ title: e.target.value });
                  }}
                />
              </div>

              <label className="container-ckb ">
                <input
                  type="checkbox"
                  checked={this.state.islink}
                  onChange={ev => {
                    this.setState({ islink: !this.state.islink });
                  }}
                />
                <span className="chklbl">É um link</span>
                <span className="checkmark"></span>
              </label>

              <label className="container-ckb" style={{ paddingBottom: 30 }}>
                <input
                  type="checkbox"
                  checked={this.state.notConvert}
                  onChange={ev => {
                    this.setState({ notConvert: !this.state.notConvert });
                  }}
                />
                <div className="container-pop">
                  <span className="chklbl">Não converter</span>
                  <div className="popover-container">
                    <div className="popover-content">
                      Ao clicar neste Box seu arquivo será carregado em seu
                      formato original sem converter para edicao.
                    </div>
                  </div>
                </div>
                <span className="checkmark"></span>
              </label>

              {this.state.islink ? (
                <div className="form-input">
                  <label className="blue-label">Link</label>
                  <input
                    type="text"
                    value={this.state.link}
                    className="blue-input"
                    placeholder="Link"
                    onChange={e => {
                      this.setState({ link: e.target.value });
                    }}
                  />
                </div>
              ) : (
                <UploadFile
                  notConvert={this.state.notConvert}
                  setState={this.setState.bind(this)}
                  doc_format={this.state.doc_format}
                  doc_source={this.state.doc_source}
                  state={this.state}
                  saveContent={this.saveContent}
                  errorContent={this.errorContent}
                />
              )}

              <div className="form-input">
                {this.state.loading_category ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="form-input">
                    <label className="blue-label">Categoria</label>
                    <select
                      value={this.state.category}
                      className="blue-input"
                      onChange={e => {
                        this.selectCategory(e.target.value);
                      }}
                    >
                      {this.state.categories.map((item, key) => {
                        return (
                          <option key={key} value={item.name}>
                            {' '}
                            &nbsp; {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>

              <div className="form-input">
                <label className="blue-label">Modelo</label>
                <input
                  type="text"
                  value={this.state.tag}
                  className="blue-input"
                  placeholder="Modelo"
                  onChange={e => {
                    this.setState({ tag: e.target.value });
                  }}
                />
              </div>
            </Col>
          </div>
          <div className="modal-row-actions">
            {this.state.loading ? (
              <ReactLoading type="bubbles" color="#00838F" />
            ) : (
              <div
                className="bt-blue"
                onClick={() => {
                  this.save();
                }}
              >
                <img
                  src={require('../../../assets/images/file_upload.svg')}
                  style={{ marginTop: -3, marginRight: 14 }}
                />
                Upload
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const ModalContentUploadToggler = ({ show, ...rest }) => {
  return show && <ModalContentUpload {...rest} />;
};

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalContentUploadToggler)
);
