// eslint-disable-next-line no-unused-vars
import React, { useRef, useEffect } from 'react';

import ApiService from '../../services/ApiService';

const apiService = new ApiService();

function useActivitySocket(userId) {
  const socketRef = useRef();

  useEffect(() => {
    if (!userId) return;

    const query = apiService.parsePayload({
      channel: userId,
      type: 'activity',
      token: apiService.getToken(),
    });

    socketRef.current = apiService.createSocket(query);

    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, [userId]);

  return socketRef.current;
}

function ActivityMonitor({ children, userId }) {
  useActivitySocket(userId);

  return <>{children}</>;
}

export default ActivityMonitor;
