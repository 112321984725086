/**
 * Action Types
 */
export const RENDER_SIDEBAR_CONTENTS = 'RENDER_SIDEBAR_CONTENTS'
export const RENDER_SIDEBAR_FINANCES = 'RENDER_SIDEBAR_FINANCES'
export const RENDER_SIDEBAR_PROPOSES = 'RENDER_SIDEBAR_PROPOSES'
export const RENDER_SIDEBAR_REPORTS = 'RENDER_SIDEBAR_REPORTS'
export const RENDER_SIDEBAR_SESSIONS = 'RENDER_SIDEBAR_SESSIONS'

/**
 * Set Sidebar Contents
 */
export const SetSidebarContents = () => dispatch => {
  dispatch({
    type: RENDER_SIDEBAR_CONTENTS,
    payload: 'SidebarContents'
  })
}

/**
 * Set Sidebar Finances
 */
export const SetSidebarFinances = () => dispatch => {
  dispatch({
    type: RENDER_SIDEBAR_FINANCES,
    payload: 'SidebarFinances'
  })
}

/**
 * Set Sidebar Proposes
 */
export const SetSidebarProposes = () => dispatch => {
  dispatch({
    type: RENDER_SIDEBAR_PROPOSES,
    payload: 'SidebarProposes'
  })
}

/**
 * Set Sidebar Reports
 */
export const SetSidebarReports = () => dispatch => {
  dispatch({
    type: RENDER_SIDEBAR_REPORTS,
    payload: 'SidebarReports'
  })
}

/**
 * Set Sidebar Sessions
 */
export const SetSidebarSessions = () => dispatch => {
  dispatch({
    type: RENDER_SIDEBAR_SESSIONS,
    payload: 'SidebarSessions'
  })
}