import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Sidebar from '../../sidebar/Sidebar';
import ReactLoading from 'react-loading';
import Switch from 'react-switch';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Submenu from './Submenu.js';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import BigCalendar from 'react-big-calendar';
import moment from 'moment/min/moment-with-locales';
import UploadImage from './UploadImage';
import { getSitePath } from './../../../libs/Utils.js';

const localizer = BigCalendar.momentLocalizer(moment);

class Messages extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      subview: 'site',
      side: 'marketing',
      tab: 'informations',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Marketing', link: 'dashboard/marketing/site' },
        { title: 'Website', link: 'dashboard/marketing/site' },
      ],
      showToolTip: false,
      wesite_available: false,
      show: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      website_name: '',
      about_me: '',
      experience: '',
      certifications: '',
      references: '',
      facebook: '',
      google_plus: '',
      linkedin: '',
      instagram: '',
      twitter: '',
      youtube: '',
      layout_model: '',
      preview_uri: '',
      iframek: 0,
      publicated: false,
      logo: '',
      photo: '',
      editing: false,
      maxLength: 3660,
      loading: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.changeTab = this.changeTab.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadMarketing();
    window.addEventListener('resize', this.handleResize);
    moment.locale('pt-BR');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async verifyWebsiteName() {
    if (this.state.website_name) {
      let result = await this.service.verifyMarketingUsed({
        website_name: this.state.website_name,
      });
      if (result && result.success) {
        this.setState({ wesite_available: !result.used });
      }
    } else {
      this.setState({ wesite_available: false });
    }
  }

  async loadMarketing() {
    let result = await this.service.marketingMe();
    if (result && result.success) {
      if (result.marketing && result.marketing != 'null') {
        this.setState({
          preview_uri: `${getSitePath()}/climby-preview/${
            result.marketing.website_name
          }/preview`,
          website_name: result.marketing.website_name || '',
          about_me: result.marketing.about_me || '',
          experience: result.marketing.experience || '',
          certifications: result.marketing.certifications || '',
          references: result.marketing.references || '',
          facebook: result.marketing.facebook || '',
          instagram: result.marketing.instagram || '',
          google_plus: result.marketing.google_plus || '',
          twitter: result.marketing.twitter || '',
          linkedin: result.marketing.linkedin || '',
          youtube: result.marketing.youtube || '',
          wesite_available:
            result.marketing.website_name &&
            result.marketing.website_name.length > 0,

          layout_model: result.marketing.layout_model || '',
          publicated: result.marketing.publicated || false,
          logo: result.marketing.logo,
          photo: result.marketing.photo,

          editing_id: result.marketing._id,
          editing: true,
        });
      } else {
        this.setState({ show: true });
      }
    }
  }

  changeTab(tab) {
    this.setState({ tab });
  }

  toggleModal() {
    this.setState({ show: !this.state.show });
  }

  async save(typeUpdate = '') {
    this.setState({ loading: true });
    let _pay = {
      website_name: this.state.website_name,
      about_me: this.state.about_me,
      experience: this.state.experience,
      certifications: this.state.certifications,
      references: this.state.references,
      facebook: this.state.facebook,
      instagram: this.state.instagram,
      google_plus: this.state.google_plus,
      twitter: this.state.twitter,
      linkedin: this.state.linkedin,
      youtube: this.state.youtube,
      layout_model: this.state.layout_model,
      publicated: this.state.publicated,
      logo: this.state.logo,
      photo: this.state.photo,
      typeUpdate,
      typeMarketing: 'site',
    };

    let result = !this.state.editing
      ? await this.service.saveMarketing(_pay)
      : await this.service.updateMarketing(_pay, this.state.editing_id);

    if (result && result.success) {
      this.props.updateAlert('Salvo com sucesso');
      if (!this.state.editing) {
        this.setState({
          editing: true,
          editing_id: result.inserted_id,
        });
      }
      this.loadUser();
      this.loadMarketing();
      this.setState({ loading: false });
    }
  }

  saveLogo(logo) {
    this.setState({ logo });
    this.save('logo');
  }

  savePhoto(photo) {
    this.setState({ photo });
    this.save('photo');
  }

  async refreshPreview(layout_model) {
    let _pay = {
      layout_model,
      typeMarketing: 'layout',
    };
    this.setState({ layout_model });
    let result = !this.state.editing
      ? await this.service.saveMarketing(_pay)
      : await this.service.updateMarketing(_pay, this.state.editing_id);

    setTimeout(() => {
      this.setState({ iframek: this.state.iframek + 1 });
    }, 300);
  }

  serverRoot() {
    return `${this.service.getSite()}`;
  }

  loadViewImg = type => {
    if (type === 'photo') {
      this.setState({ photo: '' });
    }
    if (type === 'logo') {
      this.setState({ logo: '' });
    }
  };

  render() {
    return (
      <div className="main-content zoomresolve">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />

                {(this.state.user &&
                  this.state.user.plans &&
                  !this.state.user.plans.platform) ||
                (this.state.user &&
                  this.state.user.plans &&
                  this.state.user.plans.platform &&
                  !this.state.user.plans.platform.plan.marketing_site) ? (
                  <NotAllowed
                    go={this.goTo.bind(this)}
                    user={this.state.user}
                  />
                ) : (
                  <div className="dashboard-box box100-menu">
                    <div className="row-center mtop-20">
                      <div
                        className={
                          this.state.tab == 'informations'
                            ? 'option-green'
                            : 'option-grey'
                        }
                        onClick={() => {
                          this.changeTab('informations');
                        }}
                      >
                        Informações
                      </div>
                      <div
                        className={
                          this.state.tab == 'layout'
                            ? 'option-green'
                            : 'option-grey'
                        }
                        onClick={() => {
                          this.changeTab('layout');
                        }}
                      >
                        Layout e Publicação
                      </div>
                    </div>

                    {this.state.tab == 'informations' ? (
                      <div>
                        <Col
                          className="mtop-20"
                          sm={{ size: 12 }}
                          md={{ size: 6, offset: 3 }}
                        >
                          <div className="form-input mtop-20">
                            <label className="blue-label">
                              Nome do Website
                            </label>
                            <input
                              type="text"
                              value={
                                this.state.website_name.trim() !== '' &&
                                this.state.website_name !== undefined &&
                                this.state.website_name !== 'undefined' &&
                                this.state.website_name !== null
                                  ? this.state.website_name
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Nome do Website"
                              onChange={e => {
                                this.setState({
                                  website_name: e.target.value.replace(
                                    /\ |\//g,
                                    ''
                                  ),
                                });
                              }}
                              onBlur={() => {
                                this.verifyWebsiteName();
                              }}
                            />
                          </div>

                          <div
                            className="text-site row-center-center"
                            style={{
                              cursor: 'pointer',
                              wordBreak: 'break-all',
                            }}
                            onClick={() => {
                              this.goTo(`climby/${this.state.website_name}`);
                            }}
                          >
                            {this.serverRoot()}
                            {this.state.website_name}
                          </div>
                          <div
                            className={
                              this.state.wesite_available
                                ? 'text-site-available row-center-center'
                                : 'text-site-unavailable row-center-center'
                            }
                          >
                            {this.state.wesite_available ? (
                              <i className="fas fa-check" />
                            ) : (
                              <i className="fas fa-times" />
                            )}
                            Nome{' '}
                            {this.state.wesite_available
                              ? 'disponível'
                              : 'indisponível'}
                          </div>

                          <div className="row-form">
                            <div className="form-textarea clear-textarea">
                              <label className="blue-label">Sobre mim</label>
                              <textarea
                                value={
                                  this.state.about_me.trim() !== '' &&
                                  this.state.about_me !== undefined &&
                                  this.state.about_me !== 'undefined' &&
                                  this.state.about_me !== null
                                    ? this.state.about_me
                                    : ''
                                }
                                className="blue-input"
                                placeholder="Sobre mim"
                                maxLength={this.state.maxLength}
                                onChange={e => {
                                  this.setState({ about_me: e.target.value });
                                }}
                              />
                              <span>
                                {this.state.about_me
                                  ? this.state.about_me.length
                                  : 0}
                                /{this.state.maxLength}
                              </span>
                            </div>
                          </div>

                          <div className="row-form mtop-10">
                            <div className="form-textarea clear-textarea">
                              <label className="blue-label">Experiência</label>
                              <textarea
                                value={
                                  this.state.experience.trim() !== '' &&
                                  this.state.experience !== undefined &&
                                  this.state.experience !== 'undefined' &&
                                  this.state.experience !== null
                                    ? this.state.experience
                                    : ''
                                }
                                className="blue-input"
                                placeholder="Experiência"
                                maxLength={this.state.maxLength}
                                onChange={e => {
                                  this.setState({ experience: e.target.value });
                                }}
                              />
                              <span>
                                {this.state.experience
                                  ? this.state.experience.length
                                  : 0}
                                /{this.state.maxLength}
                              </span>
                            </div>
                          </div>

                          <div className="row-form mtop-10">
                            <div className="form-textarea clear-textarea">
                              <label className="blue-label">
                                Certificações
                              </label>
                              <textarea
                                value={
                                  this.state.certifications.trim() !== '' &&
                                  this.state.certifications !== undefined &&
                                  this.state.certifications !== 'undefined' &&
                                  this.state.certifications !== null
                                    ? this.state.certifications
                                    : ''
                                }
                                className="blue-input"
                                placeholder="Certificações"
                                maxLength={this.state.maxLength}
                                onChange={e => {
                                  this.setState({
                                    certifications: e.target.value,
                                  });
                                }}
                              />
                              <span>
                                {this.state.certifications
                                  ? this.state.certifications.length
                                  : 0}
                                /{this.state.maxLength}
                              </span>
                            </div>
                          </div>

                          <div className="row-form mtop-10">
                            <div className="form-textarea clear-textarea">
                              <label className="blue-label">Rêferencias</label>
                              <textarea
                                value={
                                  this.state.references.trim() !== '' &&
                                  this.state.references !== undefined &&
                                  this.state.references !== 'undefined' &&
                                  this.state.references !== null
                                    ? this.state.references
                                    : ''
                                }
                                className="blue-input"
                                placeholder="Rêferencias"
                                maxLength={this.state.maxLength}
                                onChange={e => {
                                  this.setState({ references: e.target.value });
                                }}
                              />
                              <span>
                                {this.state.references
                                  ? this.state.references.length
                                  : 0}
                                /{this.state.maxLength}
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col sm={{ size: 12 }} md={{ size: 12 }}>
                          <div className="box-row-title row-center">
                            <div className="box-title">Redes Sociais</div>
                          </div>
                        </Col>

                        <Col sm={{ size: 12 }} md={{ size: 5, offset: 1 }}>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Facebook</label>
                            <input
                              type="text"
                              value={
                                this.state.facebook.trim() !== '' &&
                                this.state.facebook !== undefined &&
                                this.state.facebook !== 'undefined' &&
                                this.state.facebook !== null
                                  ? this.state.facebook
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Facebook"
                              onChange={e => {
                                this.setState({ facebook: e.target.value });
                              }}
                            />
                          </div>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Google+</label>
                            <input
                              type="text"
                              value={
                                this.state.google_plus.trim() !== '' &&
                                this.state.google_plus !== undefined &&
                                this.state.google_plus !== 'undefined' &&
                                this.state.google_plus !== null
                                  ? this.state.google_plus
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Google+"
                              onChange={e => {
                                this.setState({ google_plus: e.target.value });
                              }}
                            />
                          </div>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Linkedin</label>
                            <input
                              type="text"
                              value={
                                this.state.linkedin.trim() !== '' &&
                                this.state.linkedin !== undefined &&
                                this.state.linkedin !== 'undefined' &&
                                this.state.linkedin !== null
                                  ? this.state.linkedin
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Linkedin"
                              onChange={e => {
                                this.setState({ linkedin: e.target.value });
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={{ size: 12 }} md={{ size: 5 }}>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Instagram</label>
                            <input
                              type="text"
                              value={
                                this.state.instagram.trim() !== '' &&
                                this.state.instagram !== undefined &&
                                this.state.instagram !== 'undefined' &&
                                this.state.instagram !== null
                                  ? this.state.instagram
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Instagram"
                              onChange={e => {
                                this.setState({ instagram: e.target.value });
                              }}
                            />
                          </div>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Twitter</label>
                            <input
                              type="text"
                              value={
                                this.state.twitter.trim() !== '' &&
                                this.state.twitter !== undefined &&
                                this.state.twitter !== 'undefined' &&
                                this.state.twitter !== null
                                  ? this.state.twitter
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Twitter"
                              onChange={e => {
                                this.setState({ twitter: e.target.value });
                              }}
                            />
                          </div>
                          <div className="form-input mtop-20">
                            <label className="blue-label">Youtube</label>
                            <input
                              type="text"
                              value={
                                this.state.youtube.trim() !== '' &&
                                this.state.youtube !== undefined &&
                                this.state.youtube !== 'undefined' &&
                                this.state.youtube !== null
                                  ? this.state.youtube
                                  : ''
                              }
                              className="blue-input"
                              placeholder="Youtube"
                              onChange={e => {
                                this.setState({ youtube: e.target.value });
                              }}
                            />
                          </div>
                        </Col>

                        <Col sm={{ size: 12 }} md={{ size: 12 }}>
                          <div className="row-center mbottom-20">
                            {this.state.loading ? (
                              <ReactLoading type="bubbles" color="#00838F" />
                            ) : (
                              <div
                                className="bt-blue"
                                onClick={() => {
                                  this.save();
                                }}
                              >
                                {this.state.editing ? 'Salvar ' : 'Salvar '}
                              </div>
                            )}
                          </div>
                        </Col>
                      </div>
                    ) : (
                      <div>
                        <Col sm={{ size: 12 }} md={{ size: 6 }}>
                          <div
                            className="box-row-title row-center"
                            style={{ paddingBottom: 0, paddingTop: 30 }}
                          >
                            <div className="box-title">Selecione o Modelo</div>
                          </div>

                          <div
                            className="row-around row-models"
                            style={{ paddingTop: 0 }}
                          >
                            <img
                              src={require('./../../../assets/images/marketing/1.svg')}
                              width="90"
                              height="150"
                              style={{
                                opacity: this.state.layout_model == 0 ? 1 : 0.4,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.refreshPreview(0);
                              }}
                            />
                            <img
                              src={require('./../../../assets/images/marketing/2.svg')}
                              width="90"
                              height="150"
                              style={{
                                opacity: this.state.layout_model == 1 ? 1 : 0.4,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.refreshPreview(1);
                              }}
                            />

                            <img
                              src={require('./../../../assets/images/marketing/3.svg')}
                              width="90"
                              height="150"
                              style={{
                                opacity: this.state.layout_model == 2 ? 1 : 0.4,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.refreshPreview(2);
                              }}
                            />
                            <img
                              src={require('./../../../assets/images/marketing/4.svg')}
                              width="90"
                              height="150"
                              style={{
                                opacity: this.state.layout_model == 3 ? 1 : 0.4,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.refreshPreview(3);
                              }}
                            />
                          </div>

                          <Col sm={{ size: 12 }} md={{ size: 6 }}>
                            <div className="box-row-title row-center">
                              <div className="box-title">Foto</div>
                            </div>
                            <UploadImage
                              image={this.state.photo}
                              loadViewImg={this.loadViewImg}
                              idMarketing={this.state.editing_id}
                              saveImage={this.savePhoto.bind(this)}
                              upref="photo"
                            />
                          </Col>
                          <Col sm={{ size: 12 }} md={{ size: 6 }}>
                            <div className="box-row-title row-center">
                              <div className="box-title">Logotipo</div>
                            </div>
                            <UploadImage
                              image={this.state.logo}
                              loadViewImg={this.loadViewImg}
                              idMarketing={this.state.editing_id}
                              saveImage={this.saveLogo.bind(this)}
                              upref="logo"
                            />
                          </Col>
                        </Col>

                        <Col sm={{ size: 12 }} md={{ size: 6 }}>
                          {this.state.preview_uri.length < 1 ? (
                            <div className="box-preview">Preview</div>
                          ) : (
                            <iframe
                              title={this.state.preview_uri}
                              frameBorder="0"
                              key={this.state.iframek}
                              src={this.state.preview_uri}
                              className="box-preview"
                            />
                          )}

                          <div className="active-site row-center-center flex1">
                            <span>PUBLICAR</span>
                            <Switch
                              onChange={() => {
                                this.setState({
                                  publicated: !this.state.publicated,
                                });
                              }}
                              id={`toggle-visibility`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#D8D8D8'}
                              offHandleColor={'#9F9F9F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              width={40}
                              height={20}
                              checked={this.state.publicated}
                            />
                          </div>
                        </Col>

                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 12 }}
                          className="mtop-20"
                        >
                          <div className="row-center mbottom-20 mtop-20">
                            {this.state.loading ? (
                              <ReactLoading type="bubbles" color="#00838F" />
                            ) : (
                              <div
                                className="bt-blue"
                                onClick={() => {
                                  this.save();
                                }}
                              >
                                {this.state.editing ? 'Salvar ' : 'Salvar '}
                              </div>
                            )}
                          </div>
                        </Col>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        {(this.state.user &&
          this.state.user.plans &&
          !this.state.user.plans.platform) ||
        (this.state.user &&
          this.state.user.plans &&
          this.state.user.plans.platform &&
          !this.state.user.plans.platform.plan.marketing_site) ? null : (
          <div
            className={
              this.state.show ? 'modal-content active' : 'modal-content'
            }
          >
            <Col sm={{ size: 12 }} md={{ size: 6, offset: 3 }}>
              <div className="modal-box">
                <div className="modal-row-between">
                  <div className="box-title">&nbsp;</div>
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      this.toggleModal();
                    }}
                  />
                </div>
                <div className="mbottom-20 mtop-20 row-center-center">
                  <img
                    src={require('./../../../assets/images/marketing/icon-4.svg')}
                    width="150"
                  />
                </div>

                <div className="box-row-title row-center">
                  <div className="modal-marketing-title">
                    Em apenas dois passos{' '}
                    <b>crie e gerencia sua Landing Page</b> e impressione seus
                    clientes!
                  </div>
                </div>

                <div className="row-center mbottom-20 mtop-20">
                  <div
                    className="bt-blue"
                    onClick={() => {
                      this.toggleModal();
                    }}
                  >
                    Iniciar
                  </div>
                </div>
              </div>
            </Col>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
