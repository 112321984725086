import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/datePicker/style.scss';
import InputMask from 'react-input-mask';
import { Row, Col } from 'reactstrap';
import { updateImage } from '../../../actions/SimpleAction';
import SidebarFinances from './../../sidebar/SidebarFinances';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './SubmenuFinance';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class ProviderCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'finances',
      subview: 'provider',
      side: 'register',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Cadastros', link: 'finances' },
        { title: 'Fornecedores', link: 'finances/register/provider' },
        { title: 'Novo fornecedor', link: 'finances/register/provider/create' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_options: false,
      options: [],

      loading_options2: false,
      options2: [],

      name: '',
      person_type: '',
      person_document: '',
      ie_indicator: '',
      company_name: '',
      ie: '',
      im: '',
      foreign_identifier: '',
      address: [
        {
          zip_code: '',
          street_address: '',
          number: '',
          district: '',
          complement: '',
          city: '',
        },
      ],
      contact: [
        {
          comercial: '',
          residential: '',
          email: '',
          contact: '',
          celphone: '',
          observations: '',
        },
      ],

      maxLength: 3660,
      editing: false,
      editing_id: '',
      validateEmail: true,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);

    this.changeOption = this.changeOption.bind(this);
    this.changeOption2 = this.changeOption2.bind(this);

    this.valueAddress = this.valueAddress.bind(this);
    this.cvalueAddress = this.cvalueAddress.bind(this);

    this.valueContact = this.valueContact.bind(this);
    this.cvalueContact = this.cvalueContact.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.loadOptions();
    this.loadOptions2();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.finProvider(_id);
    if (result && result.success) {
      this.setState({
        name: result.provider.name,
        person_type: result.provider.person_type,
        person_document: result.provider.person_document,
        ie_indicator: result.provider.ie_indicator,
        company_name: result.provider.company_name,
        ie: result.provider.ie,
        im: result.provider.im,
        foreign_identifier: result.provider.foreign_identifier,
        address: result.provider.address,
        contact: result.provider.contact,
      });
    }
    this.setState({ loading: false });
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  //Retirado a validacao do campo email conforme solicitado 13/04/2020 - Marcos Lemos
  async save() {
    this.setState({ loading: true });
    //await this.validateEmail(this.state.contact[0].email);

    let _pay = {
      name: this.state.name,
      person_type: this.state.person_type,
      person_document: this.state.person_document,
      ie_indicator: this.state.ie_indicator,
      company_name: this.state.company_name,
      ie: this.state.ie,
      im: this.state.im,
      foreign_identifier: this.state.foreign_identifier,
      address: JSON.stringify(this.state.address),
      contact: JSON.stringify(this.state.contact),
    };

    //if (this.state.validateEmail) {
    let result = !this.state.editing
      ? await this.service.saveFinProvider(_pay)
      : await this.service.updateFinProvider(_pay, this.state.editing_id);

    if (result && result.success) {
      this.goTo('dashboard/finances/register/provider');
    }
    //}

    this.setState({ loading: false });
  }

  clearForm() {
    this.setState({
      name: '',
      person_type: '',
      person_document: '',
      ie_indicator: '',
      company_name: '',
      ie: '',
      im: '',
      foreign_identifier: '',
      address: [
        {
          zip_code: '',
          street_address: '',
          number: '',
          district: '',
          complement: '',
          city: '',
        },
      ],
      contact: [
        {
          comercial: '',
          residential: '',
          email: '',
          contact: '',
          celphone: '',
          observations: '',
        },
      ],
    });
  }

  async loadOptions() {
    this.setState({ loading_options: true });
    let result = await this.service.optionsFinProviderPT();
    if (result && result.success) {
      this.setState({
        options: result.options,
      });
    }
    this.setState({ loading_options: false });
  }

  changeOption(value) {
    this.setState({ person_type: value });
  }

  async loadOptions2() {
    this.setState({ loading_options2: true });
    let result = await this.service.optionsFinProviderIE();
    if (result && result.success) {
      this.setState({
        options2: result.options,
      });
    }
    this.setState({ loading_options2: false });
  }

  changeOption2(value) {
    this.setState({ ie_indicator: value });
  }

  valueAddress(key) {
    if (
      this.state.address &&
      this.state.address.length > 0 &&
      this.state.address[0][key]
    ) {
      return this.state.address[0][key];
    }
    return '';
  }

  cvalueAddress(key, value) {
    let address = this.state.address;
    address[0][key] = value;
    this.setState({ address });
  }

  valueContact(key) {
    if (
      this.state.contact &&
      this.state.contact.length > 0 &&
      this.state.contact[0][key]
    ) {
      return this.state.contact[0][key];
    }
    return '';
  }

  cvalueContact(key, value) {
    let contact = this.state.contact;
    contact[0][key] = value;
    this.setState({ contact });
  }

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarFinances
              goTo={this.goTo}
              setSidebarLinks={this.setSidebarLinks}
              side={this.state.side}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />

                <div className="dashboard-box box100-menu">
                  <Col xs={{ size: 12 }} md={{ size: 6 }}>
                    <div className="box-row-title">
                      <div className="box-title">
                        {this.state.editing ? 'Editar ' : 'Cadastrar '}{' '}
                        Fornecedor
                      </div>
                    </div>
                    <div className="form-input">
                      <label className="blue-label">Nome</label>
                      <input
                        type="text"
                        value={this.state.name}
                        className="blue-input"
                        placeholder="Nome"
                        onChange={e => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 12 }}>
                    <Row>
                      <Col xs={{ size: 12 }} md={{ size: 3 }}>
                        <div className="form-input">
                          <label className="blue-label">Tipo de pessoa</label>
                          {this.state.loading_options ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={this.state.person_type}
                              className="blue-input"
                              onChange={e => {
                                this.changeOption(e.target.value);
                              }}
                            >
                              <option value=""> &nbsp; Tipo de pessoa</option>
                              {this.state.options.map((item, key) => {
                                return (
                                  <option key={key} value={item}>
                                    {' '}
                                    &nbsp; {item}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </Col>
                      {this.state.person_type == 'Pessoa Física' ? (
                        <Col xs={{ size: 12 }} md={{ size: 4 }}>
                          <div className="form-input">
                            <label className="blue-label">CPF</label>
                            <InputMask
                              {...this.props}
                              mask="999.999.999-99"
                              value={this.state.person_document}
                              className="blue-input"
                              placeholder="CPF"
                              onChange={e => {
                                this.setState({
                                  person_document: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        <Col xs={{ size: 12 }} md={{ size: 4 }}>
                          <div className="form-input">
                            <label className="blue-label">CNPJ</label>
                            <InputMask
                              {...this.props}
                              mask="99.999.999/9999-99"
                              value={this.state.person_document}
                              className="blue-input"
                              placeholder="CNPJ"
                              onChange={e => {
                                this.setState({
                                  person_document: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col xs={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            Indicador de inscrição estadual
                          </label>
                          {this.state.loading_options2 ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={this.state.ie_indicator}
                              className="blue-input"
                              onChange={e => {
                                this.changeOption2(e.target.value);
                              }}
                            >
                              <option value="">
                                {' '}
                                &nbsp; Indicador de inscrição estadual
                              </option>
                              {this.state.options2.map((item, key) => {
                                return (
                                  <option key={key} value={item}>
                                    {' '}
                                    &nbsp; {item}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </Col>

                      <Col xs={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">Razão social</label>
                          <input
                            type="text"
                            value={this.state.company_name}
                            className="blue-input"
                            placeholder="Razão social"
                            onChange={e => {
                              this.setState({ company_name: e.target.value });
                            }}
                          />
                        </div>
                      </Col>

                      <Col xs={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            Inscrição estadual
                          </label>
                          <input
                            type="text"
                            value={this.state.ie}
                            className="blue-input"
                            placeholder="Inscrição estadual"
                            onChange={e => {
                              this.setState({ ie: e.target.value });
                            }}
                          />
                        </div>
                      </Col>

                      <Col xs={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            Inscrição municipal
                          </label>
                          <input
                            type="text"
                            value={this.state.im}
                            className="blue-input"
                            placeholder="Inscrição municipal"
                            onChange={e => {
                              this.setState({ im: e.target.value });
                            }}
                          />
                        </div>
                      </Col>

                      <Col xs={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            Identificador estrangeiro
                          </label>
                          <input
                            type="text"
                            value={this.state.foreign_identifier}
                            className="blue-input"
                            placeholder="Identificador estrangeiro"
                            onChange={e => {
                              this.setState({
                                foreign_identifier: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 12 }}>
                    <div className="form-subtitle">Endereço</div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">CEP</label>
                      <InputMask
                        {...this.props}
                        mask="99999-999"
                        value={this.valueAddress('zip_code')}
                        className="blue-input"
                        placeholder="CEP"
                        onChange={e => {
                          this.cvalueAddress('zip_code', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Endereço</label>
                      <input
                        type="text"
                        value={this.valueAddress('street_address')}
                        className="blue-input"
                        placeholder="Endereço"
                        onChange={e => {
                          this.cvalueAddress('street_address', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 3 }}>
                    <div className="form-input">
                      <label className="blue-label">Número</label>
                      <input
                        type="text"
                        value={this.valueAddress('number')}
                        className="blue-input"
                        placeholder="Número"
                        onChange={e => {
                          this.cvalueAddress('number', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Bairro</label>
                      <input
                        type="text"
                        value={this.valueAddress('district')}
                        className="blue-input"
                        placeholder="Bairro"
                        onChange={e => {
                          this.cvalueAddress('district', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Complemento</label>
                      <input
                        type="text"
                        value={this.valueAddress('complement')}
                        className="blue-input"
                        placeholder="Complemento"
                        onChange={e => {
                          this.cvalueAddress('complement', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 3 }}>
                    <div className="form-input">
                      <label className="blue-label">Cidade</label>
                      <input
                        type="text"
                        value={this.valueAddress('city')}
                        className="blue-input"
                        placeholder="Cidade"
                        onChange={e => {
                          this.cvalueAddress('city', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 12 }}>
                    <div className="form-subtitle">Contato</div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Telefone Comercial</label>
                      <InputMask
                        {...this.props}
                        mask="(99) 999999999"
                        value={this.valueContact('comercial')}
                        className="blue-input"
                        placeholder="Telefone Comercial"
                        onChange={e => {
                          this.cvalueContact('comercial', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Email</label>
                      <input
                        type="text"
                        value={this.valueContact('email')}
                        className="blue-input"
                        placeholder="Email"
                        onChange={e => {
                          this.cvalueContact('email', e.target.value);
                          //this.validateEmail(e.target.value);
                        }}
                      />
                      {!this.state.validateEmail && (
                        <span className="campObrigatorio">
                          Campo "Email" é obrigatório e precisa ser válido
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Telefone Residencial</label>
                      <InputMask
                        {...this.props}
                        mask="(99) 999999999"
                        value={this.valueContact('residential')}
                        className="blue-input"
                        placeholder="Telefone Residencial"
                        onChange={e => {
                          this.cvalueContact('residential', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Contato</label>
                      <input
                        type="text"
                        value={this.valueContact('contact')}
                        className="blue-input"
                        placeholder="Contato"
                        onChange={e => {
                          this.cvalueContact('contact', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 4 }}>
                    <div className="form-input">
                      <label className="blue-label">Celular</label>
                      <InputMask
                        {...this.props}
                        mask="(99) 999999999"
                        value={this.valueContact('celphone')}
                        className="blue-input"
                        placeholder="Celular"
                        onChange={e => {
                          this.cvalueContact('celphone', e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 6 }}>
                    <div className="form-textarea clear-textarea">
                      <label className="blue-label">Observações</label>
                      <textarea
                        value={this.valueContact('observations')}
                        className="blue-input h55"
                        placeholder="Observações"
                        maxLength={this.state.maxLength}
                        onChange={e => {
                          this.cvalueContact('observations', e.target.value);
                        }}
                      ></textarea>
                      <span>
                        {this.state.needs_description
                          ? this.state.needs_description.length
                          : 0}
                        /{this.state.maxLength}
                      </span>
                    </div>
                  </Col>

                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(`finances/register/provider`);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar' : 'Cadastrar'}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProviderCreate)
);
