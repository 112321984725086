import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

export default class AddNewBadgesICF extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.refProps && this.props.refProps.current) {
      this.props.refProps.current.focus();
    }
  }

  render() {
    return (
      <div className="form-doubleinput" style={{ alignItems: 'start' }}>
        <div className="form-input" data-for="tooltip-badges" data-tip>
          <label className="blue-label">URL do Perfil público (Credly)</label>
          <input
            type="text"
            ref={this.props.refProps ? this.props.refProps : null}
            value={this.props.state.fieldUrlCredly}
            placeholder="URL do Perfil público (Credly)"
            className="blue-input"
            onKeyPress={e => {
              if (e.key === 'Enter') {
              }
            }}
            onChange={e => {
              if (this.props.setState) {
                this.props.setState({
                  fieldUrlCredly: e.target.value,
                });
              }
            }}
          />
          <ReactTooltip
            className="myprofile-tootip-style"
            id="tooltip-badges"
            type="light"
            effect="solid"
            textColor="#757575"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">
              Para obter essa informação, realizar o login no site da Credly
              (https://www.credly.com/users/sign_in), clicar no seu Badge que
              quer pegar a url, dentro das informações do Badge, localizar e
              clicar no botão "Share", localizado no canto superior direito.
              Após isso localizar na tela a opção "Public Link", copiar esse
              link e inserir nesse campo.
            </p>
          </ReactTooltip>

          {this.props.state.errorbadgesSrc && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                color: 'red',
              }}
            >
              {this.props.state.errorMessage}
            </div>
          )}
        </div>
        <div
          className={
            this.props.state.fieldUrlCredly !== ''
              ? 'bt-blue buttonAddNewBadges'
              : 'bt-blue buttonAddNewBadges disable-element disable-background'
          }
          onClick={() => {
            if (
              this.props.fnAddNewBadge &&
              this.props.state.fieldUrlCredly !== ''
            ) {
              this.props.fnAddNewBadge();
            }
          }}
        >
          Adicionar
        </div>
      </div>
    );
  }
}

// ? 'bt-blue my-profile-certificate'
