import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import ReactLoading from 'react-loading';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import {
  updateImage,
  updateSession,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarSessions from './../../sidebar/SidebarSessions';
import CardGenericTableWithGroup from './../../../screens_dashboard/components/CardGenericTableWithGroup';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class ContentsRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'contents',
      side: 'coaching',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão Coaching', link: 'programs/coaching' },
        { title: 'Registro de conteúdos', link: 'programs/coaching/contents' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,

      loading_contents: false,
      sessionContents: [],

      tableTitles: [
        { title: 'Título', key: 'title' },
        { title: 'Formato', key: 'doc_format' },
        { title: 'Categoria', key: 'category' },
        { title: 'Modelo', key: 'tag' },
        { title: 'Opções', key: 'options' },
        { title: 'Concluir', key: '_response_status_answer' },
      ],
      tableActions: [
        {
          title: 'Responder',
          icon: 'fas fa-share',
          action: this.handleContent,
          hidden: 'html',
        },
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.see,
        },
      ],

      responseActions: [{ action: this.handleTasks }],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  /*
  Autor: Natanael Diego
  Data: 13/01/2020
  Hora: 09:04

  Funcao: Responsavel por permitir o olho do clic aparecer quando o conteudo for REL.
  */
  see = content => {
    let url;
    if (content.doc_format.toLowerCase() === 'link') {
      url = content.doc_source;
    } else if (content.doc_format === 'REL') {
      url = window.open(
        `${this.service.getServer()}pdf/contents/${content._id}`
      );
    } else {
      url = this.utils.resolveURL(content.doc_source, this.service.getServer());
    }

    if (!content.status || (content.status && content.status !== 'complete')) {
      this.handleAnswerTask(content);
    }

    window.open(url);
  };

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    const {
      sessionId,
      match: { params },
    } = this.props;

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser(),
        this.loadSessionContents(params.sessionId || sessionId),
      ]).finally(() => this.setState({ isLoading: false }));
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.sessionId !== this.props.match.params.sessionId
    ) {
      this.loadSessionContents(this.props.match.params.sessionId);
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`coachees/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  loadSessionContents = async sessionId => {
    if (!sessionId) {
      this.goTo('coachees/dashboard/programs');
      return;
    }

    this.setState({ loading_contents: true });
    let result = await this.service.sessionContents(sessionId);
    if (result && result.success) {
      this.setState({
        sessionContents: result.contents,
      });
      this.props.updateSession(result.sessionId);
    }
    this.setState({ loading_contents: false });
  };

  handleContent = content => {
    if (content.status && content.status === 'complete') {
      this.props.updateAlert(
        <div>
          <p className="mb-20">
            Este conteúdo já foi fechado. Gostaria de editá-lo?
          </p>

          <div className="btn-footer-modal">
            <div
              className="bt-blue-outline"
              style={{ margin: '0 5px' }}
              onClick={() => this.props.updateAlert('')}
            >
              Não
            </div>

            <div
              className="bt-blue"
              style={{ margin: '0 5px' }}
              onClick={() => {
                this.handleReopenTask(content);
                this.openEditor(content);
              }}
            >
              Sim
            </div>
          </div>
        </div>
      );
    } else {
      this.openEditor(content);
    }
  };

  handleReopenTask = async content => {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, coachee_id: coacheeId, _id: contentId } = content;
      await this.service.reopenSessionTask(
        sessionId,
        meeting,
        coacheeId,
        contentId
      );
    }
  };

  handleAnswerTask = async content => {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, coachee_id: coacheeId, _id: contentId } = content;
      const result = await this.service.answerSessionTask(
        sessionId,
        meeting,
        coacheeId,
        contentId
      );

      if (result && result.success) {
        this.loadSessionContents(sessionId);
      }
    }
  };

  openEditor = content => {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, _id: contentId, doc_format } = content;
      if (doc_format === 'wheel') {
        this.goTo(`coachees/wheel-editor/${sessionId}/${meeting}/${contentId}`);
      } else if (doc_format === 'form') {
        this.goTo(`coachees/form-editor/${sessionId}/${meeting}/${contentId}`);
      } else {
        this.goTo(
          `coachees/response-editor/${sessionId}/${meeting}/${contentId}`
        );
      }
    }
  };

  handleTasks = async content => {
    this.props.updateAlert(
      <div>
        <p className="mb-20">Deseja concluir a edição deste conteúdo?</p>
        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => this.props.updateAlert('')}
          >
            Não
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => {
              this.taskYes(content);
              this.props.updateAlert('');
            }}
          >
            Sim
          </div>
        </div>
      </div>
    );
  };

  taskYes = async content => {
    const { sessionId } = this.props;
    if (sessionId) {
      const { meeting, coachee_id: coacheeId, _id: contentId } = content;

      const result = await this.service.completeSessionTask(
        sessionId,
        meeting,
        coacheeId,
        contentId
      );

      if (result && result.success) {
        this.loadSessionContents(sessionId);
      }
    }
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      responseActions,
      tableActions,
      tableTitles,
      sessionContents,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={subview} />
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="box100-menu">
                    <div className="m--15">
                      <CardGenericTableWithGroup
                        id="coachees-contents-register-1"
                        title="Registro de Conteúdos"
                        response_actions={responseActions}
                        actions={tableActions}
                        title_rows={tableTitles}
                        groupBy="meetingTitle"
                        rows={sessionContents.map(content => {
                          const { meeting } = content;
                          let meetingTitle = '';
                          if (meeting === 'master') {
                            meetingTitle = 'Sessão Master';
                          } else if (meeting === 'session') {
                            meetingTitle = 'Conteúdo Adicional';
                          } else {
                            meetingTitle = `Sessão ${meeting}`;
                          }
                          return { ...content, meetingTitle };
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentsRegister)
);
