import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ModalProposalsModels extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      sort: '',
    };
    this.hasContent = this.hasContent.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  hasContent(item) {
    return this.contentIndex(item) !== -1;
  }

  contentIndex(item) {
    for (let ind in this.props.contents) {
      let nitem = this.props.contents[ind];
      if (nitem._id == item._id) {
        return ind;
      }
    }
    return -1;
  }

  render() {
    return (
      <div
        className={
          this.props.modal_contents ? 'modal-content active' : 'modal-content'
        }
      >
        <div className="modal-box disable-scrollbars">
          <div className="modal-row-between">
            <div className="box-title">Selecione o conteúdo</div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.props.toggleModalContent();
              }}
            ></i>
          </div>
          <div className="top-textarea modal-table-box">
            <table className="big-row table-session">
              <thead>
                <tr>
                  <td>
                    <div className="">Título</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props._sort == 'title'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('title');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Formato</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props._sort == 'doc_format'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('doc_format');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Categoria</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props._sort == 'category'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('category');
                        }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <div className="tcenter">Modelo</div>
                    {!this.props.sort ? null : (
                      <i
                        className={
                          this.props._sort == 'tag'
                            ? 'fas fa-sort-up'
                            : 'fas fa-sort-down'
                        }
                        style={{
                          float: 'right',
                          marginTop: -20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.sort('tag');
                        }}
                      ></i>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.props.list_contents.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      className={
                        this.hasContent(item)
                          ? 'modal-select-content active'
                          : 'modal-select-content'
                      }
                      onClick={() => {
                        this.props.toggleContent(item);
                      }}
                    >
                      <td className="">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Título: </span>
                          {item.title}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Formato: </span>
                          {item.doc_format}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Categoria: </span>
                          {item.category}
                        </div>
                      </td>
                      <td className="tcenter">
                        <div className="div-box-row-table">
                          <span className="mobile-label">Modelo: </span>
                          {item.tag}
                        </div>
                      </td>
                    </tr>
                  );
                })}

                {/*  */}
              </tbody>
            </table>
          </div>
          <div className="modal-row-actions">
            <div className="bt-blue" onClick={this.props.useProposal}>
              <img
                src={require('./../../assets/images/attach_file.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />
              Usar modelo
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProposalsModels);
