import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InputMask from 'react-input-mask';

import { Col } from 'reactstrap';

const getInitialState = () => ({
  action_date: '',
  action_responsible: '',
  action_description: '',
  action_action: null,
  action_meeting: '',

  maxLength: 1000,
  editing: false,
});

class ModalAction extends Component {
  constructor(props, context) {
    super(props);
    this.state = getInitialState();
    this.saveAction = this.saveAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { action, editing } = nextProps;
    if (action) {
      this.setState({
        action_date: action.date,
        action_responsible: action.responsible,
        action_description: action.description,
        action_action: action.action,
        action_meeting: action.meeting,
        editing,
      });
    } else {
      this.setState(getInitialState());
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  saveAction() {
    const {
      action_responsible: responsible,
      action_date: date,
      action_description: description,
      action_action: action,
      action_meeting: meeting,
    } = this.state;

    this.props.saveAction({
      responsible,
      date,
      description,
      action,
      meeting,
    });
    this.setState(getInitialState());
  }

  render() {
    const {
      action_responsible,
      action_date,
      action_description,
      action_action,
      maxLength,
      editing,
    } = this.state;
    return (
      <div
        className={
          this.props.modal_actions ? 'modal-content active' : 'modal-content'
        }
      >
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">Preencha a ação</div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.props.toggleModalAction();
              }}
            ></i>
          </div>
          <div className="top-textarea modal-table-box">
            <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
              <div className="modal-row-triple">
                <div className="form-input w33">
                  <label className="blue-label">Responsável</label>
                  <input
                    type="text"
                    value={action_responsible}
                    className="blue-input"
                    placeholder="Responsável"
                    onChange={e => {
                      this.setState({ action_responsible: e.target.value });
                    }}
                  />
                </div>
                <div className="form-input w33">
                  <label className="blue-label">Data para conclusão</label>
                  <InputMask
                    {...this.props}
                    mask="99/99/9999"
                    value={action_date}
                    className="blue-input"
                    placeholder="Data para conclusão"
                  />
                </div>
                <div className="form-input w20 disabled">
                  <label className="blue-label">Ação</label>
                  <input
                    type="text"
                    disabled
                    value={action_action}
                    className="blue-input"
                    placeholder="Ação"
                  />
                </div>
              </div>
              <div className="row-form">
                <div className="form-textarea clear-textarea">
                  <label className="blue-label">Descrição</label>
                  <textarea
                    value={action_description}
                    className="blue-input h55"
                    placeholder="Descrição"
                    maxLength={maxLength}
                    onChange={e => {
                      this.setState({ action_description: e.target.value });
                    }}
                  ></textarea>
                  <span>
                    {action_description.length}/{maxLength}
                  </span>
                </div>
              </div>
            </Col>
          </div>
          {editing ? (
            <div className="modal-row-actions">
              <div
                className="bt-blue"
                onClick={() => {
                  this.saveAction();
                }}
              >
                Salvar
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAction);
