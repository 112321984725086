import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { addDays, startOfDay } from 'date-fns';

import ServicePlans from './../Plans/Service.js';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';

import { Col } from 'reactstrap';

import Service from './Service.js';

import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';

import Sidebar from '../../sidebar/Sidebar';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';

class Form extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.servicePlans = new ServicePlans();

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'coupon',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Cupons de Desconto', link: 'coupons' },
        { title: 'Cadastrar Cupom de Desconto', link: 'coupons/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [
        { label: 'Nome', key: 'name', type: 'text', maxLength: 80 },
        {
          label: 'Código',
          key: 'code',
          type: 'text',
          maxLength: 11,
          minLength: 3,
        },
        {
          label: 'Tipo de plano',
          key: 'plan_periodice',
          type: 'select',
          options: [
            { _id: '1m', type: 'Mensal' },
            { _id: '12m', type: 'Anual' },
            { _id: 'all', type: 'Todos' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'type',
        },
        {
          label: 'Recorrência (em meses)',
          key: 'cycles',
          type: 'number',
          hide: { key: 'plan_periodice', value: '12m' },
          max: 12,
          min: 1,
        },
        {
          label: 'Tempo de expiração (em dias)',
          key: 'days_to_expire',
          type: 'number',
          min: 0,
        },
        {
          label: 'Data de expiração',
          key: 'expiration_date',
          type: 'birthday-mask',
          readOnly: true,
        },
        {
          label: 'Número máximo de utilizações (0 = ilimitado)',
          key: 'max_uses',
          type: 'number',
          min: 0,
        },
        {
          label: 'Tipo de desconto',
          key: 'discount_type',
          type: 'select',
          options: [
            { _id: 'fixed', type: 'Valor Fixo' },
            { _id: 'percentage', type: 'Porcentagem' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'type',
        },
        {
          label: 'Valor do desconto',
          key: 'discount_value',
          type: 'money-mask',
          show: { key: 'discount_type', value: 'fixed' },
        },
        {
          label: 'Porcentagem do desconto',
          key: 'discount_value',
          type: 'percent-mask',
          show: { key: 'discount_type', value: 'percentage' },
        },
        {
          label: 'Data de criação',
          key: 'created_at',
          type: 'birthday-mask',
          readOnly: true,
        },
        {
          label: 'Uso',
          key: 'usage',
          type: 'text',
          readOnly: true,
          show: { key: 'isEditing', value: true },
        },
        {
          label: 'Status',
          key: 'coupon_status',
          type: 'text',
          readOnly: true,
          show: { key: 'isEditing', value: true },
        },
      ],
      plans: [],
      periodice: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Cupom de Desconto',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.removePassword();
      this.setState(
        {
          editing: true,
          editing_id,
        },
        () => {
          this.load(editing_id);
        }
      );
    } else {
      this.setState({
        content: {
          days_to_expire: 15,
          max_uses: 1,
          plan_periodice: '1m',
          cycles: 1,
          discount_type: 'fixed',
          discount_value: 0,
          created_at: new Date(),
          expiration_date: new Date(),
        },
      });
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      const coupon = result[this.state.view];
      const statusCase = {
        valid: 'Válido',
        expired: 'Expirado',
        sold_out: 'Esgotado',
      };
      this.setState({
        content: {
          ...coupon,
          created_at: new Date().setTime(coupon.created_at),
          expiration_date: new Date(coupon.expiration_date),
          usage: coupon.is_unlimited
            ? `${coupon.uses}`
            : `${coupon.uses}/${coupon.max_uses}`,
          coupon_status: statusCase[coupon.status],
          isEditing: true,
        },
      });
    }
    this.setState({ loading_contents: false });
  }

  clearDiscountValue = value => {
    if (!value) return 0;
    if (typeof value !== 'string') return value;

    let cleanValue = value.replace('R$', '');
    cleanValue = cleanValue.replace('%', '');
    cleanValue = cleanValue.replace(' ', '');
    cleanValue = cleanValue.replace(/\./g, '');
    cleanValue = cleanValue.replace(',', '.');
    return cleanValue;
  };

  async save() {
    this.setState({ loading: true });
    let isValid = true;
    const discount_value = this.clearDiscountValue(
      this.state.content.discount_value
    );

    const { name, code } = this.state.content;
    if (!name) {
      await this.setItemError('name', 'Nome deve ser informado');
      isValid = false;
    }
    if (!code) {
      await this.setItemError('code', 'Código deve ser informado');
      isValid = false;
    }
    if (code && (code.length < 3 || code.length > 11)) {
      await this.setItemError(
        'code',
        'Código deve possuir entre 3 e 11 caracteres'
      );
      isValid = false;
    }

    if (!isValid) {
      this.setState({ loading: false });
      return;
    }

    let _pay = {
      ...this.state.content,
      discount_value,
    };

    let result = this.state.editing
      ? await this.service.update(_pay, this.state.editing_id)
      : await this.service.create(_pay);

    if (result) {
      if (result.success) {
        this.setSidebarLinks(this.state.breadcrumbs[1].link);
        return;
      }

      if (result.error) {
        this.props.updateAlert(result.error);
      }
    }
    this.setState({ loading: false });
  }

  removePassword() {
    let items = this.state.items;
    for (let _k in items) {
      if (items[_k].type == 'password') {
        items.splice(_k, 1);
      }
    }
    this.setState({ items });
  }

  async setItemError(key, error) {
    const items = this.state.items.map(item =>
      item.key === key ? { ...item, error } : item
    );
    await this.setState({ items });
  }

  calcExpirationDate(createdAt, expiresIn) {
    return addDays(startOfDay(createdAt), expiresIn);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.content.code !== prevState.content.code) {
      this.setState({
        content: {
          ...this.state.content,
          code: this.state.content.code
            .replace(/([^A-Za-z0-9])/g, '')
            .toUpperCase(),
        },
      });
    }

    if (
      this.state.content.discount_type === 'percentage' &&
      this.clearDiscountValue(this.state.content.discount_value) > 100
    ) {
      this.setState({
        content: { ...this.state.content, discount_value: 100 },
      });
    }

    if (this.state.content.created_at) {
      const expirationDate = this.calcExpirationDate(
        this.state.content.created_at,
        this.state.content.days_to_expire
      );
      if (
        expirationDate &&
        (!prevState.content.expiration_date ||
          prevState.content.expiration_date.getTime() !==
            expirationDate.getTime())
      ) {
        this.setState({
          content: { ...this.state.content, expiration_date: expirationDate },
        });
      }
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                      setItemError={this.setItemError}
                    />

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
