import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/datePicker/style.scss';
import { Row, Col } from 'reactstrap';
import { updateImage } from '../../../actions/SimpleAction';
import SidebarFinances from './../../sidebar/SidebarFinances';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './SubmenuBalance';
import CurrencyInput from 'react-currency-input';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './financeiro.css';
import Switch from 'react-switch';

const Typeahead = require('react-typeahead').Typeahead;

class CategoryCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'finances',
      subview: 'in',
      side: 'balance',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Financeiro', link: 'finances' },
        { title: 'Balance', link: 'finances/balance/in' },
        { title: 'Recebimento', link: 'finances/balance/in' },
        { title: 'Novo Recebimento', link: 'finances/balance/in/create' },
      ],
      pageTitle: 'Novo Recebimento',
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_options: false,
      options: [],

      loading_options2: false,
      options2: [],

      loading_options3: false,
      options3: [],

      loading_options4: false,
      options4: [],

      loading_options5: false,
      options5: [],

      loading_options6: false,
      options6: [],

      value: '',
      coachee_id: '',
      group_id: '',
      forGroups: false,
      proposal_id: '',
      provider_id: '',
      category_id: '',
      number: '',
      date: new Date(),
      items: [],
      coachees: [],
      groups: [],
      type: '',
      status: 'open',
      payment_condition: '1',
      payment_date: '',
      quotas_observation: [],
      additional_information: '',
      discount: '',
      proposal_type: 'new',

      quotas: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
      ],

      maxLength: 3660,
      editing: false,
      editing_id: '',
      statusDate: true,
      groupSessionPermission: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);

    this.changeOption = this.changeOption.bind(this);
    this.changeOption2 = this.changeOption2.bind(this);
    this.changeItems = this.changeItems.bind(this);
    this.changeOption4 = this.changeOption4.bind(this);
    this.changeOption5 = this.changeOption5.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.subtotalItem = this.subtotalItem.bind(this);
    this.changeProposalType = this.changeProposalType.bind(this);
    this.addNewQuotaObservation = this.addNewQuotaObservation.bind(this);
    this.changeQuotaInformation = this.changeQuotaInformation.bind(this);
    this.changePaymentCondition = this.changePaymentCondition.bind(this);
    this.changeDiscount = this.changeDiscount.bind(this);
    this.changeAhead = this.changeAhead.bind(this);
    this.getAheadValue = this.getAheadValue.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.addNewItem();
    this.addNewQuotaObservation();
    this.calculateParcel();
    this.loadOptions();
    this.loadOptions3();
    this.loadOptions4();
    this.loadOptions5();
    this.loadOptions6();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    } else {
      this.loadNumber();
    }

    if (params.type) {
      if (params.type == 'out') {
        this.setState({
          subview: 'out',
          breadcrumbs: [
            { title: 'Dashboard', link: '' },
            { title: 'Financeiro', link: 'finances' },
            { title: 'Balance', link: 'finances/balance/out' },
            { title: 'Despesas', link: 'finances/balance/out' },
            { title: 'Nova Despesa', link: 'finances/balance/out/create' },
          ],
          pageTitle: 'Nova Despesa',
        });
      }
      await this.setState({ type: params.type });
      this.loadOptions2();
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.balance(_id);
    if (result && result.success) {
      await this.setState({
        value: result.balance.value,
        coachee_id: result.balance.coachee_id,
        group_id: result.balance.group_id || '',
        forGroups: result.balance.for_groups || false,
        proposal_id: result.balance.proposal_id,
        category_id: result.balance.category_id,
        type: result.balance.type,
        number: result.balance.number,
        provider_id: result.balance.provider_id,
        date: new Date(this.utils.resolveSessionDate(result.balance.date)),
        items: result.balance.items,
        payment_condition: result.balance.payment_condition,
        quotas_observation: result.balance.quotas_observation,
        additional_information: result.balance.additional_information,
        discount: this.dirtyValue(result.balance.discount),
      });
    }
    this.setState({ loading: false });
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan &&
      user.plans.platform.plan.group_sessions
    ) {
      this.setState({ groupSessionPermission: true });
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    this.setState({ loading: true });

    if (
      this.state.date !== undefined &&
      this.state.date !== null &&
      this.state.date !== ''
    ) {
      await this.setState({ statusDate: true });
    } else {
      await this.setState({ statusDate: false });
    }

    if (this.state.statusDate) {
      let _pay = {
        value: this.getLiquidTotal(),
        coachee_id: this.state.coachee_id,
        group_id: this.state.group_id,
        for_groups: this.state.forGroups,
        proposal_id: this.state.proposal_id,
        category_id: this.state.category_id,
        provider_id: this.state.provider_id,
        type: this.state.type,
        number: this.state.number,
        date: this.utils.parseDateUSA(this.state.date),
        items: JSON.stringify(this.state.items),
        payment_condition: this.state.payment_condition,
        quotas_observation: JSON.stringify(this.state.quotas_observation),
        additional_information: this.state.additional_information,
        subtotal: this.clearValue(this.getTotal()),
        discount: this.clearValue(this.state.discount),
        total: this.clearValue(this.getLiquidTotal()),
      };
      let result = !this.state.editing
        ? await this.service.saveBalance(_pay)
        : await this.service.updateBalance(_pay, this.state.editing_id);

      if (result && result.success) {
        this.goTo(`dashboard/finances/balance/${this.state.type}`);
      }
    }

    this.setState({ loading: false });
  }

  clearForm() {
    this.setState({
      value: '',
      coachee_id: '',
      group_id: '',
      forGroups: false,
      proposal_id: '',
      provider_id: '',
      category_id: '',
      number: '',
      date: '',
      items: [],
      type: '',
      status: 'open',
      payment_condition: '',
      payment_date: '',
      quotas_observation: [],
      additional_information: '',
    });
  }

  async loadOptions() {
    this.setState({ loading_options: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({
        options: result.coachees,
        coachees: result.coachees,
      });
    }
    this.setState({ loading_options: false });
  }

  async loadOptions2() {
    this.setState({ loading_options2: true });

    let result =
      this.state.type == 'in'
        ? await this.service.fin_categoriesIn()
        : await this.service.fin_categoriesOut();

    if (result && result.success) {
      this.setState({
        options2: result.categories,
      });
    }
    setTimeout(() => {
      this.setState({ loading_options2: false });
    }, 300);
  }

  async loadOptions3() {
    this.setState({ loading_options3: true });
    let result = await this.service.fin_services();
    if (result && result.success) {
      this.setState({
        options3: result.services,
      });
    }
    setTimeout(() => {
      this.setState({ loading_options3: false });
    }, 300);
  }

  async loadOptions4() {
    this.setState({ loading_options4: true });
    let result = await this.service.fin_providers();
    if (result && result.success) {
      this.setState({
        options4: result.providers,
      });
    }
    setTimeout(() => {
      this.setState({ loading_options4: false });
    }, 300);
  }

  async loadOptions5() {
    this.setState({ loading_options5: true });
    let result = await this.service.proposals();
    if (result && result.success) {
      this.setState({
        options5: result.proposals,
      });
    }
    this.setState({ loading_options5: false });
  }

  async loadOptions6() {
    this.setState({ loading_options6: true });
    let result = await this.service.groups();
    if (result && result.success) {
      this.setState({
        options6: result.groups,
      });
    }
    this.setState({ loading_options6: false });
  }

  async loadNumber() {
    let result = await this.service.balanceNumber();
    if (result && result.success) {
      this.setState({
        number: result.number,
      });
    }
  }

  addNewItem() {
    let items = this.state.items;
    items.push({
      service_id: '',
      details: '',
      quantity: 1,
      value: '',
      subtotal: '',
    });
    this.setState({ items });
  }

  addNewQuotaObservation() {
    let quotas_observation = this.state.quotas_observation;
    quotas_observation.push({
      date: this.utils.parseDateUSA(new Date()),
      observation: '',
    });
    this.setState({ quotas_observation });
  }

  removeItem(key) {
    let items = this.state.items;
    items.splice(key, 1);
    this.setState({ items });
  }

  removeQuotaObservation(key) {
    let quotas_observation = this.state.quotas_observation;
    quotas_observation.splice(key, 1);
    this.setState({ quotas_observation });
  }

  subtotalItem(key) {
    let val = this.clearValue(this.state.items[key].value);
    let total = this.state.items[key].quantity * val;
    return this.dirtyValue(total);
  }

  getTotal() {
    let total = 0;
    for (let _k in this.state.items) {
      let val = this.clearValue(this.state.items[_k].value);
      total += this.state.items[_k].quantity * val;
    }
    return this.dirtyValue(total);
  }

  getLiquidTotal() {
    let total = this.clearValue(this.getTotal());
    let discount = this.clearValue(this.state.discount);
    total = total - discount;
    return this.dirtyValue(total);
  }

  clearValue(value) {
    let val = value ? value.replace('R$ ', '') : '';
    val = val.replace(/\./g, '');
    val = val.replace(',', '.');
    return val;
  }

  dirtyValue(value) {
    let _v = 0;
    if (value) {
      _v = value;
      let parts = value
        .toFixed(2)
        .toString()
        .split('.');
      parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
      value = parts.join(',');
    }
    return value && _v > 0 ? `R$ ${value}` : 'R$ 0,00';
  }

  changeOption(value) {
    if (this.state.forGroups) {
      this.setState({ group_id: value, coachee_id: '' });
    } else {
      this.setState({ coachee_id: value, group_id: '' });
    }
  }

  changeOption2(value) {
    this.setState({ category_id: value });
  }

  changeOption4(value) {
    this.setState({ provider_id: value });
  }

  async changeOption5(value) {
    this.setState({ proposal_id: value });
    let result = await this.service.proposal(value);
    if (result && result.success) {
      let coachee_id = this.getCoacheID(result.proposal);
      if (coachee_id != -1) {
        this.setState({ coachee_id });
      } else {
        ///
        console.log('Coachee not found..', result.proposal);
      }
      this.changeItems(result.proposal.title, 'details', 0);
      this.changeItems(result.proposal.value, 'value', 0);
    }
  }

  async changeItems(value, key, index) {
    let items = this.state.items;
    items[index][key] = value;
    this.setState({ items });
    if (key === 'service_id') {
      let result = await this.service.finService(value);
      if (result && result.success) {
        let items = this.state.items;
        items[index].value = result.service
          ? result.service.value
          : items[index].value;
        items[index].details = result.service
          ? result.service.code
          : items[index].details;
        this.setState({ items });
      }
    }

    this.calculateParcel();
  }

  changeQuotaInformation(value, key, index) {
    let quotas_observation = this.state.quotas_observation;
    if (key === 'date') {
      value = this.utils.parseDateUSA(value);
    }
    quotas_observation[index][key] = value;
    this.setState({ quotas_observation });
  }

  changeProposalType(e) {
    this.setState({
      proposal_type: e.currentTarget.value,
      group_id: '',
      forGroups: false,
      coachee_id: '',
    });
    if (e.currentTarget.value == 'new') {
      this.setState({
        value: '',
        proposal_id: '',
      });
    }
  }

  changePaymentCondition(value) {
    if (value > this.state.quotas_observation.length) {
      for (var i = this.state.quotas_observation.length; i < value; i++) {
        this.addNewQuotaObservation();
      }
    } else {
      for (var i = this.state.quotas_observation.length; i >= 0; i--) {
        if (i >= value) {
          this.removeQuotaObservation(i);
        }
      }
    }
    this.setState({ payment_condition: value });
    this.calculateParcel();
  }

  calculateParcel() {
    let value = this.state.quotas_observation.length;
    let parcel = this.clearValue(this.getLiquidTotal()) / value;
    let quotas_observation = this.state.quotas_observation;
    quotas_observation.map((quota, key) => {
      quotas_observation[key].observation = this.dirtyValue(parcel);
    });
    this.setState({ quotas_observation });
  }

  getToday() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;

    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
  }

  async changeDiscount(e) {
    await this.setState({ discount: e.target.value });
    this.calculateParcel();
  }

  changeAhead(key, value) {
    let _pay = {};
    _pay[key] = value;
    this.setState(_pay);
  }

  async createAhead(key, value) {
    if (key == 'category') {
      this.setState({ loading_options2: true });
      let result = await this.service.saveFinCategory({
        add_to: this.state.subview == 'in' ? 'Recebimentos' : 'Despesas',
        category: value,
      });
      this.loadOptions2();
    }

    if (key == 'provider') {
      this.setState({ loading_options4: true });
      let result = await this.service.saveFinProvider({
        name: value,
      });
      this.loadOptions4();
    }

    if (key == 'service') {
      this.setState({ loading_options3: true });
      let result = await this.service.saveFinService({
        service: value,
      });
      this.loadOptions3();
    }
  }

  getAheadValue(key, _id) {
    if (key == 'category') {
      let value = this.readArray(this.state.options2, _id);
      return value ? value.category : '';
    }
    if (key == 'provider') {
      let value = this.readArray(this.state.options4, _id);
      return value ? value.name : '';
    }
    if (key == 'service') {
      let value = this.readArray(this.state.options3, _id);
      return value ? value.service : '';
    }
    return '';
  }

  readArray(array, _id) {
    if (array)
      for (let item of array) {
        if (item._id == _id) {
          return item;
        }
      }
    return false;
  }

  getCoacheID(proposal) {
    for (let _c of this.state.coachees) {
      if (proposal.email == _c.email) {
        return _c._id;
      }
    }
    return -1;
  }

  statusDate = async () => {
    if (
      this.state.date !== undefined &&
      this.state.date !== null &&
      this.state.date !== ''
    ) {
      await this.setState({ statusDate: true });
    } else {
      await this.setState({ statusDate: false });
    }
  };

  handleSwitchGroup = () => {
    this.setState({
      forGroups: !this.state.forGroups,
      coachee_id: '',
      group_id: '',
    });
  };

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarFinances
              goTo={this.goTo}
              setSidebarLinks={this.setSidebarLinks}
              side={this.state.side}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={this.state.subview} />

                <div className="dashboard-box box100-menu">
                  <div className="box-row-title">
                    <div className="box-title">{this.state.pageTitle}</div>
                  </div>
                  {this.state.type == 'out' ? (
                    <div>
                      <Col sm={{ size: 12 }} md={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            {this.state.forGroups ? 'Grupos' : 'Coachee'}
                          </label>
                          {this.state.loading_options ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={
                                this.state.forGroups
                                  ? this.state.group_id
                                  : this.state.coachee_id
                              }
                              className="blue-input"
                              onChange={e => this.changeOption(e.target.value)}
                            >
                              <option value="">
                                &nbsp;{' '}
                                {this.state.forGroups ? 'Grupos' : 'Coachee'}
                              </option>
                              {this.state.forGroups
                                ? this.state.options6.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      &nbsp; {item.name}
                                    </option>
                                  ))
                                : this.state.options.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      &nbsp; {item.name}
                                    </option>
                                  ))}
                            </select>
                          )}
                        </div>
                      </Col>
                      {this.state.groupSessionPermission && (
                        <Col
                          className="margin-top-15"
                          sm={{ size: 12 }}
                          md={{ size: 6 }}
                        >
                          <Switch
                            onChange={this.handleSwitchGroup}
                            id={`switch-group`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            handleDiameter={20}
                            height={13}
                            width={33}
                            checked={this.state.forGroups}
                          />
                          <label
                            onClick={this.handleSwitchGroup}
                            className={`groups-label ${
                              this.state.forGroups ? 'clicked' : ''
                            }`}
                          >
                            Lançamento para Grupo
                          </label>
                        </Col>
                      )}
                    </div>
                  ) : this.state.proposal_type == 'import' ? (
                    <Col sm={{ size: 12 }} md={{ size: 4 }}>
                      <div className="form-input">
                        <label className="blue-label">Proposta</label>
                        {this.state.loading_options5 ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={this.state.proposal_id}
                            className="blue-input"
                            onChange={e => {
                              this.changeOption5(e.target.value);
                            }}
                          >
                            <option value=""> &nbsp; Proposta</option>
                            {this.state.options5.map((item, key) => {
                              return (
                                <option key={key} value={item._id}>
                                  {' '}
                                  &nbsp; {item.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </Col>
                  ) : null}

                  {this.state.type == 'in' ? (
                    <div>
                      <Col
                        xs={{ size: 12 }}
                        sm={{ size: 12 }}
                        className="mbottom-10"
                      >
                        <input
                          type="radio"
                          name="proposal_type"
                          id={`rad-1`}
                          value="import"
                          checked={this.state.proposal_type == 'import'}
                          onChange={this.changeProposalType}
                        />
                        <label htmlFor={`rad-1`}>Importar proposta</label>
                      </Col>
                      <div>
                        <Col
                          xs={{ size: 12 }}
                          md={{ size: 3 }}
                          className="radio-new-in"
                        >
                          <input
                            type="radio"
                            name="proposal_type"
                            id={`rad-2`}
                            value="new"
                            checked={this.state.proposal_type == 'new'}
                            onChange={this.changeProposalType}
                          />
                          <label htmlFor={`rad-2`}>Novo Recebimento</label>
                        </Col>

                        {this.state.groupSessionPermission &&
                          this.state.proposal_type == 'new' && (
                            <Col xs={{ size: 12 }} md={{ size: 6 }}>
                              <Switch
                                onChange={this.handleSwitchGroup}
                                id={`switch-group`}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offColor={'#D8D8D8'}
                                offHandleColor={'#9F9F9F'}
                                onColor={'#96CBCF'}
                                onHandleColor={'#00838F'}
                                handleDiameter={20}
                                height={13}
                                width={33}
                                checked={this.state.forGroups}
                              />
                              <label
                                onClick={this.handleSwitchGroup}
                                className={`groups-label ${
                                  this.state.forGroups ? 'clicked' : ''
                                }`}
                              >
                                Lançamento para Grupo
                              </label>
                            </Col>
                          )}
                      </div>
                    </div>
                  ) : null}

                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <Row>
                      {this.state.type == 'out' ? (
                        <Col sm={{ size: 9 }} md={{ size: 4 }}>
                          <div className="form-input">
                            <label className="blue-label">Fornecedor</label>
                            {this.state.loading_options4 ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <Typeahead
                                value={this.getAheadValue(
                                  'provider',
                                  this.state.provider_id
                                )}
                                defaultValue={this.getAheadValue(
                                  'provider',
                                  this.state.provider_id
                                )}
                                filterOption="name"
                                displayOption="name"
                                placeholder="Fornecedor"
                                options={this.state.options4}
                                onKeyPress={e => {
                                  if (e.key === 'Enter') {
                                    this.createAhead(
                                      'provider',
                                      e.target.value
                                    );
                                  }
                                }}
                                onOptionSelected={e => {
                                  this.changeAhead('provider_id', e._id);
                                }}
                                customClasses={{
                                  input: 'blue-input',
                                  results: 'list-messages listahead',
                                }}
                              />
                            )}
                          </div>
                        </Col>
                      ) : (
                        <Col sm={{ size: 9 }} md={{ size: 4 }}>
                          <div className="form-input">
                            <label className="blue-label">
                              {this.state.forGroups ? 'Grupos' : 'Coachee'}
                            </label>
                            {this.state.loading_options ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                value={
                                  this.state.forGroups
                                    ? this.state.group_id
                                    : this.state.coachee_id
                                }
                                className="blue-input"
                                onChange={e =>
                                  this.changeOption(e.target.value)
                                }
                              >
                                <option value="">
                                  &nbsp;{' '}
                                  {this.state.forGroups ? 'Grupos' : 'Coachee'}
                                </option>
                                {this.state.forGroups
                                  ? this.state.options6.map((item, key) => (
                                      <option key={key} value={item._id}>
                                        &nbsp; {item.name}
                                      </option>
                                    ))
                                  : this.state.options.map((item, key) => (
                                      <option key={key} value={item._id}>
                                        &nbsp; {item.name}
                                      </option>
                                    ))}
                              </select>
                            )}
                          </div>
                        </Col>
                      )}

                      <Col sm={{ size: 3 }} md={{ size: 2 }}>
                        <div className="form-input grey">
                          <label className="blue-label grey">Número</label>
                          <input
                            type="text"
                            value={this.state.number}
                            className="blue-input"
                            placeholder="Número"
                            onChange={e => {
                              this.setState({ number: e.target.value });
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={{ size: 6 }} md={{ size: 3 }}>
                        <div className="form-input-data">
                          <label className="blue-label">Data de venda</label>

                          <DatePicker
                            selected={this.state.date}
                            onChange={async e => {
                              await this.setState({ date: e });
                              this.statusDate();
                            }}
                            timeIntervals={30}
                            dateFormat="d/MM/yyyy"
                            timeCaption="time"
                            className="blue-input"
                          />
                          {!this.state.statusDate && (
                            <span className="campObrigatorio">
                              Campo "Data de venda" é obrigatório
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm={{ size: 6 }} md={{ size: 3 }}>
                        <div className="form-input">
                          <label className="blue-label">Categoria</label>
                          {this.state.loading_options2 ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <Typeahead
                              value={this.getAheadValue(
                                'category',
                                this.state.category_id
                              )}
                              defaultValue={this.getAheadValue(
                                'category',
                                this.state.category_id
                              )}
                              filterOption="category"
                              displayOption="category"
                              placeholder="Categoria"
                              options={this.state.options2}
                              onKeyPress={e => {
                                if (e.key === 'Enter') {
                                  this.createAhead('category', e.target.value);
                                }
                              }}
                              onOptionSelected={e => {
                                this.changeAhead('category_id', e._id);
                              }}
                              customClasses={{
                                input: 'blue-input',
                                results: 'list-messages listahead',
                              }}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  {/*items*/}

                  <Col
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    className="mobile-block"
                  >
                    <div className="form-subtitle">Produtos / Serviços</div>
                  </Col>

                  <Col
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    className="hidden-mobile"
                  >
                    <Col sm={{ size: 12 }} md={{ size: 2 }}>
                      <div
                        className="form-input"
                        style={{ alignItems: 'flex-start' }}
                      >
                        <label className="blue-label-dark">
                          Produto / Serviço
                        </label>
                      </div>
                    </Col>
                    <Col sm={{ size: 12 }} md={{ size: 4 }}>
                      <div
                        className="form-input"
                        style={{ alignItems: 'flex-start' }}
                      >
                        <label className="blue-label-dark">Detalhes</label>
                      </div>
                    </Col>
                    <Col sm={{ size: 12 }} md={{ size: 1 }}>
                      <div
                        className="form-input"
                        style={{ alignItems: 'flex-start' }}
                      >
                        <label className="blue-label-dark ml">Quantidade</label>
                      </div>
                    </Col>
                    <Col sm={{ size: 12 }} md={{ size: 2 }}>
                      <div className="form-input">
                        <label className="blue-label-dark center">Valor</label>
                      </div>
                    </Col>
                    <Col sm={{ size: 12 }} md={{ size: 2 }}>
                      <div
                        className="form-input"
                        style={{ alignItems: 'flex-start' }}
                      >
                        <label className="blue-label-dark center">
                          Subtotal
                        </label>
                      </div>
                    </Col>
                    <Col sm={{ size: 12 }} md={{ size: 1 }}>
                      <div className="form-input">
                        <label className="blue-label-dark center">
                          Remover
                        </label>
                      </div>
                    </Col>
                  </Col>

                  {this.state.items.map((item, key) => {
                    return (
                      <Col
                        xs={{ size: 12 }}
                        sm={{ size: 12 }}
                        key={key}
                        className={key % 2 == 0 ? 'finance-odd-row' : ''}
                      >
                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 2 }}
                          className="mobile-no-paddings"
                        >
                          <div className="form-input">
                            {this.state.loading_options3 ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <Typeahead
                                value={this.getAheadValue(
                                  'service',
                                  this.state.items[key].service_id
                                )}
                                defaultValue={this.getAheadValue(
                                  'service',
                                  this.state.items[key].service_id
                                )}
                                filterOption="service"
                                displayOption="service"
                                placeholder="Produto / Serviço"
                                options={this.state.options3}
                                onKeyPress={e => {
                                  if (e.key === 'Enter') {
                                    this.createAhead('service', e.target.value);
                                  }
                                }}
                                onOptionSelected={e => {
                                  this.changeItems(e._id, 'service_id', key);
                                }}
                                customClasses={{
                                  input: 'blue-input',
                                  results: 'list-messages listahead',
                                }}
                              />
                            )}
                          </div>
                        </Col>

                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 4 }}
                          className="mobile-no-paddings"
                        >
                          <div className="form-input">
                            <input
                              type="text"
                              value={this.state.items[key].details}
                              className="blue-input"
                              placeholder="Detalhes"
                              onChange={e => {
                                this.changeItems(
                                  e.target.value,
                                  'details',
                                  key
                                );
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 1 }}
                          className="mobile-no-paddings"
                        >
                          <div className="form-input">
                            <input
                              type="text"
                              value={this.state.items[key].quantity}
                              className="blue-input tcenter"
                              placeholder=""
                              onChange={e => {
                                this.changeItems(
                                  e.target.value,
                                  'quantity',
                                  key
                                );
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 2 }}
                          className="mobile-no-paddings"
                        >
                          <div className="form-input">
                            <CurrencyInput
                              value={this.state.items[key].value}
                              className="blue-input valFin"
                              onChangeEvent={e => {
                                this.changeItems(e.target.value, 'value', key);
                              }}
                              decimalSeparator=","
                              thousandSeparator="."
                              prefix="R$ "
                              placeholder="Valor"
                            />
                          </div>
                        </Col>
                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 2 }}
                          className="mobile-no-paddings"
                        >
                          <div className="form-input">
                            <input
                              type="text"
                              value={this.subtotalItem(key)}
                              className="blue-input valFin"
                              placeholder="Subtotal"
                              disabled
                            />
                          </div>
                        </Col>
                        <Col sm={{ size: 12 }} md={{ size: 1 }}>
                          <div
                            className="row-remove"
                            onClick={() => {
                              this.removeItem(key);
                            }}
                          >
                            {' '}
                            x{' '}
                          </div>
                        </Col>
                      </Col>
                    );
                  })}
                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                      <div
                        className="bt-blue grey"
                        onClick={() => {
                          this.addNewItem();
                        }}
                      >
                        <img
                          src={require('./../../../assets/images/plus.svg')}
                          style={{ marginTop: -3, marginRight: 14 }}
                        />
                        Adicionar Produto / Serviço
                      </div>
                    </Col>
                  </Col>

                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <Col sm={{ size: 12 }} md={{ size: 5, offset: 6 }}>
                      <div className="row-subtotal">
                        <div className="subtotal-text">Valor</div>
                        <Col
                          sm={{ size: 12 }}
                          md={{ size: 5, offset: 7 }}
                          style={{ display: 'contents' }}
                        >
                          <div className="subtotal-text">{this.getTotal()}</div>
                        </Col>
                      </div>
                      <div className="row-subtotal">
                        <div className="subtotal-text">Desconto</div>
                        <div className="form-input">
                          <label className="blue-label center mtop-10">
                            Desconto
                          </label>
                          {/* <Col sm={{ size: 12 }} md={{ size: 7 }}> */}
                          <CurrencyInput
                            value={this.state.discount}
                            className="blue-input mb0 valFin"
                            style={{ width: '50%' }}
                            onChangeEvent={e => {
                              this.changeDiscount(e);
                            }}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$ "
                            placeholder="Desconto"
                          />
                          {/* </Col> */}
                        </div>
                      </div>

                      <div
                        className="box-subtotal"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '1%',
                        }}
                      >
                        <div className="subtotal-box-text">Total liquído</div>
                        <div className="subtotal-box-text">
                          {this.getLiquidTotal()}
                        </div>
                      </div>
                    </Col>
                  </Col>

                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <div className="form-subtitle">Condição de pagamento</div>
                  </Col>

                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <Row>
                      <Col xs={{ size: 12 }} sm={{ size: 4 }}>
                        <div className="form-input">
                          <label className="blue-label">Parcelas</label>
                          <select
                            value={this.state.payment_condition}
                            className="blue-input"
                            onChange={e => {
                              this.changePaymentCondition(e.target.value);
                            }}
                          >
                            <option value=""> &nbsp; Parcelas</option>
                            {this.state.quotas.map((item, key) => {
                              return (
                                <option key={key} value={item}>
                                  {' '}
                                  &nbsp; {item == 1 ? 'À vista' : `${item} x`}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>

                      <Col xs={{ size: 12 }} sm={{ size: 8 }}>
                        {this.state.quotas_observation.map((quota, key) => {
                          return (
                            <Col
                              xs={{ size: 12 }}
                              sm={{ size: 12 }}
                              key={key}
                              className="mobile-no-paddings"
                            >
                              <Row>
                                <Col xs={{ size: 12 }} sm={{ size: 5 }}>
                                  <div className="form-input-data">
                                    <label className="blue-label">
                                      Vencimento
                                    </label>

                                    <DatePicker
                                      selected={
                                        new Date(
                                          this.utils.resolveSessionDate(
                                            quota.date
                                          )
                                        )
                                      }
                                      onChange={async e => {
                                        await this.changeQuotaInformation(
                                          e,
                                          'date',
                                          key
                                        );
                                      }}
                                      timeIntervals={30}
                                      dateFormat="d/MM/yyyy"
                                      timeCaption="time"
                                      className="blue-input"
                                    />
                                  </div>
                                </Col>
                                <Col xs={{ size: 12 }} sm={{ size: 7 }}>
                                  <div className="form-input">
                                    <label className="blue-label">
                                      Valor desta parcela
                                    </label>

                                    <CurrencyInput
                                      value={quota.observation}
                                      className="blue-input valFin"
                                      onChangeEvent={e => {
                                        this.changeQuotaInformation(
                                          e.target.value,
                                          'observation',
                                          key
                                        );
                                      }}
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      prefix="R$ "
                                      placeholder="Valor"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                    <Row>
                      <Col xs={{ size: 12 }} sm={{ size: 12 }}>
                        <div className="form-input">
                          <label className="blue-label">
                            Informações Complementares
                          </label>
                          <input
                            type="text"
                            value={this.state.additional_information}
                            className="blue-input"
                            placeholder="Informações Complementares"
                            onChange={e => {
                              this.setState({
                                additional_information: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{ size: 12 }} sm={{ size: 4, offset: 4 }}>
                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(
                            `finances/balance/${this.state.type}`
                          );
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar' : 'Cadastrar'}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryCreate)
);
