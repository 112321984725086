import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js'; 

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return (  
        <div className="principal-menu-responsible">
          <div className={ this.props.principalOpen ? "bt-menu" : "bt-menu active" } 
               onClick={ () => { this.props.togglePrincipal() }} >
            <i className="fas fa-bars"></i>
          </div>
          <ul className={ this.props.principalOpen ? "principal-menu nobox-shadow closed" : "principal-menu nobox-shadow" } >
            <li onClick={ () => { this.props.setSidebarLinks('proposals') } }  
              className={ this.props.view == 'proposals' ? "active box-shadow" : "box-shadow" }>Propostas</li> 
            <li onClick={ () => { this.props.setSidebarLinks('programs') } }   
              className={ this.props.view == 'programs' ? "active box-shadow{" : "box-shadow" }>Programa</li> 
          </ul>
        </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);