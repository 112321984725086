import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import Service from './Service.js';
import { Col } from 'reactstrap';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Submenu from './Submenu';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';

class ProfileProfessional extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'profile_professional',
      subview: 'session',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados profissionais', link: '' },
        { title: 'Sessão master gratuita', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      master: false,
      week: [
        { day: 'SEG', hours: [] },
        { day: 'TER', hours: [] },
        { day: 'QUA', hours: [] },
        { day: 'QUI', hours: [] },
        { day: 'SEX', hours: [] },
        { day: 'SÁB', hours: [] },
        { day: 'DOM', hours: [] },
      ],
      hours: [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.toggleMaster = this.toggleMaster.bind(this);
    this.addHour = this.addHour.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  toggleMaster(e) {
    this.setState({ master: e.target.checked });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.load();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  formValid() {
    return true;
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }

    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  // page

  addHour(wkey) {
    let week = this.state.week;
    for (let i = 0; i < this.state.hours.length; i++) {
      let h = this.state.hours[i];
      if (week[wkey].hours.indexOf(h) === -1) {
        week[wkey].hours.push(h);
        i = this.state.hours.length + 1;
      }
    }
    this.setState({ week });
  }

  changeSelect(wkey, hour, hkey) {
    let week = this.state.week;
    if (hour == 'remove' || week[wkey].hours.indexOf(hour) !== -1) {
      week[wkey].hours.splice(hkey, 1);
    } else {
      week[wkey].hours[hkey] = hour;
    }
    this.setState({ week });
  }

  async save() {
    if (this.formValid()) {
      this.setState({ loading: true });
      let professional_session = {
        week: this.state.week,
        master: this.state.master,
      };
      let user = { professional_session: JSON.stringify(professional_session) };
      let result = await this.service.save(user);
      if (result.success) {
        this.props.updateAlert('Salvo com sucesso');
        this.loadUser();
      }
      this.setState({ loading: false });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  async load() {
    this.setState({ loading: true });
    let result = await this.service.professional_session();
    if (result && result.professional_session != undefined) {
      this.setState({
        week: result.professional_session.week,
        master: result.professional_session.master,
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <Submenu goTo={this.goTo} subview={this.state.subview} />

              <div className="dashboard-box box100-menu box-flex">
                <div className="dflex">
                  <div className="box-row-title mobile-column">
                    {/*<div className="box-title">Sessão master gratuita</div> */}
                    <label className="container-ckb">
                      <input
                        type="checkbox"
                        checked={this.state.master}
                        onChange={this.toggleMaster}
                      />
                      <div className="container-pop">
                        <span className="chklbl">Sessão master gratuita</span>
                        <div className="popover-container">
                          <div className="popover-content">
                            Ao selecionar este item será informado no engage que
                            você realiza Sessões iniciais.
                          </div>
                        </div>
                      </div>
                      <span className="checkmark"></span>
                    </label>
                    <UploadImage
                      className="up-image-resp-new"
                      user={this.state.user}
                      uuser={this.loadUser}
                      newOption={true}
                    />
                  </div>
                  <Col
                    md={{ size: 8 }}
                    xs={{ size: 12 }}
                    className="text-description description-responsive"
                  >
                    A realização de uma Sessão Gratuita pode contribuir para uma
                    interação com o potencial Coachee além de permitir
                    esclarecer o que é e o que nao é Coaching. Ao selecionar
                    este item você estará informando que está aberto a
                    realização de Sessões gratuitas com seus potenciais
                    clientes. Em nenhum momento é um compromisso assumido,
                    apenas uma sinalizacão no engage. Recomendamos habilitá-la
                    por ser uma boa prática.
                  </Col>
                  {/* <div className="container-calendar" style={{ width: "100%" }}>
                    <div className="title-calendar">
                      Selecione sua disponibilidade
                    </div>
                    <div>
                      {this.state.week.map((wday, wkey) => {
                        return (
                          <div className="column-calendar" key={wkey}>
                            <div>{wday.day}</div>
                            {wday.hours
                              .sort((a, b) => {
                                if (a > b) {
                                  return 1;
                                }
                                if (a < b) {
                                  return -1;
                                }
                                return 0;
                              })
                              .map((hour, hkey) => {
                                return (
                                  <select
                                    value={hour}
                                    key={hkey}
                                    onChange={e => {
                                      this.changeSelect(
                                        wkey,
                                        e.target.value,
                                        hkey
                                      );
                                    }}
                                  >
                                    {this.state.hours.map((item, key) => {
                                      return (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      );
                                    })}
                                    <option value="remove">Remover</option>
                                  </select>
                                );
                              })}
                            <div
                              onClick={() => {
                                this.addHour(wkey);
                              }}
                            >
                              +
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>

                <div className="row-padding">
                  {this.state.loading ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <div
                      onClick={() => {
                        this.save();
                      }}
                      className={
                        !this.formValid()
                          ? 'bt-save hover-shadow'
                          : 'bt-save hover-shadow active'
                      }
                    >
                      Salvar Alterações
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileProfessional)
);
