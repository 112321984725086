import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/datePicker/style.scss';
import '../../../styles/css/oportunidade/style.scss';
import InputMask from 'react-input-mask';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import SidebarProposes from './../../sidebar/SidebarProposes';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class OportunityCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'register',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        {
          title: 'Cadastrar Oportunidade',
          link: 'proposals/oportunity/register',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading_coachee: false,
      loading: false,
      coachees: [],
      select_coachee: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      coachee_id: '',
      maxLength: 500,
      editing: false,
      editing_id: '',
      oportunities: [],
      validateEmail: true,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.selectCoachee = this.selectCoachee.bind(this);
    this.saveOportunity = this.saveOportunity.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.changeCoachee = this.changeCoachee.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.resolveParams();

    this.loadCoachees();
    this.loadOportunities();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.oportunity(_id);
    if (result && result.success) {
      this.setState({
        name: result.oportunity.name,
        email: result.oportunity.email,
        phone: result.oportunity.phone,
        needs_description: result.oportunity.description,
        coachee_id: result.oportunity.coachee_id,
        select_coachee:
          result.oportunity.coachee_id &&
          result.oportunity.coachee_id.length > 0,
      });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadOportunities() {
    let result = await this.service.oportunities();
    if (result && result.success) {
      this.setState({ oportunities: result.oportunities });
    }
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  selectCoachee(e) {
    this.setState({ select_coachee: e.target.checked });
  }

  async saveOportunity() {
    this.setState({ loading: true });
    await this.validateEmail(this.state.email);
    let _pay = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      description: this.state.needs_description,
      coachee_id: this.state.coachee_id,
    };

    if (this.state.validateEmail) {
      let result;
      if (this.state.editing) {
        result = await this.service.updateOportunity(
          _pay,
          this.state.editing_id
        );
      } else {
        result = await this.service.saveOportunity(_pay);
      }

      if (result && result.success) {
        this.goTo('dashboard/proposals');
        this.clearForm();
      }
    }
    this.setState({ loading: false });
  }

  clearForm() {
    this.setState({
      select_coachee: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      coachee_id: '',
    });
  }

  async changeCoachee(e) {
    this.setState({ coachee_id: e.target.value });
    let result = await this.service.coachee(e.target.value);
    if (result && result.success) {
      this.setState({
        name: result.coachee.name,
        email: result.coachee.email,
        phone: result.coachee.phone,
      });
    }
  }

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  render() {
    return (
      <div className="datepicker-style oportunidade-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan
                  .use_oportunities_proposals) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (this.state.user &&
                  this.state.user.plans &&
                  !this.state.user.plans.platform) ||
                (this.state.user &&
                  this.state.user.plans &&
                  this.state.user.plans.platform &&
                  this.state.user.plans.platform.plan.maximum_oportunities <=
                    this.state.oportunities.length) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100">
                    <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">
                          Informações da Oportunidade
                        </div>
                      </div>

                      {/*<div className="check-content">*/}

                      <Col
                        xs={{ size: 12 }}
                        sm={{ size: 12 }}
                        md={{ size: 24 }}
                      >
                        <label className="container-ckb responsive">
                          <input
                            type="checkbox"
                            checked={this.state.select_coachee}
                            onChange={ev => {
                              this.selectCoachee(ev);
                            }}
                          />
                          <span className="chklbl">
                            Selecione um Coachee Existente
                          </span>
                          <span className="checkmark" />
                        </label>
                      </Col>
                      <Col
                        xs={{ size: 12 }}
                        sm={{ size: 12 }}
                        md={{ size: 12 }}
                      >
                        <div className="form-input">
                          <label
                            className={
                              this.state.select_coachee
                                ? 'blue-label'
                                : 'new-oportunity-blue-label'
                            }
                          >
                            Coachee
                          </label>
                          {this.state.loading_coachee ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <select
                              value={this.state.coachee_id}
                              disabled={!this.state.select_coachee}
                              className={
                                this.state.select_coachee
                                  ? 'blue-input'
                                  : 'new-oportunity-blue-input'
                              }
                              onChange={e => {
                                this.changeCoachee(e);
                              }}
                            >
                              <option value=""> &nbsp; Cliente</option>
                              {this.state.coachees.map((item, key) => {
                                return (
                                  <option key={key} value={item._id}>
                                    {' '}
                                    &nbsp; {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </Col>

                      {/*</div>*/}

                      <div className="form-input">
                        <label className="blue-label">Cliente</label>
                        <input
                          type="text"
                          value={this.state.name}
                          className="blue-input"
                          placeholder="Cliente"
                          maxLength="40"
                          onChange={e => {
                            this.setState({ name: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-input">
                        <label className="blue-label">Email</label>
                        <input
                          type="text"
                          value={this.state.email}
                          className="blue-input"
                          placeholder="Email"
                          onChange={e => {
                            this.setState({ email: e.target.value });
                            this.validateEmail(e.target.value);
                          }}
                        />
                        {!this.state.validateEmail && (
                          <span className="campObrigatorio">
                            Campo "Email" é obrigatório e precisa ser válido
                          </span>
                        )}
                      </div>

                      <div className="form-input">
                        <label className="blue-label">Telefone</label>
                        <InputMask
                          {...this.props}
                          mask="(99) 999999999"
                          value={this.state.phone}
                          className="blue-input"
                          placeholder="Telefone"
                          onChange={e => {
                            this.setState({ phone: e.target.value });
                          }}
                        />
                      </div>

                      <div className="row-form">
                        <div className="form-textarea clear-textarea">
                          <label className="blue-label">
                            Descrição da Necessidade
                          </label>
                          <textarea
                            value={this.state.needs_description}
                            className="blue-input h55"
                            placeholder="Descrição da Necessidade"
                            maxLength={this.state.maxLength}
                            onChange={e => {
                              this.setState({
                                needs_description: e.target.value,
                              });
                            }}
                          />
                          <span>
                            {this.state.needs_description
                              ? this.state.needs_description.length
                              : 0}
                            /{this.state.maxLength}
                          </span>
                        </div>
                      </div>

                      <div className="row-between mtop-50 mbottom-50 btnCenter">
                        {this.state.loading ? (
                          <ReactLoading type="bubbles" color="#00838F" />
                        ) : (
                          <div
                            className="bt-blue left btnOportunity"
                            onClick={() => {
                              this.saveOportunity();
                            }}
                          >
                            {this.state.editing ? 'Salvar ' : 'Cadastrar '}
                          </div>
                        )}
                        <div
                          className="bt-blue-outline btnOportunity"
                          onClick={() => {
                            this.goTo('dashboard/proposals');
                          }}
                        >
                          Cancelar
                        </div>
                        <div
                          className="bt-blue btnOportunity"
                          onClick={() => {
                            this.goTo(
                              'dashboard/proposals/oportunity/create_batch'
                            );
                          }}
                        >
                          Importar
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OportunityCreate)
);
