import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js';

import { AreaChart } from 'react-easy-chart';  

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  } 
  
  render() {  
    return ( 

                <div className="dashboard-box box100">
                    <div className="box-row-title">
                      <div className="box-title">Desempenho na busca</div>
                      <div className="box-options">
                        <img src={ require('./../../assets/images/dashboard/i1.svg') } className="box-options-image" />
                        <img src={ require('./../../assets/images/dashboard/i2.svg') } className="box-options-image minor" />
                        <img src={ require('./../../assets/images/dashboard/i3.svg') } className="box-options-image" />
                      </div> 
                    </div>
                    <div className="box-chart">
                      <AreaChart
                          xType={'text'} 
                          xTicks={6}
                          yTicks={3}
                          grid 
                          axes={(this.props.windowWidth) > 800 ? true : false} 
                          width={this.props.windowWidth}
                          height={(this.props.windowWidth) < 600 ? 175 : 350}
                          noAreaGradient
                          style={{ color:'#F7B728' }}
                          areaColors={['#F7B728', '#28CCD9', '#CE68EB']}
                          interpolate={'cardinal'}   
                          data={this.props.areaData}
                        />
                        <ul>
                          <li>
                            <div className="before orange"></div>
                            Aparições nas busca
                          </li>
                          <li>
                            <div className="before blue"></div>
                            Cliques no perfil
                          </li>
                          <li>
                            <div className="before pink"></div>
                            Cliques no contato
                          </li> 
                        </ul>
                  </div>
                </div>

                 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);