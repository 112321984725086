import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../sidebar/Sidebar';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

// import RowStats from './../components/RowStats'

import CardGenericTable from './../../screens_dashboard/components/CardGenericTable';

import Footer from './../components/Footer';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

import BigCalendar from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';

import moment from 'moment/min/moment-with-locales';
import ApiCalendar from 'react-google-calendar-api';

const localizer = BigCalendar.momentLocalizer(moment);

window.React = React;

class HelpCenter extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'help',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Central de Ajuda', link: 'dashboard/help' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      category_faqs: [],
      faqs: [],
      open_faqs: [],
      expression: '',
    };

    this.utils = new Utils();
    this.service = new Service();

    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleFaq = this.toggleFaq.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    this.loadFaqs();
    window.addEventListener('resize', this.handleResize);
    moment.locale('pt-BR');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`coachees/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async loadFaqs() {
    let result = await this.service.readFaqs();
    if (result && result.success) {
      let category_faqs = this.readCategories(result.faqs);
      console.log({ faqs: result.faqs, category_faqs });
      this.setState({ faqs: result.faqs, category_faqs });
    }
  }

  readCategories(faqs) {
    let categories = [];
    for (let _f of faqs) {
      if (categories.indexOf(_f.category) === -1) {
        categories.push(_f.category);
      }
    }
    return categories;
  }

  toggleFaq(item) {
    let open_faqs = this.state.open_faqs;
    if (open_faqs.indexOf(item.key) === -1) {
      open_faqs.push(item.key);
    } else {
      open_faqs.splice(open_faqs.indexOf(item.key), 1);
    }
    this.setState({ open_faqs });
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.expression) {
      this.setState({ expression: params.expression });
    }
  }

  updateSearch(expression) {
    this.setState({ expression });
  }

  filterAsk(filter, item) {
    return (
      item.category == filter &&
      (item.category
        .toLowerCase()
        .indexOf(this.state.expression.toLowerCase()) !== -1 ||
        item.answer
          .toLowerCase()
          .indexOf(this.state.expression.toLowerCase()) !== -1 ||
        item.ask.toLowerCase().indexOf(this.state.expression.toLowerCase()) !==
          -1)
    );
  }

  render() {
    return (
      <div className="main-content zoomresolve">
        <Header
          goTo={this.goTo}
          expression={this.state.expression}
          updateSearch={this.updateSearch.bind(this)}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  {this.state.category_faqs
                    .filter(filter => {
                      return (
                        this.state.faqs.filter(item =>
                          this.filterAsk(filter, item)
                        ).length > 0
                      );
                    })
                    .map((filter, fkey) => {
                      return (
                        <div className="box100">
                          <div className="box-title" style={{ paddingTop: 10 }}>
                            {' '}
                            {filter}{' '}
                          </div>
                          <div className="box100">
                            {this.state.faqs
                              .filter(item => this.filterAsk(filter, item))
                              .map((item, key) => {
                                return (
                                  <div>
                                    <div
                                      className="box-title faq-question"
                                      onClick={() => {
                                        this.toggleFaq(item);
                                      }}
                                    >
                                      {item.ask}
                                    </div>
                                    <div
                                      className={`active-label-row-status faq-answer ${
                                        this.state.open_faqs.indexOf(
                                          item.key
                                        ) === -1
                                          ? 'close'
                                          : ''
                                      }`}
                                    >
                                      {item.answer
                                        .split('\n')
                                        .map((item, key) => {
                                          return <p key={key}>{item}</p>;
                                        })}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HelpCenter)
);
