import React from 'react';
import { useSelector } from 'react-redux';

import VideoSession from '../VideoSession';

function VideoSessionWrapper() {
  const {
    isActive,
    roomName,
    subject,
    type,
    userType,
    role,
    lobbyMessage,
  } = useSelector(state => state.simpleReducer.videoSession);

  return (
    isActive && (
      <VideoSession
        roomName={roomName}
        subject={subject}
        type={type}
        userType={userType}
        role={role}
        lobbyMessage={lobbyMessage}
      />
    )
  );
}

export default VideoSessionWrapper;
