import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

// import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarProfile from '../../sidebar/SidebarProfile';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';
import UploadImage from './UploadImage';

import Utils from './../../../libs/Utils.js';

class Terms extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'terms',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Termos de uso', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-box box100">
                <div className="box-row-title">
                  <div className="box-title">Termos de uso</div>
                </div>
                <p className="text-blue mleft-40">
                  Mussum Ipsum, cacilds vidis litro abertis. Praesent vel
                  viverra nisi. Mauris aliquet nunc non turpis scelerisque,
                  eget. Aenean aliquam molestie leo, vitae iaculis nisl. Paisis,
                  filhis, espiritis santis. Quem manda na minha terra sou euzis!
                </p>
                <p className="text-blue mleft-40">
                  Posuere libero varius. Nullam a nisl ut ante blandit
                  hendrerit. Aenean sit amet nisi. Sapien in monti palavris qui
                  num significa nadis i pareci latim. Si num tem leite então
                  bota uma pinga aí cumpadi! Copo furadis é disculpa de bebadis,
                  arcu quam euismod magna.
                </p>
                <p className="text-blue mleft-40">
                  Nullam volutpat risus nec leo commodo, ut interdum diam
                  laoreet. Sed non consequat odio. Em pé sem cair, deitado sem
                  dormir, sentado sem cochilar e fazendo pose. Interessantiss
                  quisso pudia ce receita de bolis, mais bolis eu num gostis. Si
                  u mundo tá muito paradis? Toma um mé que o mundo vai girarzis!
                </p>
                <p className="text-blue mleft-40">
                  Mais vale um bebadis conhecidiss, que um alcoolatra anonimis.
                  Mauris nec dolor in eros commodo tempor. Aenean aliquam
                  molestie leo, vitae iaculis nisl. Pra lá , depois divoltis
                  porris, paradis. Tá deprimidis, eu conheço uma cachacis que
                  pode alegrar sua vidis.
                </p>
                <p className="text-blue mleft-40">
                  In elementis mé pra quem é amistosis quis leo. Não sou faixa
                  preta cumpadi, sou preto inteiris, inteiris. Nec orci ornare
                  consequat. Praesent lacinia ultrices consectetur. Sed non
                  ipsum felis. Casamentiss faiz malandris se pirulitá.
                </p>
                <p className="text-blue mleft-40">
                  Suco de cevadiss deixa as pessoas mais interessantis. Admodum
                  accumsan disputationi eu sit. Vide electram sadipscing et per.
                  Cevadis im ampola pa arma uma pindureta. Todo mundo vê os
                  porris que eu tomo, mas ninguém vê os tombis que eu levo!
                </p>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terms));
