import React from 'react';

import StarsIcon from '@material-ui/icons/Stars';

import './TextRecommend.css';

const TextRecommend = ({
  classContainer,
  classIcon,
  classText,
  text,
  style,
  styleText,
  iconSize,
}) => {
  const styleIcon = { fontSize: iconSize ? iconSize : '24px' };
  const classNameText = `tr-text ${classText}`;
  return (
    <div
      className={classContainer ? classContainer : 'tr-container'}
      style={style}
    >
      <StarsIcon
        className={classIcon ? classIcon : 'tr-icon'}
        style={styleIcon}
      />
      <span className={classNameText} style={styleText}>
        {text ? text : 'Recomendamos'}
      </span>
    </div>
  );
};

export default TextRecommend;
