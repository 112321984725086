import React from 'react';
import { useSelector } from 'react-redux';

function SWUpdate(props) {
  // const isServiceWorkerInitialized = useSelector(
  //   state => state.simpleReducer.serviceWorkerInitialized,
  // );

  const isServiceWorkerUpdated = useSelector(
    state => state.simpleReducer.serviceWorkerUpdated,
  );
  const serviceWorkerRegistration = useSelector(
    state => state.simpleReducer.serviceWorkerRegistration,
  );

  const updateServiceWorker = () => {    
    const registrationWaiting = serviceWorkerRegistration ? serviceWorkerRegistration.waiting : false;
    
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (    
    <div>      
      {isServiceWorkerUpdated ? updateServiceWorker(): ''}
    </div>
  );
}

export default SWUpdate;

