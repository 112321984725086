import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import BoxOptions from './BoxOptions'; 
import Utils from './../../libs/Utils.js';

import { AreaChart } from 'react-easy-chart';  

class Dashboard extends Component { 

  constructor(props) {
    super(props);  
    this.state = {
      colors:[ 
        'orange',
        'blue',
        'pink'
      ],
      visible: true
    }
    this.utils = new Utils(); 
  }

  componentDidMount(){ 
      if( this.isClosed( `${ this.props.id }` ) ){
        this.setState({ visible:false })
      }
  }
  
  componentWillUnmount() {  
  } 


  toggleVisibility(){
    if( !this.state.visible ){
      this.setClosed(`${ this.props.id }`, '')
    }else{
      this.setClosed(`${ this.props.id }`, 'closed')
    }
    this.setState({ visible: !this.state.visible })
  }

    isClosed(_k){ 
    let key = `climby::${ _k }`
    try {
      return ( localStorage.getItem(key) !== null 
           && localStorage.getItem(key) !== 'null'
           && localStorage.getItem(key) !== '' )
      } catch (e) {
        return ( sessionStorage.getItem(key) !== null 
           && sessionStorage.getItem(key) !== 'null'
           && sessionStorage.getItem(key) !== '' )
      } 
  }
    
  setClosed(_k, t){
      let key = `climby::${ _k }`
        try {
          return localStorage.setItem(key, t);
      } catch (e) {
        return sessionStorage.setItem(key, t);
      }
  }

  
  render() {  
    return ( 

                <div className="dashboard-box box100">
                    <div className="box-row-title">
                      <div className="box-title">{ this.props.title }</div>
                      <BoxOptions visible={ this.state.visible } toggleVisibility={ this.toggleVisibility.bind(this) } /> 
                    </div>
                   { this.state.visible ? 
                    <div className="box-chart">
                      <AreaChart
                          xType={'text'} 
                          xTicks={6}
                          yTicks={3}
                          grid 
                          axes={(this.props.windowWidth) > 767 ? true : false} 
                          width={this.props.windowWidth}
                          height={(this.props.windowWidth) < 600 ? 122 : 210}
                          noAreaGradient
                          style={{ color:'#F7B728' }}
                          areaColors={['#F7B728', '#28CCD9', '#CE68EB']}
                          interpolate={'cardinal'}   
                          data={this.props.areaData}
                        />
                        <ul>
                          { 
                              this.props.labels.map( ( item, key ) => {
                                 return <li>
                                          <div className={ `before ${ this.state.colors[key] }` } ></div>
                                          { item }
                                        </li>
                              })
                          } 
                        </ul>
                  </div> : null }
                </div>

                 
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);