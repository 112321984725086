import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class Template6 extends Component {
  constructor(props, context) {
    super(props);

    this.utils = new Utils();
    this.service = new Service();
    this.goTo = this.goTo.bind(this);

    this.state = {
      homeMenu: 'block',
      sobreMenu: 'none',
      expeMenu: 'none',
      certMenu: 'none',
      refMenu: 'none',
    };
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  componentDidMount() {
    moment.locale('pt-BR');
  }

  componentWillUnmount() { }

  openSocial(uri) {
    window.open(uri, 'social');
  }

  acaoMenu = type => {
    switch (type) {
      case 0:
        this.setState({
          homeMenu: 'block',
          sobreMenu: 'none',
          expeMenu: 'none',
          certMenu: 'none',
          refMenu: 'none',
        });
        break;
      case 1:
        this.setState({
          homeMenu: 'none',
          sobreMenu: 'block',
          expeMenu: 'none',
          certMenu: 'none',
          refMenu: 'none',
        });
        break;
      case 2:
        this.setState({
          homeMenu: 'none',
          sobreMenu: 'none',
          expeMenu: 'block',
          certMenu: 'none',
          refMenu: 'none',
        });
        break;
      case 3:
        this.setState({
          homeMenu: 'none',
          sobreMenu: 'none',
          expeMenu: 'none',
          certMenu: 'block',
          refMenu: 'none',
        });
        break;
      case 4:
        this.setState({
          homeMenu: 'none',
          sobreMenu: 'none',
          expeMenu: 'none',
          certMenu: 'none',
          refMenu: 'block',
        });
        break;
      default:
        this.setState({
          homeMenu: 'block',
          sobreMenu: 'none',
          expeMenu: 'none',
          certMenu: 'none',
          refMenu: 'none',
        });
    }
  };

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  render() {
    return (
      <div>
        {/* start navigation */}
        <div className="navbar-expand-lg">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar">
              <i className="fas fa-bars"></i>
            </span>
          </button>
          <nav
            className="pages-nav collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <div className="pages-nav__item">
              <a
                className="link link--page"
                style={{ cursor: 'pointer', padding: 10 }}
                onClick={() => this.acaoMenu(0)}
              >
                Home
              </a>
            </div>
            <div className="pages-nav__item">
              <a
                className="link link--page"
                style={{ cursor: 'pointer', padding: 10 }}
                onClick={() => this.acaoMenu(1)}
              >
                Sobre mim
              </a>
            </div>
            <div className="pages-nav__item">
              <a
                className="link link--page"
                style={{ cursor: 'pointer', padding: 10 }}
                onClick={() => this.acaoMenu(2)}
              >
                Experiência
              </a>
            </div>
            <div className="pages-nav__item">
              <a
                className="link link--page"
                style={{ cursor: 'pointer', padding: 10 }}
                onClick={() => this.acaoMenu(3)}
              >
                Certificações
              </a>
            </div>
            <div className="pages-nav__item">
              <a
                className="link link--page"
                style={{ cursor: 'pointer', padding: 10 }}
                onClick={() => this.acaoMenu(4)}
              >
                Referências
              </a>
            </div>
          </nav>
        </div>
        {/* and navigation */}

        <div className="pages-stack">
          {/* Start Home Banner */}
          <div
            className="page home-banner white-bg"
            id="home"
            style={{ display: this.state.homeMenu }}
          >
            <div className="container-fluid p-0">
              <div className="row no-gutters full-screen">
                <div className="col-lg-3 col-xl-4">
                  {this.props.state.photo !== null &&
                    this.props.state.photo !== undefined &&
                    this.props.state.photo.trim() !== '' ? (
                      <div className="d-flex align-items-end home-user-avtar v-center-box">
                        <img
                          src={this.resolveImage(this.props.state.photo)}
                          style={{ width: 400, alignSelf: 'center' }}
                          title=""
                          alt=""
                        />
                      </div>
                    ) : (
                      ''
                    )}
                </div>
                <div className="col-lg-9 col-xl-8">
                  <div className="page-scroll">
                    <div className="page-content">
                      <div className="v-center-box d-flex align-items-center">
                        <div className="home-text">
                          <div className="col-lg-3 col-xl-6">
                            {this.props.state.logo !== null &&
                              this.props.state.logo !== undefined &&
                              this.props.state.logo.trim() !== '' ? (
                                <div className="d-flex align-items-end home-user-avtar logoImg">
                                  <img
                                    src={this.resolveImage(this.props.state.logo)}
                                    style={{ width: 400, alignSelf: 'center' }}
                                    title=""
                                    alt=""
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            <h1 className="d-flex align-items-center nomeCoach">
                              {this.props.state.nomeCoach}
                            </h1>
                          </div>
                        </div>
                        <ul className="social-icons">
                          {this.props.state.facebook === '' ||
                            this.props.state.facebook === null ||
                            this.props.state.facebook === undefined ||
                            !this.props.state.facebook.trim() ? null : (
                              <li>
                                <a
                                  className="facebook"
                                  href={this.props.state.facebook}
                                  target="_blank"
                                >
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                              </li>
                            )}
                          {this.props.state.twitter === '' ||
                            this.props.state.twitter === null ||
                            this.props.state.twitter === undefined ||
                            !this.props.state.twitter.trim() ? null : (
                              <li>
                                <a
                                  className="twitter"
                                  href={this.props.state.twitter}
                                  target="_blank"
                                >
                                  <i className="fab fa-twitter"></i>
                                </a>
                              </li>
                            )}
                          {this.props.state.google_plus === '' ||
                            this.props.state.google_plus === null ||
                            this.props.state.google_plus === undefined ||
                            !this.props.state.google_plus.trim() ? null : (
                              <li>
                                <a
                                  className="google"
                                  href={this.props.state.google_plus}
                                  target="_blank"
                                >
                                  <i className="fab fa-google-plus-g"></i>
                                </a>
                              </li>
                            )}
                          {this.props.state.linkedin === '' ||
                            this.props.state.linkedin === null ||
                            this.props.state.linkedin === undefined ||
                            !this.props.state.linkedin.trim() ? null : (
                              <li>
                                <a
                                  className="linkedin"
                                  href={this.props.state.linkedin}
                                  target="_blank"
                                >
                                  <i className="fab fa-linkedin-in"></i>
                                </a>
                              </li>
                            )}
                          {this.props.state.youtube === '' ||
                            this.props.state.youtube === null ||
                            this.props.state.youtube === undefined ||
                            !this.props.state.youtube.trim() ? null : (
                              <li>
                                <a
                                  className="youtube"
                                  href={this.props.state.youtube}
                                  target="_blank"
                                >
                                  <i className="fab fa-youtube"></i>
                                </a>
                              </li>
                            )}
                          {this.props.state.instagram === '' ||
                            this.props.state.instagram === null ||
                            this.props.state.instagram === undefined ||
                            !this.props.state.instagram.trim() ? null : (
                              <li>
                                <a
                                  className="instagram"
                                  href={this.props.state.instagram}
                                  target="_blank"
                                >
                                  <i className="fab fa-instagram"></i>
                                </a>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* AND Home Banner */}

          {/* Start sobremim */}
          <div
            className="page about-section white-bg"
            id="sobremim"
            style={{ display: this.state.sobreMenu }}
          >
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-12 col-xl-12">
                  <div className="page-scroll">
                    <div className="page-content">
                      {/* Page Titel */}
                      <div className="section-titel">
                        <div className="st-title">
                          <h2 className="theme-after dark-color">Sobre mim</h2>
                        </div>
                      </div>

                      {/* sobremim */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="resume-row">
                            <h2 className="theme-after dark-color">
                              Sobre mim
                            </h2>
                            <p className="m-0px justText">
                              {this.props.state.about_me}{' '}
                            </p>
                          </div>
                          {/* sobremim-text */}
                        </div>
                      </div>
                      {/* row */}
                    </div>
                    {/* page-content */}
                  </div>
                  {/* page-scroll */}
                </div>
              </div>
              {/* row */}
            </div>
            <div
              className="col-md-12 col-lg-12 col-xl-12"
              style={{
                marginBottom: '5%',
                flex: 1,
                flexDirection: 'row',
                height: 50,
              }}
            ></div>
            <div
              className="col-lg-12 col-xl-12"
              style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}
            >
              <div className="home-banner">
                <ul className="social-icons">
                  {this.props.state.facebook === '' ||
                    this.props.state.facebook === null ||
                    this.props.state.facebook === undefined ||
                    !this.props.state.facebook.trim() ? null : (
                      <li>
                        <a
                          className="facebook"
                          href={this.props.state.facebook}
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.twitter === '' ||
                    this.props.state.twitter === null ||
                    this.props.state.twitter === undefined ||
                    !this.props.state.twitter.trim() ? null : (
                      <li>
                        <a
                          className="twitter"
                          href={this.props.state.twitter}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.google_plus === '' ||
                    this.props.state.google_plus === null ||
                    this.props.state.google_plus === undefined ||
                    !this.props.state.google_plus.trim() ? null : (
                      <li>
                        <a
                          className="google"
                          href={this.props.state.google_plus}
                          target="_blank"
                        >
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.linkedin === '' ||
                    this.props.state.linkedin === null ||
                    this.props.state.linkedin === undefined ||
                    !this.props.state.linkedin.trim() ? null : (
                      <li>
                        <a
                          className="linkedin"
                          href={this.props.state.linkedin}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.youtube === '' ||
                    this.props.state.youtube === null ||
                    this.props.state.youtube === undefined ||
                    !this.props.state.youtube.trim() ? null : (
                      <li>
                        <a
                          className="youtube"
                          href={this.props.state.youtube}
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.instagram === '' ||
                    this.props.state.instagram === null ||
                    this.props.state.instagram === undefined ||
                    !this.props.state.instagram.trim() ? null : (
                      <li>
                        <a
                          className="instagram"
                          href={this.props.state.instagram}
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
          {/* And sobremim */}

          {/* Start experiencia */}
          <div
            className="page resume-section white-bg"
            id="experiencia"
            style={{ display: this.state.expeMenu }}
          >
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-12 col-xl-12">
                  <div className="page-scroll">
                    <div className="page-content">
                      {/* Page Titel */}
                      <div className="section-titel">
                        <div className="st-title">
                          <h2 className="theme-after dark-color">
                            Experiência
                          </h2>
                        </div>
                      </div>

                      {/* Resume experiencia */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="resume-row">
                            <h2 className="theme-after dark-color">
                              Experiência
                            </h2>
                            <p className="m-0px justText">
                              {this.props.state.experience}
                            </p>
                          </div>
                        </div>
                        {/* col */}
                      </div>
                      {/* row */}
                    </div>
                    {/* page-content */}
                  </div>
                  {/* page-scroll */}
                </div>
              </div>
              {/* row */}
            </div>
            <div
              className="col-md-12 col-lg-12 col-xl-12"
              style={{
                marginBottom: '5%',
                flex: 1,
                flexDirection: 'row',
                height: 50,
              }}
            ></div>
            <div
              className="col-lg-12 col-xl-12"
              style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}
            >
              <div className="home-banner">
                <ul className="social-icons">
                  {this.props.state.facebook === '' ||
                    this.props.state.facebook === null ||
                    this.props.state.facebook === undefined ||
                    !this.props.state.facebook.trim() ? null : (
                      <li>
                        <a
                          className="facebook"
                          href={this.props.state.facebook}
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.twitter === '' ||
                    this.props.state.twitter === null ||
                    this.props.state.twitter === undefined ||
                    !this.props.state.twitter.trim() ? null : (
                      <li>
                        <a
                          className="twitter"
                          href={this.props.state.twitter}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.google_plus === '' ||
                    this.props.state.google_plus === null ||
                    this.props.state.google_plus === undefined ||
                    !this.props.state.google_plus.trim() ? null : (
                      <li>
                        <a
                          className="google"
                          href={this.props.state.google_plus}
                          target="_blank"
                        >
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.linkedin === '' ||
                    this.props.state.linkedin === null ||
                    this.props.state.linkedin === undefined ||
                    !this.props.state.linkedin.trim() ? null : (
                      <li>
                        <a
                          className="linkedin"
                          href={this.props.state.linkedin}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.youtube === '' ||
                    this.props.state.youtube === null ||
                    this.props.state.youtube === undefined ||
                    !this.props.state.youtube.trim() ? null : (
                      <li>
                        <a
                          className="youtube"
                          href={this.props.state.youtube}
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.instagram === '' ||
                    this.props.state.instagram === null ||
                    this.props.state.instagram === undefined ||
                    !this.props.state.instagram.trim() ? null : (
                      <li>
                        <a
                          className="instagram"
                          href={this.props.state.instagram}
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
          {/* And experiencia */}

          {/* Start certificacoes */}
          <div
            className="page resume-section white-bg"
            id="certificacoes"
            style={{ display: this.state.certMenu }}
          >
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-12 col-xl-12">
                  <div className="page-scroll">
                    <div className="page-content">
                      {/* Page Titel */}
                      <div className="section-titel">
                        <div className="st-title">
                          <h2 className="theme-after dark-color">
                            Certificações
                          </h2>
                        </div>
                      </div>

                      {/* Resume certificacoes */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="resume-row">
                            <h2 className="theme-after dark-color">
                              Certificações
                            </h2>
                            <p className="m-0px justText">
                              {this.props.state.certifications}
                            </p>
                          </div>
                        </div>
                        {/* col */}
                      </div>
                      {/* row */}
                    </div>
                    {/* page-content */}
                  </div>
                  {/* page-scroll */}
                </div>
              </div>
              {/* row */}
            </div>
            <div
              className="col-md-12 col-lg-12 col-xl-12"
              style={{
                marginBottom: '5%',
                flex: 1,
                flexDirection: 'row',
                height: 50,
              }}
            ></div>
            <div
              className="col-lg-12 col-xl-12"
              style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}
            >
              <div className="home-banner">
                <ul className="social-icons">
                  {this.props.state.facebook === '' ||
                    this.props.state.facebook === null ||
                    this.props.state.facebook === undefined ||
                    !this.props.state.facebook.trim() ? null : (
                      <li>
                        <a
                          className="facebook"
                          href={this.props.state.facebook}
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.twitter === '' ||
                    this.props.state.twitter === null ||
                    this.props.state.twitter === undefined ||
                    !this.props.state.twitter.trim() ? null : (
                      <li>
                        <a
                          className="twitter"
                          href={this.props.state.twitter}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.google_plus === '' ||
                    this.props.state.google_plus === null ||
                    this.props.state.google_plus === undefined ||
                    !this.props.state.google_plus.trim() ? null : (
                      <li>
                        <a
                          className="google"
                          href={this.props.state.google_plus}
                          target="_blank"
                        >
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.linkedin === '' ||
                    this.props.state.linkedin === null ||
                    this.props.state.linkedin === undefined ||
                    !this.props.state.linkedin.trim() ? null : (
                      <li>
                        <a
                          className="linkedin"
                          href={this.props.state.linkedin}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.youtube === '' ||
                    this.props.state.youtube === null ||
                    this.props.state.youtube === undefined ||
                    !this.props.state.youtube.trim() ? null : (
                      <li>
                        <a
                          className="youtube"
                          href={this.props.state.youtube}
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.instagram === '' ||
                    this.props.state.instagram === null ||
                    this.props.state.instagram === undefined ||
                    !this.props.state.instagram.trim() ? null : (
                      <li>
                        <a
                          className="instagram"
                          href={this.props.state.instagram}
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
          {/* And certificacoes */}

          {/* Start referencias */}
          <div
            className="page resume-section white-bg"
            id="referencias"
            style={{ display: this.state.refMenu }}
          >
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-12 col-xl-12">
                  <div className="page-scroll">
                    <div className="page-content">
                      {/* Page Titel */}
                      <div className="section-titel">
                        <div className="st-title">
                          <h2 className="theme-after dark-color">
                            Referências
                          </h2>
                        </div>
                      </div>

                      {/* Resume referencias */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="resume-row">
                            <h2 className="theme-after dark-color">
                              Referências
                            </h2>
                            <p className="m-0px justText">
                              {this.props.state.references}
                            </p>
                          </div>
                        </div>
                        {/* col */}
                      </div>
                      {/* row */}
                    </div>
                    {/* page-content */}
                  </div>
                  {/* page-scroll */}
                </div>
              </div>
              {/* row */}
            </div>
            <div
              className="col-md-12 col-lg-12 col-xl-12"
              style={{
                marginBottom: '5%',
                flex: 1,
                flexDirection: 'row',
                height: 50,
              }}
            ></div>
            <div
              className="col-lg-12 col-xl-12"
              style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}
            >
              <div className="home-banner">
                <ul className="social-icons">
                  {this.props.state.facebook === '' ||
                    this.props.state.facebook === null ||
                    this.props.state.facebook === undefined ||
                    !this.props.state.facebook.trim() ? null : (
                      <li>
                        <a
                          className="facebook"
                          href={this.props.state.facebook}
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.twitter === '' ||
                    this.props.state.twitter === null ||
                    this.props.state.twitter === undefined ||
                    !this.props.state.twitter.trim() ? null : (
                      <li>
                        <a
                          className="twitter"
                          href={this.props.state.twitter}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.google_plus === '' ||
                    this.props.state.google_plus === null ||
                    this.props.state.google_plus === undefined ||
                    !this.props.state.google_plus.trim() ? null : (
                      <li>
                        <a
                          className="google"
                          href={this.props.state.google_plus}
                          target="_blank"
                        >
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.linkedin === '' ||
                    this.props.state.linkedin === null ||
                    this.props.state.linkedin === undefined ||
                    !this.props.state.linkedin.trim() ? null : (
                      <li>
                        <a
                          className="linkedin"
                          href={this.props.state.linkedin}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.youtube === '' ||
                    this.props.state.youtube === null ||
                    this.props.state.youtube === undefined ||
                    !this.props.state.youtube.trim() ? null : (
                      <li>
                        <a
                          className="youtube"
                          href={this.props.state.youtube}
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                  {this.props.state.instagram === '' ||
                    this.props.state.instagram === null ||
                    this.props.state.instagram === undefined ||
                    !this.props.state.instagram.trim() ? null : (
                      <li>
                        <a
                          className="instagram"
                          href={this.props.state.instagram}
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
          {/* And referencias */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Template6)
);
