import ApiService from '../../services/ApiService';

class Service extends ApiService {
  async oportunities() {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesFree() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/free`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesMaster() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/master`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveOportunity(_payload) {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveOpportunitiesBatch(_payload) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/batch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateOportunity(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async shareReport(report) {
    try {
      let response = await fetch(`${this.endPoint}share/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(report),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionContent(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSessionContent(
    sessionId,
    meetingType,
    coacheeId,
    contentId,
    payload
  ) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeOportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coachees() {
    try {
      let response = await fetch(`${this.endPoint}coachees`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coachee(_id) {
    try {
      let response = await fetch(`${this.endPoint}coachee/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveCoachee(_payload) {
    try {
      let response = await fetch(`${this.endPoint}coachees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateCoachee(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}coachees/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveCoacheesBatch(_payload) {
    try {
      let response = await fetch(`${this.endPoint}coachees/batch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeCoachee(_id) {
    try {
      let response = await fetch(`${this.endPoint}coachees/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async inviteCoachee(_id) {
    try {
      let response = await fetch(`${this.endPoint}coachees/invite/${_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async verifyCoachee(_payload) {
    try {
      let response = await fetch(`${this.endPoint}coachees/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async verifyGroup(_payload) {
    try {
      let response = await fetch(`${this.endPoint}groups/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postFile(fileToUpload, notConvert = false) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('notConvert', String(notConvert));    
    try {
      let response = await fetch(`${this.endPoint}upload/file`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
        body: formData,
      });

      let responseJson = '';
      if (response.ok) {
        responseJson = await response.json();
      } else {
        responseJson = response;
      }
      return responseJson;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async convertFileDocx(_payload) {
    try {
      let response = await fetch(`${this.endPoint}upload/convert/docx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async convertFilePDF(_payload) {
    try {
      let response = await fetch(`${this.endPoint}upload/convert/pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async categories() {
    try {
      let response = await fetch(`${this.endPoint}categories`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveContent(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveContentUpdate(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveContentToMe(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents/copy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateContent(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}contents/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contents() {
    try {
      let response = await fetch(`${this.endPoint}contents`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async foldersAvailable() {
    try {
      let response = await fetch(`${this.endPoint}contents/folders`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionActions(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/actions`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async content(_id) {
    try {
      let response = await fetch(`${this.endPoint}contents/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsFolder(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory/folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsRel() {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsClimb() {
    try {
      let response = await fetch(`${this.endPoint}contents/all`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsClimbOne(contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}contents/climby/${contentId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeContent(_id) {
    try {
      let response = await fetch(`${this.endPoint}contents/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessions() {
    try {
      let response = await fetch(`${this.endPoint}sessions`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async session(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/oportunity/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionC(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/coachee/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionG(groupId) {
    try {
      let response = await fetch(`${this.endPoint}sessions/group/${groupId}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionS(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/session/${sessionId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createSession(_payload) {
    try {
      let response = await fetch(`${this.endPoint}sessions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSession(sessionId, payload) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${sessionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updatePlanning(sessionId, payload) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/planning`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async storeMeeting(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/meetings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async destroyMeeting(sessionId, number) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/meetings/${number}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateMeeting(sessionId, meetingType, payload) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/meetings/${meetingType}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async storeSessionContents(
    sessionId,
    meetingType,
    coacheeIds,
    contentIds,
    isReport = false
  ) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/${meetingType}/contents`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify({ coacheeIds, contentIds, isReport }),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async destroySessionContent(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/${meetingType}/contents/${coacheeId}/${contentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async completeSessionTask(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}/complete`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async storeSessionActions(sessionId, meetingType, coacheeIds, action) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/${meetingType}/actions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify({ coacheeIds, action }),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSessionAction(
    sessionId,
    meetingType,
    coacheeId,
    actionNumber,
    action
  ) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/actions/${meetingType}/${coacheeId}/${actionNumber}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(action),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async destroySessionAction(sessionId, meetingType, coacheeId, actionNumber) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/${meetingType}/actions/${coacheeId}/${actionNumber}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async completeSessionAction(sessionId, meetingType, coacheeId, actionNumber) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/actions/${meetingType}/${coacheeId}/${actionNumber}/complete`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeSession(_id) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionContents(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/${sessionId}/contents`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async fin_providers() {
    try {
      let response = await fetch(`${this.endPoint}finance/providers`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async finProvider(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/provider/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveFinProvider(_payload) {
    try {
      let response = await fetch(`${this.endPoint}finance/providers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateFinProvider(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}finance/providers/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeFinProvider(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/providers/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async fin_services() {
    try {
      let response = await fetch(`${this.endPoint}finance/services`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async finService(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/service/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveFinService(_payload) {
    try {
      let response = await fetch(`${this.endPoint}finance/services`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateFinService(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}finance/services/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeFinService(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/services/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async fin_categories() {
    try {
      let response = await fetch(`${this.endPoint}finance/categories`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async fin_categoriesIn() {
    try {
      let response = await fetch(`${this.endPoint}finance/categories/in`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async fin_categoriesOut() {
    try {
      let response = await fetch(`${this.endPoint}finance/categories/out`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async finCategory(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/category/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveFinCategory(_payload) {
    try {
      let response = await fetch(`${this.endPoint}finance/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateFinCategory(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}finance/categories/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeFinCategory(_id) {
    try {
      let response = await fetch(`${this.endPoint}finance/categories/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async optionsFinCategory() {
    try {
      let response = await fetch(`${this.endPoint}finance/categories/options`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async optionsFinProviderPT() {
    try {
      let response = await fetch(
        `${this.endPoint}finance/providers/options-pt`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async optionsFinProviderIE() {
    try {
      let response = await fetch(
        `${this.endPoint}finance/providers/options-ie`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async modelProposals() {
    try {
      let response = await fetch(`${this.endPoint}model/proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposals() {
    try {
      let response = await fetch(`${this.endPoint}proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}proposal/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveProposal(_payload) {
    try {
      let response = await fetch(`${this.endPoint}proposals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProposal(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}proposals/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeProposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}proposals/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balances() {
    try {
      let response = await fetch(`${this.endPoint}balances`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balancesIn() {
    try {
      let response = await fetch(`${this.endPoint}balances/in`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balancesOut() {
    try {
      let response = await fetch(`${this.endPoint}balances/out`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balance(_id) {
    try {
      let response = await fetch(`${this.endPoint}balance/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveBalance(_payload) {
    try {
      let response = await fetch(`${this.endPoint}balances`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateBalance(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}balances/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeBalance(_id) {
    try {
      let response = await fetch(`${this.endPoint}balances/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balanceNumber() {
    try {
      let response = await fetch(`${this.endPoint}balances/number`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balanceDashboard(periodicy = false) {
    try {
      let response = await fetch(
        `${this.endPoint}balances/dashboard${periodicy ? `?periodicy=${periodicy}` : ''}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async balanceChart() {
    try {
      let response = await fetch(`${this.endPoint}balances/chart`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async search(_payload) {
    try {
      let response = await fetch(`${this.endPoint}search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async readFaqs() {
    try {
      let response = await fetch(`${this.endPoint}faqs`, {
        method: 'GET',
        headers: {},
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createMessages(_pay) {
    try {
      let response = await fetch(`${this.endPoint}chat/message/coach`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_pay),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createNewChatByCoach(payload) {
    try {
      const response = await fetch(`${this.endPoint}chat/create/coach`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(payload),
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateChat(payload, _id) {
    try {
      const response = await fetch(`${this.endPoint}chat/update/coach/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(payload),
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateReadedChat(_id) {
    try {
      const response = await fetch(`${this.endPoint}chat/update/readed/coach/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async getCoachChats() {
    try {
      const response = await fetch(`${this.endPoint}chat/list/coach`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async calendar() {
    try {
      let response = await fetch(`${this.endPoint}calendar`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createCalendar(_pay) {
    try {
      let response = await fetch(`${this.endPoint}calendar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_pay),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateCalendar(_pay, _id) {
    try {
      let response = await fetch(`${this.endPoint}calendar/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_pay),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteCalendar(_id) {
    try {
      let response = await fetch(`${this.endPoint}calendar/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async dashboard(periodicy = false) {
    try {
      let response = await fetch(
        `${this.endPoint}dashboard${periodicy ? `?periodicy=${periodicy}` : ''}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async marketingSlug(slug) {
    try {
      let response = await fetch(`${this.endPoint}marketing/open/${slug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async marketingSlugPreview(slug) {
    try {
      let response = await fetch(`${this.endPoint}marketing/preview/${slug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async marketingMe() {
    try {
      let response = await fetch(`${this.endPoint}marketing/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async marketingTemplate(_payload) {
    try {
      let response = await fetch(`${this.endPoint}marketing/template`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async verifyMarketingUsed(_payload) {
    try {
      let response = await fetch(`${this.endPoint}marketing/used`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveMarketing(_payload) {
    try {
      let response = await fetch(`${this.endPoint}marketing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateMarketing(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}marketing/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postImage(fileToUpload) {
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    try {
      let response = await fetch(`${this.endPoint}upload/image`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
        body: formData,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async exportFile(_payload) {
    try {
      let response = await fetch(`${this.endPoint}export`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async invoiceGenerate(_payload) {
    try {
      let response = await fetch(`${this.endPoint}invoice/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async firstAccess() {
    try {
      let response = await fetch(`${this.endPoint}firstaccess`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notifyCoachee(coachee_id, sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}session-notify/${coachee_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify({ sessionId }),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async notifyGroup(groupId, sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}session-notify/group/${groupId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify({ sessionId }),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateShare(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}updateshare/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeImage(_payload, _id) {
    try {
      let response = await fetch(
        `${this.endPoint}marketing/removeimage/${_id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: this.parsePayload(_payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheesAtivos() {
    try {
      let response = await fetch(`${this.endPoint}coachees/ativos`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveGroup(_payload) {
    try {
      let response = await fetch(`${this.endPoint}groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateGroup(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}groups/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async groups() {
    try {
      let response = await fetch(`${this.endPoint}groups`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async group(_id) {
    try {
      let response = await fetch(`${this.endPoint}groups/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeGroup(_id) {
    try {
      let response = await fetch(`${this.endPoint}groups/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeGroupImageById(_id) {
    try {
      let response = await fetch(
        `${this.endPoint}groups/removeimagebyid/${_id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeGroupImage(_payload) {
    try {
      let response = await fetch(`${this.endPoint}groups/removeimage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeFile(_payload) {
    try {
      let response = await fetch(
        `${this.endPoint}upload/file/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(_payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
