import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import PropTypes from 'prop-types';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction, updateImage } from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../sidebar/Sidebar';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

import CardGenericTable from './../../screens_dashboard/components/CardGenericTable';

import Footer from './../components/Footer';

import RowStats from './../components/RowStats';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';
import moment from 'moment';

class Dashboard extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'dash',
      breadcrumbs: [{ title: 'Dashboard', link: '' }],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,

      user: {},

      rows: [],
      titles: [
        { title: 'Nome', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
      ],

      rows: [],
      titles: [
        { title: 'Nome', key: '_profile' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
      ],

      rows1: [],
      titles1: [
        { title: 'Nome', key: 'name' },
        { title: 'Status', key: '_status' },
      ],

      rows2: [],
      titles2: [
        { title: 'Nome', key: '_profile' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
      ],

      rows3: [],
      titles3: [
        { title: 'Título', key: 'title' },
        { title: 'Valor', key: 'value' },
      ],

      rows4: [],
      titles4: [
        { title: 'Coach', key: 'name' },
        { title: 'Status no engage', key: 'statusProfile' },
        { title: 'Data do cadastro', key: 'created_at' },
      ],

      rows5: [],
      titles5: [
        { title: 'Coach esperando habilitar', key: 'name' },
        { title: 'Data solicitação', key: 'dateCuration' },
        { title: 'Aprovação', key: 'statusProfile' },
        { title: 'Status', key: 'statusCuration' },
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadDash();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadDash() {
    let result = await this.service.dashboard();
    if (result && result.success) {
      await this.setState({
        rows: result.coachs,
        rows1: result.coachees,
        rows2: result.admins,
        rows3: result.plans,
        rows4: result.coachs.map(item => ({
          ...item,
          created_at: moment(+item.created_at).format('DD/MM/YYYY'),
        })),
        rows5: result.curation.map(item => ({
          _id: item._id,
          name: item.name,
          dateCuration: moment(item.curation.date).format('DD/MM/YYYY'),
          statusProfile: item.curation.status === 'Aprovado',
          statusCuration: item.curation.review ? 'Revisão' : 'Nova solicitação',
          curation: { ...item.curation },
        })),
        stats: result.stats,
      });
    }
  }

  toggleSwitchEngage = async indexRow => {
    const rows4 = this.state.rows4;
    const newProfileActive = {
      profile_active: !rows4[indexRow].profile_active,
    };
    const user = { coach_id: rows4[indexRow]._id, newData: newProfileActive };
    const result = await this.service.updateCoach(user);
    if (result && result.success) {
      this.loadDash();
    }
  };

  toggleSwitchCuration = async indexRow => {
    const rows5 = this.state.rows5;
    const newCurantionData = {
      curation: {
        active: true,
        date: new Date(),
        status: 'Aprovado',
        review: false,
      },
    };
    const user = { coach_id: rows5[indexRow]._id, newData: newCurantionData };
    const result = await this.service.updateCoach(user);
    if (result && result.success) {
      this.loadDash();
    }
  };

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            {/* <PrincipalMenu 
              principalOpen={ this.state.principalOpen } 
              view={ this.state.view } 
              setSidebarLinks={ this.setSidebarLinks }
              togglePrincipal={ this.togglePrincipal } /> */}

            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                {/*<PeriodiceFilter filter={ "Semestral" } />*/}
              </div>

              <RowStats stats={this.state.stats} />

              <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                <Col md={{ size: '12' }} lg={{ size: '7' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="admin-dashboard-dashboard-1"
                      title="Coaches"
                      state={this.state}
                      title_rows={this.state.titles}
                      rows={this.state.rows}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '5' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      title="Coachees"
                      id="admin-dashboard-dashboard-2"
                      state={this.state}
                      title_rows={this.state.titles1}
                      rows={this.state.rows1}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '6' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="admin-dashboard-dashboard-3"
                      title="Planos"
                      state={this.state}
                      title_rows={this.state.titles3}
                      rows={this.state.rows3}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '6' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="admin-dashboard-dashboard-4"
                      title="Administradores"
                      state={this.state}
                      title_rows={this.state.titles2}
                      rows={this.state.rows2}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '12' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="admin-dashboard-dashboard-1"
                      title="Engage"
                      state={this.state}
                      title_rows={this.state.titles4}
                      rows={this.state.rows4}
                      toggleSwitch={this.toggleSwitchEngage}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '12' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="admin-dashboard-dashboard-1"
                      title="Curadoria"
                      state={this.state}
                      title_rows={this.state.titles5}
                      rows={this.state.rows5}
                      toggleSwitch={this.toggleSwitchCuration}
                    />
                  </div>
                </Col>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
