import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import {
  updateImage,
  updateAlert,
  updateSession,
} from '../../actions/SimpleAction';
import Submenu from './Groups/Submenu';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import CardGenericTable from './../components/CardGenericTable';
import NotAllowed from './../components/NotAllowed';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import NewModalCoacheeDelete from './../components/NewModalCoacheeDelete';
import ModalContents from './Session/ModalContents';

class Groups extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      subview: 'groups',
      side: 'coachees',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Grupos', link: 'groups' },
      ],
      showToolTip: false,
      opened_coachee: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      rows: [],
      title_rows: [
        { title: 'Nome', key: 'group' },
        { title: 'Tipo de atendimento', key: 'typeService' },
        { title: 'Ação', key: 'options' },
      ],
      actions: [
        {
          title: 'Compartilhar Conteúdo',
          svg: {
            icon: require('./../../assets/images/tables/share_content.svg'),
            width: 17,
          },
          action: this.openContents.bind(this),
        },
        {
          title: 'Sessão de coaching',
          svg: {
            icon: require('./../../assets/images/tables/session.svg'),
            width: 24,
          },
          action: this.openMaster.bind(this),
        },
        {
          title: 'Financeiro',
          svg: {
            icon: require('./../../assets/images/tables/finance.svg'),
            width: 17,
          },
          action: this.openFinance.bind(this),
        },
        {
          title: 'Relatório',
          svg: {
            icon: require('./../../assets/images/tables/printer.svg'),
            width: 17,
          },
          action: this.openReports.bind(this),
        },
        {
          title: 'Editar',
          svg: {
            icon: require('./../../assets/images/tables/edit.svg'),
            width: 17,
          },
          action: this.edit.bind(this),
        },
        {
          title: 'Remover',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeGroup.bind(this),
        },
      ],
      contents: [],
      sort: '',
      new_modal_group_delete: false,
      groupName: '',
      groupId: '',
      showModalContents: false,
      contentList: [],
      selectedContents: [],
      groupList: [],
      selectedCoachees: [],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadGroups();
    this.loadContents();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    const result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    const user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadGroups() {
    let result = await this.service.groups();
    if (result && result.success) {
      const groups = result.groups.map(item => ({
        ...item,
        group: item.name,
        typeService: this.getTypeService(item),
      }));
      this.setState({ rows: groups, groupList: result.groups });
    }
  }

  getTypeService = item => (
    <div className="row-center">
      <div
        className={
          !item.presential
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-5.svg')}
          width="12"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-5b.svg')}
          width="12"
        />
        Presenciais
      </div>
      <div
        className={
          !item.online
            ? 'option-type img-type-service'
            : 'option-type active img-type-service'
        }
      >
        <img
          src={require('./../../assets/images/menu/personal/icon-6.svg')}
          width="30"
        />
        <img
          src={require('./../../assets/images/menu/personal/icon-6b.svg')}
          width="30"
        />
        Online
      </div>
    </div>
  );

  async removeGroup(item) {
    await this.setState({
      groupId: item._id,
      groupName: item.name ? item.name : '',
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    let result = await this.service.removeGroup(this.state.groupId);
    if (result && result.success) {
      this.loadGroups();
      this.props.updateAlert('Deletado com sucesso');
    }
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      new_modal_group_delete: !this.state.new_modal_group_delete,
    });
  }

  edit(item) {
    this.setSidebarLinks(`groups/create/${item._id}`);
  }

  openContents(item) {
    if (this.state.selectedGroup !== item._id) {
      this.setState({ selectedContents: [], selectedCoachees: [] });
    }
    this.setState({ selectedGroup: item._id }, () => {
      this.toggleModalContent();
    });
  }

  toggleModalContent = () => {
    const { showModalContents } = this.state;
    this.setState({ showModalContents: !showModalContents });
  };

  async loadContents() {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents });
    }
    this.setState({ loading_contents: false });
  }

  getGroupCoachees = () => {
    const { selectedGroup, groupList } = this.state;
    const group =
      selectedGroup &&
      groupList &&
      groupList.find(group => group._id === selectedGroup);
    return group ? group.coachees : [];
  };

  toggleContent = selectedItem => {
    let selectedContents = [...this.state.selectedContents];
    const isContentSelected = selectedContents.find(
      content => content._id === selectedItem._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== selectedItem._id
      );
    } else {
      selectedContents.push(selectedItem);
    }
    this.setState({ selectedContents });
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  share = async () => {
    const { selectedContents, selectedCoachees } = this.state;
    const contentIds = selectedContents.map(content => content._id);
    const sendTo = selectedCoachees.map(coachee => coachee._id);
    if (sendTo.length > 0 && contentIds.length > 0) {
      const session = await this.verifyCreateGroupSession(
        this.state.selectedGroup
      );

      if (session) {
        const result = await this.service.storeSessionContents(
          session._id,
          'session',
          sendTo,
          contentIds
        );

        if (result && result.success) {
          this.props.updateAlert('Compartilhado com sucesso');
        }
      }
    }

    this.toggleModalContent();
  };

  async openMaster(group) {
    const session = await this.verifyCreateGroupSession(group._id);
    this.goTo(`dashboard/programs/coaching/edit/${session._id}`);
  }

  openFinance() {
    this.goTo(`dashboard/finances`);
  }

  openReports() {
    this.goTo(`dashboard/reports`);
  }

  verifyCreateGroupSession(group_id) {
    return this.service.verifyGroup({ group_id });
  }

  sort(key) {
    let order = this.state.sort == '' || this.state.sort == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort: `${key}${order}`, rows });
  }

  render() {
    return (
      <div className="main-content zoomresolve">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter with-margin-left">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <Submenu
                  goTo={this.goTo}
                  subview={this.state.subview}
                  user={this.state.user}
                />

                {this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform ? (
                  <NotAllowed
                    go={this.goTo.bind(this)}
                    user={this.state.user}
                  />
                ) : (
                  <div>
                    <div className="dashboard-boxes zoomed dashboard-boxes-with-margin-left">
                      {/* Cards */}
                      <CardGenericTable
                        title="Grupos"
                        id="groups-1"
                        sort={this.sort.bind(this)}
                        _sort={this.state.sort}
                        actions={this.state.actions}
                        title_rows={this.state.title_rows}
                        rows={this.state.rows}
                        // toggleSwitch={this.toggleSwitch}
                        edit={this.edit.bind(this)}
                        campoImagem={true}
                        campoNome={true}
                        campoEmail={true}
                      />
                    </div>
                    <div className="row-start">
                      <div className="row-button">
                        <div
                          className="bt-blue margin-left-25"
                          onClick={() => this.setSidebarLinks('groups/create')}
                        >
                          <img
                            src={require('./../../assets/images/buttons/add_coachee.svg')}
                            style={{ width: 20, marginRight: 10 }}
                          />
                          Cadastrar Grupo
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={this.state.sort}
          show={this.state.showModalContents}
          contentList={this.state.contentList}
          selectedContents={this.state.selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={this.state.selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
          share={this.share.bind(this)}
        />

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_group_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_group_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Deseja deletar: ${this.state.groupName} ?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert, updateSession }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Groups));
