import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../styles/css/style.css';
 
import { Button } from 'reactstrap';

import Header from './../../includes/Header'

import { Link } from 'react-router-dom'

class NotFound extends Component {

  render() {
    return ( 
      <div>
      	  <Header />
	      <div className="not-found-label">
	      	<p className="card-title">Página não encontrada</p> 
	      </div>
      </div>
    );
  }
}

export default connect()(NotFound);