import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { startOfToday, subYears } from 'date-fns';

import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import { Col } from 'reactstrap';

import { updateImage, updateAlert } from '../../actions/SimpleAction';

import ActivityMonitor from '../components/ActivityMonitor';
import ModalAlert from '../components/ModalAlert';
import Service from './Profile/Service';
import Utils from './../../libs/Utils.js';
import Header from './../../includes/Header';
import './../../styles/css/index.css';
import './../../styles/css/datePicker/style.scss';

const InputError = ({ error, children }) => {
  return (
    <>
      {children}
      {error && <span className="cool-input-error">{error}</span>}
    </>
  );
};

class RegisterCompletion extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: {
        _id: '',
        name: '',
        surname: '',
        cpf: '',
        birthday: '',
        state: '',
        city: '',
        country: 'Brasil',
      },

      states: [],
      cities: [],
      loading_countries: false,
      loading_state: false,
      loading_cities: false,

      loading: false,

      cpfError: '',
      birthdayError: '',
      countryError: '',
      countries: [],
    };

    this.service = new Service();
    this.utils = new Utils();
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCountries();
    this.loadStates(this.state.user.country);
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  loadDdi = async country => {
    let ddiCountry = '55';
    if (country) {
      const countryFiltered = this.state.countries.filter(
        item => item.name === country
      );
      if (countryFiltered[0]) {
        ddiCountry = countryFiltered[0].ddi[0]
          ? countryFiltered[0].ddi[0]
          : '55';
      }
    }
    return ddiCountry;
  };

  save = async () => {
    const {
      name,
      surname,
      // cpf,
      city,
      state,
      birthday,
      country,
    } = this.state.user;
    if (this.formValid()) {
      this.setState({ loading: true });
      const professional_value = {
        currency: 'R$',
        price: 'Sob consulta',
        specif_value: '',
      };

      const ddi = await this.loadDdi(country);

      const user = {
        name,
        surname,
        cpf: '',
        location: JSON.stringify([{ country, state, city }]),
        birthday: birthday ? this.utils.parseDateUSA(birthday) : '',
        engage_name: `${name || ''} ${surname || ''}`,
        contact: JSON.stringify([{ ddi, phone: '', site: '' }]),
        professional_value: JSON.stringify(professional_value),
      };
      const result = await this.service.save(user);
      if (result.success) {
        this.loadUser();
        this.props.setForceRefresh(true);
      }
      if (result.status === 'Error') {
        this.props.updateAlert(result.messages[0]);
      }
      this.setState({ loading: false });
    } else {
      const allFieldsFilled = name && surname && city && state && country;
      // name && surname && cpf && birthday && city && state;

      if (!allFieldsFilled) {
        this.props.updateAlert('Preencha todos os campos');
        return;
      }

      // const isCpfValid = this.utils.validateCPF(cpf);
      const isBirthdayValid = this.validateDate(birthday);

      // if (!isCpfValid) this.setState({ cpfError: 'CPF Inválido' });

      if (!isBirthdayValid)
        this.setState({ birthdayError: 'Data de Nascimento Inválida' });
    }
  };

  validateDate = date => {
    if (date) {
      const parsedDate = new Date(
        this.utils.resolveSessionDate(this.utils.parseDateUSA(date))
      );

      return parsedDate instanceof Date && !isNaN(parsedDate);
    } else {
      return true;
    }
  };

  formValid() {
    // const { name, surname, cpf, birthday, city, state } = this.state.user;
    // const allFieldsFilled = name && surname && cpf && birthday && city && state;

    const { name, surname, birthday, city, state, country } = this.state.user;
    const allFieldsFilled = name && surname && city && state && country;

    if (!allFieldsFilled) return false;

    // const isCpfValid = this.utils.validateCPF(cpf);
    const isBirthdayValid = this.validateDate(birthday);

    // return isCpfValid && isBirthdayValid;
    return isBirthdayValid;
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  loadStates = async country => {
    this.setState({ loading_state: true });
    if (country === 'Brasil') {
      const result = await this.service.states();
      if (result && result.status === 'Success')
        this.setState({ loading_state: false, states: result.states });
    } else {
      const param = encodeURI(country);
      const result = await this.service.statesForeign(param);
      if (result && result.success) {
        this.setState({
          loading_state: false,
          states: result.data[0].states ? result.data[0].states : [],
          cities: [],
        });
      }
    }
  };

  loadCities = async (state, country) => {
    this.setState({
      user: { ...this.state.user, state, city: '' },
      cities: [],
    });

    if (country === 'Brasil' || country === undefined) {
      if (!state) return;
      this.setState({ loading_cities: true });
      const result = await this.service.cities(state);
      if (result && result.status === 'Success')
        this.setState({ loading_cities: false, cities: result.cities });
    } else {
      this.setState({ loading_cities: true });
      const citiesData = this.state.states.filter(item => item.name === state);
      this.setState({
        loading_cities: false,
        cities:
          citiesData.length > 0 && citiesData[0].cities
            ? citiesData[0].cities
            : [],
      });
    }
  };

  async loadUser() {
    this.setState({ loading: true });
    const result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    const user = {
      _id: result.user._id,
      name: result.user.name || '',
      surname: result.user.surname || '',
      // cpf: result.user.cpf || '',
      birthday: result.user.birthday
        ? new Date(this.utils.resolveSessionDate(result.user.birthday))
        : '',
      country: 'Brasil',
    };

    delete user.location;
    if (result.user.location && result.user.location.length > 0) {
      user.state = result.user.location[0].state;
      user.city = result.user.location[0].city;
      user.country = result.user.location[0].country || 'Brasil';
      await this.loadStates(user.country);
      this.loadCities(user.state, user.country);
    }

    this.setState({ loading: false, user });

    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  loadCountries = async () => {
    this.setState({ loading_countries: true });
    const result = await this.service.countries();
    if (result && result.success) {
      if (result.countries && result.countries.length === 0)
        result.countries = [{ name: 'Brasil' }];

      this.setState({
        loading_countries: false,
        countries: result.countries,
      });
    } else {
      this.setState({
        loading_countries: false,
        countries: [{ name: 'Brasil' }],
        countryError: 'Houve um erro ao carregar os países',
      });
    }
  };

  render() {
    const {
      user,
      loading_countries,
      loading_state,
      loading_cities,
      states,
      cities,
      loading,
      // cpfError,
      birthdayError,
      countries,
      countryError,
    } = this.state;
    // const { _id: userId, name, surname, cpf, birthday, state, city } = user;
    const { _id: userId, name, surname, birthday, state, city, country } = user;

    return (
      <ActivityMonitor userId={userId}>
        <div className="datepicker-style">
          <Header />
          <Col xs="12" className="mtop30">
            {loading_countries ? (
              <ReactLoading type="bubbles" color="#B1CD49" />
            ) : (
              <>
                <div className="card-shadow">
                  <p className="card-title">cadastro</p>

                  <div className="box-row-title">
                    <div className="box-alert">
                      Para finalizar seu cadastro preencha as informações
                      obrigatórias abaixo:
                    </div>
                  </div>

                  <input
                    type="text"
                    placeholder="NOME"
                    value={name}
                    className="cool-input"
                    onChange={e =>
                      this.setState({ user: { ...user, name: e.target.value } })
                    }
                  />

                  <input
                    type="text"
                    placeholder="SOBRENOME"
                    value={surname}
                    className="cool-input"
                    onChange={e =>
                      this.setState({
                        user: { ...user, surname: e.target.value },
                      })
                    }
                  />
                  {/* <InputError error={cpfError}>
                <InputMask
                  mask="999.999.999-99"
                  value={cpf}
                  onChange={e =>
                    this.setState({
                      user: { ...user, cpf: e.target.value.replace(/\D/g, '') },
                      cpfError: '',
                    })
                  }
                >
                  {inputProps => (
                    <input
                      type="text"
                      placeholder="CPF"
                      className="cool-input"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </InputError> */}
                  <InputError error={birthdayError}>
                    <DatePicker
                      selected={birthday}
                      minDate={subYears(startOfToday(), 150)}
                      maxDate={startOfToday()}
                      onChange={e =>
                        this.setState({
                          user: { ...user, birthday: e },
                          birthdayError: '',
                        })
                      }
                      onChangeRaw={event => {
                        if (
                          event.target.value &&
                          event.target.value === '__/__/____'
                        ) {
                          this.setState({
                            user: { ...user, birthday: '' },
                            birthdayError: '',
                          });
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DATA DE NASCIMENTO"
                      className="cool-input"
                      customInput={<InputMask mask="99/99/9999" />}
                    />
                  </InputError>

                  {loading_countries ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <InputError error={countryError}>
                      <select
                        value={country}
                        className={`cool-input ${!country && `small-option`}`}
                        onChange={e => {
                          this.loadStates(e.target.value);
                          this.setState({
                            user: { ...user, country: e.target.value },
                          });
                        }}
                      >
                        <option value="">PAÍS</option>
                        {countries.map((countryItem, key) => {
                          return (
                            <option key={key} value={countryItem.name}>
                              {' '}
                              &nbsp; {countryItem.name}
                            </option>
                          );
                        })}
                      </select>
                    </InputError>
                  )}

                  {loading_state ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <select
                      value={state}
                      className={`cool-input ${!state && `small-option`}`}
                      onChange={e => this.loadCities(e.target.value, country)}
                    >
                      <option value="">ESTADO</option>
                      {states.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={item.sigla_uf ? item.sigla_uf : item.name}
                          >
                            {' '}
                            &nbsp; {item.nome_uf ? item.nome_uf : item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}

                  {loading_cities ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <select
                      value={city}
                      className={`cool-input ${!city && `small-option`}`}
                      onChange={e =>
                        this.setState({
                          user: { ...user, city: e.target.value },
                        })
                      }
                      style={{ marginBottom: 30 }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          this.save();
                        }
                      }}
                    >
                      <option value="">CIDADE</option>
                      {cities.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={
                              item.nome_municipio
                                ? item.nome_municipio
                                : item.name
                            }
                          >
                            {' '}
                            &nbsp;{' '}
                            {item.nome_municipio
                              ? item.nome_municipio
                              : item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
                <div className="container-bt-confirm">
                  {loading ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <div
                      onClick={() => {
                        this.save();
                      }}
                      className={
                        !this.formValid()
                          ? 'bt-confirm hover-shadow'
                          : 'bt-confirm hover-shadow avaiable'
                      }
                    >
                      <p className="bt-label-o">Cadastrar</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </Col>
          <ModalAlert />
        </div>
      </ActivityMonitor>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterCompletion)
);
