import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import CardGenericTable from './../components/CardGenericTable';
import NotAllowed from './../components/NotAllowed';
import Footer from './../components/Footer';
import ModalContents from './Session/ModalContents';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import NewModalCoacheeDelete from './../components/NewModalCoacheeDelete';
import Submenu from './Groups/Submenu';

class Coachees extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      subview: 'coachee',
      side: 'coachees',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Coachees', link: 'coachees' },
      ],
      showToolTip: false,
      selectedCoachee: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      rows: [],
      title_rows: [
        { title: 'Cliente', key: 'name' },
        { title: 'Email', key: 'email' },
        { title: 'Telefone', key: 'phone' },
        { title: 'Endereço', key: 'address' },
        { title: 'Data de nascimento', key: 'birthday' },
        { title: 'Status', key: 'status' },
        { title: 'Ação', key: 'options' },
      ],
      actions: [
        // {
        //   title: "Editar",
        //   svg: {
        //     icon: require("./../../assets/images/tables/edit.svg"),
        //     width: 18
        //   },
        //   action: this.edit.bind(this)
        // },
        {
          title: 'Compartilhar Conteúdo',
          svg: {
            icon: require('./../../assets/images/tables/share_content.svg'),
            width: 17,
          },
          action: this.openContents.bind(this),
        },
        {
          title: 'Reenviar Convite',
          svg: {
            icon: require('./../../assets/images/tables/follow.svg'),
            width: 22,
          },
          action: this.invite.bind(this),
        },
        {
          title: 'Sessão de coaching',
          svg: {
            icon: require('./../../assets/images/tables/session.svg'),
            width: 24,
          },
          action: this.openMaster.bind(this),
        },
        {
          title: 'Financeiro',
          svg: {
            icon: require('./../../assets/images/tables/finance.svg'),
            width: 17,
          },
          action: this.openFinance.bind(this),
        },
        {
          title: 'Relatórios',
          svg: {
            icon: require('./../../assets/images/tables/printer.svg'),
            width: 17,
          },
          action: this.openReports.bind(this),
        },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.removeCoachee.bind(this),
        },
      ],
      showModalContents: false,
      contentList: [],
      selectedContents: [],
      zoom: '',
      sortField: '',
      sort2: '',
      new_modal_coachee_delete: false,
      content_share: [],
      nameCoachee: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCoachees();
    this.loadContents();
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      // this.setState({ zoom: "zoomresolve" })
    }, 10000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCoachees() {
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ rows: result.coachees });
    }
  }

  async removeCoachee(item) {
    await this.setState({
      content_share: item,
      nameCoachee: item.name ? item.name : '',
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    let result = await this.service.removeCoachee(this.state.content_share._id);
    if (result && result.success) {
      this.loadCoachees();
      this.props.updateAlert('Deletado com sucesso');
    }
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      new_modal_coachee_delete: !this.state.new_modal_coachee_delete,
    });
  }

  edit(item) {
    this.setSidebarLinks(`coachees/create/${item._id}`);
  }

  testeLink = () => {
    console.log(`LINK`);
  };

  async invite(item) {
    if (
      (this.state.user &&
        this.state.user.plans &&
        !this.state.user.plans.platform) ||
      (this.state.user &&
        this.state.user.plans &&
        this.state.user.plans.platform &&
        !this.state.user.plans.platform.plan.use_area_coachee)
    ) {
      this.props.updateAlert(
        <div>
          <p>
            {' '}
            Você não possui acesso à essa funcionalidade, atualize seu plano
            para continuar.{' '}
          </p>
          <br />{' '}
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    } else {
      let result = await this.service.inviteCoachee(item._id);
      if (result && result.success) {
        this.props.updateAlert(
          <div>
            Convite enviado com sucesso,
            <br /> o link de acesso do Coachee é: <br />
            <div style={{ fontSize: 12 }}>
              <br /> {result.link}{' '}
            </div>
          </div>
        );
      } else {
        this.props.updateAlert(
          <div>
            Erro ao enviar convite,
            <br /> o link de acesso do Coachee é: <br />
            <div style={{ fontSize: 12 }}>
              <br /> {result.link}{' '}
            </div>
          </div>
        );
      }
    }
  }

  async toggleSwitch(key) {
    if (
      (this.state.user &&
        this.state.user.plans &&
        !this.state.user.plans.platform) ||
      (this.state.user &&
        this.state.user.plans &&
        this.state.user.plans.platform &&
        parseInt(this.state.user.plans.platform.plan.active_coachees_size) <=
          this.state.rows.filter(item => {
            return item.status == 'true';
          }).length &&
        this.state.rows[key].status != 'true' &&
        this.state.user.plans.platform.plan.active_coachees_size.toLowerCase() !=
          'ilimitado')
    ) {
      this.props.updateAlert(
        <div>
          <p>
            Você atingiu o limite máximo de coachee ativos permitidos para o seu
            plano, aumente seu plano ou desative um coachee para continuar..
          </p>
          <br />
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    } else {
      let rows = this.state.rows;
      rows[key].status = rows[key].status == 'true' ? 'false' : 'true';
      this.setState({ rows });
      let result = await this.service.updateCoachee(
        { status: rows[key].status },
        rows[key]._id
      );
      if (result && result.success) {
        this.loadCoachees();
      }
    }
  }

  async openMaster(coachee) {
    const session = await this.verifyCreateCoacheeSession(coachee._id);
    this.goTo(`dashboard/programs/coaching/edit/${session._id}`);
  }

  openFinance(coachee) {
    this.goTo(`dashboard/finances`);
  }

  openReports(coachee) {
    this.goTo(`dashboard/reports`);
  }

  openContents(coachee) {
    this.setState({ selectedCoachee: coachee }, () => {
      this.toggleModalContent();
    });
  }

  async loadContents() {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents });
    }
    this.setState({ loading_contents: false });
  }

  toggleContent = selectedItem => {
    let selectedContents = [...this.state.selectedContents];
    const isContentSelected = selectedContents.find(
      content => content._id === selectedItem._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== selectedItem._id
      );
    } else {
      selectedContents.push(selectedItem);
    }
    this.setState({ selectedContents });
  };

  toggleModalContent = () => {
    const { showModalContents } = this.state;
    this.setState({ showModalContents: !showModalContents });
  };

  verifyCreateCoacheeSession(coachee_id) {
    return this.service.verifyCoachee({ coachee_id });
  }

  share = async () => {
    const { selectedContents, selectedCoachee: coachee } = this.state;
    const contentIds = selectedContents.map(content => content._id);
    if (coachee && contentIds.length > 0) {
      let session = await this.verifyCreateCoacheeSession(coachee._id);

      if (session) {
        const result = await this.service.storeSessionContents(
          session._id,
          'session',
          [coachee._id],
          contentIds
        );

        if (result && result.success) {
          this.props.updateAlert('Compartilhado com sucesso');
        }
      }
    }

    this.toggleModalContent();
  };

  sort(key) {
    let order =
      this.state.sortField == '' || this.state.sortField == key ? '-down' : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sortField: `${key}${order}`, contentList });
  }

  sort2(key) {
    let order =
      this.state.sort2 == '' || this.state.sort2 == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort2: `${key}${order}`, rows });
  }

  render() {
    return (
      <div className="main-content zoomresolve">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter with-margin-left">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <Submenu
                  goTo={this.goTo}
                  subview={this.state.subview}
                  user={this.state.user}
                />

                {this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform ? (
                  <NotAllowed
                    go={this.goTo.bind(this)}
                    user={this.state.user}
                  />
                ) : (
                  <div>
                    <div className="dashboard-boxes zoomed dashboard-boxes-with-margin-left">
                      {/* Cards */}
                      <CardGenericTable
                        title="Coachees"
                        id="coachees-coachees-1"
                        sort={this.sort2.bind(this)}
                        _sort={this.state.sort2}
                        actions={this.state.actions}
                        title_rows={this.state.title_rows}
                        rows={this.state.rows}
                        toggleSwitch={this.toggleSwitch}
                        edit={this.edit.bind(this)}
                        campoImagem={true}
                        campoNome={true}
                        campoEmail={true}
                      />
                    </div>
                    <div className="row-start">
                      <div className="row-button">
                        <div
                          className="bt-blue margin-left-25"
                          onClick={() => {
                            // Abaixo condição para saber se o cliente pode ou não criar mais COACHEES
                            /* if( this.state.user
                                && this.state.user.plans
                                && !this.state.user.plans.platform
                                || this.state.user
                                && this.state.user.plans
                                && this.state.user.plans.platform
                                && this.state.user.plans.platform.plan.size_coachee  ){
                                  this.props.updateAlert(<div><p>Você atingiu o limite máximo de cadastros permitidos para o seu plano, aumente seu plano ou remova um coachee para continuar"</p><br/><div className="bt-blue" style={{ margin:'0 auto' }}  onClick={ () =>{ this.goTo('dashboard/profile/plans')  } }>Alterar plano</div></div>)
                                }else{*/
                            this.setSidebarLinks('coachees/create');
                            //}
                          }}
                        >
                          <img
                            src={require('./../../assets/images/buttons/add_coachee.svg')}
                            style={{ width: 20, marginRight: 10 }}
                          />
                          Cadastrar Coachee
                        </div>
                      </div>
                      <div className="row-button">
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.setSidebarLinks('coachees/create_batch');
                          }}
                        >
                          <img
                            src={require('./../../assets/images/buttons/add_coachee.svg')}
                            style={{ width: 20, marginRight: 10 }}
                          />
                          Importar
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={this.state.sortField}
          show={this.state.showModalContents}
          contentList={this.state.contentList}
          selectedContents={this.state.selectedContents}
          toggleContent={this.toggleContent}
          share={this.share.bind(this)}
          toggleModalContent={this.toggleModalContent}
        />

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_coachee_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_coachee_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Deseja deletar: ${this.state.nameCoachee} ?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Coachees)
);
