import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import '../../styles/css/tutorial/styles.scss';

import { simpleAction } from '../../actions/SimpleAction';

import Utils from './../../libs/Utils.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
  }

  render() {
    const classMenu = this.props.principalOpen
      ? 'principal-menu pMenu closed'
      : 'principal-menu pMenu';
    return (
      <div className="principal-menu-responsible tutorial-styles">
        <div
          className={this.props.principalOpen ? 'bt-menu' : 'bt-menu active'}
          onClick={() => {
            this.props.togglePrincipal();
          }}
        >
          <i className="fas fa-bars"></i>
        </div>
        <ul
          className={classMenu}
          style={{
            width: this.props.menu
              ? 'calc(100vw - 55px)'
              : 'calc(100vw - 16.7%)',
          }}
        >
          <li
            onClick={() => {
              this.props.setSidebarLinks('proposals');
            }}
            className={this.props.view === 'proposals' ? 'active' : ''}
          >
            Propostas
          </li>
          <li
            onClick={() => {
              this.props.setSidebarLinks('contents');
            }}
            className={this.props.view === 'contents' ? 'active' : ''}
          >
            Conteúdo
            {this.props.simpleReducer.tutorial_status &&
              this.props.simpleReducer.tutorial_posicao >= 6 && (
                <span className="modalTutorial">4</span>
              )}
          </li>
          <li
            onClick={() => {
              this.props.setSidebarLinks('programs');
            }}
            className={this.props.view === 'programs' ? 'active' : ''}
          >
            Programa
            {this.props.simpleReducer.tutorial_status &&
              this.props.simpleReducer.tutorial_posicao >= 7 && (
                <span className="modalTutorial">5</span>
              )}
          </li>
          <li
            onClick={() => {
              this.props.setSidebarLinks('finances');
            }}
            className={this.props.view === 'finances' ? 'active' : ''}
          >
            Financeiro
          </li>
          <li
            onClick={() => {
              this.props.setSidebarLinks('reports');
            }}
            className={this.props.view === 'reports' ? 'active' : ''}
          >
            Relatórios Gerenciais
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
