import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import moment from 'moment/min/moment-with-locales';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class Template5 extends Component {
  constructor(props, context) {
    super(props);

    this.utils = new Utils();
    this.service = new Service();
    this.goTo = this.goTo.bind(this);
    this.state = {
      social: '',
    };
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  componentDidMount() {
    moment.locale('pt-BR');
    if (
      (this.props.state.facebook !== '' ||
        this.props.state.twitter !== '' ||
        this.props.state.instagram !== '' ||
        this.props.state.linkedin !== '' ||
        this.props.state.youtube !== '' ||
        this.props.state.google_plus !== '') &&
      (this.props.state.facebook !== null ||
        this.props.state.twitter !== null ||
        this.props.state.instagram !== null ||
        this.props.state.linkedin !== null ||
        this.props.state.youtube !== null ||
        this.props.state.google_plus !== null) &&
      (this.props.state.facebook !== undefined ||
        this.props.state.twitter !== undefined ||
        this.props.state.instagram !== undefined ||
        this.props.state.linkedin !== undefined ||
        this.props.state.youtube !== undefined ||
        this.props.state.google_plus !== undefined) &&
      (this.props.state.facebook.trim() ||
        this.props.state.twitter.trim() ||
        this.props.state.instagram.trim() ||
        this.props.state.linkedin.trim() ||
        this.props.state.youtube.trim() ||
        this.props.state.google_plus.trim())
    ) {
      this.setState({
        social: 'social',
      });
    }
  }

  componentWillUnmount() { }

  openSocial(uri) {
    window.open(uri, 'social');
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  render() {
    return (
      <div>
        {/* Start Loading */}
        <div className="loading">
          <div className="gooey">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {/* End Loading */}

        {/* Start Navbar */}
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            {/* Logo */}
            {this.props.state.logo !== null &&
              this.props.state.logo !== undefined &&
              this.props.state.logo.trim() !== '' ? (
                <a className="logo">
                  <img
                    src={this.resolveImage(this.props.state.logo)}
                    alt="logo"
                  />
                </a>
              ) : (
                ''
              )}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            {/* navbar links */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link active" data-scroll-nav="0">
                    <AnchorLink href="#home">Home</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="1">
                    <AnchorLink href="#sobremim">Sobre mim</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="2">
                    <AnchorLink href="#experiencia">Experiência</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="3">
                    <AnchorLink href="#certificacoes">Certificações</AnchorLink>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-scroll-nav="4">
                    <AnchorLink href="#referencias">Referências</AnchorLink>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* End Navbar */}

        {/* Start Header */}
        <header
          className="headerTop valign bg-img position-re"
          data-scroll-index="0"
          data-overlay-dark="4"
          data-stellar-background-ratio="0.5"
        >
          {/* <img src={header} alt="" /> */}
          <div id="home" className="container">
            <div className="row">
              <div className="full-width text-center caption mt-50">
                <h1 className="cd-headline push">
                  <span className="blc">{this.props.state.nomeCoach}</span>
                </h1>
                <div className={`${this.state.social} mt-30`}>
                  {this.props.state.facebook === '' ||
                    this.props.state.facebook === null ||
                    this.props.state.facebook === undefined ||
                    !this.props.state.facebook.trim() ? null : (
                      <a href={this.props.state.facebook} target="_blank">
                        <i className="icofont icofont-social-facebook"></i>
                      </a>
                    )}
                  {this.props.state.twitter === '' ||
                    this.props.state.twitter === null ||
                    this.props.state.twitter === undefined ||
                    !this.props.state.twitter.trim() ? null : (
                      <a href={this.props.state.twitter} target="_blank">
                        <i className="icofont icofont-social-twitter"></i>
                      </a>
                    )}
                  {this.props.state.instagram === '' ||
                    this.props.state.instagram === null ||
                    this.props.state.instagram === undefined ||
                    !this.props.state.instagram.trim() ? null : (
                      <a href={this.props.state.instagram} target="_blank">
                        <i className="icofont icofont-social-instagram"></i>
                      </a>
                    )}
                  {this.props.state.linkedin === '' ||
                    this.props.state.linkedin === null ||
                    this.props.state.linkedin === undefined ||
                    !this.props.state.linkedin.trim() ? null : (
                      <a href={this.props.state.linkedin} target="_blank">
                        <i className="icofont icofont-brand-linkedin"></i>
                      </a>
                    )}
                  {this.props.state.youtube === '' ||
                    this.props.state.youtube === null ||
                    this.props.state.youtube === undefined ||
                    !this.props.state.youtube.trim() ? null : (
                      <a href={this.props.state.youtube} target="_blank">
                        <i className="icofont icofont-social-youtube"></i>
                      </a>
                    )}
                  {this.props.state.google_plus === '' ||
                    this.props.state.google_plus === null ||
                    this.props.state.google_plus === undefined ||
                    !this.props.state.google_plus.trim() ? null : (
                      <a href={this.props.state.google_plus} target="_blank">
                        <i className="icofont icofont-social-google-plus"></i>
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* End Header */}

        {/* Start sobremim */}
        <section
          id="sobremim"
          className="hero section-padding position-re"
          data-scroll-index="1"
        >
          <div className="container">
            <div className="row">
              <div className="section-head text-center col-md-12">
                <h4>Sobre mim</h4>
              </div>
              <div className="col-lg-5">
                {this.props.state.photo !== null &&
                  this.props.state.photo !== undefined &&
                  this.props.state.photo.trim() !== '' ? (
                    <div className="hero-img">
                      <img
                        src={this.resolveImage(this.props.state.photo)}
                        alt=""
                      />
                    </div>
                  ) : (
                    ''
                  )}
              </div>
              <div className="col-lg-6">
                <div className="content">
                  <h4>Sobre mim</h4>
                  <p className="mb-10 justText">{this.props.state.about_me}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End sobremim */}

        {/* Start experiencia */}
        <section
          id="experiencia"
          className="hero services section-padding bg-dark position-re"
          data-scroll-index="2"
        >
          <div className="container">
            <div className="row">
              <div className="section-head text-center col-md-12">
                <h4>Experiência</h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="content">
                  <h4>Experiência</h4>
                  <p className="justText">{this.props.state.experience}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End experiencia */}

        {/* Start certificacoes */}
        <section
          id="certificacoes"
          className="hero section-padding position-re"
          data-scroll-index="1"
        >
          <div className="container">
            <div className="row">
              <div className="section-head text-center col-md-12">
                <h4>Certificações</h4>
              </div>
              <div className="col-lg-12">
                <div className="content">
                  <h4>Certificações</h4>
                  <p className="mb-10 justText">
                    {this.props.state.certifications}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End certificacoes */}

        {/* Start referencias */}
        <section
          id="referencias"
          className="hero services section-padding bg-dark position-re"
          data-scroll-index="2"
        >
          <div className="container">
            <div className="row">
              <div className="section-head text-center col-md-12">
                <h4>Referências</h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="content">
                  <h4>Referências</h4>
                  <p className="justText">{this.props.state.references}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End referencias */}

        {/* Start Footer */}
        <footer className="text-center bg-dark position-re">
          <div className="container">
            {/* Logo */}
            <a className="logo">
              <img src={this.resolveImage(this.props.state.logo)} alt="logo" />
            </a>
            <div className="social">
              {this.props.state.facebook === '' ||
                this.props.state.facebook === null ||
                this.props.state.facebook === undefined ||
                !this.props.state.facebook.trim() ? null : (
                  <a href={this.props.state.facebook} target="_blank">
                    <i className="icofont icofont-social-facebook"></i>
                  </a>
                )}
              {this.props.state.twitter === '' ||
                this.props.state.twitter === null ||
                this.props.state.twitter === undefined ||
                !this.props.state.twitter.trim() ? null : (
                  <a href={this.props.state.twitter} target="_blank">
                    <i className="icofont icofont-social-twitter"></i>
                  </a>
                )}
              {this.props.state.instagram === '' ||
                this.props.state.instagram === null ||
                this.props.state.instagram === undefined ||
                !this.props.state.instagram.trim() ? null : (
                  <a href={this.props.state.instagram} target="_blank">
                    <i className="icofont icofont-social-instagram"></i>
                  </a>
                )}
              {this.props.state.linkedin === '' ||
                this.props.state.linkedin === null ||
                this.props.state.linkedin === undefined ||
                !this.props.state.linkedin.trim() ? null : (
                  <a href={this.props.state.linkedin} target="_blank">
                    <i className="icofont icofont-brand-linkedin"></i>
                  </a>
                )}
              {this.props.state.youtube === '' ||
                this.props.state.youtube === null ||
                this.props.state.youtube === undefined ||
                !this.props.state.youtube.trim() ? null : (
                  <a href={this.props.state.youtube} target="_blank">
                    <i className="icofont icofont-social-youtube"></i>
                  </a>
                )}
              {this.props.state.google_plus === '' ||
                this.props.state.google_plus === null ||
                this.props.state.google_plus === undefined ||
                !this.props.state.google_plus.trim() ? null : (
                  <a href={this.props.state.google_plus} target="_blank">
                    <i className="icofont icofont-social-google-plus"></i>
                  </a>
                )}
            </div>
            <p>
              &copy; 2019 {this.props.state.nomeCoach}. All Rights Reserved.
            </p>
          </div>
        </footer>
        {/* End Footer */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Template5)
);
