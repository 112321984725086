import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css'; 

import { simpleAction } from '../../actions/SimpleAction'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Utils from './../../libs/Utils.js';

import { AreaChart } from 'react-easy-chart';  

class BoxOptions extends Component { 

  constructor(props) {
    super(props);  
    this.state = { }
    this.utils = new Utils(); 
  }

  componentDidMount(){  
  }

  componentWillUnmount() {  
  }  
  
  render() {  
    return (  
      <div className="box-options">
        { !this.props.visible ? 
          <i className="far fa-window-restore" style={{ fontSize:17, color:'#3a838f', cursor:'pointer' }}
               onClick={ () => { this.props.toggleVisibility() } } title="Minimizar"></i>
        :
          <img src={ require('./../../assets/images/dashboard/i2.svg') } className="box-options-image f" 
               onClick={ () => { this.props.toggleVisibility() } } title="Maximizar" />
        }
        
        {/*<img src={ require('./../../assets/images/dashboard/i3.svg') } className="box-options-image" />*/}
      </div>      
    );
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(BoxOptions);