import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Service from './Service.js';

import { updateImage, updateUser } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../../sidebar/Sidebar';
import CardGenericTable from './../../../screens_dashboard/components/CardGenericTable';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import Footer from './../../components/Footer';

import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';

class Form extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'plan',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Planos', link: 'plans' },
        { title: 'Cadastrar Plano', link: 'plans/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [
        { label: 'Título', key: 'title', type: 'text' },
        { label: 'Descrição', key: 'description', type: 'text' },
        { label: 'Texto Descrição Landpage', key: 'text', type: 'text' },
        { label: 'Valor', key: 'value', type: 'money-mask' },
        {
          label: 'Valor antes do desconto',
          key: 'value_full',
          type: 'money-mask',
        },
        {
          label: 'Tipo',
          key: 'type',
          type: 'select',
          options: [
            { _id: 'seacher', name: 'Buscador' },
            { _id: 'platform', name: 'Plataforma' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'name',
        },
        {
          label: 'Periodicidade',
          key: 'periodice',
          type: 'select',
          options: [
            { _id: '1m', name: 'Mensal' },
            { _id: '12m', name: 'Anual' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'name',
        },
        {
          label: 'Corner Ativado',
          offlabel: 'Corner Desativado',
          key: 'corner',
          type: 'checkbox',
        },
        {
          label: 'Mais usado',
          offlabel: 'Comum',
          key: 'most_used',
          type: 'checkbox',
        },
        {
          label: 'Identificador do plano (Checkout)',
          key: 'iugu_plan_identifier',
          type: 'text',
        },
        {
          label: 'Adicionar detalhe ao plano',
          title: 'Detalhes do plano',
          key: 'details',
          type: 'array-text',
          option: 'title',
        },

        /// Regras de negócio aplicaveis aos planos

        {
          label: 'Quantidade de cadastros',
          key: 'size',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Quantidade de propostas por cliente',
          key: 'size_per_client',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Quantidade de armazenamento (Mb)',
          key: 'size_storage',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Quantidade de coachees',
          key: 'size_coachee',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Quantidade de notas fiscais',
          key: 'size_invoice',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Listagem no Buscador',
          offlabel: 'Listagem no Buscador',
          key: 'searcher_list',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Destaque no Buscador',
          offlabel: 'Destaque no Buscador',
          key: 'searcher_spotlight',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Criação de website personalizado',
          offlabel: 'Criação de website personalizado',
          key: 'marketing_site',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Criação de Mensagens personalizadas',
          offlabel: 'Criação de Mensagens personalizadas',
          key: 'marketing_messages',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Gestão de oportunidades e propostas',
          offlabel: 'Gestão de oportunidades e propostas',
          key: 'use_oportunities_proposals',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Quantidade máxima de modelos de propostas',
          key: 'maximum_proposal_models',
          type: 'text',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Quantidade máxima de oportunidades gerenciadas',
          key: 'maximum_oportunities',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: `Quantidade máxima de conteúdos importados para "minha area" do Coach"`,
          key: 'contents_size',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Customização e Edição de Conteúdos',
          offlabel: 'Customização e Edição de Conteúdos',
          key: 'use_customize_contents',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Atendimento de suporte e central de ajuda',
          key: 'support_type',
          type: 'select',
          options: [
            { _id: 'web', name: 'website/chat' },
            { _id: 'web-phone', name: 'website/chat/telefone' },
          ],
          loading: false,
          opt_key: '_id',
          opt_value: 'name',
        },

        {
          label: 'Número de coachees ativos',
          key: 'active_coachees_size',
          type: 'text',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Planejamento do Programa de Coaching',
          offlabel: 'Planejamento do Programa de Coaching',
          key: 'use_program_planning',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Registro de sessões de Coaching',
          offlabel: 'Registro de sessões de Coaching',
          key: 'use_program_register',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Compartilhamento de agenda com coachee',
          offlabel: 'Compartilhamento de agenda com coachee',
          key: 'use_share_calendar',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Integração da agenda com Google',
          offlabel: 'Integração da agenda com Google',
          key: 'use_google_calendar',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Emissão de relatório de sessão de Coaching',
          offlabel: 'Emissão de relatório de sessão de Coaching',
          key: 'use_reports_coaching',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Troca de Mensagens com Coachee',
          offlabel: 'Troca de Mensagens com Coachee',
          key: 'use_chat',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Área de acesso exclusiva do Coachee',
          offlabel: 'Área de acesso exclusiva do Coachee',
          key: 'use_area_coachee',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Gestão de fluxo de caixa',
          offlabel: 'Gestão de fluxo de caixa',
          key: 'use_finance',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Acesso a área de Relatórios Gerenciais',
          offlabel: 'Acesso a área de Relatórios Gerenciais',
          key: 'use_reports',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Sessões em grupo',
          offlabel: 'Sessões em grupo',
          key: 'group_sessions',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        {
          label: 'Tempo permitido para o período de teste (em dias)',
          key: 'test_time',
          type: 'number',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Área liberada para período de testes',
          offlabel: 'Área liberada para período de testes',
          key: 'test_area',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Minimo de avaliações necessárias para aparecer na busca',
          key: 'min_rate',
          type: 'number',
          hide: { key: 'type', value: 'platform' },
        },

        {
          label: 'Acesso a área do Dashboard',
          offlabel: 'Acesso a área do Dashboard',
          key: 'dashboard_area',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },
        {
          label: 'Acesso a área da Agenda',
          offlabel: 'Acesso a área da Agenda',
          key: 'schedule_area',
          type: 'checkbox',
          hide: { key: 'type', value: 'seacher' },
        },

        // { label:"Senha", key:'password', type:'password'},
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Plano',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.removePassword();
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      this.setState({ content: result[this.state.view] });
    }
    this.setState({ loading_contents: false });
  }

  async save() {
    this.setState({ loading: true });
    let _pay = { ...this.state.content };

    _pay['details'] = JSON.stringify(_pay.details);
    _pay['value'] = _pay.value ? _pay.value : 'R$ 0,00';
    _pay['value_full'] = _pay.value_full ? _pay.value_full : 'R$ 0,00';

    let result = this.state.editing
      ? await this.service.update(_pay, this.state.editing_id)
      : await this.service.create(_pay);

    if (result && result.success) {
      this.setSidebarLinks(this.state.breadcrumbs[1].link);
    }
    this.setState({ loading: false });
  }

  removePassword() {
    let items = this.state.items;
    for (let _k in items) {
      if (items[_k].type == 'password') {
        items.splice(_k, 1);
      }
    }
    this.setState({ items });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                    />

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
